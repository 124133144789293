import { Pipe, PipeTransform } from '@angular/core';
import { ShipStation_Shipments } from 'src/app/models/shipstation/shipstation-shipment';
import { Shipstation_weight } from 'src/app/models/shipstation/shipstation-weight';

@Pipe({
  name: 'poidsEnLivres'
})

/**
 * Ce pipe prend un Shipment shipstation en param, va chercher sa props 'Weight' (si existe), et retourne le POIDS
 */
export class PoidsEnLivresPipe implements PipeTransform {

  transform(myShipment: ShipStation_Shipments): string {
    let valeur: number = 0;

    if (!myShipment || !myShipment.weight || myShipment.weight === undefined)
      return "";

    //on va prendre les 3 premier chars de l'unité du poids, pour s'éviter les soucis de plurieurs/singulier (qui semblait arriver ...)
    let myUnit = myShipment.weight.units.toLowerCase().substring(0,3);

    // Conversion en fonction de l'unité de départ
    switch (myUnit) {
      case 'pou': //pounds, pound
        valeur = myShipment.weight.value;
        break;
      case 'gra': //grams, gram
        valeur = myShipment.weight.value * 0.0022046244;
        break;
      case 'oun': //ounce, ounces
        valeur = myShipment.weight.value * 0.0625;
        break;
    }

    // Arrondi à 2 décimales
    let chaine = valeur.toFixed(2);
    // Élimine les zéros et points en fin de string
    chaine = chaine.replace(/[.0]+$/, '');
    chaine += ' lb.';

    return chaine;
  }

}
