import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DVLOrder } from 'src/app/models/DVL/DVLOrder';
import { NumericKeyboard } from 'src/app/models/numericKeyboard';
import { AuthService } from 'src/app/service/auth.service';
import { DVLService } from 'src/app/service/DVL.service';
// import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-dialog-selection-dvl',
  templateUrl: './dialog-selection-dvl.component.html',
  styleUrls: ['./dialog-selection-dvl.component.scss'],
})
export class DialogSelectionDvlComponent implements OnInit {
  ListeDVL: DVLOrder[];
  DVLSelectionnee: DVLOrder;

  // Filtres
  FiltrerParQuantiteMax: boolean = false;
  QuantiteMax: number = 1;
  MasquerLesEnTraitement: boolean = true;
  SeulementLesReadyToGo: boolean = true;
  MasquerLesTermines: boolean = true;

  // clavier numérique
  numericKeyboard: NumericKeyboard;

  Loading: boolean = false;
  RechercheEffectuee: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogSelectionDvlComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public matDialog: MatDialog,
    private dvlService: DVLService,
    public authService: AuthService
  ) // private loaderService: LoaderService,
  {}

  ngOnInit(): void {
    // this.RefreshDVLShown();
  }

  async RefreshDVLShown() {
    // Quantité max à zéro si on ne veut pas filtrer par quantité
    let qteMax = this.QuantiteMax;
    if (!this.FiltrerParQuantiteMax) qteMax = 0;

    // this.loaderService.show(); // on affiche le loader
    this.Loading = true;

    let tmpListe = await this.dvlService
      .GetDVL(this.data.productNumber, qteMax, this.SeulementLesReadyToGo, this.MasquerLesEnTraitement, this.MasquerLesTermines)
      .toPromise();
    this.ListeDVL = [];
    this.ListeDVL = this.ListeDVL.concat(tmpListe);
    // this.loaderService.hide(); // on cache le loader
    this.Loading = false;
  }

  /**
   * Clic sur le bouton "Rechercher"
   */
  onRefreshGridButton() {
    this.RechercheEffectuee = true;
    this.RefreshDVLShown();
  }

  /**
   * Clic sur une des DVL trouvées
   */
  onSelectDVL(row) {
    this.DVLSelectionnee = row;
  }

  /**
   * Permet de fermer le popup.
   */
  onAbortClick(): void {
    this.dialogRef.close(null);
  }
  onConfirmClick(): void {
    this.dialogRef.close(this.DVLSelectionnee);
  }

  openNumericKeyboard(): void {
    this.numericKeyboard.open(this.QuantiteMax.toString());
  }

  scanQte_AddOne(): void {
    this.QuantiteMax++;
  }
  scanQte_RemoveOne(): void {
    this.QuantiteMax--;
  }
}
