import { Injectable, ModuleWithProviders } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DVLOrder } from '../models/DVL/DVLOrder';
import { enumItemTraceExpedition_Statut_For_API } from '../models/enum/enumItemTraceExpedition_Statut';
import { ItemExpeditionTrace } from '../models/DVL/itemExpeditionTrace';
import { APIPostProblem } from '../models/API/APIPostProblem';
import { ShipStation_ShippingRatesData } from '../models/shipstation/shipstation-shipping-rates-data';
import { ShipStation_Orders } from '../models/shipstation/shipstation-order';
import { Shipstation_rate } from '../models/shipstation/shipstation-rate';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DVLService {
  constructor(private httpClient: HttpClient, private dialog: MatDialog) {}

  /**
   * Effectue un GET API pour récupérer un sku à partir du code barre
   * @param upc code barre
   */
  GetDVL(
    paramsku: string,
    paramQteMax: number = 0,
    paramOnlyReadyToGo: boolean = true,
    paramSkipFlagEnTraitementPieuvre: boolean = true,
    paramSkipFlagCompleted: boolean = true,
    paramPicklistPriority: boolean = true
  ): Observable<DVLOrder> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    //{{API-URL}}/api/OrderDetailsView?$filter=sku_Cleaned eq '1874564' and SS_Tag_ready_to_go eq true&$orderby=SS_Tag_prioritaire desc&$orderby=OrderDate
    let fullURI = `${environment.apiUrl}OrderDetailsView?$filter=sku_Cleaned eq '${paramsku}'`;

    // Si on veut limiter la quantité
    if (paramQteMax > 0) fullURI += ` and FinalQty le ${paramQteMax}`;

    //si on ne veut QUE les ready to go...
    if (paramOnlyReadyToGo) fullURI += ' and SS_Tag_ready_to_go eq true';

    //si on ne veut pas les 'en traitement pieuvre...
    if (paramSkipFlagEnTraitementPieuvre) fullURI += ' and SS_Tag_EnTraitementPieuvre eq false';

    //si on ne veut pas les 'complété'...
    if (paramSkipFlagCompleted) fullURI += ' and SS_Tag_completed eq false';

    if (paramPicklistPriority) fullURI += '&$orderby=SS_Tag_Picklist desc';

    fullURI += '&$orderby=SS_Tag_prioritaire desc&$orderby=OrderDate';

    //le endpoint est ODATA maintenant..donc on doit lire en conséquence
    return this.httpClient.get(fullURI).pipe(
      map((result: any) => {
        var MyValue = result.value;
        return MyValue;
      })
    );
  }

  Scan2Emballeur_EntamerTraitementDVL(SiteOrderID: string, ProductNumber: string): Observable<any> {
    let url = environment.apiUrl + 'Expedition/Scan2Emballeur_EntamerTraitementDVL';
    url += '?SiteOrderID=' + SiteOrderID;
    url += '&ProductNumber=' + ProductNumber;
    return this.httpClient.post(url, {});
  }

  Scan2Emballeur_AnnulerTraitementDVL(SiteOrderID: string, problemCode: string, ItemExpeditionTraceID: number = -1): Observable<any> {
    let url = environment.apiUrl + 'Expedition/Scan2Emballeur_AnnulerTraitementDVL';
    url += '?SiteOrderID=' + SiteOrderID;
    url += '&Param_problemCode=' + problemCode;
    if (ItemExpeditionTraceID !== -1) url += '&ItemExpeditionTraceID=' + ItemExpeditionTraceID;
    return this.httpClient.post(url, {});
  }

  /**
   * Cette méthode permet d'avoir uin accès unique centrale et 'méthode agnostique' pour imprimer une DVL.
   * Va aussi (et surtout) incrémenter le comtpeur D'impression, et créer un auditLog pour l'imrpession
   * @param SiteOrderID
   * @param ItemExpeditionTraceID
   * @returns
   */
  Scan2Emballeur_ImpressionShipstationLabel(SiteOrderID: string, ItemExpeditionTraceID: number): Observable<any> {
    let url = environment.apiUrl + 'Expedition/Scan2Emballeur_ImpressionShipstationLabel';
    url += '?SiteOrderID=' + SiteOrderID;
    url += '&ItemExpeditionTraceID=' + ItemExpeditionTraceID;
    return this.httpClient.post(url, {});
  }

  Scan2Emballeur_CompleteDVL(SiteOrderID: string, paramForceClose: boolean): Observable<any> {
    let url = environment.apiUrl + 'Expedition/Scan2Emballeur_CompleteDVL';
    url += '?SiteOrderID=' + SiteOrderID;
    url += '&paramForceClose=' + paramForceClose;
    return this.httpClient.post(url, {});
  }

  RefreshOrders_ReadyToGo(): Observable<any> {
    let url = environment.apiUrl + 'shipStationOrders/RefreshOrders_ReadyToGo';
    return this.httpClient.post(url, {});
  }

  /**
   * Retourne le PDF à imprimer
   * @param shipment shipment en cours
   * @param type type de doc demandé (label, packinglist)
   */
  Scan2GetShippingLabelPDF(CurrentShipStationShipmentID: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + 'Expedition/Scan2GetShippingLabelPDF?', {
      params: { ShipmentID: CurrentShipStationShipmentID },
      responseType: 'arraybuffer',
    });
  }

  /**
   * Retourne le PDF à imprimer
   * @param shipment shipment en cours
   * @param type type de doc demandé (label, packinglist)
   */
  GetPDFLabelCrawlerMode(CurrentShipStationShipmentID: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + 'Expedition/GetPDFLabelCrawlerMode?', {
      params: { ShipmentID: CurrentShipStationShipmentID },
      responseType: 'arraybuffer',
    });
  }

  /**
   * Permet d'aller chercher les traces d'expéditions
   * @param ProductNumber
   * @returns
   */
  GetExpeditionTraceByProduct(
    ProductNumber: string,
    ListOfStatus: enumItemTraceExpedition_Statut_For_API[] = [
      enumItemTraceExpedition_Statut_For_API.SCAN1_PENDING,
      enumItemTraceExpedition_Statut_For_API.WORKING,
    ]
  ): Observable<ItemExpeditionTrace[]> {
    //{{API-URL}}/api/Expedition/GetItemExpeditionTrace?$filter=SCAN_ProductNumber eq '6431545' and (false or SCAN_ProcessingStatus eq mdpweb_SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'PENDING' or SCAN_ProcessingStatus eq mdpweb_SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'WORKING')
    var MyURL = environment.apiUrl + 'Expedition/GetItemExpeditionTrace?';
    MyURL += "$filter=SCAN_ProductNumber eq '" + ProductNumber + "' ";

    if (ListOfStatus && ListOfStatus.length > 0) {
      MyURL += ' and ( false ';
      ListOfStatus.forEach((element) => {
        MyURL += ' or SCAN_ProcessingStatus eq ' + element; //c'est comme ça qu'on mesure sur un ENUM...
      });
      MyURL += '      )';
    }

    return this.httpClient.get<ItemExpeditionTrace[]>(MyURL, {});
  }

  /**
   * Permet d'aller chercher les traces d'expéditions
   * @param ProductNumber
   * @returns
   */
  GetExpeditionTraceBySiteOrderID(SiteOrderID: string): Observable<ItemExpeditionTrace[]> {
    //{{API-URL}}/api/Expedition/GetItemExpeditionTrace?$filter=SCAN_ProductNumber eq '6431545' and (false or SCAN_ProcessingStatus eq mdpweb_SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'PENDING' or SCAN_ProcessingStatus eq mdpweb_SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'WORKING')
    var MyURL = environment.apiUrl + 'Expedition/GetItemExpeditionTrace?';
    MyURL += "$filter=DVL_SiteOrderID eq '" + SiteOrderID + "' ";

    return this.httpClient.get<ItemExpeditionTrace[]>(MyURL, {});
  }

  PostProblemeScan2Emballage(data: APIPostProblem): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.post(environment.apiUrl + 'Expedition/ProblemeEmballage', data);
  }

  GetAllTestOrders(): Observable<ShipStation_Orders[]> {
    let fullURI = `${environment.apiUrl}shipStationOrders/GetAllTestOrders?$expand=items,dimensions,weight,shipTo`;

    return this.httpClient.get<ShipStation_Orders[]>(fullURI);
  }

  GetAllOrdersAwaitingShipment(): Observable<ShipStation_Orders[]> {
    let fullURI = `${environment.apiUrl}shipStationOrders/GetAllOrdersAwaitingShipment?$expand=items,dimensions,weight,shipTo`;

    return this.httpClient.get<ShipStation_Orders[]>(fullURI);
  }

  ShippingLabelFullProcessOneOrder(orderId: number): Observable<any> {
    var MyURL = `${environment.apiUrl}Expedition/ShippingLabelFullProcessOneOrder?orderId=${orderId}`;

    return this.httpClient.get(MyURL, {});
  }

  UpdateShippingRatesData(ratesData: ShipStation_ShippingRatesData): Observable<any> {
    var MyURL = `${environment.apiUrl}Expedition/UpdateShippingRatesData`;

    return this.httpClient.post(MyURL, ratesData);
  }

  CreateLabelForOneOrderManual(orderId: number, rate: Shipstation_rate): Observable<any> {
    var MyURL = `${environment.apiUrl}Expedition/CreateLabelForOneOrderManual?orderId=${orderId}`;

    return this.httpClient.post(MyURL, rate);
  }

  GetDetailsCalculRate(myRate: Shipstation_rate): string {
    let strings = [];
    try {
      strings.push('------------');
      if (myRate.calc_TotalShippingCostNoTax !== undefined) strings.push(`D - Total Shipping Cost: $${(myRate.calc_TotalShippingCostNoTax ?? 0).toFixed(2)}`);

      if (myRate.shipmentCostNoTax !== undefined) strings.push(`-> Base Cost (noTax) : $${(myRate.shipmentCostNoTax ?? 0).toFixed(2)}`);
      else strings.push(`-> Base Cost : $${(myRate.shipmentCost ?? 0).toFixed(2)}`);

      if (myRate.otherCostNoTax !== undefined) strings.push(`-> Other Cost (NoTax): $${(myRate.otherCostNoTax ?? 0).toFixed(2)}`);
      else strings.push(`-> Other Cost: $${(myRate.otherCost ?? 0).toFixed(2)}`);

      strings.push(`-> Frais additionnels: $${(myRate.mdpweb_custom_extraFees ?? 0).toFixed(2)}`);

      if (myRate.raisonsFrais.length > 0) {
        strings.push('------------');
        strings.push(`Raisons frais:`);
        myRate.raisonsFrais.forEach((x) => strings.push(x));
      }

      if (myRate.calc_TotalOrigShipstationCostWithTax !== undefined)
        strings.push(`-> (info) Orig SS Shippping Cost: $${(myRate.calc_TotalOrigShipstationCostWithTax ?? 0).toFixed(2)}`);

      if (myRate.shipstationTaxAmount && myRate.shipstationTaxAmount !== 0)
        strings.push(`-> (info) Shipping Tax: $${(myRate.shipstationTaxAmount ?? 0).toFixed(2)}`);
    } catch (error) {
      strings.push('Erreur affichage:' + error);
    }
    return strings.join('\n');
  }
}
