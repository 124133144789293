import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from 'src/app/models/loader';

@Injectable({
  providedIn: 'root',
})
/**
 * Service qui permet de controller le loader.
 */
export class LoaderService {
  // subject qui représente le loader
  readonly loaderSubject = new Subject<LoaderState>();
  constructor() {}

  /**
   * Permet d'afficher le loader.
   */
  show(): void {
    this.loaderSubject.next({ isVisible: true } as LoaderState);
  }

  /**
   * Permet de cacher le loader.
   */
  hide(): void {
    this.loaderSubject.next({ isVisible: false } as LoaderState);
  }
}
