import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { PickListSummarized } from 'src/app/models/PickList/picklist-summarized';
import { PickListHeader } from 'src/app/models/PickList/picklist-header';
import { PicklistService } from 'src/app/service/picklist.service';
import {
  Workbook,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
  WorkbookOptions,
  WorkbookSheetRowCellBorderBottom,
  WorkbookSheetRowCellBorderTop,
  WorkbookSheetRowCellBorderLeft,
  WorkbookSheetRowCellBorderRight,
  CellBorderSize,
} from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';

@Component({
  selector: 'app-picklist-summarized-list',
  templateUrl: './picklist-summarized-list.component.html',
  styleUrls: ['./picklist-summarized-list.component.scss'],
})
export class PickListSummarizedListComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid!: GridComponent;

  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public isLoading = false;
  public totalItemCount = 0;

  private unSubNotifier = new Subject<void>();
  public _data: PickListSummarized[] = [];

  constructor(
    private route: ActivatedRoute,
    private utilService: UtilService,
    private pickListService: PicklistService,
    private popUpDialogService: PopUpDialogService
  ) {}

  public state: State = {
    take: 25,
    skip: 0,
  };

  TitreGlobalPage: string = 'PickList Sommarisée';
  IconGlobalPage: string = 'checklist_rtl';

  showChildForm: boolean = false; //
  selectedItem: any; //représente l'entité actuellement sélectionnée
  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...
  childHaveChanges: boolean; //indique si le panneau ENFANT contient une modification
  needRefresh: boolean = false;
  pickListHeader: PickListHeader = new PickListHeader();

  ngOnInit(): void {
    //Setup la pagination
    this.state.take = this.utilService.getRowPerPage();

    let pickListHeaderID = +this.route.snapshot.paramMap.get('pickListHeaderId');

    // ATTENTION CE N'EST PAS UN ENDPOINT ODATA!!!!!
    // Il faut tous gérer le paging à la main
    this.pickListService
      .getDetailsSummarized(pickListHeaderID)
      .pipe(takeUntil(this.unSubNotifier))
      .subscribe((data) => {
        this._data = data;
        this.gridView = {
          data: data,
          total: data.length,
        };
        this.totalItemCount = data.length;
      });

    this.pickListService
      .getHeader(pickListHeaderID)
      .pipe(takeUntil(this.unSubNotifier))
      .subscribe((data) => {
        this.pickListHeader = data;
      });
  }

  ngOnDestroy(): void {
    this.unSubNotifier.next();
    this.unSubNotifier.unsubscribe();
  }

  refresh() {
    let dataToSendToGrid = this._data; //données fetchées de l'API stockées en mémoire.

    // 1re étape faire le sorting
    this.state.sort?.forEach((sortParam) => {
      if (sortParam.dir === 'asc') {
        dataToSendToGrid.sort((a, b) => a[sortParam.field] - b[sortParam.field]);
      } else if (sortParam.dir === 'desc') {
        dataToSendToGrid.sort((a, b) => b[sortParam.field] - a[sortParam.field]);
      }
    });

    // 2e Étape on s'occupe des éléments à skip
    // (Combien d'élement qu'on skip depuis le début)
    if (this.state.skip) {
      dataToSendToGrid = dataToSendToGrid.splice(0, this.state.skip - 1);
    }

    // 3e Étape on fait le take (top)
    // (Top -> 1er élement jusqu'à le nombre donné)
    if (this.state.take) {
      dataToSendToGrid = dataToSendToGrid.splice(this.state.take, this.dataStateChange.length - 1);
    }
    this.totalItemCount = dataToSendToGrid.length;
  }

  dataStateChange(state: State) {
    this.utilService.setRowPerPage(state.take ?? 25);
    this.state = state;
    this.refresh();
  }

  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  modifier(e: any) {
    this.showChildForm = true;
    if (e) {
      this.selectedItem = e.dataItem;
    }
  }

  //evenement, issue du child, indiquant qu'on veut afficher ou masquer le FORM
  OnChildFormVisibilityChanged(val: boolean): void {
    this.showChildForm = val;
  }

  getDate(): string {
    let _date = new Date();
    let formattedDate = `${_date.getFullYear()}-${_date.getMonth()}-${_date.getDay()} ${_date.getHours()}`;
    return formattedDate;
  }

  /**
   * Sert à traficoter le fichier Excel à exporté.
   * Voir: https://www.telerik.com/kendo-angular-ui/components/knowledge-base/export-to-separate-sheets/
   * voir https://www.telerik.com/kendo-angular-ui/components/excel-export/workbook/new-custom/
   */
  downloadPicklistExcelExport(_worksheets: WorkbookSheet[], reportName: string): void {
    let excelOptions: WorkbookOptions = {
      creator: 'Logiciel MDPWEB',
      rtl: false,
      date: new Date(),
      sheets: _worksheets,
    };

    let workbook = new Workbook(excelOptions);

    // On force le download du fichier Excel
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, reportName);
    });
  }

  private makeExcelCell(_value: any, _textAlign = 'center'): WorkbookSheetRowCell {
    let borderColour = '#000000';
    let borderSize: CellBorderSize = 1;

    let topBorder: WorkbookSheetRowCellBorderTop = { color: borderColour, size: borderSize };
    let bottomBorder: WorkbookSheetRowCellBorderBottom = { color: borderColour, size: borderSize };
    let leftBorder: WorkbookSheetRowCellBorderLeft = { color: borderColour, size: borderSize };
    let rightBorder: WorkbookSheetRowCellBorderRight = { color: borderColour, size: borderSize };

    return {
      value: _value,
      borderBottom: bottomBorder,
      borderTop: topBorder,
      borderLeft: leftBorder,
      borderRight: rightBorder,
      textAlign: _textAlign,
    } as WorkbookSheetRowCell;
  }

  private makeHeaderCell(_value: any): WorkbookSheetRowCell {
    let headerBackgroundColour = '#808080';
    let modifiedCell = this.makeExcelCell(_value);
    modifiedCell.background = headerBackgroundColour;
    return modifiedCell;
  }
  /**
   * Fabrique le corps ainsi que le header de chaque tableau Excel des rapports
   * @param splittingNumber
   * @returns
   */
  private makeWorkSheet(splittingNumber: number | string, param_OverrideSheetName: string = ''): WorkbookSheet {
    let _worksheet: WorkbookSheet = {
      name: `Split_No_${splittingNumber}`,
      columns: [{ width: 150 }, { width: 150 }, { width: 120 }, { width: 700 }, { width: 150 }, { width: 150 }, { width: 150 }],
      rows: [
        {
          cells: [
            {
              //Titre du tableau
              value: `DÉBUT : ${this.formatDate(this.pickListHeader.StartDate)} - FIN : ${this.formatDate(this.pickListHeader.EndDate)}`,
              fontSize: 20,
              background: '#FFFFFF',
              colSpan: 6,
              textAlign: 'center',
              verticalAlign: 'center',
            },
          ],
          height: 60,
        },
        {
          cells: [
            //Nom des Cols
            this.makeHeaderCell('O/N'),
            this.makeHeaderCell('QTY'),
            this.makeHeaderCell('SKU'),
            this.makeHeaderCell('DESCRIPTION'),
            this.makeHeaderCell('ACOMBA'),
            this.makeHeaderCell('TRANSIT'),
            this.makeHeaderCell('NOTE'),
          ],
        },
      ],
    };

    if (param_OverrideSheetName !== '') {
      _worksheet.name = param_OverrideSheetName;
    }

    return _worksheet;
  }

  private makeWorkSheetRows(_data: PickListSummarized[]): WorkbookSheetRow[] {
    let sheetRows: WorkbookSheetRow[] = [];

    for (const dataRow of _data) {
      sheetRows.push({
        cells: [
          this.makeExcelCell(''), // La colonne Oui / non est vide par défaut, ils vont le remplir à la main.
          this.makeExcelCell(dataRow.QuantityToPick),
          this.makeExcelCell(dataRow.ProductNumber),
          this.makeExcelCell(dataRow.ProductDescription, 'left'),
          this.makeExcelCell(dataRow.QtyInstore_Acomba),
          this.makeExcelCell(dataRow.QtyTransit),
          this.makeExcelCell(''), // Les notes sont vide par Défaut
        ],
      });
    }

    return sheetRows;
  }

  /**
   * Sert pour cérer un fichier Excel où crée un worksheet par split de la picklist
   */
  public getFullReport(): void {
    let splittingNumbers = [...new Set(this._data.map((e) => e.SplittingIdenfier))];
    let newWorkSheets: WorkbookSheet[] = [];

    let SummaryWorkSheet = this.makeWorkSheet(0, 'Sommaire');
    SummaryWorkSheet.rows = SummaryWorkSheet.rows.concat(this.makeWorkSheetRows(this._data));
    newWorkSheets.push(SummaryWorkSheet);

    for (const splittingNumber of splittingNumbers) {
      let worksheet = this.makeWorkSheet(splittingNumber);
      let dataSubSet = this._data.filter((x) => x.SplittingIdenfier === splittingNumber);

      worksheet.rows = worksheet.rows.concat(this.makeWorkSheetRows(dataSubSet));
      newWorkSheets.push(worksheet);
    }

    this.downloadPicklistExcelExport(newWorkSheets, `PickList_No_${this.pickListHeader.PickListHeaderID}_${this.getDate()}.xlsx`);
  }

  /**
   * Sert pour faire un rapport Excel sur les lignes où on coche les checkbox dans le grid.
   */
  public getUnfinishedReport(): void {
    let worksheet = this.makeWorkSheet(1);
    let dataSubSet = this._data.filter((x) => x.LineIsUnfinished === true);

    worksheet.rows = worksheet.rows.concat(this.makeWorkSheetRows(dataSubSet));

    this.downloadPicklistExcelExport([worksheet], `Rapport_Lignes_Imcompletes_PickList_No${this.pickListHeader.PickListHeaderID}_${this.getDate()}.xlsx`);
  }

  public getReadyToGoOnOldPickList() {
    this.pickListService.getRemainingReadyToGoFromPickList(this.pickListHeader.PickListHeaderID).subscribe((productNumbers) => {
      let dataSubSet = this._data.filter((x) => productNumbers.indexOf(x.ProductNumber) > -1);
      let worksheet = this.makeWorkSheet(1);
      worksheet.rows = worksheet.rows.concat(this.makeWorkSheetRows(dataSubSet));
      this.downloadPicklistExcelExport([worksheet], `Rapport_ReadyToGo_PickList_No${this.pickListHeader.PickListHeaderID}_${this.getDate()}.xlsx`);
    });
  }

  public formatDate(_date: Date): string {
    return this.utilService.formatDateToString(_date).substring(0, 19); // On ne veut pas afficher les ms
  }

  public sendPickListToClickNCollect() {
    this.pickListService.sendPickListToClickNCollect(this.pickListHeader.PickListHeaderID).subscribe(() => {
      let dialogData = {
        titre: 'PickList envoyée',
        texte: 'La PickList a été envoyée à Click-N-Collect',
        affirmativeActionName: 'OK',
        showDismissiveButton: false,
      };

      this.popUpDialogService.FullDialog(dialogData);
    });
  }
}
