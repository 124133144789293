import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { BehaviorSubject, take, Subject, takeUntil } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { UserDefinedRatesService } from 'src/app/service/userDefinedRates.service';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rates-list',
  templateUrl: './rates-list.component.html',
  styleUrls: ['./rates-list.component.scss'],
})
export class UserDefinedRatesListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridComponent) grid!: GridComponent;
  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public isLoading = false;
  public totalItemCount = 0;

  public unsubNotifier = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private utilService: UtilService,
    private ratesService: UserDefinedRatesService,
    private dialog: MatDialog
  ) {}

  public state: State = {
    take: 25,
    skip: 0,
  };

  TitreGlobalPage: string = 'Taux personnalisables';
  IconGlobalPage: string = 'percent';

  showChildForm: boolean = true; //
  selectedItem: any; //représente l'entité actuellement sélectionnée
  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...
  childHaveChanges: boolean; //indique si le panneau ENFANT contient une modification
  needRefresh: boolean = false;

  ngOnInit(): void {
    //Setup la pagination
    this.state.take = this.utilService.getRowPerPage();

    // subscribe to data
    this.ratesService.UserDefinedRates.pipe(takeUntil(this.unsubNotifier)).subscribe((response) => {
      // set grid data
      this.totalItemCount = response['@odata.count'];
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }

  ngAfterViewInit(): void {
    // On va aller chercher les noms et les types des colonnes du grid kendo.
    let ColsNamesAndTypes: FieldType[] = this.grid.columns.toArray().map((y: any) => ({ name: y.field, type: y.filter }));

    // Cette méthode permet de créer le state initiale du grid.
    this.initGridFilter(ColsNamesAndTypes);

    // Puisqu'on change des données à la fin d'un change detection cycle, il faut manuellement dire à Angular qu'il ya eu des changements.
    // Sinon, sa fait des exceptions: NG0100.
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  refresh() {
    this.isLoading = true;
    this.ratesService
      .odata_Rates(this.state)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((res) => {
        this.isLoading = false;
      });
  }

  /**
   * Initialise l'objet filter du state du Kendo Grid à partir des params de l'URI
   * @param ColsNamesAndTypes Données des colonnes du Kendo Grid
   */
  private initGridFilter(columnNamesAndTypes: FieldType[]): void {
    //Si les données ne sont pas chargées, alors on ne fait rien.
    if (columnNamesAndTypes.length === 0) return;

    // Si on a le modèle des colonnes ainsi que leur types.
    // alors on va contruire le filter object du kendo Grid
    this.route.params.pipe(take(1)).subscribe((uriParams: Params) => {
      this.state.filter = this.utilService.constructKendoFilterFromQueryParams(uriParams, columnNamesAndTypes);
      this.refresh();
    });
  }

  dataStateChange(state: State) {
    this.utilService.setRowPerPage(state.take ?? 25);
    this.state = state;
    this.removeQueryParams();
    this.reloadQueryParams();
    this.refresh();
  }

  removeQueryParams() {
    let currentUrl = this.location.path();
    let updatedUrl = currentUrl.split(';')[0]; // Remove the query parameters
    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  reloadQueryParams() {
    let updatedUrl = `${this.location.path()}`;
    updatedUrl += this.utilService.constructURIQueryParamsFromFilter(this.state.filter);

    if (updatedUrl.charAt(updatedUrl.length - 1) === ';') {
      updatedUrl = updatedUrl.substring(0, updatedUrl.length - 1);
    }

    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  public formatDate(_date: Date): string {
    return this.utilService.formatDateToString(_date);
  }

  modifier(e: any) {
    this.ratesService.get(e.dataItem.UserDefinedRateId).subscribe((rate) => {
      this.selectedItem = rate;
      this.location.replaceState('user-defined-rates/' + e.dataItem.UserDefinedRateId);
      this.showChildForm = true;
    });
  }

  createNew() {
    this.selectedItem = null;
    this.showChildForm = true;
  }

  //evenement, issue du child, indiquant qu'on veut afficher ou masquer le FORM
  OnChildFormVisibilityChanged(val: boolean): void {
    if (val === false && this.childHaveChanges === true) this.popUpConfirmDiscard();
    else this.showChildForm = val;

    if (this.needRefresh === true) {
      this.refresh();
      this.needRefresh = false;
    }
  }

  onChangeChildState(val: any): void {
    this.childHaveChanges = val;
    if (val === true) this.needRefresh = true;
    else this.needRefresh = false;
    this.cd.detectChanges(); //bidouille, car la valeur childHaveChanges pointe sur un Ngif..et que c'Est ca- https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  }

  //Ce event indique que l'enfant à demander au parent (nous) de forcer un refresh des datas.
  OnChildAskForForceRefresh(val: any): void {
    if (val === true) {
      this.refresh();
      this.needRefresh = false;
    }
  }

  //ici, c'est qu'on a cliqué sur le panneau de protection de 'changement détecté'.  ca permet de faire un DISCARD
  popUpConfirmDiscard() {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Changements non sauvegardés',
        texte:
          'Attention! Des changements ont été détectés dans votre formulaire.  Voulez-vous IGNORER ces changementes ou POURSUIVRE la modification à droite?',
        affirmativeActionName: 'Poursuivre',
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Ignorer',
        dismissiveActionColor: 'warn',
      },
    });

    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        return;
      }

      this.showChildForm = false;
      this.childHaveChanges = false;
    });
  }
}
