<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>

  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    filterable="menu"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
        <mat-icon>delete</mat-icon>
        Vider les filtres
      </button>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>
    <!-- COLUMNS -->
    <kendo-grid-excel fileName="RapportFacturationAcomba.xlsx"></kendo-grid-excel>

    <!-- SS_OrderNo -->
    <kendo-grid-column field="SS_OrderNo" title="#Commande SS" [width]="200" [minResizableWidth]="200" filter="text"></kendo-grid-column>

    <!-- SS_OrderDate -->
    <kendo-grid-column field="SS_OrderDate" title="Date Commande SS" [width]="200" [minResizableWidth]="200" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ formatDate(dataItem.SS_OrderDate) }}
      </ng-template>
    </kendo-grid-column>

    <!-- SS_OrderMonth -->
    <kendo-grid-column field="SS_OrderMonth" title="Mois Commande SS" [width]="150" [minResizableWidth]="150" filter="numeric" format="0"></kendo-grid-column>

    <!-- Sommative_NoClient -->
    <kendo-grid-column field="Sommative_NoClient" title="Sommative NoClient" [width]="150" [minResizableWidth]="150" filter="text"></kendo-grid-column>

    <!-- Sommative_NoClient -->
    <kendo-grid-column field="Sommative_NoFact" title="Sommative NoFact" [width]="150" [minResizableWidth]="150" filter="text"></kendo-grid-column>

    <!-- Sommative_DateFact -->
    <kendo-grid-column field="Sommative_DateFact" title="Sommative Date Fact" [width]="200" [minResizableWidth]="200" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ formatDate(dataItem.Sommative_DateFact) }}
      </ng-template>
    </kendo-grid-column>

    <!-- Sommative_MoisFact -->
    <kendo-grid-column
      field="Sommative_MoisFact"
      title="Sommative Mois Fact"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="0"
    ></kendo-grid-column>

    <!-- MDP_NoClient -->
    <kendo-grid-column field="MDP_NoClient" title="MDP NoClient" [width]="200" [minResizableWidth]="200" filter="text"></kendo-grid-column>

    <!-- MDP_NoFact -->
    <kendo-grid-column field="MDP_NoFact" title="MDP NoFact" [width]="150" [minResizableWidth]="150" filter="text"></kendo-grid-column>

    <!-- MDP_DateFact -->
    <kendo-grid-column field="MDP_DateFact" title="MDP Date Fact" [width]="200" [minResizableWidth]="200" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ formatDate(dataItem.MDP_DateFact) }}
      </ng-template>
    </kendo-grid-column>

    <!-- MDP_MoisFact -->
    <kendo-grid-column field="MDP_MoisFact" title="MDP Mois Fact" [width]="150" [minResizableWidth]="150" filter="numeric" format="0"></kendo-grid-column>

    <!-- SS_Sku -->
    <kendo-grid-column field="SS_Sku" title="SS Sku" [width]="150" [minResizableWidth]="150" filter="text"></kendo-grid-column>

    <!-- SS_Qty -->
    <kendo-grid-column field="SS_Qty" title="SS Qty" [width]="100" [minResizableWidth]="100" filter="numeric" format="0"></kendo-grid-column>

    <!-- CA_AttributeCost_Base -->
    <kendo-grid-column
      field="CA_AttributeCost_Base"
      title="CA AttributeCost Base"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="c2"
    ></kendo-grid-column>

    <!-- CA_AttributeCost_Base -->
    <kendo-grid-column
      field="CA_AttributeCost_plus_35"
      title="CA AttributeCost plus 3.5%"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="c2"
    ></kendo-grid-column>

    <!-- CA_AttributeCost_Base -->
    <kendo-grid-column
      field="CA_ExtendedCost_At_Cost_plus_35"
      title="CA Extended (@Cost+3.5)"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="c2"
    ></kendo-grid-column>

    <!-- NbMoisDiff_AcombaMDP_vs_sommative -->
    <kendo-grid-column
      field="NbMoisDiff_AcombaMDP_vs_sommative"
      title="Nb Mois Diff AcombaMDP vs sommative"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="0"
    ></kendo-grid-column>

    <!-- orderItemId -->
    <kendo-grid-column field="orderItemId" title="Id item" [width]="150" [minResizableWidth]="150" filter="numeric" format="0"></kendo-grid-column>
  </kendo-grid>
</div>
