<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{IconGlobalPage}}</mat-icon>{{TitreGlobalPage}}
  </h1>
</div>
  
<div class="main-page-container">

  <div class="listpage-container">

    <div class="ss-list-button-container"></div>
    
    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [sortable]="true"
      [pageable]="pageable"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="modifier($event)"
    >
    
      <ng-template kendoGridToolbarTemplate>
        <button
          mat-stroked-button
          color="primary" 
          type="button" 
          fillMode="clear"
          (click)="clearFilters()"
        >
          <mat-icon>delete</mat-icon>
          Vider les filtres
        </button>
        <button
          type="button"  
          mat-flat-button 
          color="accent" 
          (click)="getFullReport()"
        >
          <mat-icon>download</mat-icon>
          <span>PickList Excel</span>
        </button>
        <button
          type="button"  
          mat-flat-button 
          color="accent" 
          (click)="getUnfinishedReport()"
        >
          <mat-icon>download</mat-icon>
          <span>Rapport Lignes Incomplètes</span>
        </button>
        <button
          type="button"  
          mat-flat-button 
          color="accent" 
          (click)="getReadyToGoOnOldPickList()"
        >
          <mat-icon>download</mat-icon>
          <span>Rapport ReadyToGo</span>
        </button>
        <button
          type="button"  
          mat-flat-button 
          color="accent" 
          (click)="sendPickListToClickNCollect()"
          [disabled]="this.pickListHeader.PickListHeaderID < 1"
        >
          <mat-icon>upload_file</mat-icon>
          <span>Envoyer à Click-N-Collect</span>
        </button>
        <p class="elements-count">{{totalItemCount}} Élément(s) trouvé(s)</p>
      </ng-template>
  
      <!-- COLUMNS -->

      <!-- ProductNumber -->
      <kendo-grid-column
        field="ProductNumber"
        title="Sku"
        [width]="12"
        [minResizableWidth]="12"
      ></kendo-grid-column>

      <!-- ProductDescription -->
      <kendo-grid-column
        field="ProductDescription"
        title="Description"
        [width]="25"
        [minResizableWidth]="25"
      ></kendo-grid-column>

      <!-- QtyInstore_Acomba -->
      <kendo-grid-column
        field="QuantityToPick"
        title="Qty à Ramasser"
        [width]="12"
        [minResizableWidth]="12"
      ></kendo-grid-column>

      <!-- QtyInstore_Acomba -->
      <kendo-grid-column
        field="QtyInstore_Acomba"
        title="Qty Acomba Instore"
        [width]="12"
        [minResizableWidth]="12"
      ></kendo-grid-column>

      <!-- QtyTransit -->
      <kendo-grid-column
        field="QtyTransit"
        title="Qty Transit"
        [width]="12"
        [minResizableWidth]="12"
      ></kendo-grid-column>

      <!-- SplittingIdenfier -->
      <kendo-grid-column
        field="SplittingIdenfier"
        title="Split"
        [width]="10"
        [minResizableWidth]="10"
      ></kendo-grid-column>

      <!-- PickListHeaderID -->
      <kendo-grid-column
        field="PickListHeaderId"
        title="ID Entête"
        [width]="10"
        [minResizableWidth]="10"
      ></kendo-grid-column>

      <!-- Ligne Non Terminée -->
      <kendo-grid-column
        field="LineIsUnfinished"
        title="Ligne Non Terminée"
        [width]="12"
        [minResizableWidth]="12"
        [sortable]="false"
      > 

        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [(ngModel)]="dataItem.LineIsUnfinished" />
        </ng-template>
      </kendo-grid-column>

      <!-- PickListHeaderID -->
      <!--Demande de Marc, trop peux lignes seront différentes entre qty rammasée et qty a ramasser, alors on les met les mêmes valeurs-->
      <kendo-grid-column
        field="QuantityToPick"
        title="Qty Ramassée"
        [width]="10"
        [minResizableWidth]="10"
        [sortable]="false"
      ></kendo-grid-column>
      
    </kendo-grid>
  </div>
  <app-picklist-summarized-form 
    #myChildForm 
    *ngIf="showChildForm" 
    [currentItem]="selectedItem"
    class="formpage-container"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)" 
  ></app-picklist-summarized-form>

</div>


  