import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageCommandeAwaitingShipment',
})
export class AgeCommandeAwaitingShipmentPipe implements PipeTransform {
  transform(dateCommandeStr: string): string {
    // Date stockée en string => création d'un objet Date
    let dateCommande = Date.parse(dateCommandeStr);

    // Âge de la commande en millisecondes
    let ageCommande = new Date().valueOf() - dateCommande;

    // Conversion en minutes
    ageCommande /= 60 * 1000;

    // Moins d'une heure => âge en minutes
    if (ageCommande < 60) return `${Math.floor(ageCommande)} min`;

    // Conversion en heures
    ageCommande /= 60;

    // Moins d'une journée => âge en heures
    if (ageCommande < 24) return `${Math.floor(ageCommande)} h`;

    // Conversion en jours
    ageCommande /= 24;

    // Âge en jours
    return `${Math.floor(ageCommande)} j`;
  }
}
