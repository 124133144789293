import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { ListComponent } from 'src/app/component/core/list/list.component';
import { SystemManagerService } from 'src/app/module/systemManager/SystemManager.service';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-channelAdvisor-ProductSync-Overview',
  templateUrl: './ProductSyncOverview.component.html',
  styleUrls: ['./ProductSyncOverview.component.css'],
})
export class ProductSyncOverviewComponent extends ListComponent {
  constructor(protected httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private loaderShown: LoaderService) {
    super(httpClient);

    this.endpoint = 'ChannelAdvisor_ProductSync_Overview';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
        hide: true,
      },
      {
        key: 'CA_ProduitID',
        nom: 'CA_ProduitID',
        type: 'number',
      },
      {
        key: 'SkuRaw',
        nom: 'SkuRaw',
        type: 'string',
      },
      {
        key: 'SkuClean',
        nom: 'SkuClean',
        type: 'string',
      },

      {
        key: 'HH_Description',
        nom: 'HH_Description',
        type: 'string',
      },

      {
        key: 'ProductPriority',
        nom: 'ProductPriority',
        type: 'number',
      },
      {
        key: 'QteParPaquet',
        nom: 'QteParPaquet',
        type: 'number',
      },

      {
        key: 'HH_QteDebert',
        nom: 'HH_QteDebert',
        type: 'number',
      },
      {
        key: 'HH_QteStJacob',
        nom: 'HH_QteStJacob',
        type: 'number',
      },
      {
        key: 'HH_QteWetawiskin',
        nom: 'HH_QteWetawiskin',
        type: 'number',
      },
      {
        key: 'HH_getQtyDate',
        nom: 'HH_getQtyDate',
        type: 'datetime',
      },
      {
        key: 'HH_GetQtyBatchNumber',
        nom: 'HH_GetQtyBatchNumber',
        type: 'number',
      },
      {
        key: 'HH_getQtyErreur',
        nom: 'HH_getQtyErreur',
        type: 'string',
      },

      {
        key: 'CA_updateQtyDate',
        nom: 'CA_updateQtyDate',
        type: 'datetime',
      },
      {
        key: 'CA_updateQtyLastError',
        nom: 'CA_updateQtyLastError',
        type: 'string',
      },
      {
        key: 'CA_updateQtyLastInfo',
        nom: 'CA_updateQtyLastInfo',
        type: 'string',
      },

      {
        key: 'HH_Coutant',
        nom: 'HH_Coutant',
        type: 'number',
      },
      {
        key: 'CA_Coutant',
        nom: 'CA_Coutant',
        type: 'number',
      },
      {
        key: 'CA_updateCostDate',
        nom: 'CA_updateCostDate',
        type: 'datetime',
      },
      {
        key: 'HH_getCostDate',
        nom: 'HH_getCostDate',
        type: 'datetime',
      },

      {
        key: 'HH_GetCostBatchNumber',
        nom: 'HH_GetCostBatchNumber',
        type: 'number',
      },
    ];
    this.champsHardcoded = ['VoirTraceLogExecution'];
    this.defaultOrderBy = 'SkuClean';
    this.recherche = {
      IsCompleted: undefined,
    };
  }

  champsDisplay: any[];

  TitreGlobalPage: string = 'Liste des synchronization par produits';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionDetail';

  ngOnInit(): void {
    super.ngOnInit();

    this.recherche.SkuRaw = this.route.snapshot.paramMap.get('SkuRaw');
    this.recherche.SkuClean = this.route.snapshot.paramMap.get('SkuClean');
    this.recherche.CA_ProduitID = this.route.snapshot.paramMap.get('CA_ProduitID');
    this.recherche.HH_getQtyDate_DATE_FILTER_GE = this.route.snapshot.paramMap.get('HH_getQtyDate_DATE_FILTER_GE');
    this.recherche.HH_getQtyDate_DATE_FILTER_LE = this.route.snapshot.paramMap.get('HH_getQtyDate_DATE_FILTER_LE');
  }

  rechercher() {
    var params = [];
    if (this.recherche.HH_getQtyDate_DATE_FILTER_GE) params['HH_getQtyDate_DATE_FILTER_GE'] = this.formatDate(this.recherche.HH_getQtyDate_DATE_FILTER_GE);
    if (this.recherche.HH_getQtyDate_DATE_FILTER_LE) params['HH_getQtyDate_DATE_FILTER_LE'] = this.formatDate(this.recherche.HH_getQtyDate_DATE_FILTER_LE);
    if (this.recherche.SkuRaw) params['SkuRaw'] = this.recherche.SkuRaw;
    if (this.recherche.SkuClean) params['SkuClean'] = this.recherche.SkuClean;
    if (this.recherche.CA_ProduitID) params['CA_ProduitID'] = this.recherche.CA_ProduitID;

    this.router.navigate(['/channelAdvisor-ProductSync-Overview', params]);
    this.launchRefresh();
  }
}
