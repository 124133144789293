import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { LocalStorage } from 'src/app/models/enum/LocalStorage';
import { WorkStation } from 'src/app/models/security/WorkStation';
import { AuthService } from 'src/app/service/auth.service';
import { ProductService } from 'src/app/service/product.service';
import { WorkstationService } from 'src/app/service/security/workstation.service';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';

@Component({
  selector: 'app-workstation-selection',
  templateUrl: './workstation-selection.component.html',
  styleUrls: ['./workstation-selection.component.scss'],
})
export class WorkstationSelectionComponent implements OnInit {
  isForceConnectAsked: boolean = false;

  // Comme on peut désormais changer de station depuis n'importe quelle page,
  // on garde en mémoire la page vers laquelle naviguer une fois la station choisie
  UrlDestinationApresChoix: string;

  constructor(
    public matDialog: MatDialog,
    public authService: AuthService,
    private workstationService: WorkstationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private snack: MatSnackBar
  ) {}

  listExistingWorkstation: WorkStation[] = [];
  WorkstationAlreadyAssigned: WorkStation;

  ngOnInit(): void {
    this.refreshWorkStationfromAPI();
    this.activatedRoute.params.subscribe((params) => (this.UrlDestinationApresChoix = params['destination']));
  }

  refreshWorkStationfromAPI() {
    this.workstationService.getAllOdata().subscribe(
      (jsonData) => {
        this.listExistingWorkstation = jsonData.value;
        this.WorkstationAlreadyAssigned = this.listExistingWorkstation.find((x) => x.CurrentLoggedUser_UserCode == this.authService.info.name);
      },
      (error: HttpErrorResponse) => {
        alert('Échec du changement de mot de passse!');
      }
    );
  }

  async ChooseNoWorkstation() {
    //************************
    //pop-=up de confirmation de sur-assignation
    let popupResult = this.matDialog.open(PopConfirmComponent, {
      data: {
        titre: "Sélection d'aucune station de travail",
        texte: 'Attention!<br/><br/>Vous allez vous connecter SANS station de travail!<br/><br/>Êtes-vous certain de vouloir poursuivre?',
        mainIcon: 'warning',
      },
    });

    var monRetour = await popupResult.afterClosed().toPromise();
    if (monRetour !== true) {
      return;
    }
    //************************

    if (this.WorkstationAlreadyAssigned != undefined) {
      // Si on est déjà logué et qu'on veut juste quitter la station, on désassigne
      await this.workstationService.AssignWorkStation(this.WorkstationAlreadyAssigned.id, this.isForceConnectAsked, true).toPromise();
    }
    localStorage.setItem(LocalStorage.SCAN_CURRENT_STATION_NAME, 'AUCUNE_SÉLECTIONNÉE');
    this.authService.hydrate();
    this.NavigateOut();
  }

  async selectWorkstation(paramWorkStationID: number) {
    let LaWorkstation: WorkStation = this.listExistingWorkstation.find((x) => x.id == paramWorkStationID);

    this.isForceConnectAsked = false;

    //si la station sélectionnée est la même que la station en cours, on va caller l'API pour rafraichir la date de debut et la nouvelle session
    if (this.WorkstationAlreadyAssigned != null && this.WorkstationAlreadyAssigned?.id === LaWorkstation?.id) {
      await this.LaunchAssignation(LaWorkstation);
      return;
    }

    //si la station sélectionnée est la même que la station en cours, on va caller l'API pour rafraichir la date de debut et la nouvelle session
    if (this.WorkstationAlreadyAssigned != null && this.WorkstationAlreadyAssigned.id !== LaWorkstation?.id) {
      //************************
      //pop-=up de confirmation de sur-assignation
      let strMessage = 'Attention!<br/><br/>';
      strMessage += "Assignation à la station '" + LaWorkstation.WorkStationCode + "'<br/>";
      strMessage += "Votre usager en cours '" + this.authService.info.name + "' ";
      strMessage += " est déjà assigné à une d'autre station travail ('" + this.WorkstationAlreadyAssigned.WorkStationCode + "'). <br/>";
      strMessage += '<br/>Voulez-vous changer de station?';
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Assignation forcée de station',
          texte: strMessage,
          mainIcon: 'warning',
        },
      });

      var monRetour = await popupResult.afterClosed().toPromise();

      //si l'usager ne dit pas OUI
      if (monRetour !== true) {
        return;
      } else {
        // Désassignation de la station actuelle
        var result = await this.workstationService.AssignWorkStation(this.WorkstationAlreadyAssigned.id, this.isForceConnectAsked, true).toPromise();
        if (result) {
          // Assignation à la nouvelle station si la désassignation ^ a fonctionné
          await this.LaunchAssignation(LaWorkstation);
          return;
        }
      }
    }

    if (LaWorkstation.CurrentLoggedUser_StartTime != null) {
      if (LaWorkstation.CurrentLoggedUser_UserCode !== this.authService.info.name) {
        //************************
        //pop-=up de confirmation de sur-assignation
        let strMessage = 'Attention!<br/><br/>La station ' + LaWorkstation.WorkStationCode + ' - sélectionnée semble déjà assignée ';
        strMessage += " à l'usager " + LaWorkstation.CurrentLoggedUser_UserCode + '.';
        strMessage += 'Voulez-vous vraiment écraser sa connexion?';
        let popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Assignation forcée de station',
            texte: strMessage,
            mainIcon: 'warning',
          },
        });

        var monRetour = await popupResult.afterClosed().toPromise();
        if (monRetour !== true) {
          return;
        }
        //************************
      }

      this.isForceConnectAsked = true;
    }

    await this.LaunchAssignation(LaWorkstation);
    return;
  }

  private async LaunchAssignation(param_Workstation: WorkStation) {
    var result = await this.workstationService.AssignWorkStation(param_Workstation.id, this.isForceConnectAsked).toPromise();

    localStorage.setItem(LocalStorage.SCAN_CURRENT_STATION_NAME, param_Workstation.WorkStationCode);
    this.authService.hydrate();
    this.NavigateOut();
  }

  NavigateOut() {
    this.route.navigateByUrl(this.UrlDestinationApresChoix);
  }
}
