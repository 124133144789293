<div class="kbd-bg">
  <div class="kbd-container">
    <div class="login-container">
      <div class="login-content">
        <h2 class="page-titre">Connexion</h2>
        <mat-form-field appearance="outline">
          <mat-label>Identifiant de connexion</mat-label>
          <input matInput [(ngModel)]="email" name="email" (keydown.enter)="login()" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Mot de passe</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="passwordInput" name="passwordInput" (keydown.enter)="login()" />
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          <mat-hint *ngIf="!passwordInput">Enter your password</mat-hint>
        </mat-form-field>
        <mat-checkbox color="primary" [(ngModel)]="rememberMe" name="rememberMe" (change)="onChangeRememberMe($event)">Se souvenir de moi</mat-checkbox>
        <div class="login-btn-bar">
          <button mat-stroked-button (click)="effacer()"><mat-icon>delete</mat-icon> Effacer</button>
          <button mat-flat-button color="primary" (click)="login()"><mat-icon>check</mat-icon> Se connecter</button>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-top: 5em; padding: 0.5em">
    <div>
      <button (click)="LogByPinLogin()" mat-stroked-button>
        <div>
          <mat-icon style="vertical-align: middle">pin</mat-icon>
          Changer pour une connexion avec PIN
        </div>
      </button>
    </div>
  </div>
</div>
