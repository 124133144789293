import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendErpService } from 'src/app/service/backend-erp.service';
import { homeReceiveFileService } from 'src/app/service/homeReceiveFile.service';
import { ListComponent } from '../../core/list/list.component';

@Component({
  selector: 'hhfile-get-log-execution',
  templateUrl: './hhfile-get-log-execution.component.html',
  styleUrls: ['./hhfile-get-log-execution.component.scss'],
})
export class hhfileLogExecution extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private backendERPService: BackendErpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private MyHomeReceiveFileService: homeReceiveFileService
  ) {
    super(httpClient);

    this.endpoint = 'HomeReceiveFile/GetLogExecution';
    this.IsOdataEndpoint = false;
    this.autoload = true;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
      {
        key: 'DateHeureDebut',
        nom: 'DateHeureDebut',
        type: 'datetime',
      },
      {
        key: 'DateHeureFin',
        nom: 'DateHeureFin',
        type: 'datetime',
      },
      {
        key: 'TypeExecution',
        nom: 'TypeExecution',
        type: 'string',
      },
      {
        key: 'statutExecution',
        nom: 'statutExecution',
        type: 'string',
      },
      {
        key: 'DetailExecution',
        nom: 'DetailExecution',
        type: 'string',
      },
    ];
    this.champsHardcoded = [];

    this.orderBy = 'DateHeureDebut desc';

    this.recherche = {
      ItemNumber: undefined,
      DateHeureDebut_DATE_FILTER_GE: undefined,
      DateHeureDebut_DATE_FILTER_LE: undefined,
      InvoiceNumber: undefined,
      ERP_Purchase_Number: undefined,
    };
  }

  champsDisplay: any[];

  DateDebutRecherche: Date;
  DateFinRecherche: Date;

  TitreGlobalPage: string = 'Home Receive File - detail factures H01';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionDetail';

  ngOnInit(): void {
    super.ngOnInit();

    this.recherche.DateHeureDebut_DATE_FILTER_GE = this.route.snapshot.paramMap.get('DateHeureDebut_DATE_FILTER_GE');
    this.recherche.DateHeureDebut_DATE_FILTER_LE = this.route.snapshot.paramMap.get('DateHeureDebut_DATE_FILTER_LE');

    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.DateHeureDebut_DATE_FILTER_GE != null)
      params['DateHeureDebut_DATE_FILTER_GE'] = this.formatDate(this.recherche.DateHeureDebut_DATE_FILTER_GE);
    if (this.recherche.DateHeureDebut_DATE_FILTER_LE != null)
      params['DateHeureDebut_DATE_FILTER_LE'] = this.formatDate(this.recherche.DateHeureDebut_DATE_FILTER_LE);

    this.router.navigate(['/hhfileLogExecution', params]);

    this.launchRefresh();
  }
}
