import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PackageTypes } from '../models/packageTypes';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class homeReceiveFileService {
  constructor(private httpclient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}HomeReceiveFile`;

  GetInfoSommaireH01(DateDebutRecherche: string, DateFinRecherche: string): Observable<any> {
    let MyUrl = `${this.BASE_URL}/GetInfoSommaireH01`;
    MyUrl += '?DateDebutRecherche' + DateDebutRecherche;

    if (DateFinRecherche) MyUrl += '&DateFinRecherche' + DateFinRecherche;

    return this.httpclient.get<any>(MyUrl);
  }
}
