//ENUM permettant de MAPPER avec l'enum du model de l'API public enum DVL_ProcessingStatus
export enum enumItemTraceExpedition_Statut_For_API {
  SCAN1_PENDING = "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'PENDING'",
  CANCELLED = "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'CANCELLED'",
  WORKING = "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'WORKING'",
  COMPLETED = "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'COMPLETED'",
}

//ENUM local quand on compare en javascript (car l'objet reoputrne un simple string...)
export enum enumItemTraceExpedition_Statut_String {
  SCAN1_PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  WORKING = 'WORKING',
  COMPLETED = 'COMPLETED',
}
