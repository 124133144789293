/**
 * Interface permettant de gérer les stations de travail
 */
export class WorkStation {
  id: number;
  WorkStationCode: string;
  FullName: string;
  CurrentLoggedUser_UserCode: string;
  CurrentLoggedUser_StartTime: Date;
}
