import { Component, OnInit } from '@angular/core';
import { BackendErpService } from 'src/app/service/backend-erp.service';

@Component({
  selector: 'app-oerp-admin-panel',
  templateUrl: './oerp-admin-panel.component.html',
  styleUrls: ['./oerp-admin-panel.component.scss'],
})
export class OerpAdminPanelComponent implements OnInit {
  constructor(private mybackendERPService: BackendErpService) {}
  CodeLicenceOERP: string = 'TEST';

  MyFullOERPQuery: string;
  MyFullResponse: any;

  ngOnInit(): void {}

  sendQueryToOERP() {
    this.mybackendERPService.ProxyToOnlineERP(this.MyFullOERPQuery).subscribe((data) => {
      this.MyFullResponse = data;
    });
  }
}
