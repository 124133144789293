import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { Observable, tap, ReplaySubject, map } from 'rxjs';
import { toODataString, State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { UserDefinedRates } from '../models/UserDefinedRates';

@Injectable({
  providedIn: 'root',
})
export class UserDefinedRatesService {
  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  private BASE_URL = `${environment.apiUrl}`;

  public UserDefinedRates: ReplaySubject<any> = new ReplaySubject<any>(1);

  // ODATA : STATE

  odata_Rates(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}UserDefinedRates?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.UserDefinedRates.next(response);
      })
    );
  }

  // ODATA  : STRING
  public odataStr_Rates(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${this.BASE_URL}?${oDataFullString}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.UserDefinedRates.next(response);
      })
    );
  }

  get(id: number): Observable<UserDefinedRates> {
    let uri = `${this.BASE_URL}UserDefinedRates?$filter=UserDefinedRateId eq ${id}`;
    return this.httpClient.get<UserDefinedRates>(uri).pipe(map((x: any) => x.value[0]));
  }

  create(udr: UserDefinedRates): Observable<any> {
    let uri = `${this.BASE_URL}Finance/UserDefinedRates`;
    return this.httpClient.post(uri, udr);
  }

  update(udr: UserDefinedRates): Observable<any> {
    let uri = `${this.BASE_URL}Finance/UserDefinedRates/${udr.UserDefinedRateId}`;
    return this.httpClient.put(uri, udr);
  }

  /*delete(id:number): Observable<any> {
    return this.httpClient.delete(`${this.BASE_URL}/${id}`);
  }*/
}
