import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { ListComponent } from 'src/app/component/core/list/list.component';
import { BackendErpService } from 'src/app/service/backend-erp.service';
import { HttpErrorMsg } from 'src/app/models/HttpErrorMsg';

import { PopUpDialogService } from 'src/app/service/popUpDialog.service';

@Component({
  selector: 'rapport-reception-acomba',
  templateUrl: './rapport-reception-acomba.html',
  styleUrls: ['./rapport-reception-acomba.css'],
})
export class RapportReceptionAcombaComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private backendERPService: BackendErpService,
    private route: ActivatedRoute,
    public dialogService: PopUpDialogService
  ) {
    super(httpClient);

    this.endpoint = 'ItemReceptionDetails';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
      {
        key: 'ItemReceptionHeaderId',
        nom: 'ID en-tête',
        type: 'number',
      },
      {
        key: 'ProductNumber',
        nom: '#Produit',
        type: 'string',
      },
      {
        key: 'ProductDescription',
        nom: 'Description produit',
        type: 'string',
      },
      {
        key: 'QtyToReceive',
        nom: 'Qté à recevoir',
        type: 'number',
      },
      {
        key: 'QtyReceived',
        nom: 'Qté reçu',
        type: 'number',
      },
      {
        key: 'ERP_PurchaseNumber',
        nom: 'No Achat Acomba',
        type: 'string',
      },
      {
        key: 'ERP_PurchaseLineNumber',
        nom: 'No ligne Achat',
        type: 'string',
      },
      {
        key: 'ERP_QtyPushedToERPReceipt',
        nom: 'Qté recu Acomba',
        type: 'number',
      },
      {
        key: 'ERP_QtyPushedToERP_LastError',
        nom: 'Erreur réception',
        type: 'string',
      },
      {
        key: 'LigneErreurReceptionERP',
        type: 'AutoCalcFilter',
        hide: true,
        filter: 'ERP_QtyPushedToERPReceipt lt QtyReceived and QtyReceived gt 0',
      },
    ];
    this.champsHardcoded = ['ForageDetail'];

    this.recherche = {
      LigneRecuePartiellement: true,
    };
  }

  champsDisplay: any[];

  TitreGlobalPage: string = 'Rapport réception item dans Acomba';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionDetail';

  ngOnInit(): void {
    super.ngOnInit();

    this.recherche.ItemReceptionHeaderId = this.route.snapshot.paramMap.get('ItemReceptionHeaderId');
    this.recherche.ProductNumber = this.route.snapshot.paramMap.get('ProductNumber');
    this.recherche.LigneErreurReceptionERP = this.route.snapshot.paramMap.get('LigneErreurReceptionERP');
    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.ItemReceptionHeaderId) params['ItemReceptionHeaderId'] = this.recherche.ItemReceptionHeaderId;
    if (this.recherche.ProductNumber) params['ProductNumber'] = this.recherche.ProductNumber;
    if (this.recherche.LigneErreurReceptionERP) params['LigneErreurReceptionERP'] = this.recherche.LigneErreurReceptionERP;

    this.router.navigate(['/RapportReceptionAcomba', params]);
    this.launchRefresh();
  }

  async RelancerReceptionLigneNonRecue() {
    let CurrentItemReceptionId = this.recherche.ItemReceptionHeaderId;
    if (CurrentItemReceptionId != null && isNaN(+CurrentItemReceptionId) === false) {
      CurrentItemReceptionId = this.recherche.ItemReceptionHeaderId;
    } else {
      const myText = 'Vous devez choisir UN seul entête ID pour exécuter cette commande.<br/><br/>Saisissez un filtre dans Id en-tête';
      await this.dialogService.SimpleErrorAlert(myText, 'Numéro de réception invalide', 'error');
      return;
    }

    const textConfirmation = 'Êtes vous certain de vouloir relancer la réception des lignes non-recues?';
    // pop dialog
    var MyREsult = await this.dialogService.ConfirmDialog(
      textConfirmation,
      'Relancer réception Acomba',
      'live_help',
      'Oui, faire la réception Acomba',
      'Non, annuler mon action'
    );

    if (MyREsult === true) {
      this.backendERPService.ReprocessReceptionLinesForItemReceptionHeader(CurrentItemReceptionId).subscribe(
        (data) => {
          // update row
          console.log(data);
        }
        /*,
        (error:HttpErrorMsg) =>{this.dialogService.SimpleErrorAlert(error.messageErreurDetail,'Erreur de l\'Api','error');
        }*/
      );
    }
  }
}
