<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre"><mat-icon>account_circle</mat-icon>{{ TitreGlobalPage }}</h1>
</div>

<!---->
<div class="main-page-container">
  <!--corps principals du mainPAGE - à gauche la liste, à droite le form-->
  <div class="listpage-container">
    <!--Grosse barrure indique que des changemnets sont en cours-->
    <div *ngIf="childHaveChanges" class="modal-preventChange" (click)="popUpConfirmDiscard()">Changements détectés...</div>

    <div class="listpage-header">
      <!-- CHAMPS RECHERCHE -->
      <div class="listpage-search-container">
        <mat-form-field appearance="outline" style="font-size: 10px; padding: 0em; padding-right: 2.5em">
          <mat-label>Recherche</mat-label>
          <input matInput [(ngModel)]="textualSearchCritera" name="Global" (keyup.enter)="launchRefresh()" />
          <button matSuffix mat-icon-button aria-label="Clear" (click)="launchRefresh()">
            <mat-icon>search</mat-icon>
          </button>
          <button *ngIf="textualSearchCritera" matSuffix mat-icon-button aria-label="Clear" (click)="textualSearchCritera = ''; launchRefresh()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field ngDefaultControl appearance="outline" style="font-size: 10px; padding: 0em; padding-right: 2.5em">
          <mat-label>Filtrer selon statut</mat-label>
          <mat-select (selectionChange)="applyActiveStatusFilter($event.value)">
            <mat-option value="reset">...</mat-option>
            <mat-option value="true">Actif</mat-option>
            <mat-option value="false">Inactif</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button (click)="createNew()" color="primary"><mat-icon>add</mat-icon> Ajouter un usager</button>
      </div>

      <!-- PAGINATION -->
      <!-- 
        <div   class="pagination-count">{{ count }} {{NomEntiteCourante}}(s) trouvé(s)</div>
      -->
    </div>

    <!-- LISTE -->
    <div class="listpage-body">
      <table
        mat-table
        class="table-liste table-liste-clickable"
        #table
        [dataSource]="dataSource"
        matSort
        matSortStart="desc"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="{{ champ['key'] }}" *ngFor="let champ of champs">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [class.align-right]="champ['type'] == 'number' || champ['type'] == 'currency'"
            [class.align-center]="champ['type'] == 'boolean'"
          >
            {{ champ["nom"] }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [class.align-right]="champ['type'] == 'number' || champ['type'] == 'currency'"
            [class.align-center]="champ['type'] == 'boolean'"
          >
            <span *ngIf="champ['type'] == 'string' || champ['type'] == 'number'">{{ row[champ["key"]] }}</span>
            <span *ngIf="champ['type'] == 'currency'">{{ row[champ["key"]] | currency }}</span>
            <span *ngIf="champ['type'] == 'date'">{{ row[champ["key"]] | formatDate : "D" }}</span>
            <span *ngIf="champ['type'] == 'boolean' && row[champ['key']] == true">
              <mat-icon>check</mat-icon>
            </span>
          </td>
        </ng-container>

        <!--C'est ici qu'on mets les sticky row header-->
        <tr mat-header-row *matHeaderRowDef="champsKey; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: champsKey"
          (click)="selectedRow = row; openForm(row)"
          [ngClass]="{ 'table-liste-item-selected': row === selectedRow }"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="99">Aucun élément trouvé</td>
        </tr>
      </table>
    </div>

    <div class="listpage-footer">
      <div class="pagination-container" *ngIf="count != 0">
        Items par page:
        <mat-select
          appearance="inline"
          class="pagination-select"
          [(ngModel)]="rowPerPage"
          style="font-size: 8; width: 5em; padding-left: 10px"
          (selectionChange)="changeRowPerPage($event)"
        >
          <mat-option *ngFor="let count of rowPerPageSelect" [value]="count">{{ count }}</mat-option>
        </mat-select>
        <button mat-icon-button [disabled]="currentPage == 0" (click)="pagination('debut')">
          <mat-icon>first_page</mat-icon>
        </button>
        <button mat-icon-button [disabled]="currentPage == 0" (click)="pagination('precedent')">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="pagination-pages">{{ from }} à {{ to }} de {{ count }}</div>
        <button mat-icon-button [disabled]="to > count || count == 0" (click)="pagination('suivant')">
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button mat-icon-button [disabled]="to > count || count == 0" (click)="pagination('fin')">
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!--Composant ENFANT-->
  <app-usager-form
    #myChildForm
    *ngIf="showChildForm"
    [currentItem]="selectedItem"
    (submitEmitterChildHaveChanges)="onChangeChildState($event)"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)"
    class="formpage-container"
  ></app-usager-form>
</div>
