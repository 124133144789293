import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendErpService } from 'src/app/service/backend-erp.service';
import { homeReceiveFileService } from 'src/app/service/homeReceiveFile.service';
import { ListComponent } from '../../core/list/list.component';

@Component({
  selector: 'app-hhfile-get-info-sommaire-h01',
  templateUrl: './hhfile-get-info-sommaire-h01.component.html',
  styleUrls: ['./hhfile-get-info-sommaire-h01.component.scss'],
})
export class HhfileGetInfoSommaireH01Component extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private backendERPService: BackendErpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private MyHomeReceiveFileService: homeReceiveFileService
  ) {
    super(httpClient);

    this.endpoint = 'HomeReceiveFile/GetInfoSommaireH01';
    this.IsOdataEndpoint = false;
    this.autoload = false;

    this.champs = [
      {
        key: 'InvoiceNumber',
        nom: 'InvoiceNumber',
        type: 'number',
      },
      {
        key: 'ERP_Purchase_Number',
        nom: 'ERP_Purchase_Number',
        type: 'string',
      },
      {
        key: 'FullFilePath',
        nom: 'Fichier',
        type: 'string',
      },
      {
        key: 'CreationDate',
        nom: 'CreationDate',
        type: 'datetime',
      },
      {
        key: 'NbItems',
        nom: 'NbItems',
        type: 'number',
      },
      {
        key: 'NbLignesERPSuccess',
        nom: 'NbLignesERPSuccess',
        type: 'number',
      },
      {
        key: 'NbLignesERPFailed',
        nom: 'NbLignesERPFailed',
        type: 'number',
      },
    ];
    this.champsHardcoded = ['ForageDetail'];

    this.recherche = {
      LigneRecuePartiellement: true,
      //ItemNumber : undefined,
      CreationDate_DATE_FILTER_GE: undefined,
      CreationDate_DATE_FILTER_LE: undefined,
      InvoiceNumber: undefined,
      ERP_Purchase_Number: undefined,
    };

    this.orderBy = 'creationDate desc';
  }

  champsDisplay: any[];

  DateDebutRecherche: Date;
  DateFinRecherche: Date;

  TitreGlobalPage: string = 'Home Receive File - Sommaire des factures HomeHardware recu';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionDetail';

  ngOnInit(): void {
    super.ngOnInit();
    var firstDay = new Date(); //
    var lastDay = new Date(); //
    var firstdaycalc = new Date(firstDay.setDate(firstDay.getDate() - 30));
    var lastdaycalc = new Date(lastDay.setDate(lastDay.getDate() - 0));
    this.DateDebutRecherche = firstdaycalc;
    this.DateFinRecherche = lastdaycalc;
  }

  rechercher() {
    this.additionnalUrlParams = [];
    /*let datedebut = this.route.snapshot.paramMap.get('DateDebutRecherche');
    let dateFin = this.route.snapshot.paramMap.get('DateFinRecherche'); */

    if (this.DateDebutRecherche) this.additionnalUrlParams.push({ key: 'DateDebutRecherche', value: this.formatDate(this.DateDebutRecherche) });

    if (this.DateFinRecherche) this.additionnalUrlParams.push({ key: 'DateFinRecherche', value: this.formatDate(this.DateFinRecherche) });

    this.recherche.CreationDate_DATE_FILTER_GE = this.DateDebutRecherche;
    this.recherche.CreationDate_DATE_FILTER_LE = this.DateFinRecherche;

    this.launchRefresh();
  }
}
