<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="main-page-container">
  <div class="listpage-container">
    <!--Grosse barrure indique que des changemnets sont en cours-->
    <div *ngIf="childHaveChanges" class="modal-preventChange" (click)="popUpConfirmDiscard()">Changements détectés...</div>
    <div class="ss-list-button-container">
      <button mat-stroked-button (click)="createNew()" color="primary"><mat-icon>add</mat-icon> Ajouter un taux</button>
    </div>

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      filterable="menu"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="modifier($event)"
    >
      <!-- COLUMNS -->

      <!-- SS_OrderNo -->
      <kendo-grid-column field="RateMainCode" title="Code Principal" [width]="50" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <!-- SS_OrderDate -->
      <kendo-grid-column field="RateSubCode1" title="Sous-Code" [width]="50" [minResizableWidth]="50" filter="text"> </kendo-grid-column>

      <!-- SS_OrderDate -->
      <kendo-grid-column field="RateAmount" title="Valeur Taux" [width]="50" [minResizableWidth]="50" filter="numeric"> </kendo-grid-column>
    </kendo-grid>
  </div>
  <app-rates-form
    #myChildForm
    *ngIf="showChildForm"
    [currentItem]="selectedItem"
    class="formpage-container"
    (submitEmitterChildHaveChanges)="onChangeChildState($event)"
    (submitEmitterForceRefresh)="OnChildAskForForceRefresh($event)"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)"
  ></app-rates-form>
</div>
