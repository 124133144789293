import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { Observable, tap, ReplaySubject, map } from 'rxjs';
import { toODataString, State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { ProductCombo } from '../models/ProductCombo';

@Injectable({
  providedIn: 'root',
})
export class ProductComboService {
  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  private BASE_URL = `${environment.apiUrl}ProductComboOverrideDimensions`;

  public ProductCombos: ReplaySubject<any> = new ReplaySubject<any>(1);

  // ODATA : STATE
  odata_ProductCombo(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.ProductCombos.next(response);
      })
    );
  }

  // ODATA  : STRING
  public odataStr_ProductCombo(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${this.BASE_URL}?${oDataFullString}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.ProductCombos.next(response);
      })
    );
  }

  get(id: number): Observable<ProductCombo> {
    let uri = `${this.BASE_URL}?$filter=id eq ${id}`;
    return this.httpClient.get<ProductCombo>(uri).pipe(map((x: any) => x.value[0]));
  }

  GetCombosForOneProduct(sku: string): Observable<ProductCombo[]> {
    let uri = `${this.BASE_URL}/GetComboOverrideDimensionsForOneProduct?sku=${sku}`;
    return this.httpClient.get<any>(uri);
  }

  create(pc: ProductCombo): Observable<any> {
    let uri = `${this.BASE_URL}/CreateProductComboOverrideDimensions`;
    return this.httpClient.post(uri, pc);
  }

  update(pc: ProductCombo): Observable<ProductCombo> {
    let uri = `${this.BASE_URL}/ModifyProductComboOverrideDimensions/${pc.id}`;
    return this.httpClient.put<ProductCombo>(uri, pc);
  }
}
