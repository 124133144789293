import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PackageTypes } from '../models/packageTypes';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackagetypesService {
  constructor(private httpclient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}PackageTypes`;

  getAll(): Observable<PackageTypes[]> {
    return this.httpclient.get<PackageTypes[]>(`${this.BASE_URL}?$orderby=DisplayIndex`);
  }

  get(id: number): Observable<PackageTypes> {
    return this.httpclient.get<PackageTypes>(`${this.BASE_URL}/${id}`);
  }

  create(pt: PackageTypes): Observable<any> {
    return this.httpclient.post(`${this.BASE_URL}`, pt);
  }

  update(pt: PackageTypes): Observable<any> {
    return this.httpclient.put(`${this.BASE_URL}/${pt.id}`, pt);
  }

  delete(id: number): Observable<any> {
    return this.httpclient.delete(`${this.BASE_URL}/${id}`);
  }
}
