import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReceptionService } from '../../service/reception.service';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { NumericKeyboard } from '../../models/numericKeyboard';

import { MatDialog } from '@angular/material/dialog';

import { LocalStorage } from '../../models/enum/LocalStorage';
import { ApiPostScan1Action_V2 } from '../../models/API/ApiPostScan1Action';
import { Scan1Destination } from '../../models/scan1Destination';
import { OData } from 'src/app/models/oData/OData_file';
import { HttpClient } from '@angular/common/http';
import { Station } from '../../models/enum/Station';
import { Item } from '../../models/item';

import { PopConfirmComponent } from '../core/pop-confirm/pop-confirm.component';
import { ProductService } from 'src/app/service/product.service';
import { DialogProblemComponent } from '../problem/dialog-problem/dialog-problem.component';
import { APIPostProblem } from 'src/app/models/API/APIPostProblem';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScanProblemTypesService } from 'src/app/service/scanProblemTypes.service';
import { ItemReceptionCompiledDTO_Overall } from 'src/app/models/API/ItemReceptionCompiledDTO_Overall';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { catchError, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-scan1_OldBefore202405',
  templateUrl: './scan1-oldV2Before202405.component.html',
  styleUrls: ['./scan1-oldV2Before202405.component.scss'],
})
export class Scan1Component_OldBefore202405 implements OnInit {
  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private productsService: ProductService,
    private route: Router,
    private httpClient: HttpClient,
    private receptionService: ReceptionService,
    private snack: MatSnackBar,
    private scanProblemTypesService: ScanProblemTypesService,
    private popUpDialogService: PopUpDialogService
  ) {}

  @ViewChild('upc') Input_UPC: ElementRef;

  // Initialisation des formulaires
  formT: UntypedFormGroup;
  formSKU: UntypedFormGroup;

  CurrentProductInfo = {} as Item;
  barcodeScanned = ''; //on peut vouloir noter le code-BARRE utilisé

  /** Tableau principal qui est exposé dans la liste des destinations possible du Scan1 */
  listPossibleDestination: ItemReceptionCompiledDTO_Overall[] = [];

  Selection_NbTraitementSelection: number = 0;
  Selection_ListDestination: string;
  Selection_ListSiteOrderID: string;
  Selection_QtyEnCommandeTotale: number;
  Selection_QtyAlreadyScanned: number;

  // bool qui cache tout excepté le tableau permettant de choisir
  modalSelectSKU = false;

  displayNoDataAlertMessage = false; //indique qu'il n'y a aucune réception à faire pour le rpoduit scanné
  ProductDontExistError = false; //indique que le PRODUIT saisie n'Existe pas (ou le code upc...)

  // endpointReception
  scanQte = 1;
  CurrentSelectedSku: string = '';
  LastScannedSkuOrBarCode: string = 'WILL_BE_REPLACED'; //permet de stocker le dernier sku OU barCode scanné...

  LineNumberToStartAutoAssigned = 1;

  // clavier numérique
  numericKeyboard: NumericKeyboard;

  // droits scan 2
  scan2rights: boolean;

  QteTotalToReceive = 0;
  QteAlreadyScanned = 0;
  TooltipQtyToReceive: string = undefined; // Tooltip pour "Qté totale réception"

  // indique si les rapports sont affichées ou non.
  isReportShown = false;

  // Pour désactivation du bouton Rechercher si pas de station sélectionnée
  UneStationEstSelectionnee = true;
  // Éventuel message d'erreur
  globalErrorMessage: string = '';

  // liste des destinations possible
  listdestination: Scan1Destination[] = [];

  // oData
  odataGetHeadersReception: OData = new OData(this.httpClient);
  qteScannedHeader: boolean = null;
  qteTotalHeader: boolean = null;

  // Actions On HTML
  lockConfirmButton: boolean = false;

  ngOnInit(): void {
    localStorage.setItem(LocalStorage.SCAN_TYPE_CURRENT_MODE, Station.SCAN1);

    //on va aller chercher la liste des destinations possible
    this.receptionService.GetListDestinationCodeScan1().then((result) => {
      this.listdestination = result;
    });

    // Initialisation des formulaires
    this.initForm();

    // Initialisation du clavier numérique
    this.numericKeyboard = new NumericKeyboard(false, 'Quantité de votre scan');

    // On regarde si l'user dispose des droits pour le scan 2 (affichage bouton validation)
    this.scan2rights = this.authService.rights.scan2Emballeur;

    // Ne pas permettre de faire des Scan1 si pas de station sélectionnée
    if (this.authService.info.stationCode === 'AUCUNE_SÉLECTIONNÉE') {
      this.UneStationEstSelectionnee = false;
      this.globalErrorMessage = 'Une station de travail doit être sélectionnée';
    }

    this.scanProblemTypesService.getAll(); //fire and forget..c'est juste pour forcer la cache à se rafraichir.
  }

  /**
   * Initialise les formulaires
   * @private
   */
  private initForm(): void {
    // Formulaire UPC/Barcode
    this.formT = this.formBuilder.group({
      data: ['', Validators.required],
    });
  }

  /**
   * CHERCHER PRODUIT -> Fonction qui s'active quand on click sur RECHERCHER() un UPC/Sku
   */
  public async onSubmitFormSearchItem() {
    let Sku_or_upc = this.formT.get('data').value; // on récupère la valeur du formulaire

    //Si l'usager re-scan avec le MEME code produit ou BarCode, et que la liste de destination est BIEN populé, alors on va INCRÉMENTER les QTÉS SCANNÉES.  Cela permettra d'augmenter l'Efficacité des scan de 3-4 items (beep-beep-bepp-bepp donne 4 unités)
    if (this.listPossibleDestination && this.listPossibleDestination.length > 0 && this.LastScannedSkuOrBarCode === Sku_or_upc) {
      this.scanQte++;
      this.AutoAssignQtyToCommand();
      return;
    }

    this.LastScannedSkuOrBarCode = Sku_or_upc;
    this.displayNoDataAlertMessage = false; // on cache un éventuel message d'erreur
    this.ProductDontExistError = false;
    this.scanQte = 1; //on reset la qté scannée à 1
    this.barcodeScanned = ''; //on invalide le barcode à chaque scan..

    //***************************************
    //on va permettre de passer un code produit au format 9999-999
    if (Sku_or_upc !== undefined) {
      Sku_or_upc = Sku_or_upc.trim();

      var test = Sku_or_upc.substring(4, 1);
      if (Sku_or_upc.substring(4, 5) === '-' && Sku_or_upc.length === 8) {
        var FirstPart = Sku_or_upc.substring(0, 4);
        var SecondPart = Sku_or_upc.substring(5, 8);
        Sku_or_upc = FirstPart.concat(SecondPart);
      }
    }
    //***************************************

    //***************************************
    //Get product data
    //si c'est un barcode, on va tenter immédiatement de convertir ça en code produit...
    if (this.productsService.isUpc(Sku_or_upc)) {
      this.barcodeScanned = Sku_or_upc; //on va noter le barcode...

      var data = await this.productsService.getNoProductFromUpc(Sku_or_upc).toPromise();
      if (data.length !== 0) {
        await this.SearchLinesForProductNumber(data[0].sku);
      } else {
        this.ProductDontExistError = true;
      }
    } //si c'est PAS un BarCode...
    else {
      this.SearchLinesForProductNumber(Sku_or_upc);
    }
    //***************************************
  }

  /**
   * Fonction qui s'active quand l'utilisateur sélectionne une ligne de réception ou clique sur le bouton correspondant
   * à la commande dans la fenêtre modale de choix du SKU multiple.
   * va FORCER la sélection pour la ligne en cours
   * @param command
   */
  public OnClickLigneReception(command: ItemReceptionCompiledDTO_Overall, event): void {
    let boolCurrentRowWasAlreadyChecked = command.IsSelectedForProcess;
    let SelectedRows = this.listPossibleDestination.filter((x) => x.IsSelectedForProcess === true);

    this.LineNumberToStartAutoAssigned = command.priority;

    SelectedRows.forEach((dataline) => {
      dataline.IsSelectedForProcess = false;
      dataline.qtyAssignedScan1 = 0;
    });

    command.IsSelectedForProcess = !command.IsSelectedForProcess;

    if (command.IsSelectedForProcess === true) {
      this.scanQte = 1;
      command.qtyAssignedScan1 = this.scanQte; // command.qtyToReceive - this.QteAlreadyScanned;
      if (this.Selection_NbTraitementSelection > 0) {
        //si on coche une 2eme (ou 3eme ou 4eme ligne), alors les lignes qui étaient PRÉCÉDEMMENT cochées devront être traité INTÉGRALEMETN..????
      }
    } else {
      command.qtyAssignedScan1 = 0;
    }

    //on recalcul quelques infos
    this.RecalculerInfoSelection();
  }

  /**
   * Fonction qui se déclenche au clic sur le bouton confirmation+step2.
   */
  on_main_confirmation_and_scan2(): void {}

  /**
   * Fonction qui se déclenche au clic sur le bouton de confirmation
   */
  public async on_main_confirmation() {
    if (this.Selection_NbTraitementSelection === 0) {
      this.popUpDialogService.SimpleErrorAlert('Vous devez sélectionner une ligne à traiter.', 'Aucune ligne à traiter');
      return;
    }

    if (!Number.isSafeInteger(this.scanQte)) {
      this.popUpDialogService.SimpleErrorAlert("Le nombre d'unité saisie ne semble pas numérique (Qté Scan:" + this.scanQte + ').', 'Quantité SCAN invalide');
      return;
    } else {
      this.scanQte = +this.scanQte;
    }

    const MyScan1Actions: ApiPostScan1Action_V2[] = [];
    let SelectedRows = this.listPossibleDestination.filter((x) => x.IsSelectedForProcess === true);

    let QtyTotalScanned = 0;

    SelectedRows.forEach((dataline) => {
      let RemainingQty = dataline.qtyToReceive - dataline.qtyAlreadyProcessed;
      let QtyToAssigneHere = this.Selection_NbTraitementSelection > 1 ? RemainingQty : this.scanQte;
      QtyToAssigneHere = dataline.qtyAssignedScan1;
      QtyTotalScanned += QtyToAssigneHere;

      const line: ApiPostScan1Action_V2 = {
        ProductNo: this.CurrentSelectedSku,

        QtyScanned: QtyToAssigneHere,
        QtyRemaining: RemainingQty,

        ScannedBarCode: this.barcodeScanned,
        ListPossibleDVL: dataline.listPossibleDVL,

        ListPossibleItemReceptionDetailId: dataline.listPossibleItemReceptionDetailId,
        Destination: dataline.FeededDestination.codeDestination,
      };

      MyScan1Actions.push(line);
    });

    if (this.Selection_NbTraitementSelection === 1) {
      let MyScanData = MyScan1Actions[0];
      //************************
      //pop-=up de confirmation de sur-assignation
      if (MyScanData.QtyScanned > MyScanData.QtyRemaining || MyScanData.QtyScanned < this.scanQte) {
        let popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Sur-assignation des quantitées',
            texte: "Attention!<br/><br/>Vous ne pouvez pas sur-assigner de quantitée.<br/><br/>Cliquez sur 'problème' pour les quantitées en over.",
            mainIcon: 'warning',
            showDismissiveButton: false,
            affirmativeActionColor: 'warn',
          },
        });
        var monRetour = await popupResult.afterClosed().toPromise();
        return;
      }

      if (MyScanData.Destination == 'SHOPATRON') {
        let popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Confirmation boite Shopatron',
            texte: "S'agit-il bel et bien d'une boite avec les stickers Shopatron oranges?",
          },
        });

        var monRetour = await firstValueFrom(popupResult.afterClosed());
        if (monRetour !== true) {
          return;
        }
      }
    } else {
      //************************
      //pop-=up de confirmation de sur-assignation

      let QteRestante = this.QteTotalToReceive - this.QteAlreadyScanned;
      if (QteRestante < this.scanQte) {
        let popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Sur-assignation des quantitées',
            texte:
              'Attention!<br/><br/>Vous ne pouvez pas assigner ' +
              this.scanQte +
              " unité(s) de ce produit, car il semble n'en rester que  " +
              QteRestante +
              " à réceptionner.  <br/><br/>Cliquez sur 'problème' pour les quantitées en over.",
            mainIcon: 'warning',
            showDismissiveButton: false,
            affirmativeActionColor: 'warn',
          },
        });

        var monRetour = await popupResult.afterClosed().toPromise();
        return;
      }

      //on a sélectionné PLUSIEURS lignes différentes à traiter ONE-SHOT
      let strMessage = 'Vous avez sélectionné PLUSIEURS lignes.<br/><br/>';
      strMessage += 'Confirmez-vous avoir<p> <h3>' + this.scanQte + ' unités </h3></p> devant vous?<br/><br/>';

      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Confirmation mode multiple',
          texte: strMessage,
          affirmativeActionName: 'Oui, je confirme',
          dismissiveActionName: 'Non, annuler',
        },
      });

      var monRetour = await popupResult.afterClosed().toPromise();
      if (monRetour !== true) {
        return;
      }
    }

    //si on se rend jusqu'ici, c'est que toute les confirmation qui avait à avoir ont été remplie
    this.confirmerLigneScan1(MyScan1Actions);
  }

  /**
   * Fonction qui se déclenche au clic sur le bouton Problem.
   */
  public on_main_Problem(): void {
    if (this.CurrentSelectedSku === undefined || this.CurrentSelectedSku === '') {
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Problème Invalide',
          texte: 'Vous devez absolument saisir un code de produit pour faire lever un problème.',
          mainIcon: 'warning',
          showDismissiveButton: false,
          affirmativeActionName: 'OK',
        },
      });
      return;
    }

    //veut-on récupérer la ligne de réception en cours si, par exemple, c'est la ligne DVL en cours qui contient un produit cassé??

    let myScaninfo: ItemReceptionCompiledDTO_Overall = undefined;
    let CheckedItems = this.listPossibleDestination.filter((x) => x.IsSelectedForProcess === true);

    //si plus qu'une sélection....
    if (CheckedItems.length > 1) {
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Problem Invalide',
          texte: "Vous ne pouvez pas Problemr plusiuers lignes de réception à la fois.<br/><br/>N'en sélectionnez qu'une, ou aucune.",
          mainIcon: 'warning',
          showDismissiveButton: false,
          affirmativeActionName: 'OK',
        },
      });
      return;
    } else if (CheckedItems.length === 1) {
      myScaninfo = CheckedItems[0];
    }

    let MyMsg: string = 'Attention!  Vous vous apprêtez à déclarer un problème.  Info :';
    MyMsg += '<br/><br/>Produit sélectionné : #' + this.CurrentSelectedSku + '(' + this.CurrentProductInfo?.Title + ')';
    if (myScaninfo === undefined)
      MyMsg += '<br/><br/>Ligne de réception : <u>AUCUNE</u>.<br/>-->Cela signifie que les quantitées à recevoir vont rester tel-quelle.';
    else MyMsg += '<br/><br/>Ligne de réception : SÉLECTIONNÉ (Destination : ' + myScaninfo.FeededDestination.codeDestination + ').';
    MyMsg += '<br/><br/>Veuillez choisir un motif au problème et une QUANTITÉE en problème (par défaut, 1)';

    //**********************************************
    //on prépare les données au problème
    let MyApidata: APIPostProblem = {
      productNumber: this.CurrentSelectedSku,
      ScannedBarCode: this.barcodeScanned,
      productDescription: this.CurrentProductInfo.Title,
      fonctionOrig: Station.SCAN1, //on indique que au problème provient du scan1
      stationOrig: this.authService.info.stationCode,
      userOrig: this.authService.info.name,
      qtyProblem: 1,
      displayedMessage: MyMsg,
    };
    if (myScaninfo !== undefined) MyApidata.ListPossibleItemReceptionDetailId = myScaninfo.listPossibleItemReceptionDetailId;
    //**********************************************

    //**********************************************
    // afficher le  popup
    const ProblemDialog = this.matDialog.open(DialogProblemComponent, {
      data: MyApidata,
    });

    // Exécution de la réponse après la fermeture.
    ProblemDialog.afterClosed().subscribe((resultDialogProblem) => {
      //si l'usager clique sur 'CONFIRMER'
      if (resultDialogProblem !== null) {
        //on pousse vers le SERVICE, qui poussera vers l'API
        this.receptionService.PostProblemScan1(resultDialogProblem).subscribe((resulat) => {
          console.log('Resultat Problem : ' + resulat);
          this.snack.open('Le problème a été soumit correctement.  Vous pouvez poursuivre.', 'OK', { duration: 3000 });
          this.resetPage(); // on reset la page
        });
      }
    });
    //**********************************************
  }

  /**
   * Fonction qui se déclenche au clic sur le bouton cancel
   */
  public on_main_cancel(): void {
    this.resetPage(); // on reset la page
  }

  /**
   * Permet d'ouvrir un nouvel onglet sur la page spécifié
   */
  public goToLink(url: string) {
    window.open(url, '_blank');
  }

  public scanQte_AddOne(): void {
    this.scanQte++;
    this.AutoAssignQtyToCommand();
  }
  public scanQte_RemoveOne(): void {
    this.scanQte--;
    this.AutoAssignQtyToCommand();
  }

  public Selection_QtyRemainingToScan(): number {
    return this.Selection_QtyEnCommandeTotale - this.Selection_QtyAlreadyScanned;
  }

  //********************************************************** */
  //**********     FONCTIONS DU CLAVIER NUMÉRIQUE   ********** */
  //********************************************************** */
  /**Affiche le clavier numérique */
  public openNumericKeyboard(): void {
    this.numericKeyboard.open(this.scanQte.toString());
  }

  public CloseNumericKeyboard(confirmValue: boolean): void {
    const data = this.numericKeyboard.reset();
    if (confirmValue) {
      this.scanQte = parseInt(data, 10);
      this.AutoAssignQtyToCommand();
    }
  }

  /**
   * CLAVIER NUMÉRIQUE : permet d'ajouter une unité string aux NbScan du CLAVIER NUMÉRIOQUE (pas la qté scannée du scan1)
   * @param removeAll
   */
  public addNbrScanne(qte: string): void {
    this.numericKeyboard.addValue(qte);
  }

  /**
   * CLAVIER NUMÉRIQUE : permet d'ajouter une unité précise aux NbScan (pas la qté scannée du scan1)
   * @param removeAll
   */
  public addNbrScanneAsNumber(qte: number): void {
    if (isNaN(parseInt(this.numericKeyboard.valueNumericKeyboard, 10))) {
      this.numericKeyboard.valueNumericKeyboard = '0';
    }
    this.numericKeyboard.valueNumericKeyboard = (parseInt(this.numericKeyboard.valueNumericKeyboard, 10) + qte).toString(10);
  }

  /**
   * CLAVIER NUMÉRIQUE : Fonction permettant d'effacer UN ou TOUS les chiffres inscrits dans le keypad
   * @param removeAll
   */
  public removeNbrScanne(removeAll: boolean): void {
    this.numericKeyboard.remove(removeAll);
  }
  //********************************************************** */
  //********************************************************** */

  /**
   * Fonction qui reset toutes les valeurs de la page.
   * @private
   */
  private resetPage(): void {
    this.listPossibleDestination = [];
    this.CurrentProductInfo = {} as Item;
    //this.OLDMETHODE_UniqueSelectedCommand = undefined;
    this.displayNoDataAlertMessage = false;
    this.ProductDontExistError = false;
    this.scanQte = 1; //on reset la qté scannée à 1
    this.formT.reset('data');
    this.barcodeScanned = '';
    this.Selection_NbTraitementSelection = 0 as number;
    document.getElementById('upc').focus();
  }

  /**
  Cette méthode permet de convertir les données recu de l'API SCAN1 vers la classe contenant l'info
  */
  private populate_sku(e: ItemReceptionCompiledDTO_Overall): void {
    let MyDest = e.destination;
    let IsDVL: boolean = false;
    let MyEnumDest: Scan1Destination = undefined;
    if (MyDest !== undefined) {
      MyEnumDest = this.destinationStringToEnum(MyDest);
      let Mycode = MyEnumDest.codeDestination.substr(0, 3);
      if (Mycode == 'DVL') IsDVL = true;
    }

    let strMessage = '';
    /*
    if (IsDVL && e.listDetailAssignation && e.listDetailAssignation.length > 0) {
      let NbDVLTotal = e.listDetailAssignation.length;
      let NbHighPriority = 0;

      e.listDetailAssignation.forEach((detailAssign) => {
        if (detailAssign && detailAssign.CA_IsToPriorized && detailAssign.CA_IsToPriorized === true) {
          NbHighPriority++;
        }
      });

      strMessage = NbDVLTotal.toString() + ' DVL trouvée(s).';
      if (NbHighPriority > 0) strMessage += ' - ' + NbHighPriority.toString() + ' prioritaire!';
    }
*/
    const line = {
      priority: e.priority,
      FeededDestination: MyEnumDest,

      qtyToReceive: e.qtyToReceive,
      qtyAssignedScan1: 0,
      qtyAlreadyProcessed: e.qtyAlreadyProcessed,
      CA_IsToPriorized: e.CA_IsToPriorized,
      infoDetailOverral: strMessage,

      cleanedSku: e.cleanedSku,
      IsSelectedForProcess: false,
      IsDVL: true,
      destination: e.destination,
      subDestination: e.subDestination,
      listPossibleDVL: e.listPossibleDVL,
      listPossibleItemReceptionDetailId: e.listPossibleItemReceptionDetailId,
    } as ItemReceptionCompiledDTO_Overall; // On génère un objet conforme à l'interface

    this.listPossibleDestination.push(line); // On ajoute la ligne au tableau
  }

  /**
   * Fonction qui permettant de recalculer des informations graphiques selon les lignes sélectionnées
   */
  private RecalculerInfoSelection() {
    //on va sélectionner les lignes qui sont marquées 'sélectionnées'
    let SelectedRows = this.listPossibleDestination.filter((x) => x.IsSelectedForProcess === true);

    //Nb de ligne totale sélections..
    this.Selection_NbTraitementSelection = SelectedRows.length;

    //********************
    //on regarde la ou LES destinations en sélections...
    let ListDistinctDestination = [...new Set(SelectedRows.map((item) => item.FeededDestination.destination_scan1))];
    if (ListDistinctDestination.length === 0) this.Selection_ListDestination = '';
    else if (ListDistinctDestination.length === 1) this.Selection_ListDestination = ListDistinctDestination[0];
    else this.Selection_ListDestination = 'Multiple';
    //********************

    //********************
    //gestion des quantitées
    this.Selection_QtyEnCommandeTotale = SelectedRows.reduce((MyTotal, { qtyToReceive }) => MyTotal + qtyToReceive, 0);
    this.Selection_QtyAlreadyScanned = SelectedRows.reduce((MyTotal, { qtyAlreadyProcessed }) => MyTotal + qtyAlreadyProcessed, 0);

    //********************
  }

  /*
    Fonction qui sert à confirmer la réception d'une ligne du scan1
  */
  private confirmerLigneScan1(MyScanData: ApiPostScan1Action_V2[]): void {
    this.lockConfirmButton = true;
    this.receptionService
      .Scan1_PostProductAssignations_V2(MyScanData)
      .pipe(
        catchError((err) => {
          this.lockConfirmButton = false;
          throw err;
        })
      )
      .subscribe((data) => {
        // si l'API répond favorablement
        this.lockConfirmButton = false;
        console.log(data);
        this.resetPage(); // on reset la page
      });
  }

  /**
   *
   * @param sku
   * @returns
   */

  private async SearchLinesForProductNumber(sku: string): Promise<void> {
    //on assigne le CODE PRODUIT à une variable GLOBALE
    this.CurrentSelectedSku = sku;

    //on va chercher dans l'API le détail du produit, nom, image, etc....
    let myProductInfo = await this.productsService.GetProductDetail(this.CurrentSelectedSku).toPromise();

    //si produit non trouvé
    if (myProductInfo.length === 0) {
      this.ProductDontExistError = true;
      return;
    }

    // Assign product information to a global variable
    this.CurrentProductInfo = {
      SkuOrig: this.CurrentSelectedSku,
      Title: myProductInfo[0].ProductShortDescFR,
      imageUrl: myProductInfo[0].MainImageURL,
      SkuCleaned: myProductInfo[0].ProductNo,
    };

    //ici, nous allons aller chercher les informations de réceptions sur ce code produit­.  ce n'est que pour alimenter un drop-down des réceptions précécentes
    this.GenerateLineReceptionMetadata(this.CurrentSelectedSku);

    //ici, on va chercher les lignes de RÉCEPTIONS pour ce produit
    let MyPossibleDestination = await this.receptionService.Scan1_GetProductDestinationOverall_OLD_BeforeV202405(this.CurrentSelectedSku).toPromise();

    // on vide les tableaux
    this.listPossibleDestination = [];
    // si l'api retourne une réponse vide, on affiche un message afin d'en informer le lecteur
    if (MyPossibleDestination.length === 0) {
      this.displayNoDataAlertMessage = true;
      return;
    }

    MyPossibleDestination.forEach((e) => {
      this.populate_sku(e);
    });

    if (this.listPossibleDestination.length > 0) {
      this.OnClickLigneReception(this.listPossibleDestination[0], null);
    }
  }

  /**
   * Permet d'aller chercher les quantitées totales à recevoir/recu du produit passé en param
   * @constructor
   */
  private GenerateLineReceptionMetadata(sku: string): void {
    const oData = new OData(this.httpClient);

    new OData(this.httpClient)
      .getOdatafromApi(
        OData.generateODataLink('ItemReceptionDetails', [
          {
            key: '$filter',
            //value: 'ItemReceptionHeaderId eq ' + scan1HeaderId + " and ProductNumber eq '" + sku + "'",
            value: "ProductNumber eq '" + sku + "' and ItemReceptionHeader/IsCompleted eq false",
          },
          {
            key: '$select',
            value: 'ItemReceptionHeaderId,ProductDescription,QtyToReceive,QtyReceived  ',
          },
        ])
      )
      .subscribe((data) => {
        let QteTotalToReceive = 0;
        let QteTotalScanned = 0;
        let NbLigneTotale = 0;
        let NbLigneTermine = 0;
        let lignesTooltipQtyToReceive = ['Qté à recevoir / Qté reçue : ', ''];

        data.forEach((e) => {
          NbLigneTotale++;
          QteTotalToReceive += e.QtyToReceive;
          QteTotalScanned += e.QtyReceived;

          lignesTooltipQtyToReceive.push(`Header #${e.ItemReceptionHeaderId} : ${e.QtyToReceive} / ${e.QtyReceived}`);
        });

        this.QteTotalToReceive = QteTotalToReceive;
        this.QteAlreadyScanned = QteTotalScanned;
        this.TooltipQtyToReceive = lignesTooltipQtyToReceive.join('\n');
      });
  }
  /**
   * Convertis la destination données par l'API en station de travail compréhensible par le système.
   * @param dest string contenant la destination
   */
  private destinationStringToEnum(dest: string): Scan1Destination {
    let result = this.listdestination.find((x) => x.codeDestination === dest);
    if (result === undefined) {
      result = {
        codeDestination: 'ERROR (' + dest + ')',
        destination_scan1: 'ERROR',
        description: 'Destination not found',
        priority: -1,
      } as Scan1Destination;
    }
    return result;
  }

  /** Cette méthode va permettre d'assigner automatiquement des QTÉS selon la priorité.
   * * sert surtout quand l'usager augmente le nombre d'unité SCANNÉE [en double-scan, ou en cliquant sur +,  ou en inscrivant une quantitée directe]
   * l'auto assignation 'par d'en haut' et va sélectionner les lignes voulues...
   */
  private AutoAssignQtyToCommand() {
    //on arrive ici quand on AJOUTE ou SOUSTRAIT des QTÉS...

    if (this.listPossibleDestination.length === 0) return;

    let TotalQtyScanned = this.scanQte;
    let TotalQtyAlreadyAssigened = 0;
    let QuantityRemaining = this.scanQte;
    let SelectedRows = this.listPossibleDestination.filter((x) => x.IsSelectedForProcess === true);

    //on passe toute les lignes du tableau
    this.listPossibleDestination.forEach((maligne) => {
      if (maligne.priority < this.LineNumberToStartAutoAssigned) {
      } else {
        //si il reste des quantitées à assigner (versus le QTÉ SCAN)
        if (QuantityRemaining > 0) {
          maligne.IsSelectedForProcess = true;

          let QteAssignedRemainingHere = maligne.qtyToReceive - maligne.qtyAlreadyProcessed;
          if (QteAssignedRemainingHere <= 0) QteAssignedRemainingHere = 0;

          maligne.qtyAssignedScan1 = QuantityRemaining;

          //ne jamais assigner plus que ce que la ligne peut prendre...
          if (maligne.qtyAssignedScan1 >= QteAssignedRemainingHere) maligne.qtyAssignedScan1 = QteAssignedRemainingHere;

          QuantityRemaining -= maligne.qtyAssignedScan1;
        } else {
          maligne.IsSelectedForProcess = false;
          maligne.qtyAssignedScan1 = 0;
        }
      }
    });

    //on recalcul quelques infos
    this.RecalculerInfoSelection();
  }
}
