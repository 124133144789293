import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChannelAdvisorProductAttributes } from '../models/ChannelAdvisor/ChannelAdvisorProductAttributes';
import { InfoCalculCoutantCommande } from '../models/shipstation/InfoCalculCoutantCommande';

@Injectable({
  providedIn: 'root',
})
export class ChanneladvisorService {
  constructor(private httpClient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}expedition/`;

  GetProductAttributes(SkuRaw: string, attributsJson: string = null): Observable<ChannelAdvisorProductAttributes[]> {
    let url = `${this.BASE_URL}GetChannelAdvisorProductAttributes?SkuRaw=${SkuRaw}`;
    if (attributsJson != null) url += `&attributs=${attributsJson}`;
    let myLocalObservable = this.httpClient.get<ChannelAdvisorProductAttributes[]>(url);
    return myLocalObservable;
  }

  UpdateBoiteProduit(SkuRaw: string, attribut: string, valeur: string) {
    let url = `${this.BASE_URL}UpdateChannelAdvisorProductAttribute?SkuRaw=${SkuRaw}&attribut=${attribut}&valeur=${valeur}`;
    let myLocalObservable = this.httpClient.post(url, null);
    return myLocalObservable;
  }
}
