<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre"><mat-icon>account_balance_wallet</mat-icon>Achat labels</h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    filterable="menu"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
        <mat-icon>delete</mat-icon>
        Vider les filtres
      </button>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>
    <!-- COLUMNS -->
    <kendo-grid-excel fileName="Products.xlsx"></kendo-grid-excel>

    <!-- SiteOrderId -->
    <kendo-grid-column field="SiteOrderId" title="ID en-tête" [width]="60" [minResizableWidth]="60" filter="text"></kendo-grid-column>

    <!--Order Date-->
    <kendo-grid-column field="Shipstation_OrderDate" title="Date" [width]="50" [minResizableWidth]="50" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.Shipstation_OrderDate | date : "yyyy-MM-dd HH:mm" }}
      </ng-template>
    </kendo-grid-column>

    <!--shipping Label-->
    <kendo-grid-column title="Label" [width]="28" [minResizableWidth]="28" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <a *ngIf="dataItem.ShippingLabel_Info_LastPDFLabelFullPath" target="_blank" href="{{ dataItem.ShippingLabel_Info_LastPDFLabelFullPath }}">
            <button kendoButton>
              <div class="si-btn-icon">
                <span>Voir</span>
                <mat-icon>receipt</mat-icon>
              </div>
            </button>
          </a>
          <span *ngIf="!dataItem.ShippingLabel_Info_LastPDFLabelFullPath">(absent)</span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- CArrier name -->
    <kendo-grid-column title="Carrier" [width]="30" [minResizableWidth]="30" filter="text" media="(min-width: 1300px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          {{ dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate?.carrierName }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- service du carrier -->
    <kendo-grid-column title="Service" [width]="30" [minResizableWidth]="30" filter="text" media="(min-width: 1300px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          {{ dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate?.serviceName }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Profit -->
    <kendo-grid-column title="Profit" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <!-- Popover (devra etre externaliser...du moins pas dans le datagrid...)-->
          <kendo-popover #myDetailCalculPopover>
            <ng-template kendoPopoverTitleTemplate>Détail du calcul du profit</ng-template>
            <ng-template kendoPopoverBodyTemplate>
              <!-- Ici, on va boucler sur le résultat du PIPE 'shippingCalcDetailsCalculProfit'.  ce Pipe retourne un array de string... -->
              <div *ngFor="let item of dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults | shippingCalcDetailsCalculProfit">
                {{ item }}
              </div>
            </ng-template>
            <ng-template kendoPopoverActionsTemplate></ng-template>
          </kendo-popover>

          <!--DIV du text affich-->
          <div kendoPopoverAnchor [popover]="myDetailCalculPopover" showOn="hover">
            <div>
              <button kendoButton (click)="OnClickCalcul(dataItem)" style="width: 90px; height: 30px">
                <span
                  *ngIf="
                    dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate != null &&
                    dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate.profit != null
                  "
                  >{{ dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate.profit | number : "1.2-2" }} $</span
                >
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Prix Envoi -->
    <kendo-grid-column title="Prix Envoi" [width]="25" [minResizableWidth]="25" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <ng-container *ngIf="dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.manuallySelectedRate != null; else autoSelectedPrice">
            ${{
              dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.manuallySelectedRate.shipmentCostNoTax +
                dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.manuallySelectedRate.otherCostNoTax +
                dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.manuallySelectedRate.mdpweb_custom_extraFees | number : "1.2-2"
            }}
          </ng-container>
          <ng-template #autoSelectedPrice>
            <ng-container *ngIf="dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate != null">
              ${{
                dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate.shipmentCostNoTax +
                  dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate.otherCostNoTax +
                  dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate.mdpweb_custom_extraFees | number : "1.2-2"
              }}
            </ng-container>
          </ng-template>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Raison rejet -->
    <kendo-grid-column title="Raison Rejet" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <kendo-popover #myPopoverError>
            <ng-template kendoPopoverTitleTemplate>Détail de l'Erreur</ng-template>
            <ng-template kendoPopoverBodyTemplate>
              <!-- Ici, on va boucler sur le résultat du PIPE 'shippingCalcDetailsCalculProfit'.  ce Pipe retourne un array de string... -->
              <div>
                {{ dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults | shippingCalcGetErrorPrettierPipe }}
              </div>
            </ng-template>
            <ng-template kendoPopoverActionsTemplate></ng-template>
          </kendo-popover>

          <div kendoPopoverAnchor [popover]="myPopoverError" showOn="hover">
            <u>
              <ng-container *ngIf="dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.autoSelectedRate == null">
                {{ dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.raisonRejetTitre }}
              </ng-container>
            </u>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Poids -->
    <kendo-grid-column title="Poids" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          {{ dataItem.ShippingLabel_Buying_LastCreationResults | convertStringToShipstationShipment | poidsEnLivres }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Dimensions -->
    <kendo-grid-column title="Dimensions" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          {{
            dataItem.ShippingLabel_Buying_LastCreationResults
              | convertStringToShipstationShipment
              | dimensionsEnPouces : dataItem.ShippingLabel_Buying_LastShippingRatesCalcResults?.infoCalculCoutantCommande?.CodeBoite
          }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- ShippingLabel_Info_LastTrackingNumber -->
    <kendo-grid-column
      field="ShippingLabel_Info_LastTrackingNumber"
      title="Last Tracking Number"
      [width]="50"
      [minResizableWidth]="50"
      filter="text"
    ></kendo-grid-column>

    <!-- ShippingLabel_Info_LastTotalPaidPrice -->
    <kendo-grid-column
      *ngIf="false"
      field="ShippingLabel_Info_LastTotalPaidPrice"
      title="prix payé"
      [width]="30"
      [minResizableWidth]="30"
      filter="text"
      format="C"
    ></kendo-grid-column>

    <!-- ShippingLabel_Info_LastCalculatedCost -->
    <kendo-grid-column
      *ngIf="false"
      field="ShippingLabel_Info_LastCalculatedCost"
      title="Cost"
      [width]="30"
      [minResizableWidth]="30"
      filter="text"
      format="C"
    ></kendo-grid-column>

    <!-- Lien Shipstation -->
    <kendo-grid-column title="Ship Station" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a kendoButton target="_blank" href="https://ship11.shipstation.com/orders/all-orders-search-result?quickSearch={{ dataItem.SiteOrderId }}">
          <button kendoButton>
            <div class="si-btn-icon">
              <img height="16" width="16" src="https://logos.shipstation.com/ipaas/ordersource/shipstation/icon.svg" />
              <span>Voir</span>
            </div>
          </button>
        </a>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
