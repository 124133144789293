import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendErpService } from 'src/app/service/backend-erp.service';
import { homeReceiveFileService } from 'src/app/service/homeReceiveFile.service';
import { ListComponent } from '../../core/list/list.component';

@Component({
  selector: 'hhfile-h01-get-details',
  templateUrl: './hhfile-h01-get-details.component.html',
  styleUrls: ['./hhfile-h01-get-details.component.scss'],
})
export class hhfileH01GetDetails extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private backendERPService: BackendErpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private MyHomeReceiveFileService: homeReceiveFileService
  ) {
    super(httpClient);

    this.endpoint = 'HomeReceiveFile';
    this.IsOdataEndpoint = true;
    this.autoload = true;

    this.champs = [
      {
        key: 'ctrl_OrigHHFileFullPath',
        nom: 'Fichier',
        type: 'string',
      },
      {
        key: 'CreationDate',
        nom: 'CreationDate',
        type: 'datetime',
      },
      {
        key: 'InvoiceNumber',
        nom: 'InvoiceNumber',
        type: 'number',
      },

      {
        key: 'PickWarehouse',
        nom: 'PickWarehouse',
        type: 'number',
      },
      {
        key: 'OrderNumber',
        nom: 'OrderNumber',
        type: 'number',
      },
      {
        key: 'OrderLine',
        nom: 'OrderLine',
        type: 'number',
      },
      {
        key: 'ItemNumber',
        nom: 'ItemNumber',
        type: 'number',
      },
      {
        key: 'ConsumerNumber',
        nom: 'ConsumerNumber',
        type: 'number',
      },
      {
        key: 'QuantityShipped',
        nom: 'QuantityShipped',
        type: 'number',
      },
      {
        key: 'QuantityOrdered',
        nom: 'QuantityOrdered',
        type: 'number',
      },

      {
        key: 'QuantityCancelled',
        nom: 'QuantityCancelled',
        type: 'number',
      },
      {
        key: 'DealerRetailPrice',
        nom: 'DealerRetailPrice',
        type: 'number',
      },
      {
        key: 'AccountingCost',
        nom: 'AccountingCost',
        type: 'number',
      },
      {
        key: 'AllInCost',
        nom: 'AllInCost',
        type: 'number',
      },
      {
        key: 'ERP_Purchase_Number',
        nom: 'ERP_Purchase_Number',
        type: 'string',
      },
      {
        key: 'ERP_Purchase_Line',
        nom: 'ERP_Purchase_Line',
        type: 'number',
      },
      {
        key: 'ERP_Purchase_CreatedDate',
        nom: 'ERP_Purchase_CreatedDate',
        type: 'datetime',
      },
      {
        key: 'ERP_Purchase_Error',
        nom: 'ERP_Purchase_Error',
        type: 'string',
      },
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
    ];
    this.champsHardcoded = [];

    this.orderBy = 'creationDate desc';

    this.recherche = {
      ItemNumber: undefined,
      CreationDate_DATE_FILTER_GE: undefined,
      CreationDate_DATE_FILTER_LE: undefined,
      InvoiceNumber: undefined,
      ERP_Purchase_Number: undefined,
    };
  }

  champsDisplay: any[];

  DateDebutRecherche: Date;
  DateFinRecherche: Date;

  TitreGlobalPage: string = 'Home Receive File - detail factures H01';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionDetail';

  ngOnInit(): void {
    super.ngOnInit();

    this.recherche.ItemNumber = this.route.snapshot.paramMap.get('ItemNumber');
    this.recherche.CreationDate_DATE_FILTER_GE = this.route.snapshot.paramMap.get('CreationDate_DATE_FILTER_GE');
    this.recherche.CreationDate_DATE_FILTER_LE = this.route.snapshot.paramMap.get('CreationDate_DATE_FILTER_LE');
    this.recherche.InvoiceNumber = this.route.snapshot.paramMap.get('InvoiceNumber');
    this.recherche.ERP_Purchase_Number = this.route.snapshot.paramMap.get('ERP_Purchase_Number');

    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.ItemNumber != null) params['ItemNumber'] = this.recherche.ItemNumber;
    if (this.recherche.InvoiceNumber != null) params['InvoiceNumber'] = this.recherche.InvoiceNumber;
    if (this.recherche.ERP_Purchase_Number != null) params['ERP_Purchase_Number'] = this.recherche.ERP_Purchase_Number;
    if (this.recherche.CreationDate_DATE_FILTER_GE != null) params['CreationDate_DATE_FILTER_GE'] = this.formatDate(this.recherche.CreationDate_DATE_FILTER_GE);
    if (this.recherche.CreationDate_DATE_FILTER_LE != null) params['CreationDate_DATE_FILTER_LE'] = this.formatDate(this.recherche.CreationDate_DATE_FILTER_LE);

    this.router.navigate(['/hhfileH01GetDetails', params]);
    this.launchRefresh();
  }
}
