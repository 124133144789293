import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiPostScan1Action, ApiPostScan1Action_V2 } from '../models/API/ApiPostScan1Action';
import { AuthService } from './auth.service';
import { APIPostProblem } from '../models/API/APIPostProblem';
import { Scan1Destination } from '../models/scan1Destination';
import { ItemReceptionCompiledDTO_Overall } from '../models/API/ItemReceptionCompiledDTO_Overall';

import { State } from '@progress/kendo-data-query';
import { UtilService } from './util.service';
import { toODataString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class ReceptionService {
  public ReceptionDetails: ReplaySubject<any> = new ReplaySubject<any>(1);

  private BASE_URL = environment.apiUrl;

  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  /**
   * Permet de récupérer la liste des codes de destination.
   */
  public async GetListDestinationCodeScan1(): Promise<Scan1Destination[]> {
    let FetchFromAPIData: any = await this.httpClient.get(this.BASE_URL + 'Reception/GetListDestinationCode').toPromise();

    const destCode: Scan1Destination[] = [];

    FetchFromAPIData.forEach((dataline) => {
      const line: Scan1Destination = {
        codeDestination: dataline.CodeDestination,
        destination_scan1: dataline.Destination_scan1,
        description: dataline.Description,
        priority: dataline.Priority,
      };
      destCode.push(line);
    });

    return destCode;
  }

  /**
   * Requête à l'API quand l'user clique sur « confirmer »
   * @param order objet permettant d'identifier la commande
   */
  Scan1PostProductAssignations(order: ApiPostScan1Action[]): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.post(this.BASE_URL + 'Reception/Scan1PostProductAssignations', order);
  }

  /**
   * Requête à l'API quand l'user clique sur « confirmer »
   * @param order objet permettant d'identifier la commande
   */
  Scan1_PostProductAssignations_V2(order: ApiPostScan1Action_V2[]): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.post(this.BASE_URL + 'Reception/Scan1_PostProductAssignations_V2', order);
  }

  PostProblemScan1(data: APIPostProblem): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.post(this.BASE_URL + 'Reception/ProblemeScan', data);
  }

  /**
   * Permet d'obtenir la liste des commandes pour le produit scanné.
   * @param receptionHeaderId identifient de la réception en cours
   * @param noProduct SKU/n° de produit scanné
   */
  Scan1GetProductDestinations(receptionHeaderId: number, noProduct: number): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    if (receptionHeaderId === null || noProduct === null) {
      return throwError(() => new Error('ReceptionHeaderId or noProduct is null.'));
    } else {
      return this.httpClient.get(this.BASE_URL + 'Reception/Scan1GetProductDestinations', {
        params: { ReceptionHeaderID: receptionHeaderId, ProductNo: noProduct },
      });
    }
  }

  /**
   * Permet d'obtenir la liste des commandes pour le produit scanné.
   * @param receptionHeaderId identifient de la réception en cours
   * @param noProduct SKU/n° de produit scanné
   */
  Scan1_GetProductDestinationOverall(noProduct: string): Observable<ItemReceptionCompiledDTO_Overall[]> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.get<ItemReceptionCompiledDTO_Overall[]>(this.BASE_URL + 'Reception/Scan1_GetProductDestinationOverall', {
      params: { ProductNo: noProduct },
    });
  }

  /**
   * Permet d'obtenir la liste des commandes pour le produit scanné.
   * @param receptionHeaderId identifient de la réception en cours
   * @param noProduct SKU/n° de produit scanné
   */
  Scan1_GetProductDestinationOverall_OLD_BeforeV202405(noProduct: string): Observable<ItemReceptionCompiledDTO_Overall[]> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.get<ItemReceptionCompiledDTO_Overall[]>(this.BASE_URL + 'Reception/Scan1_GetProductDestinationOverall_OLD_BeforeV202405', {
      params: { ProductNo: noProduct },
    });
  }

  ItemReceptionHeaderMarkAsCompleted(id, isCompleted): Observable<any> {
    let url = this.BASE_URL + 'ItemReceptionHeaders/ItemReceptionHeaderMarkAsCompleted?';
    url += 'ItemReceptionHeaderId=' + id;
    url += '&IsCompleted=' + isCompleted;
    return this.httpClient.post(url, {});
  }

  /** permet d'indiquer qu'un claim a été faite sur une ligne
   *
   */
  MarquerItemReceptionLigneReclamee(ListIds: number[], markAsProcessed: boolean): Observable<any> {
    var Body = {
      ListIds: ListIds,
      markAsProcessed: markAsProcessed,
    };

    let url = this.BASE_URL + 'ItemReceptionReclamation/ItemReceptionLineMarkAsClaimed';

    return this.httpClient.post(url, Body);
  }

  /** permet de réserver une trace (au niveau du scan2).  Back-end, ca va assigner la session, le user et la station,
   *
   */
  ReserverTraitementTrace(id: number, mustReserve: boolean): Observable<any> {
    let url = this.BASE_URL + 'Reception/Scan2Emballeur_ReservationTrace?';
    url += 'ReceptionTraceID=' + id;
    url += '&ToReserve=' + mustReserve;
    return this.httpClient.post(url, {});
  }

  /** permet de supprimer une trace de réception.
   *
   */
  delete(ItemReceptionTraceID: number, Explanation: string, itemReceptionDetailID?: number): Observable<any> {
    let url = this.BASE_URL + 'ItemReceptionTraces?ItemReceptionTraceID=' + ItemReceptionTraceID;
    if (Explanation === undefined || Explanation == '') Explanation = '(vide)';
    url += '&Explanation=' + encodeURIComponent(Explanation);
    if (itemReceptionDetailID !== null) url += '&itemReceptionDetailID=' + itemReceptionDetailID;
    let retour = this.httpClient.delete(url);
    return retour;
  }

  // ODATA : STATE

  odata_ReceptionDetails(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}ItemReceptionDetails?$expand=ItemReceptionHeader&${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.ReceptionDetails.next(response);
      })
    );
  }

  /** odataStr_ReceptionDetails - Permet de faire un call sur le endpoint ItemReceptionDetails, en passant un odataString au complet ($select, etc..)*/

  public odataStr_ReceptionDetails(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${this.BASE_URL}ItemReceptionDetails?${oDataFullString}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.ReceptionDetails.next(response);
      })
    );
  }
}
