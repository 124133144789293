import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScanProblemTypes } from '../models/scanProblemTypes';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { DateFilterFn } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root',
})
export class ScanProblemTypesService {
  constructor(private httpclient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}ScanProblemType`;

  cachedScanProblemTypes: ScanProblemTypes[] = undefined;
  dateOfLastReload: Date;

  getFromCache(): ScanProblemTypes[] {
    return this.cachedScanProblemTypes;
  }

  getAll(forceRefreshCache: boolean = true): Observable<ScanProblemTypes[]> {
    let aef: Observable<ScanProblemTypes[]> = undefined;

    //ici, on permettra de récupérer les données à partir de la cache, si possible
    if (this.cachedScanProblemTypes == undefined || forceRefreshCache === true) {
      aef = this.httpclient.get<ScanProblemTypes[]>(`${this.BASE_URL}`);
      aef.subscribe((data) => {
        this.dateOfLastReload = new Date();
        this.cachedScanProblemTypes = data;
      });
    }

    return aef;
  }
  get(id: number): Observable<ScanProblemTypes> {
    return this.httpclient.get<ScanProblemTypes>(`${this.BASE_URL}/${id}`);
  }

  create(pt: ScanProblemTypes): Observable<any> {
    return this.httpclient.post(`${this.BASE_URL}`, pt);
  }

  update(pt: ScanProblemTypes): Observable<any> {
    return this.httpclient.put(`${this.BASE_URL}/${pt.id}`, pt);
  }

  delete(id: number): Observable<any> {
    return this.httpclient.delete(`${this.BASE_URL}/${id}`);
  }
}
