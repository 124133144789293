<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre"><mat-icon>business_center</mat-icon> aeg</h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body">
    <div style="display: flex; flex-direction: column">
      <mat-form-field appearance="outline">
        <mat-label>CodeLicenceOERP</mat-label>
        <input matInput [(ngModel)]="CodeLicenceOERP" name="PackageShortCode" class="form-control" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>MyFullOERPQuery</mat-label>
        <textarea matInput [(ngModel)]="MyFullOERPQuery" name="MyFullOERPQuery" class="form-control" required> </textarea>
      </mat-form-field>
      <button type="button" mat-flat-button color="primary" (click)="sendQueryToOERP()" style="margin-bottom: 10px">
        <mat-icon>search</mat-icon> sendQueryToOERP
      </button>
      <mat-form-field appearance="outline">
        <mat-label>MyFullResponse</mat-label>
        <textarea matInput name="MyFullResponse" class="form-control">                {{ MyFullResponse | json }}   </textarea>
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer"></div>
</form>
