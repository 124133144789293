import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { LocalStorage } from 'src/app/models/enum/LocalStorage';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { HttpErrorMsg } from 'src/app/models/HttpErrorMsg';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';

@Component({
  selector: 'app-LoginUserPwd',
  templateUrl: './LoginUserPwd.component.html',
  styleUrls: ['./LoginUserPwd.component.scss'],
})
export class LoginUserPwdComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, public popupDialog: PopUpDialogService) {
    this.authService.logout(false);

    // Récupération d'éventuelles données ajoutées lors de la redirection (depuis logByPin)
    // https://stackoverflow.com/a/58557082
    let navigation = this.router.getCurrentNavigation();
    if (navigation != null && navigation.extras != null && navigation.extras.state != null) {
      let state = navigation.extras.state as { data: string };
      this.navigationData = state.data;
    }
  }

  hide: boolean = true;
  email: string = '';
  passwordInput: string = '';
  rememberMe: boolean;
  navigationData: string;

  ngOnInit() {
    this.authService.CurrentLoginPage = '/regularLogin';
    localStorage.setItem(LocalStorage.LOGIN_PREFERED_METHOD, this.authService.CurrentLoginPage);

    // remember me
    var local_rememberMe = localStorage.getItem(LocalStorage.LOGIN_REMEMBER_ME);
    if (local_rememberMe == 'true') {
      this.rememberMe = true;
      this.email = localStorage.getItem(LocalStorage.LAST_LOGIN_CODE);
    }

    // Check si le user a été redirigé depuis logByPin car il doit se logger par mdp
    if (this.navigationData === 'LastJWT invalide') {
      let data = {
        titre: 'Authentification par mot de passe requise',
        texte: 'Veuillez vous authentifier avec votre usager/mot de passe ' + "avant d'utiliser le mode de connexion par PIN.",
        showDismissiveButton: false,
        affirmativeActionName: 'OK',
        affirmativeActionColor: 'primary',
        mainIcon: 'info',
      };
      this.popupDialog.FullDialog(data);
    }
  }

  /**
   * Appelle l'API pour débuter l'authentification
   */
  async login() {
    // remember me
    if (this.rememberMe) localStorage.setItem(LocalStorage.LAST_LOGIN_CODE, this.email);

    // login
    try {
      await this.authService.Login(this.email, this.passwordInput);
    } catch (errorResponse: any) {
      console.log(errorResponse);
      let errMsg: string;
      if (<HttpErrorMsg>errorResponse) {
        let MyHttpError: HttpErrorMsg = <HttpErrorMsg>errorResponse; //on convertit l'erreur global en erreur géré par nous

        if (MyHttpError.statusCode == HttpStatusCode.Unauthorized)
          errMsg = 'La combinaison usager/mot de passe ne semble pas fonctionner!  Validez votre mot de passe';
        else if (MyHttpError.statusCode == HttpStatusCode.NotFound)
          errMsg = 'La combinaison usager/mot de passe ne semble pas fonctionner!  Validez votre usager';
        else errMsg = MyHttpError.messageErreurDetail;
      } else {
        errMsg = errorResponse.message ? errorResponse.message : errorResponse.toString();
      }

      let strMessageHTML = '<br/>Une erreur est survenue lors de votre authentification.  <br/><br/>Détail:<br/><br/>';
      strMessageHTML += errMsg;
      let data = {
        titre: 'Erreur authentification',
        texte: strMessageHTML,
        showDismissiveButton: false,
        affirmativeActionName: 'OK',
        affirmativeActionColor: 'warn',
        mainIcon: 'error',
      };
      this.popupDialog.FullDialog(data);
    }
  }

  /**
   * Efface la form
   */
  effacer(): void {
    this.email = '';
    this.passwordInput = '';
  }

  /**
   * Toggle du checkbox "Se souvenir de moi"
   */
  onChangeRememberMe(event) {
    this.rememberMe = event.checked;
    localStorage.setItem(LocalStorage.LOGIN_REMEMBER_ME, event.checked ? 'true' : 'false');
    if (!event.checked) localStorage.removeItem(LocalStorage.LAST_LOGIN_CODE);
  }

  LogByPinLogin(): void {
    this.authService.CurrentLoginPage = '/logByPin';
    localStorage.setItem(LocalStorage.LOGIN_PREFERED_METHOD, this.authService.CurrentLoginPage);
    this.router.navigate([this.authService.CurrentLoginPage]);
  }
}
