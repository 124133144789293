import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { OData } from 'src/app/models/oData/OData_file';
import { ReceptionHeader } from 'src/app/models/receptionHeader';
import { ReceptionService } from 'src/app/service/reception.service';
import { ListComponent } from 'src/app/component/core/list/list.component';

@Component({
  selector: 'app-auditlogs-list',
  templateUrl: './auditlogs-list.component.html',
  styleUrls: ['./auditlogs-list.component.scss'],
})
export class AuditlogsListComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    public dialog: MatDialog,
    private receptionService: ReceptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(httpClient);

    this.endpoint = 'AuditLogs';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
      {
        key: 'OrigUserCode',
        nom: 'OrigUserCode',
        type: 'string',
      },
      {
        key: 'OrigStationCode',
        nom: 'OrigStationCode',
        type: 'string',
      },

      {
        key: 'OrigFonctionName',
        nom: 'OrigFonctionName',
        type: 'string',
      },
      {
        key: 'LogCode',
        nom: 'LogCode',
        type: 'string',
      },
      {
        key: 'LogSubCode',
        nom: 'LogSubCode',
        type: 'string',
      },
      {
        key: 'ProductNumber',
        nom: 'ProductNumber',
        type: 'string',
      },
      {
        key: 'SiteOrderID',
        nom: 'SiteOrderID',
        type: 'string',
      },
      {
        key: 'Qty',
        nom: 'Qty',
        type: 'number',
      },
      {
        key: 'ctrl_DateInserted',
        nom: 'ctrl_DateInserted',
        type: 'datetime',
      },
      {
        key: 'MessageWasRead',
        nom: 'MessageWasRead',
        type: 'boolean',
      },
      {
        key: 'ReadUserCode',
        nom: 'ReadUserCode',
        type: 'string',
      },
      {
        key: 'ReadUserDate',
        nom: 'ReadUserDate',
        type: 'datetime',
      },
      {
        key: 'DetailedMessage',
        nom: 'DetailedMessage',
        type: 'string',
      },
    ];
  }

  TitreGlobalPage: string = 'Liste des Audit Logs';
  IconGlobalPage: string = 'warning';
  NomEntiteCourante: string = 'auditLogs';

  champsDisplay: any[];

  ngOnInit() {
    super.ngOnInit();
    this.recherche.ctrl_DateInserted_DATE_FILTER_GE = this.route.snapshot.paramMap.get('ctrl_DateInserted_DATE_FILTER_GE');
    this.recherche.ctrl_DateInserted_DATE_FILTER_LE = this.route.snapshot.paramMap.get('ctrl_DateInserted_DATE_FILTER_LE');
    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.ctrl_DateInserted_DATE_FILTER_GE)
      params['ctrl_DateInserted_DATE_FILTER_GE'] = this.formatDate(this.recherche.ctrl_DateInserted_DATE_FILTER_GE);
    if (this.recherche.ctrl_DateInserted_DATE_FILTER_LE)
      params['ctrl_DateInserted_DATE_FILTER_LE'] = this.formatDate(this.recherche.ctrl_DateInserted_DATE_FILTER_LE);
    this.router.navigate(['/auditLogList', params]);
    this.launchRefresh();
  }

  effacerRecherche(): void {
    this.recherche = new ReceptionHeader();
    this.orderBy = 'id desc';
  }
}
