<div class="globalPopupClass">
  <div class="MainTitleSection">
    <mat-icon style="align-self: center">playlist_add_check</mat-icon>
    <h3 style="padding-left: 20px; align-self: center">Sélection de DVL</h3>
  </div>

  <p>
    Merci de sélectionner une DVL <span>pour le produit #{{ data.productNumber }} ({{ data.productDescription }})</span>.
  </p>

  <div mat-dialog-content id="popup-selection-dvl">
    <!-- Checkboxes filtres -->
    <mat-checkbox
      [(ngModel)]="SeulementLesReadyToGo"
      name="seulementReadyToGo"
      [disabled]="!authService.rights.globalAdmin"
      matTooltip="Cette option est limitée aux administrateurs!"
    >
      Seulement les "Ready-to-GO" (admin)
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="MasquerLesEnTraitement"
      name="MasquerEnTraitement"
      [disabled]="!authService.rights.globalAdmin"
      matTooltip="Cette option est limitée aux administrateurs!"
    >
      Masquer les "En Traitement" (admin)
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="MasquerLesTermines"
      name="MasquerTermines"
      [disabled]="!authService.rights.globalAdmin"
      matTooltip="Cette option est limitée aux administrateurs!"
    >
      Masquer les "Terminé" (admin)
    </mat-checkbox>

    <div style="grid-row: span 2">
      <mat-checkbox [(ngModel)]="FiltrerParQuantiteMax" name="FiltrerQuantite"> Filtrer par quantité </mat-checkbox>
      <mat-form-field appearance="outline">
        <mat-label>Qté maximale</mat-label>
        <div style="display: flex; flex-direction: row">
          <input matInput class="input-stroked" type="text" [(ngModel)]="QuantiteMax" (click)="openNumericKeyboard()" [disabled]="!FiltrerParQuantiteMax" />

          <button mat-button (click)="scanQte_AddOne()" [disabled]="!FiltrerParQuantiteMax">
            <mat-icon>add_box</mat-icon>
          </button>
          <button mat-button (click)="scanQte_RemoveOne()" [disabled]="!FiltrerParQuantiteMax">
            <mat-icon>indeterminate_check_box</mat-icon>
          </button>
        </div>
      </mat-form-field>
    </div>
  </div>

  <button id="search-button" mat-raised-button color="primary" (click)="onRefreshGridButton()">Rechercher</button>

  <!-- Résultats de la recherche -->
  <div *ngIf="!Loading && ListeDVL?.length > 0">
    <table mat-table [dataSource]="ListeDVL" class="table-dvl">
      <!-- No Commande -->
      <ng-container matColumnDef="noCommande">
        <th mat-header-cell *matHeaderCellDef>No Commande</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.SiteOrderID }}</td>
      </ng-container>
      <!-- Date Commande -->
      <ng-container matColumnDef="dateCommande">
        <th mat-header-cell *matHeaderCellDef>Date Commande</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.OrderDate | date : "yyyy-MM-dd" }}</td>
      </ng-container>
      <!-- Âge (Hrs) -->
      <ng-container matColumnDef="ageCommande">
        <th mat-header-cell *matHeaderCellDef>Âge (Jours)</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.OrderDate | ageCommande }}</td>
      </ng-container>
      <!-- Quantité -->
      <ng-container matColumnDef="quantite">
        <th mat-header-cell *matHeaderCellDef>Quantité</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.FinalQty }}</td>
      </ng-container>
      <!-- Nb de produit différent -->
      <ng-container matColumnDef="NbProdDifferent">
        <th mat-header-cell *matHeaderCellDef>
          Nb Produits diff.<br />
          dans DVL
        </th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.NbOfItemsInCurrentOrder }}</td>
      </ng-container>
      <!-- Prioritaire -->
      <ng-container matColumnDef="prioritaire">
        <th mat-header-cell *matHeaderCellDef>Prioritaire</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.SS_Tag_prioritaire ? "OUI" : "NON" }}</td>
      </ng-container>
      <!-- ReadyToGo -->
      <ng-container matColumnDef="readyToGo">
        <th mat-header-cell *matHeaderCellDef>Ready To Go</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.SS_Tag_ready_to_go ? "OUI" : "NON" }}</td>
      </ng-container>
      <!-- EnTraitementPieuvre -->
      <ng-container matColumnDef="enTraitementPieuvre">
        <th mat-header-cell *matHeaderCellDef>En Traitement<br />Pieuvre</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.SS_Tag_EnTraitementPieuvre ? "OUI" : "NON" }}</td>
      </ng-container>
      <!-- Terminé -->
      <ng-container matColumnDef="termine">
        <th mat-header-cell *matHeaderCellDef>Terminé</th>
        <td mat-cell *matCellDef="let dvl">{{ dvl.SS_Tag_completed ? "OUI" : "NON" }}</td>
      </ng-container>
      <!-- Définition des colonnes -->
      <tr
        mat-header-row
        *matHeaderRowDef="[
          'noCommande',
          'dateCommande',
          'ageCommande',
          'quantite',
          'NbProdDifferent',
          'prioritaire',
          'readyToGo',
          'enTraitementPieuvre',
          'termine'
        ]"
      ></tr>
      <!-- Définition des rows -->
      <tr
        mat-row
        [ngClass]="{ selectedRow: DVLSelectionnee?.SiteOrderID == row.SiteOrderID }"
        *matRowDef="
          let row;
          columns: ['noCommande', 'dateCommande', 'ageCommande', 'quantite', 'NbProdDifferent', 'prioritaire', 'readyToGo', 'enTraitementPieuvre', 'termine']
        "
        (click)="onSelectDVL(row)"
      ></tr>
    </table>
  </div>
  <div *ngIf="!Loading && ListeDVL?.length == 0">Aucun résultat</div>
  <div *ngIf="Loading">Recherche en cours…</div>

  <div mat-dialog-actions style="display: flex; flex-direction: row">
    <button mat-raised-button style="flex: 50" (click)="onAbortClick()">Annuler</button>
    <button mat-raised-button color="primary" style="flex: 50" (click)="onConfirmClick()" [disabled]="DVLSelectionnee == null">Sélectionner</button>
  </div>
</div>
