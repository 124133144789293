import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, take, Subject, takeUntil, catchError } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FacturationAcombaService } from 'src/app/service/facturationAcomba.service';

@Component({
  selector: 'app-rapport-facturation-acomba',
  templateUrl: './rapport-facturation-acomba.component.html',
  styleUrls: ['./rapport-facturation-acomba.component.scss'],
})
export class RapportFacturationAcombaComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridComponent) grid!: GridComponent;
  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public isLoading = false;
  public totalItemCount = 0;

  private unSubNotifier = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private facturationAcombaService: FacturationAcombaService,
    private location: Location,
    private utilService: UtilService
  ) {}

  public state: State = {
    take: 25,
    skip: 0,
  };

  TitreGlobalPage: string = 'Rapport Facturation Acomba';
  IconGlobalPage: string = 'local_shipping';

  ngOnInit(): void {
    //Setup la pagination
    this.state.take = this.utilService.getRowPerPage();

    // subscribe to data
    this.facturationAcombaService.RapportFacturationAcomba.pipe(takeUntil(this.unSubNotifier)).subscribe((response) => {
      // set grid data
      this.totalItemCount = response['@odata.count'];
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }

  ngAfterViewInit(): void {
    // On va aller chercher les noms et les types des colonnes du grid kendo.
    let ColsNamesAndTypes: FieldType[] = this.grid.columns.toArray().map((y: any) => ({ name: y.field, type: y.filter }));

    // Cette méthode permet de créer le state initiale du grid.
    this.initGridFilter(ColsNamesAndTypes);

    // Puisqu'on change des données à la fin d'un change detection cycle, il faut manuellement dire à Angular qu'il ya eu des changements.
    // Sinon, sa fait des exceptions: NG0100.
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.unSubNotifier.next();
    this.unSubNotifier.unsubscribe();
  }

  refresh() {
    this.isLoading = true;
    this.facturationAcombaService
      .odata_RapportFacturationAcomba(this.state)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          throw err;
        }),
        takeUntil(this.unSubNotifier)
      )
      .subscribe((res) => {
        this.isLoading = false;
      });
  }

  /**
   * Initialise l'objet filter du state du Kendo Grid à partir des params de l'URI
   * @param ColsNamesAndTypes Données des colonnes du Kendo Grid
   */
  private initGridFilter(columnNamesAndTypes: FieldType[]): void {
    //Si les données ne sont pas chargées, alors on ne fait rien.
    if (columnNamesAndTypes.length === 0) return;

    // Si on a le modèle des colonnes ainsi que leur types.
    // alors on va contruire le filter object du kendo Grid
    this.route.params.pipe(take(1), takeUntil(this.unSubNotifier)).subscribe((uriParams: Params) => {
      this.state.filter = this.utilService.constructKendoFilterFromQueryParams(uriParams, columnNamesAndTypes);
      this.addCustomAttributesToFilter(uriParams);
      this.refresh();
    });
  }

  dataStateChange(state: State) {
    this.utilService.setRowPerPage(state.take ?? 25);
    this.state = state;
    this.removeQueryParams();
    this.reloadQueryParams();
    this.refresh();
  }

  removeQueryParams() {
    let currentUrl = this.location.path();
    let updatedUrl = currentUrl.split(';')[0]; // Remove the query parameters
    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  reloadQueryParams() {
    let updatedUrl = `${this.location.path()}`;
    updatedUrl += this.utilService.constructURIQueryParamsFromFilter(this.state.filter);

    if (updatedUrl.charAt(updatedUrl.length - 1) === ';') {
      updatedUrl = updatedUrl.substring(0, updatedUrl.length - 1);
    }

    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  private addCustomAttributesToFilter(params: Params): void {
    let newFilterToAdd: FilterDescriptor;

    // attribut custom (anciennement une colonne autoCalc) si présent on rajoute un filtre
    if (params.hasOwnProperty('MoisSommatif')) {
      let MoisSommatifValue = params['MoisSommatif'];
      let _date = new Date();
      let sommativeMonth = '';

      switch (MoisSommatifValue) {
        case 'Precedent':
          _date.setMonth(_date.getMonth() - 1);
          break;
        case 'Suivant':
          _date.setMonth(_date.getMonth() + 1);
          break;
        default:
          break;
      }
      sommativeMonth = this.transformDateToSommativesMonth(_date);
      newFilterToAdd = {
        field: 'Sommative_MoisFact',
        operator: 'contains',
        value: sommativeMonth,
      };

      this.state.filter.filters.push(newFilterToAdd);
    }

    if (params.hasOwnProperty('ShowMismatchMonthOnly')) {
      newFilterToAdd = {
        field: 'NbMoisDiff_AcombaMDP_vs_sommative',
        operator: 'ge',
        value: 1,
      };

      this.state.filter.filters.push(newFilterToAdd);
    }
  }

  private transformDateToSommativesMonth(date: Date): string {
    return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  }

  public formatDate(_date: Date): string {
    return this.utilService.formatDateToString(_date);
  }
}
