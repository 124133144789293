/**
 * Gestion d'un clavier numérique
 */
export class NumericKeyboard {
  private _valueNumericKeyboard = '';
  private _displayNumericKeyboard = false;
  private _firstTimeNumericKeyboard = true;

  private readonly neverClose: boolean;
  private readonly _title: string;

  /**
   * Instancie un clavier numérique
   * @param neverClose Permet de forcer l'affichage du clavier, false par défaut.
   * @param title titre donné au clavier
   */
  constructor(neverClose: boolean = false, title: string = '') {
    this.neverClose = neverClose;
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  get valueNumericKeyboard(): string {
    return this._valueNumericKeyboard;
  }

  set valueNumericKeyboard(value: string) {
    this._valueNumericKeyboard = value;
  }

  get valueNumericKeyboardAsNumber(): number {
    return parseInt(this._valueNumericKeyboard, 10);
  }

  get displayNumericKeyboard(): boolean {
    if (this.neverClose) {
      return true;
    } else {
      return this._displayNumericKeyboard;
    }
  }

  get firstTimeNumericKeyboard(): boolean {
    return this._firstTimeNumericKeyboard;
  }

  /**
   * Ouvre le clavier numérique
   * @param defaultValue Permet d'ajouter une valeur par défaut.
   */
  open(defaultValue: string = ''): void {
    this._valueNumericKeyboard = defaultValue;
    this._displayNumericKeyboard = true;
  }

  /**
   * Ajoute une valeur (ajoute à la fin, n'additionne pas!!)
   * @param value valeur à ajouter à la fin
   */
  addValue(value: string): void {
    if (this.firstTimeNumericKeyboard) {
      this.valueNumericKeyboard = value;
      this._firstTimeNumericKeyboard = false;
    } else {
      this.valueNumericKeyboard += value;
    }
  }

  /**
   * Retire du contenu
   * @param removeAll vide tout si true, ou uniquement le dernier caractère sinon.
   */
  remove(removeAll: boolean): void {
    if (removeAll) {
      this.valueNumericKeyboard = '';
    } else {
      this.valueNumericKeyboard = this.valueNumericKeyboard.slice(0, -1);
    }
  }

  /**
   * Réinitialise les valeurs et retourne le contenu du clavier numérique
   */
  reset(): string {
    const content = this.valueNumericKeyboard;
    this.valueNumericKeyboard = '';
    this._displayNumericKeyboard = false;
    this._firstTimeNumericKeyboard = true;
    return content;
  }
}
