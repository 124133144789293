import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkStation } from 'src/app/models/security/WorkStation';

//import
@Injectable({
  providedIn: 'root',
})
export class WorkstationService {
  private apiUrl = environment.apiUrl + 'WorkStations';

  constructor(private httpClient: HttpClient) {}

  // READ

  getAllOdata(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}`);
  }

  get(id: number): Observable<WorkStation> {
    return this.httpClient.get<WorkStation>(`${this.apiUrl}/${id}`);
  }

  create(workstation: WorkStation): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}`, workstation);
  }

  // UPDATE

  update(workstation: WorkStation): Observable<any> {
    return this.httpClient.put(`${this.apiUrl}/${workstation.id}`, workstation);
  }

  // DELETE
  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }

  // AssignWorkStation

  AssignWorkStation(id: number, forceAssignation: boolean = false, ForceDeassign: Boolean = false): Observable<any> {
    var data = {
      idStation: id,
      forceAssignation: forceAssignation,
      forceDeassign: ForceDeassign,
    };
    var MyUrl = `${environment.apiUrl}Auth/AssignWorkStation`;
    return this.httpClient.post(MyUrl, data);
  }
}
