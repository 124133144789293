<div class="globalPopupClass">
  <div class="MainTitleSection">
    <mat-icon style="align-self: center">playlist_add_check</mat-icon>
    <h1 style="padding-left: 20px; align-self: center">Sélection d'envoi</h1>
  </div>

  <p>
    Sélection manuelle d'un envoi <span>pour la commande {{ ratesData?.orderNumber }}</span
    >.
  </p>

  <hr />
  <br />

  <div mat-dialog-content id="popup-selection-dvl">
    <!-- Affichage de la sélection automatique -->
    <div>
      <h2>Envoi sélectionné automatiquement</h2>
      <ng-container *ngIf="ratesData != null && ratesData.autoSelectedRate != null; else aucuneSelectionAutomatique">
        <table class="table-liste table-liste-clickable table-envoi">
          <tr>
            <th>Carrier</th>
            <th>Service</th>
            <th>Tarif</th>
          </tr>
          <tr
            [ngClass]="{
              'selected-row':
                ratesData.manuallySelectedRate == null ||
                (ratesData.autoSelectedRate?.carrierCode == ratesData.manuallySelectedRate?.carrierCode &&
                  ratesData.autoSelectedRate?.serviceCode == ratesData.manuallySelectedRate?.serviceCode)
            }"
          >
            <td class="label-cell">
              {{ ratesData.autoSelectedRate.carrierName }}
            </td>
            <td class="label-cell">
              {{ ratesData.autoSelectedRate.serviceName }}
            </td>
            <td class="label-cell">
              ${{
                ratesData.autoSelectedRate.shipmentCostNoTax + ratesData.autoSelectedRate.otherCostNoTax + ratesData.autoSelectedRate.mdpweb_custom_extraFees
                  | number : "1.2-2"
              }}
            </td>
          </tr>
        </table>
      </ng-container>
      <ng-template #aucuneSelectionAutomatique> Aucun </ng-template>
    </div>

    <hr />
    <br />

    <!-- Sélection manuelle -->
    <h2>Sélection manuelle</h2>
    <div *ngIf="!loading && ratesData != null">
      <table id="myMainTable" class="table-liste table-liste-clickable table-envoi">
        <tr>
          <th>Carrier</th>
          <th>Service</th>
          <th>Tarif</th>
        </tr>
        <tr
          *ngFor="let rate of ratesData.allRates"
          (click)="onSelectRow(rate)"
          [ngClass]="{
            'selected-row':
              rate === ratesData.manuallySelectedRate ||
              (ratesData.manuallySelectedRate == null &&
                rate.carrierCode == ratesData.autoSelectedRate?.carrierCode &&
                rate.serviceCode == ratesData.autoSelectedRate?.serviceCode)
          }"
        >
          <td class="label-cell">
            {{ rate.carrierName }}
          </td>
          <td class="label-cell">
            {{ rate.serviceName }}
          </td>
          <td class="label-cell" [matTooltip]="dvlService.GetDetailsCalculRate(rate)">
            ${{ rate.shipmentCostNoTax + rate.otherCostNoTax + rate.mdpweb_custom_extraFees | number : "1.2-2" }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="!loading && ratesData == undefined">Aucun résultat</div>
  <div *ngIf="loading">Chargement des données de ShipStation en cours…</div>

  <div mat-dialog-actions style="display: flex; flex-direction: row">
    <button mat-raised-button style="flex: 50" (click)="onAbortClick()" [disabled]="loading">Annuler</button>
    <button mat-raised-button style="flex: 50" (click)="onReloadClick()" [disabled]="loading || !isRefreshRatesAllowed">Recharger</button>
  </div>

  <div mat-dialog-actions style="display: flex; flex-direction: row">
    <button mat-raised-button color="primary" style="flex: 50" (click)="onConfirmClick()" [disabled]="loading || ratesData == null || !isRefreshRatesAllowed">
      Enregistrer
    </button>
    <button
      mat-raised-button
      color="accent"
      style="flex: 50"
      (click)="onPurchaseClick()"
      [disabled]="!isRefreshRatesAllowed || loading || (ratesData?.autoSelectedRate == null && ratesData?.manuallySelectedRate == null)"
    >
      Acheter
    </button>
  </div>
</div>
