/**
 * Classe représentant un objet oData
 */
import { IoData } from './IoData_file';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export class OData {
  // variables représentant une données oData
  private _content; // contenu de la dernière réponse oData
  private nextLink: URL; // Url de la page suivante
  private context; // Url du context du endpoint
  private _count: number; // quantité retournée par $count

  // variables pour le bon fonctionnement interne de la classe
  private history: URL[] = []; // tableau permettant de mémoriser les appels précédents
  private index = -1; // index de l'historique

  private isTopAndSkip: boolean;
  private linesPerPage: number; // $top
  private _currentPage: number; // $page actuelle de 0 à n-1

  constructor(private httpClient: HttpClient) {}

  /**
   * Permet de faciliter la création d'un lien oData.
   * @param controller controlleur sur lequel on souhaite se connecter
   * @param params liste d'ojets {key: xxx, valeur: yyy}
   * Ex: generateODataLink('endpoint', [
   *  {
   *    key: '$filter',
   *    value: 'column eq 1741'
   *  },
   *  {
   *    key: '$select',
   *    value: 'Champ1,Champ2,Champ3'
   *  }
   * ]);
   */
  static generateODataLink(controller: string, params: any[]): URL {
    const url = new URL(environment.apiUrl);
    url.pathname += controller;
    params.forEach((line) => {
      url.searchParams.append(line.key, line.value);
    });

    return url;
  }

  /**
   * Permet d'appeler un endpoint odata.
   * Si un appel a déjà été effectué, utilisez next() ou previous().
   * @param url lien de l'endpoint (oData.generateODataLink() peut aider)
   * @param maxLines nombre max de lignes
   */
  getOdatafromApi(url: URL, maxLines: number = null, isOdataContainerActive: boolean = true): Observable<any> {
    if (!url.searchParams.has('$count')) {
      url.searchParams.append('$count', 'true');
    }

    this.history.push(url);

    if (maxLines !== null) {
      maxLines = parseInt(maxLines.toString(), 10);

      if (isNaN(maxLines) || maxLines < 1) {
        throw new Error('Max line must be an interger greater than 0.');
      }

      this.isTopAndSkip = true;
      this.linesPerPage = maxLines;
      this._currentPage = 0;
      if (url.searchParams.has('$count') || url.searchParams.has('$skip') || url.searchParams.has('$top')) {
        console.warn('$count, $skip and $top are ignored when maxLines is set.');
      }
      url.searchParams.set('$count', 'true');
      url.searchParams.set('$skip', '0');
      url.searchParams.set('$top', maxLines.toString());
    } else {
      this.isTopAndSkip = false;
    }
    const urlApi = url;

    return this.getOdata(urlApi.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this._count = oData['@odata.count'];
        this.update(oData);
        this.generateNextUrl(urlApi);

        if (isOdataContainerActive) return oData.value;
        else return oData;
      })
    );
  }

  /**
   * Permet d'obtenir un count.
   * @param controller controlleur à contacter
   * @param filter filtre éventuel (sans le $filter=)
   */
  getCount(controller: string, filter: string): Observable<any> {
    if (filter == null) {
      filter = 'true';
    }
    const url = OData.generateODataLink(controller, [
      {
        key: '$filter',
        value: filter,
      },
      {
        key: '$count',
        value: 'true',
      },
      {
        key: '$top',
        value: '0',
      },
    ]);

    return this.getOdata(url.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        return oData['@odata.count'];
      })
    );
  }

  /**
   * Permet de changer le nombre de lignes par page « en cours de route ».
   * Conserve la position dans la pagination.
   * @param lines
   */
  changeLinesPerPage(lines: number): Observable<any> {
    lines = parseInt(lines.toString(), 10);
    if (isNaN(lines) || lines < 1) {
      throw new Error('Max line must be an interger greater than 0.');
    }

    const currentItem = this._currentPage * this.linesPerPage;
    this._currentPage = Math.floor(currentItem / lines);
    this.linesPerPage = lines;

    const url = this.history[0];
    const skip = this._currentPage * this.linesPerPage;
    url.searchParams.set('$skip', skip.toString());
    url.searchParams.set('$top', this.linesPerPage.toString());
    return this.getOdata(url.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this.update(oData);
        this.generateNextUrl(url);
        return oData.value;
      })
    );
  }

  /**
   * Indique s'il existe une page suivante
   */
  haveNext(): boolean {
    return this.nextLink != null;
  }

  /**
   * Indique s'il existe une page précédente
   */
  havePrevious(): boolean {
    if (this.isTopAndSkip) {
      return this._currentPage > 0;
    } else {
      return this.index > 0;
    }
  }

  /**
   * Permet de récupérer le contenu utile de la réponse OData
   */
  get content(): any {
    return this._content;
  }

  /**
   * Permet de récupérer le nombre de résultats.
   */
  get count(): number {
    return this._count;
  }

  /**
   * Permet d'afficher la page actuelle (en partant de 1)
   */
  get currentPage(): number {
    return this._currentPage + 1;
  }

  /**
   * Permet de refaire la requête de la page précédente.
   */
  previous(): Observable<any> {
    if (!this.havePrevious()) {
      // si l'index est à 0, on ne va pas aller rechercher le -1, on soulève une erreur
      return throwError(() => new Error('No previous page available.'));
    }

    if (this.index > 0) {
      this.index -= 1; // on change l'index de l'historique à n-1
    }

    let previousLink: URL;
    if (this.isTopAndSkip) {
      previousLink = this.generatePreviousPage(); // on récupère l'url n-1
    } else {
      previousLink = this.history[this.index]; // on récupère l'url n-1
    }

    this.history.length = this.index + 1; // on supprime tout ce qui suit

    return this.getOdata(previousLink.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this.update(oData);
        this._currentPage -= 1;
        this.generateNextUrl(previousLink);
        return oData.value;
      })
    );
  }

  /**
   * Permet de récupérer la prochaine page. Met à jour la classe et retourne le contenu utile dans l'observable.
   */
  next(): Observable<any> {
    if (this.nextLink == null) {
      return throwError(() => new Error('No next link available.'));
    }

    const currentNextLink = this.nextLink;
    return this.getOdata(currentNextLink.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this.history.push(this.nextLink);
        this.index += 1;
        this._currentPage += 1;
        this.update(oData);
        this.generateNextUrl(currentNextLink);
        return oData.value;
      })
    );
  }

  /**
   * Permet de récupérer la première page
   */
  first(): Observable<any> {
    const url = this.history[0];
    url.searchParams.set('$skip', '0');
    return this.getOdata(url.toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this.index = 0;
        this.history.length = 1;
        this._currentPage = 0;
        this.update(oData);
        this.generateNextUrl(this.history[0]);
        return oData.value;
      })
    );
  }

  /**
   * Permet de récupérer la dernière page
   */
  last(): Observable<any> {
    return this.getOdata(this.lastPageUrl().toString()).pipe(
      map((result) => {
        const oData: IoData = result;
        this.index = 0;
        this.history.length = 1;
        this._currentPage = Math.floor(this._count / this.linesPerPage);
        this.update(oData);
        this.generateNextUrl(this.lastPageUrl());
        return oData.value;
      })
    );
  }

  /**
   * Permet d'obtenir un DOMParser XM0L donnant le contexte du endpoint odata actuel.
   */
  OdataContext(): Observable<any> {
    return this.getOdataContext(this.context).pipe(
      map((result) => {
        const xml = new DOMParser();
        xml.parseFromString(result, 'image/svg+xml');
        return result;
      })
    );
  }

  /**
   * Permet de mettre à jour la classe à partir d'une réponse oData.
   * @param oData
   * @private
   */
  private update(oData: IoData): void {
    const oDataResult: IoData = oData;
    if (oDataResult['@odata.nextLink'] === undefined) {
      this.nextLink = null;
    } else {
      this.nextLink = new URL(oDataResult['@odata.nextLink']);
    }

    this._content = oDataResult.value;
    this.context = oDataResult['@odata.context'];
  }

  /**
   * Appel HTTP GET vers le endpoint. Retourne un json.
   * @param url lien
   * @private
   */
  private getOdata(url: string): Observable<any> {
    return this.httpClient.get(url);
  }

  /**
   * Appel HTTP GET vers le endpoint. Retourne un text.
   * @param url lien
   * @private
   */
  private getOdataContext(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'text' });
  }

  /**
   * Permet de générer le prochain nextUrl si le serveur n'en retourne pas (top & skip).
   * @param url url actuel
   * @private
   */
  private generateNextUrl(url: URL): void {
    if (!this.isTopAndSkip) {
      return; // si le serveur en retourne un, on arrête
    }
    if ((this._currentPage + 1) * this.linesPerPage <= this._count - 1) {
      const skip = this.linesPerPage * (this._currentPage + 1);
      url.searchParams.set('$skip', skip.toString());
      url.searchParams.set('$top', this.linesPerPage.toString());
      this.nextLink = url;
    } else {
      this.nextLink = null;
    }
  }

  /**
   * Construit l'URL de la page précédente
   * @private
   */
  private generatePreviousPage(): URL {
    let url: URL;
    if (this.nextLink === null) {
      url = this.lastPageUrl();
    } else {
      url = this.nextLink;
    }

    const skip = this.linesPerPage * (this._currentPage - 1);
    url.searchParams.set('$skip', skip.toString());
    url.searchParams.set('$top', this.linesPerPage.toString());

    return url;
  }

  /**
   * Construit l'URL de la dernière page
   * @private
   */
  private lastPageUrl(): URL {
    const url = this.history[0];
    const skip = this._count - 1;
    url.searchParams.set('$skip', skip.toString());
    url.searchParams.set('$top', this.linesPerPage.toString());
    return url;
  }
}
