import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isSideNavOpen: boolean;

  constructor(public authService: AuthService) {}

  ngOnInit() {
    //on rafraichit les variables de log
    this.authService.hydrate();
    const checkLogin = AuthService.checkIfLoginExpired();
    let checkEnvironment = AuthService.checkIfSameEnvironment();
    if (checkLogin !== null || checkEnvironment !== null) {
      this.authService.logout();
    }
    var sidenav = localStorage.getItem('sidenav_visibilite');
    this.isSideNavOpen = !(sidenav == 'hide');
  }

  /**
   * Toggle la visibilité du SideNav
   */
  public toggleSideNav() {
    localStorage.setItem('sidenav_visibilite', this.isSideNavOpen ? 'hide' : 'show');
    this.isSideNavOpen = !this.isSideNavOpen;
  }
}
