import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { Usager } from 'src/app/models/usager';
import { UsagerService } from 'src/app/service/usager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRights } from 'src/app/models/user/userRights';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-usager-form',
  templateUrl: './usager-form.component.html',
  styleUrls: ['./usager-form.component.scss'],
})
export class UsagerFormComponent implements OnInit, OnChanges {
  @Input() currentItem: Usager;
  @Input() _beforeChangedItem: Usager;

  @Output() submitEmitterCanCloseChildForm = new EventEmitter<any>(); //event permettant d'indiquer que l'enfant (ici) veut se fermer
  @Output() submitEmitterChildHaveChanges = new EventEmitter<any>(); //event permettant d'indiquer que l'enfant (ici) a des changemenets

  @ViewChild(NgForm) myform!: NgForm; //permet d'accéder ici (TS) au FORM fabriqué FrontEnd, nommé MyForm.

  addNewUserMode: boolean = false;
  titre: string;
  NomEntiteCourante: string = 'usager';
  newPassword: string = '';
  IsChangesPublished: boolean = false;
  newPasswordEntered: boolean = false;
  passwordRegexPattern: string = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})';
  passwordLock: boolean = false;
  activationStatus: string = 'Actif';

  userRights: UserRights = {
    isAnonymous: false,
    globalAdmin: false,
    onlineOrders: false,
    reception: false,
    scan1: false,
    scan2Emballeur: false,
    sysAdmin: false,
    financeAdmin: false,
    scan2EmballeurChangerDVL: false,
    scan2EmballeurChangerBoite: false,
    achatLabels: false,
  };

  originalUserRights: UserRights = {
    isAnonymous: false,
    globalAdmin: false,
    onlineOrders: false,
    reception: false,
    scan1: false,
    scan2Emballeur: false,
    sysAdmin: false,
    financeAdmin: false,
    scan2EmballeurChangerDVL: false,
    scan2EmballeurChangerBoite: false,
    achatLabels: false,
  };

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private usagerService: UsagerService,
    private dialogService: PopUpDialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    //on va essayer de ramasser le username de l'usager par la route, si possible (en cas de pop-up, entre autre.. pas obligé)
    let MyUserNameByParam = this.route.snapshot.paramMap.get('username');
    //si on recoit un ID par la route, on va instancier le composant actuel
    if (MyUserNameByParam) {
      this.currentItem = new Usager();
      this.currentItem.UserName = MyUserNameByParam;
    }
    if (this.currentItem === undefined || this.currentItem === null) {
      this.addNewUser();
    }
  }

  lockSubmitButton(): boolean {
    if (this.newPassword === undefined || this.newPassword === null || this.newPassword === '') {
      return true;
    } else {
      let arr: any = this.newPassword.match(this.passwordRegexPattern);
      if (arr === undefined || arr === null || arr.length < 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  //méthode permettant de rafraichir les données actuelle
  refreshInterface() {
    //let id = this.route.snapshot.paramMap.get('id');
    if (this.currentItem !== undefined && this.currentItem !== null) {
      // EDIT
      this.titre = 'Modifier un usager';
      this.addNewUserMode = false;
      // fetch project data
      this.usagerService.get(this.currentItem.UserName).subscribe((jsonData) => {
        this.currentItem = jsonData[0];
      });

      this._beforeChangedItem = this.currentItem;

      // fetch droits
      this.originalUserRights = this.getUserRights(this.currentItem.UserName);
      this.userRights = this.getUserRights(this.currentItem.UserName);
    } else {
      this.addNewUser();
    }
  }

  addNewUser() {
    this.currentItem = new Usager();
    this.titre = 'Ajouter un usager';
    this.resetUserRights();
    this.addNewUserMode = true;
  }

  getUserRights(username: string): UserRights {
    let userRights: UserRights = {
      isAnonymous: false,
      globalAdmin: false,
      onlineOrders: false,
      reception: false,
      scan1: false,
      scan2Emballeur: false,
      sysAdmin: false,
      financeAdmin: false,
      scan2EmballeurChangerDVL: false,
      scan2EmballeurChangerBoite: false,
      achatLabels: false,
    };

    this.usagerService.getUserRights(username).subscribe((data) => {
      let rightsInString = data.toString();
      let rights = rightsInString.split(',');
      rights.forEach((right) => {
        if (right.length > 0) {
          userRights[right] = true;
        }
      });
    });

    return userRights;
  }

  //Evenement de sauvegarde...on encapsule pour gérer si on doit faire un pop-up ou non
  OnSauvegardeClick() {
    //si le formulaire est invalide [des champs required qui sont vides], on va demander à l'usager de confirmer
    if (this.myform.valid === false) {
      // pop dialog
      const dialogRef = this.dialog.open(PopConfirmComponent, {
        data: {
          titre: 'Formulaire avec champ invalide',
          texte: 'Attention!  Certains champs obligatoires ne semblent pas avoir été remplit. <br/><br/> Désirez-vous enregistrer QUAND MÊME?',
          affirmativeActionName: 'Enregistrer',
          affirmativeActionColor: 'primary',

          dismissiveActionName: 'Revenir en arrière',
          dismissiveActionColor: 'warn',
        },
      });
      // after close dialog
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.sauvegarder();
        }
      });
    } else {
      //si le modèle était valide dès le début, on enregistre.
      this.sauvegarder();
    }
  }

  resetUserRights() {
    this.userRights = {
      isAnonymous: false,
      globalAdmin: false,
      onlineOrders: false,
      reception: false,
      scan1: false,
      scan2Emballeur: false,
      sysAdmin: false,
      financeAdmin: false,
      scan2EmballeurChangerDVL: false,
      scan2EmballeurChangerBoite: false,
      achatLabels: false,
    };
    this.originalUserRights = {
      isAnonymous: false,
      globalAdmin: false,
      onlineOrders: false,
      reception: false,
      scan1: false,
      scan2Emballeur: false,
      sysAdmin: false,
      financeAdmin: false,
      scan2EmballeurChangerDVL: false,
      scan2EmballeurChangerBoite: false,
      achatLabels: false,
    };
  }

  //méthode pour enregistrer dans la bd
  public async sauvegarder() {
    if (!this.addNewUserMode) {
      if (this._beforeChangedItem.IsEnabled != this.currentItem.IsEnabled) {
        let textConfirmation = `Information importante!<br/><br/>Vous allez changer le statut de l'usager '${this._beforeChangedItem.UserName}' à '${this.activationStatus}'<br/><br/>`;
        let buttonText = '';

        if (this.currentItem.IsEnabled) {
          textConfirmation += 'Êtes vous sûr de vouloir réactiver cet usager?<br/><br/>';
          buttonText = 'Oui, Je veux réactiver';
        } else {
          textConfirmation += 'Êtes-vous sûr de vouloir désactiver cet usager?<br/><br/>';
          textConfirmation += 'Son pin sera reset à la désactivaton. <br/><br/>';
          buttonText = 'Oui, Je veux désactiver';
        }

        textConfirmation += 'Désirez-vous procéder au changement du statut ?';
        var res = await this.dialogService.ConfirmDialog(
          textConfirmation,
          "Changement du statut d'un usager",
          'live_help',
          buttonText,
          'Non, annuler mon action'
        );

        if (res === false || res === '') return;
      }

      if (this._beforeChangedItem.UserName != this.currentItem.UserName) {
        let textConfirmation = `Information importante!<br/><br/>Vous allez changer le code de l'usager de '${this._beforeChangedItem.UserName}' à '${this.currentItem.UserName}'<br/><br/>`;
        textConfirmation += "Sachez que les traces précédentes faites par l'usager <u>GARDERONT</u> le code l'ancien usager<br/><br/>";
        textConfirmation +=
          "Toutefois, cela n'affecte en rien la bonne marche du système et le nouveau code sera immédiatement fonctionnel et utilisé<br/><br/>";
        textConfirmation += 'Désirez-vous procéder au changement du code usager ?';
        var MyREsult = await this.dialogService.ConfirmDialog(
          textConfirmation,
          "Changement d'un code usager",
          'live_help',
          "Oui, changer le code de l'usager",
          'Non, annuler mon action'
        );

        if (MyREsult === false || MyREsult === '') return;
      }

      // EDIT
      if (this.newPasswordEntered) {
        alert(
          'Vous avez apporté des changements au champ Nouveau mot de passe sans avoir appuyer sur le boutton Forcer un nouveau mot de passe! \n Les changements ne seront pas sauvegardés!'
        );
      }

      if (this._beforeChangedItem.IsEnabled != this.currentItem.IsEnabled) {
        this.usagerService.changeActiveStatus(this.currentItem).subscribe((res) => {});
      }

      this.usagerService.update(this.currentItem).subscribe((d) => {
        this.sauvegarderPt2('sauvegardé');
      });
    } else {
      this.usagerService.addUsager(this.currentItem, this.newPassword).subscribe((data) => {
        this.usagerService.get(this.currentItem.UserName).subscribe((userdata) => {
          this.currentItem = userdata[0];
          this.sauvegarderPt2('ajouté');
        });
      });
    }
  }

  private async sauvegarderPt2(action: string) {
    await this.updateUsagerRoles();
    //on ferme le FORM
    this.cacherForm();

    //pop-up...
    this.snack.open("L'usager à été " + action + '.', '', { duration: 3000 });
    this.IsChangesPublished = true;
  }

  private async updateUsagerRoles() {
    let rolesToAdd: string[] = [];
    let rolesToRemove: string[] = [];
    let rolesNames = Object.keys(this.originalUserRights);
    let rolesOrignalValues = Object.values(this.originalUserRights);
    let rolesValuesChanged = Object.values(this.userRights);

    for (let i = 0; i < rolesNames.length; i++) {
      if (rolesValuesChanged[i] !== rolesOrignalValues[i]) {
        if (rolesOrignalValues[i]) {
          rolesToRemove.push(rolesNames[i]);
        } else {
          rolesToAdd.push(rolesNames[i]);
        }
      }
    }

    if (rolesToAdd !== undefined && rolesToAdd !== null && rolesToAdd.length > 0) {
      for (let z = 0; z < rolesToAdd.length; z++) {
        await this.usagerService.addRole(this.currentItem.UserName, rolesToAdd[z]).toPromise();
      }
    }

    if (rolesToRemove !== undefined && rolesToRemove !== null && rolesToRemove.length > 0) {
      for (let y = 0; y < rolesToRemove.length; y++) {
        await this.usagerService.removeRole(this.currentItem.UserName, rolesToRemove[y]).toPromise();
      }
    }
  }

  fermer() {
    this.submitEmitterCanCloseChildForm.emit(true);
  }

  cacherForm(): void {
    this.submitEmitterChildHaveChanges.emit(false); //on raise le event indiquant si l'enfant a des changements en cours (false, ici)
    this.submitEmitterCanCloseChildForm.emit(true); //on raise le Event permettant de demander de se faire fermer
  }

  forceNewPassword(newPassword: string, user: Usager) {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Forcer un nouveau mot de passe',
        texte: 'Êtes-vous sûr de vouloir <b>CHANGER</b> le mot de passe cet usager?',
        affirmativeActionName: 'Changer',
        affirmativeActionColor: 'warn',

        dismissiveActionName: 'Annuler',
        dismissiveActionColor: '',
      },
    });
    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        //si on répond OUI, (affirmative)  on procède au chagement de mot de passe
        this.usagerService.updatePassword(newPassword, user).subscribe(
          (jsonData) => {
            this.snack.open('Le mot de passe a été changé.', '', { duration: 3000 });
            this.newPassword = '';
          },
          (error: HttpErrorResponse) => {
            alert('Échec du changement de mot de passse!');
          }
        );
      }
    });
  }

  //Détecte N'IMPORTE quel changement sur mon composant
  ngDoCheck(changes: any) {
    if (this.newPassword.length > 0) {
      this.newPasswordEntered = true;
      this.passwordLock = this.lockSubmitButton();
    } else this.newPasswordEntered = false;

    if ((this.myform?.pristine === false || this.newPasswordEntered === true) && this.IsChangesPublished === false) {
      this.IsChangesPublished = true;
      this.submitEmitterChildHaveChanges.emit(true);
    }

    if (this.currentItem.IsEnabled) {
      this.activationStatus = 'Actif';
    } else {
      this.activationStatus = 'Inactif';
    }
  }

  //ne détecte QUE les changements sur les @input values
  ngOnChanges(changes: SimpleChanges) {
    this.refreshInterface();
  }

  getConnectedUsersRights(): UserRights {
    return this.authService.rights;
  }
}
