<div class="main">
  <div class="left">
    <div matRipple centered="true" class="nav-btn" (click)="app.toggleSideNav()">
      <mat-icon>menu</mat-icon>
    </div>
  </div>
  <div class="right">
    <!-- STATION -->
    <div class="station-navbar">
      <!-- <button *ngIf="authService.isLogged" mat-button [matMenuTriggerFor]="menuUsager"> -->
      <span class="nom-station">
        <button *ngIf="authService.isLogged" mat-button [matMenuTriggerFor]="menuStation">
          <mat-icon>computer</mat-icon>Station: {{ userInfo.stationCode }}
        </button>
      </span>
      <span class="nom-usager">
        <button *ngIf="authService.isLogged" mat-button [matMenuTriggerFor]="menuUsager"><mat-icon>person</mat-icon>Usager: {{ userInfo.name }}</button>
      </span>
      <!-- </button> -->
    </div>

    <!--FULLSCREEN btn-->
    <div matRipple centered="true" class="nav-btn" (click)="toggleFullScreen()">
      <mat-icon>fullscreen</mat-icon>
    </div>

    <!-- MENU STATION -->
    <mat-menu #menuStation="matMenu">
      <button mat-button class="btn-usager" (click)="onClickChangeWorkstation()">
        <mat-icon>autorenew</mat-icon>
        Changer de station
      </button>
    </mat-menu>

    <!-- MENU USAGER -->
    <mat-menu #menuUsager="matMenu">
      <button mat-button class="btn-usager" (click)="authService.logout()">
        <mat-icon>logout</mat-icon>
        Se déconnecter
      </button>
    </mat-menu>
  </div>
</div>
