<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="main-page-container">
  <div class="listpage-container">
    <div class="listpage-header">
      <!-- CHAMPS RECHERCHE -->
      <div class="listpage-search-container">
        <mat-form-field appearance="outline">
          <mat-label>Date</mat-label>
          <mat-date-range-input [rangePicker]="pickerRange">
            <input matStartDate placeholder="Date début" [(ngModel)]="recherche.SessionLoggedInDate_DATE_FILTER_GE" name="SessionLoggedInDate_DATE_FILTER_GE" />
            <input matEndDate placeholder="Date fin" [(ngModel)]="recherche.SessionLoggedInDate_DATE_FILTER_LE" name="dateSessionLoggedInDate_DATE_FILTER_LE" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerRange"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerRange></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="recherche-btn-container">
        <button type="button" mat-stroked-button color="primary" (click)="effacerRecherche()"><mat-icon>delete</mat-icon> Effacer</button>
        <button type="button" mat-flat-button color="primary" (click)="rechercher()"><mat-icon>search</mat-icon> Rechercher</button>
        <a mat-flat-button color="accent" style="cursor: pointer; align-self: baseline" (click)="exportexcel()"> <mat-icon>upload_file</mat-icon>Excel </a>
        <span class="pagination-count">{{ count ? count + " élément(s) trouvé(s)" : " " }}</span>
      </div>
    </div>
    <div class="listpage-body">
      <!-- LISTE -->

      <table
        id="myMainTable"
        class="table-liste table-liste-clickable"
        mat-table
        #table
        [dataSource]="dataSource"
        matSort
        matSortStart="desc"
        (matSortChange)="sortData($event)"
      >
        <!-- colonnes autogénérées -->
        <ng-container matColumnDef="{{ champ['key'] }}" *ngFor="let champ of champs">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [class.align-right]="champ['type'] == 'number' || champ['type'] == 'currency'"
            [class.align-center]="champ['type'] == 'boolean'"
          >
            {{ champ["nom"] }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [class.align-right]="champ['type'] == 'number' || champ['type'] == 'currency'"
            [class.align-center]="champ['type'] == 'boolean'"
          >
            <span *ngIf="champ['type'] == 'string' || champ['type'] == 'number'">{{ row[champ["key"]] }}</span>
            <span *ngIf="champ['type'] == 'currency'">{{ row[champ["key"]] | currency }}</span>
            <span *ngIf="champ['type'] == 'date'">{{ row[champ["key"]] | formatDate : "D" }}</span>
            <span *ngIf="champ['type'] == 'datetime'">{{ row[champ["key"]] | formatDate : "DT" }}</span>
            <span *ngIf="champ['type'] == 'boolean' && row[champ['key']] == true">
              <mat-icon>check</mat-icon>
            </span>
          </td>
        </ng-container>

        <!-- header, rows -->
        <tr mat-header-row *matHeaderRowDef="champsDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: champsDisplay" (click)="clickRow(row.id)" [class.row-highlight]="currentId == row.id"></tr>

        <!-- not found -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="99">Aucun élément trouvé</td>
        </tr>
      </table>
    </div>
    <div class="listpage-footer">
      <div class="pagination-container" *ngIf="count != 0">
        Items par page:
        <mat-select
          appearance="inline"
          class="pagination-select"
          [(ngModel)]="rowPerPage"
          style="font-size: 8; width: 5em; padding-left: 10px"
          (selectionChange)="changeRowPerPage($event)"
        >
          <mat-option *ngFor="let count of rowPerPageSelect" [value]="count">{{ count }}</mat-option>
        </mat-select>
        <button mat-icon-button [disabled]="currentPage == 0" (click)="pagination('debut')">
          <mat-icon>first_page</mat-icon>
        </button>
        <button mat-icon-button [disabled]="currentPage == 0" (click)="pagination('precedent')">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="pagination-pages">{{ from }} à {{ to }} de {{ count }}</div>
        <button mat-icon-button [disabled]="to > count || count == 0" (click)="pagination('suivant')">
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button mat-icon-button [disabled]="to > count || count == 0" (click)="pagination('fin')">
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!--fin listpage-container -->

  <!--Mettre l'entité de FORM ici...-->
</div>
