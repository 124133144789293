// Concatène les infos disponibles sur le calcul de profit en une string affichable
import { Pipe, PipeTransform } from '@angular/core';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';

@Pipe({
  name: 'shippingCalcGetErrorPrettierPipe',
})
export class ShippingCalcGetErrorPrettierPipe implements PipeTransform {
  transform(ratesData: ShipStation_ShippingRatesData): string {
    if (ratesData == null) return null;

    let indexRaison = 1;
    let prettyStrings = [];

    for (let str of ratesData.raisonRejetDescription) {
      prettyStrings.push(`[${indexRaison++}] ${str}`);
    }

    return prettyStrings.join('\n\n');
  }
}
