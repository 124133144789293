import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OData } from 'src/app/models/oData/OData_file';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import * as XLSX from 'xlsx'; //import * as XLSX from 'xlsx';

import { LocalStorage } from 'src/app/models/enum/LocalStorage';
import { KeyValue } from '@angular/common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  template: '',
})

/**
 * Classe abstraite des components de réception.
 */
export abstract class ListComponent implements OnInit {
  dataSource = new MatTableDataSource();

  recherche: any;
  rechercheOverrideGlobalSearchFilterFields: string[];
  textualSearchCritera: string;

  ExportExcelfileName: string = 'RapportExcel.xlsx';

  count = 0;
  currentPage = 0;
  from = 0;
  to = 0;
  rowPerPage: number;
  rowPerPageSelect = [10, 25, 50, 100, 500, 1000];
  orderBy: string;
  currentId: number;

  IsOdataEndpoint: boolean = true; //indique si les données seront dans le containeur {...,"values":[{},{},{}]} de oData ou non (non étant [{},{},{}])

  oData: OData = new OData(this.httpClient);
  champsKey: string[];
  // parametrable

  defaultOrderBy: string; // ordre du query
  SkipSelectOdataSection: boolean = false; //permet de ne PAS préciser la portion $select du odata
  champs: any[]; // liste des colonnes a afficher
  champsHardcoded: string[];
  champsHardcoded_Before: string[];
  champsDisplay: any[];
  additionnalUrlParams: any[]; //permet de pousser des paramètres (query params) additionnel simplement

  endpoint: string; // nom du endpoint
  autoload: boolean; // si true, va éxécuter un fetch des premier record quand le composant s'affiche

  CurrentData: any;
  CurrentSelectedData: any;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() showChild: EventEmitter<string> = new EventEmitter();

  protected constructor(protected httpClient: HttpClient) {}

  ngOnInit(): void {
    const rowPerPage = localStorage.getItem(LocalStorage.PAGINATION_ROW_PER_PAGE);
    this.rowPerPage = rowPerPage ? +rowPerPage : 100;
    //    this.champsKey = this.champs.map((champ) => champ.key);
    this.champsKey = this.champs
      .filter(function (champ) {
        return champ.hide !== true;
      })
      .map((champ) => champ.key);

    // champs auto + custom
    this.champsDisplay = [...this.champsKey];

    if (this.champsHardcoded !== undefined && this.champsHardcoded.length > 0) this.champsDisplay = this.champsDisplay.concat(this.champsHardcoded);

    if (this.champsHardcoded_Before !== undefined && this.champsHardcoded_Before.length > 0)
      this.champsDisplay = this.champsHardcoded_Before.concat(this.champsDisplay);

    this.effacerRecherche();
    if (this.autoload) this.launchRefresh();
  }

  /**
   * Version NON-ASYNC du refresh des données
   *
   */
  launchRefresh(): void {
    //on fait juste un call direct à la méthode async...
    this.launchRefreshAsync().subscribe();
  }

  /**
   * Méthode async pour caller l'API et refresh les data
   */
  launchRefreshAsync(): Observable<any> {
    const params = [];

    // select
    if (this.SkipSelectOdataSection === false) {
      const select = [...this.champsKey];
      params.push({
        key: '$select',
        value: select.join(),
      });
    }
    // order by
    if (this.orderBy) {
      params.push({ key: '$orderby', value: this.orderBy });
    }
    // where filter
    let filter = '';

    // Filtre global pour une recherche avec une seule entré à parse dans plusieurs champs
    if (this.rechercheOverrideGlobalSearchFilterFields !== undefined && this.textualSearchCritera !== undefined && this.textualSearchCritera !== '') {
      this.rechercheOverrideGlobalSearchFilterFields.forEach((champ) => {
        filter += 'contains(' + champ + ",'" + this.textualSearchCritera + "')";
        filter += ' or ';
      });
      filter = filter.substring(0, filter.length - 3);
    } else {
      for (const key of Object.keys(this.recherche)) {
        let ComparisonOperator = 'eq';

        //********************************
        //on nettoie le nom du champs, si jamais il contient des marqueurs de comparaisons (DateFacture_DATE_FILTER_GE [greater Then)], etc...)
        let nomChampBase = key;
        if (nomChampBase.endsWith('_DATE_FILTER_GE')) {
          nomChampBase = nomChampBase.replace('_DATE_FILTER_GE', '');
          ComparisonOperator = 'ge';
        } else if (nomChampBase.endsWith('_DATE_FILTER_LE')) {
          nomChampBase = nomChampBase.replace('_DATE_FILTER_LE', '');
          ComparisonOperator = 'le';
        }
        //********************************

        let nomChampSourceSystem = nomChampBase;
        const champ = this.champs.find((c) => c.key === nomChampBase);

        if (!champ) throw 'launchRefresh()-Le filtre de recherche (' + nomChampBase + ') ne semble pas exister dans votre liste de champ (this.champs)';

        //on va aller dans le dictionnaire des filtres en cours fetcher cette clé
        const value = this.recherche[key];

        //si on trouve un filtre à appliquer...
        if (value !== undefined && value !== null) {
          //un champ peut aussi avoir une différente entre le champ qui est la clé utilisé dans le Champs(), et le VRAI champ dans oData.... :\
          if (champ.BackEndFieldName !== undefined) {
            nomChampSourceSystem = champ.BackEndFieldName;
          }

          //prévalidation-1 : si le champ requiert d'appliquer un filtre pré-défini, on va utiliser ce champs
          if (champ.type === 'date' || champ.type === 'datetime') {
            let MyValue = value;
            var tmpdate = Date.parse(MyValue);
            let Madate = new Date(tmpdate);
            MyValue = Madate.toISOString().split('T')[0];
            filter += nomChampSourceSystem + ' ' + ComparisonOperator + ' ' + MyValue;
          } else if (champ.type === 'number' || champ.type === 'bool' || champ.type === 'boolean') {
            // number
            filter += nomChampSourceSystem + ' ' + ComparisonOperator + ' ' + value;
          } else if (champ.type === 'AutoCalcFilter') {
            //dès qu'on
            if (value === true || value === false) {
              filter += ' (' + champ.filter + ')' + ' ' + ComparisonOperator + ' ' + value;
            } else {
              filter += ' (' + champ.filter + ') ';
            }
          } else if (typeof value === 'string') {
            // string
            filter += 'contains(' + nomChampSourceSystem + ",'" + value + "')";
          }
          filter += ' and ';
        }
      }
      filter = filter.substring(0, filter.length - 4);
    }

    if (filter !== '') {
      params.push({ key: '$filter', value: filter });
    }

    if (this.additionnalUrlParams && this.additionnalUrlParams.length > 0) {
      this.additionnalUrlParams.forEach((element) => {
        params.push(element);
      });
    }

    // fetch
    this.oData = new OData(this.httpClient);

    let myUrl = OData.generateODataLink(this.endpoint, params);
    let MyplainURI: string = decodeURIComponent(decodeURIComponent(myUrl.href));

    //retourne le pipe->mergemap
    return this.oData.getOdatafromApi(myUrl, this.rowPerPage, this.IsOdataEndpoint).pipe(
      mergeMap((data) => {
        this.refreshData(data);
        return of(true); //retourne TRUE...surtout pour dire que le call et le refresh sont terminés
      })
    );
  }

  /**
   * Efface la recherche.
   */
  effacerRecherche(): void {
    this.recherche = [];
    this.textualSearchCritera = '';

    this.orderBy = this.defaultOrderBy;
  }

  /**
   * Permet de changer et persister le changement de lignes par page
   * @param event événement contenant l'information.
   */
  changeRowPerPage(event): void {
    this.rowPerPage = event.value;
    localStorage.setItem(LocalStorage.PAGINATION_ROW_PER_PAGE, event.value);
    this.launchRefresh();
  }

  /**
   * Met à jour les données.
   * @param data nouvelles données
   */
  refreshData(data): void {
    this.CurrentData = data;
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.count = this.oData.count;
    this.currentPage = this.oData.currentPage - 1;
    this.from = this.currentPage * this.rowPerPage;
    this.from++;
    this.to = (this.currentPage + 1) * this.rowPerPage;
  }

  /**
   * Gestion du système de pagination
   * @param action suivant/precedent/debut/fin
   */
  pagination(action: 'suivant' | 'precedent' | 'debut' | 'fin'): void {
    switch (action) {
      case 'suivant':
        this.oData.next().subscribe((data) => {
          this.refreshData(data);
        });
        break;

      case 'precedent':
        this.oData.previous().subscribe((data) => {
          this.refreshData(data);
        });
        break;

      case 'debut':
        this.oData.first().subscribe((data) => {
          this.refreshData(data);
        });
        break;

      case 'fin':
        this.oData.last().subscribe((data) => {
          this.refreshData(data);
        });
        break;
    }
  }

  /**
   * Trrie les données
   * @param e event
   */
  sortData(e): void {
    this.dataSource = null;
    this.orderBy = e.active + ' ' + e.direction;
    this.launchRefresh();
  }

  /**
   * Permet d'afficher le contenu enfant de la ligne sélectionné (ex: select details à partir de header)
   * @param id id de la ligne
   */
  clickRow(id): void {
    this.currentId = id;
    this.showChild.emit(id);
  }

  exportexcel(): void {
    //https://medium.com/@patade888/exporting-data-to-excel-in-angular-8-5a7cf5d0d25d
    /* table id is passed over here */
    let element = document.getElementById('myMainTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.ExportExcelfileName);
  }

  /**
   * formate la date en YYYY-MM-DD.
   */
  formatDate(date): any {
    if (typeof date == 'string') return date;
    else return date.toJSON().split('T')[0];
  }
}
function mergemap(arg0: (data: any) => void): import('rxjs').OperatorFunction<any, unknown> {
  throw new Error('Function not implemented.');
}
