import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from 'src/app/service/auth.service';
import { UserInfo } from 'src/app/models/user/UserInfo';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() userInfo: UserInfo;
  isFullScreen: boolean;
  constructor(public authService: AuthService, public app: AppComponent, public router: Router) {}

  ngOnInit(): void {}

  /**
   * Toggle l'application en mode full screen / normal
   */
  toggleFullScreen(): void {
    if (!this.isFullScreen) document.documentElement.requestFullscreen();
    else document.exitFullscreen();
    this.isFullScreen = !this.isFullScreen;
  }

  onClickChangeWorkstation() {
    let currentUrl = this.router.url;
    this.router.navigate(['chooseWorkstation', currentUrl]);
  }
}
