import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { OData } from 'src/app/models/oData/OData_file';
import { ListComponent } from 'src/app/component/core/list/list.component';
import { DVLService } from 'src/app/service/DVL.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { LoaderService } from 'src/app/service/loader.service';
@Component({
  selector: 'app-online-order-list',
  templateUrl: './online-order-list.component.html',
  styleUrls: ['./online-order-list.component.css'],
})
export class OnlineOrderListComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,

    private dvlService: DVLService,
    private snack: MatSnackBar,
    private dialogService: PopUpDialogService,
    private loaderService: LoaderService
  ) {
    super(httpClient);

    this.endpoint = 'OrderDetailsView';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'ProfileID',
        nom: 'Profile',
        type: 'number',
        hide: true,
      },
      {
        key: 'SiteID',
        nom: 'SiteID',
        type: 'number',
        hide: true,
      },
      {
        key: 'sku_Cleaned',
        nom: 'sku',
        type: 'string',
      },
      {
        key: 'SiteOrderID',
        nom: 'Site Order ID',
        type: 'string',
      },
      {
        key: 'Sitename',
        nom: 'Site',
        type: 'string',
      },
      {
        key: 'OrderDate',
        nom: 'Date commande',
        type: 'date',
      },
      {
        key: 'FinalQty',
        nom: 'Qté commandé',
        type: 'number',
      },
      {
        key: 'NbOfItemsInCurrentOrder',
        nom: 'Nb Produits dans DVL',
        type: 'number',
      },
      {
        key: 'CA_Order_ID',
        nom: 'CA_Order_ID',
        type: 'number',
        hide: false,
      },
      {
        key: 'OrigTrans_TotalAmount',
        nom: 'Montant total',
        type: 'currency',
      },
      {
        key: 'SummativeInvoiceNumber',
        nom: 'Acomba fact sommative',
        type: 'string',
      },
      {
        key: 'LocalERP_CreatedTransactionNumber',
        nom: 'Acomba détail',
        type: 'string',
      },
      {
        key: 'SS_Tag_prioritaire',
        nom: 'SS - Prioritaire',
        type: 'boolean',
      },
      {
        key: 'SS_Tag_ready_to_go',
        nom: 'SS - Ready To Go',
        type: 'boolean',
      },
      {
        key: 'SS_Tag_EnTraitementPieuvre',
        nom: 'SS - Traitement Pieuvre',
        type: 'boolean',
      },

      {
        key: 'SS_Tag_completed ',
        nom: 'SS - Complété',
        type: 'boolean',
      },
      {
        key: 'pretPourExpedition',
        type: 'AutoCalcFilter',
        hide: true,
        filter: 'SS_Tag_completed eq false and SS_Tag_ready_to_go eq true',
      },
    ];
    this.champsHardcoded = ['ForageDetailTrace', 'LienShipstation', 'LienChannelAdvisor'];

    this.recherche = {
      ProfileID: undefined,
      SiteID: undefined,
      SiteOrderID: undefined,
      sku_Cleaned: undefined,
      pretPourExpedition: undefined,
    };
  }
  TitreGlobalPage: string = 'Listes des ventes DVL';
  IconGlobalPage: string = 'shopping_cart';
  NomEntiteCourante: string = 'trace';

  profiles = [
    {
      ProfileID: 12010161,
      name: 'Le monde du foyer',
    },
    {
      ProfileID: 12011108,
      name: 'MEXICO',
    },
    {
      ProfileID: 12009943,
      name: 'USA',
    },
  ];
  sites = [
    {
      SiteID: 587,
      SiteName: 'Checkout Direct',
    },
    {
      SiteID: 1261,
      SiteName: 'Walmart Canada',
    },
    {
      SiteID: 644,
      SiteName: 'Amazon Seller Central - CA',
    },
    {
      SiteID: 862,
      SiteName: 'Shopify',
    },
    {
      SiteID: 1275,
      SiteName: 'Best Buy CA',
    },
    {
      SiteID: 576,
      SiteName: 'eBay Fixed Price CA',
    },
    {
      SiteID: 640,
      SiteName: 'Amazon Seller Central - US',
    },
  ];

  ngOnInit(): void {
    super.ngOnInit();
    this.recherche.ProfileID = this.route.snapshot.paramMap.get('ProfileID');
    this.recherche.SiteID = this.route.snapshot.paramMap.get('SiteID');
    this.recherche.SiteOrderID = this.route.snapshot.paramMap.get('SiteOrderID');
    this.recherche.sku_Cleaned = this.route.snapshot.paramMap.get('sku_Cleaned');
    this.recherche.pretPourExpedition = this.route.snapshot.paramMap.get('pretPourExpedition');
    this.recherche.OrderDate_DATE_FILTER_GE = this.route.snapshot.paramMap.get('OrderDate_DATE_FILTER_GE');
    this.recherche.OrderDate_DATE_FILTER_LE = this.route.snapshot.paramMap.get('OrderDate_DATE_FILTER_LE');
    if (
      this.recherche.ProfileID ||
      this.recherche.SiteID ||
      this.recherche.sku_Cleaned ||
      this.recherche.pretPourExpedition ||
      this.recherche.OrderDate_DATE_FILTER_GE ||
      this.recherche.OrderDate_DATE_FILTER_LE
    )
      this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.ProfileID) params['ProfileID'] = this.recherche.ProfileID;
    if (this.recherche.SiteID) params['SiteID'] = this.recherche.SiteID;
    if (this.recherche.SiteOrderID) params['SiteOrderID'] = this.recherche.SiteOrderID;
    if (this.recherche.sku_Cleaned) params['sku_Cleaned'] = this.recherche.sku_Cleaned;
    if (this.recherche.pretPourExpedition) params['pretPourExpedition'] = this.recherche.pretPourExpedition;
    if (this.recherche.OrderDate_DATE_FILTER_GE) params['OrderDate_DATE_FILTER_GE'] = this.formatDate(this.recherche.OrderDate_DATE_FILTER_GE);
    if (this.recherche.OrderDate_DATE_FILTER_LE) params['OrderDate_DATE_FILTER_LE'] = this.formatDate(this.recherche.OrderDate_DATE_FILTER_LE);

    this.router.navigate(['/commande', params]);

    this.launchRefresh();
  }

  public async RefreshDVL() {
    let tmpTest = '';
    this.dvlService.RefreshOrders_ReadyToGo().subscribe((jsonData) => {
      this.snack.open('Les DVL Ready-to-go devrait être rafraichit.', '', { duration: 3000 });
    });
    this.loaderService.hide(); // on cache le loader

    this.snack.open('Le rafraichissement a été déclanché, et devrait prendre quelques minutes (2-3 minutes)', '', { duration: 3000 });
  }
}
