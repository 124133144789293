import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { UserRights } from 'src/app/models/user/userRights';
import { ReceptionService } from '../../../service/reception.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  currentRoute: string;

  @Input() userRight: UserRights;

  constructor(public authService: AuthService, public receptionService: ReceptionService, private router: Router) {}

  ngOnInit() {}

  /**
   * Permer de se déconnecter.
   */
  logout(): void {
    this.authService.logout();
  }
}
