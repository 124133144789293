import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, lastValueFrom } from 'rxjs';

import { ReceptionService } from 'src/app/service/reception.service';
import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { AuthService } from 'src/app/service/auth.service';

import { ListComponent } from 'src/app/component/core/list/list.component';
import { shipstationService } from 'src/app/service/shipstation.service';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
@Component({
  selector: 'app-reception-trace',
  templateUrl: './reception-trace.component.html',
  styleUrls: ['./reception-trace.component.css'],
})
export class ReceptionTraceComponent extends ListComponent {
  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private snack: MatSnackBar,
    protected httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private receptionService: ReceptionService,
    private shipStationService: shipstationService,
    private popupService: PopUpDialogService
  ) {
    super(httpClient);

    this.endpoint = 'ItemReceptionTraces';
    this.IsOdataEndpoint = true;
    this.autoload = false;
    this.additionnalUrlParams = [{ key: '$expand', value: 'ItemReceptionDetail($select=PrecalcPieuvreDestination,HH_ConsumerNumber,SupplierInvoiceNumber)' }];

    this.champs = [
      {
        key: 'ItemReceptionHeaderId',
        nom: '#En-tête',
        type: 'number',
        headerCSSClass: 'header-group_info-produit',
      },
      {
        key: 'ScannedBarCode',
        nom: 'Barcode scanné',
        type: 'string',
        headerCSSClass: 'header-group_info-produit',
      },
      {
        key: 'ProductNumber',
        nom: '#Produit',
        type: 'string',
        headerCSSClass: 'header-group_info-produit',
      },
      {
        key: 'SCAN1_DateTime',
        nom: 'Scan1 date',
        type: 'datetime',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_ctrl_UserName',
        nom: 'Scan1 usager',
        type: 'string',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_ctrl_StationName',
        nom: 'Scan1 station',
        type: 'string',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_CA_QteShipped',
        nom: 'Scan1 Qté expédiée',
        type: 'number',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_FinalAction',
        nom: 'Scan1 action finale',
        type: 'string',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_ProblemCode',
        nom: 'Scan1 Problem code',
        type: 'string',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'SCAN1_DetailInformation',
        nom: 'Scan1 info détail',
        type: 'string',
        headerCSSClass: 'header-group_info-scan1',
      },
      {
        key: 'id',
        nom: '#Trace',
        type: 'number',
      },

      /*Section pour de sfiltres complexes */

      {
        key: 'ItemReceptionDetailId',
        nom: 'ID Détail',
        type: 'number',
      },
    ];

    this.generateHTMLDisplayColumns();

    this.champsHardcoded = ['removeTrace'];

    this.defaultOrderBy = 'id desc';

    this.recherche = {
      ItemReceptionHeaderId: undefined,
      ItemReceptionDetailId: undefined,
      ProductNumber: undefined,
      ScannedBarCode: undefined,
      SCAN1_CA_SiteOrderID: undefined,
      SCAN1_DateTime_DATE_FILTER_GE: undefined,
      SCAN1_DateTime_DATE_FILTER_LE: undefined,
    };
  }

  TitreGlobalPage: string = 'Réceptions / Traces';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'trace';

  pieuvreDestinations = ['DVL', 'DVL-UNIQUE', 'INCONNU', 'MAGASIN', 'MAGASIN-COMMANDE-CLIENTE', 'SPECIALISTE', 'PROBLEM', 'SHOPATRON', 'CHANNELADVISOR'];

  ColumnsOrder = [];
  overrideChampsDiplay = [];

  ngOnInit(): void {
    super.ngOnInit();

    // Important d'override le truc du list component par notre nouvel colsdef
    let overrideChampsKey = this.ColumnsOrder.filter(function (champ) {
      return champ.hide !== true;
    }).map((champ) => champ.key);
    this.overrideChampsDiplay = [...overrideChampsKey];

    this.recherche.ItemReceptionHeaderId = this.route.snapshot.paramMap.get('ItemReceptionHeaderId');
    this.recherche.ItemReceptionDetailId = this.route.snapshot.paramMap.get('ItemReceptionDetailId');
    this.recherche.ProductNumber = this.route.snapshot.paramMap.get('ProductNumber');
    this.recherche.ScannedBarCode = this.route.snapshot.paramMap.get('ScannedBarCode');
    this.recherche.SCAN1_DateTime_DATE_FILTER_GE = this.route.snapshot.paramMap.get('SCAN1_DateTime_DATE_FILTER_GE');
    this.recherche.SCAN1_DateTime_DATE_FILTER_LE = this.route.snapshot.paramMap.get('SCAN1_DateTime_DATE_FILTER_LE');
    this.recherche.SCAN1_FinalAction = this.route.snapshot.paramMap.get('SCAN1_FinalAction');

    this.launchRefresh();
  }

  generateHTMLDisplayColumns() {
    // On va override  les colunne a display par cet array avec les colonnes provenant d'un odata expand
    this.ColumnsOrder = structuredClone(this.champs); //DeepClone

    let commandeFournissseur = {
      key: 'SupplierInvoiceNumber',
      nom: '#Commande Fournisseur',
      type: 'string',
      expand: 'ItemReceptionDetail',
      headerCSSClass: 'header-group_info-produit',
    };
    let pieuvreDestination = {
      key: 'PrecalcPieuvreDestination',
      nom: 'Destination orig',
      type: 'string',
      expand: 'ItemReceptionDetail',
      headerCSSClass: 'header-group_info-scan1',
    };
    let hhConsumerNumber = {
      key: 'HH_ConsumerNumber',
      nom: 'Code Conso orig',
      type: 'string',
      expand: 'ItemReceptionDetail',
      headerCSSClass: 'header-group_info-scan1',
    };
    this.ColumnsOrder.splice(3, 0, commandeFournissseur); // Manière bizarre de faire un insert à un index préçis
    this.ColumnsOrder.splice(10, 0, pieuvreDestination);
    this.ColumnsOrder.splice(11, 0, hhConsumerNumber);
  }

  rechercher() {
    var params = [];
    if (this.recherche.ItemReceptionHeaderId != null) params['ItemReceptionHeaderId'] = this.recherche.ItemReceptionHeaderId;
    if (this.recherche.ItemReceptionDetailId != null) params['ItemReceptionDetailId'] = this.recherche.ItemReceptionDetailId;
    if (this.recherche.ProductNumber != null) params['ProductNumber'] = this.recherche.ProductNumber;
    if (this.recherche.ScannedBarCode != null) params['ScannedBarCode'] = this.recherche.ScannedBarCode;

    if (this.recherche.SCAN1_DateTime_DATE_FILTER_GE != null)
      params['SCAN1_DateTime_DATE_FILTER_GE'] = this.formatDate(this.recherche.SCAN1_DateTime_DATE_FILTER_GE);
    if (this.recherche.SCAN1_DateTime_DATE_FILTER_LE != null)
      params['SCAN1_DateTime_DATE_FILTER_LE'] = this.formatDate(this.recherche.SCAN1_DateTime_DATE_FILTER_LE);
    if (this.recherche.SCAN1_FinalAction != null) params['SCAN1_FinalAction'] = this.recherche.SCAN1_FinalAction;
    this.router.navigate(['/reception-trace', params]);
    this.launchRefresh();
  }

  async DeleteSelectedTraceExecution(traceID, DetailID) {
    let MyTraceID: number = traceID;
    let MyReceptionDetailID: number = DetailID;
    let MyCurrentuser: string = this.authService.info.name;
    let MyCurrentExplanation: string;

    //************************
    //pop-=up de confirmation de sur-assignation
    let msg = 'Attention!<br/><br/>Souhaitez vous vraiment SUPPRIMER cette trace de réception?';
    msg += '<br/><br/>La trace sera supprimée, et la ligne de réception HOME sera aussi corrigée.';
    let popupResult = this.matDialog.open(PopConfirmComponent, {
      data: {
        titre: "Suppression d'une trace de réception",
        texte: msg,
        affirmativeActionName: 'Oui, supprimer la trace',
        affirmativeActionColor: 'warn',
        dismissiveActionName: "Non, annuler l'action",
        dismissiveActionColor: 'primary',
      },
    });

    let monRetour = await lastValueFrom(popupResult.afterClosed());
    if (monRetour === true) {
      this.receptionService
        .delete(MyTraceID, 'delete from recepetion-trace component', MyReceptionDetailID)
        .pipe(
          catchError((erreur) => {
            console.log(erreur);
            this.popupService.httpErrorPopup(erreur);
            this.snack.open('Erreur lors de la suppression : ' + erreur, 'OK', { duration: 5000 });
            return erreur;
          })
        )
        .subscribe((data) => {
          this.snack.open('La trace semble avoir été supprimée correctement', 'OK', { duration: 3000 });
        });
    }
    //************************
  }
}
