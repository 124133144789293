import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListComponent } from '../../core/list/list.component';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { PackageTypes } from '../../../models/packageTypes';
import { PackagetypesService } from '../../../service/packagetypes.service';

@Component({
  selector: 'app-package-types-list',
  templateUrl: './package-types-list.component.html',
  styleUrls: ['./package-types-list.component.scss'],
})
export class PackageTypesListComponent extends ListComponent {
  showChildForm: boolean = true; //

  selectedItem: any; //représente l'entité actuellement sélectionnée

  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...

  childHaveChanges: boolean; //indique si le panneau ENFANT contient une modification
  needRefresh: boolean = false;

  TitreGlobalPage: string = 'Gestion des types de paquet';
  NomEntiteCourante: string = 'Types de paquet';

  constructor(protected httpClient: HttpClient, private dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    super(httpClient);
    this.endpoint = 'PackageTypes';

    this.autoload = true;
    this.rechercheOverrideGlobalSearchFilterFields = ['PackageShortCode', 'PackageDescription', 'LengthInch', 'WidthInch', 'HeighInch'];
    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
        hide: false, //pour l'instnat, on est obligé sinon le udpate casse...
      },
      {
        key: 'PackageShortCode',
        nom: 'Short Code',
        type: 'string',
      },
      {
        key: 'PackageDescription',
        nom: 'Description',
        type: 'string',
      },
      {
        key: 'DisplayIndex',
        nom: 'Ordre',
        type: 'number',
      },
      {
        key: 'LengthInch',
        nom: 'Longeur (Po)',
        type: 'number',
      },
      {
        key: 'WidthInch',
        nom: 'Largeur (Po)',
        type: 'number',
      },
      {
        key: 'HeighInch',
        nom: 'Hauteur (Po)',
        type: 'number',
      },
      {
        key: 'UsableLengthInch',
        nom: 'Longueur Utilisable',
        type: 'number',
      },
      {
        key: 'UsableWidthInch',
        nom: 'Largeur Utilisable',
        type: 'number',
      },
      {
        key: 'UsableHeighInch',
        nom: 'Hauteur Utilisable',
        type: 'number',
      },
      {
        key: 'PackageCost',
        nom: 'Prix',
        type: 'number',
      },
      {
        key: 'MaxWeightPound',
        nom: 'Poid Max (lbs)',
        type: 'number',
      },
      {
        key: 'VolumetricWeight',
        nom: 'Poids Volumétrique',
        type: 'number',
      },
      {
        key: 'IsActive',
        nom: 'Est Actif',
        type: 'boolean',
      },
    ];
    this.defaultOrderBy = 'DisplayIndex asc';
  }

  openForm(MyRow: any) {
    this.selectedItem = MyRow;

    this.showChildForm = true;
  }

  //evenement, issue du child, indiquant qu'on veut afficher ou masquer le FORM
  OnChildFormVisibilityChanged(val: boolean): void {
    if (val === false && this.childHaveChanges === true) this.popUpConfirmDiscard();
    else this.showChildForm = val;

    if (this.needRefresh === true) {
      this.launchRefresh();
      this.needRefresh = false;
    }
  }

  onChangeChildState(val: any): void {
    this.childHaveChanges = val;
    if (val === true) this.needRefresh = true;
    else this.needRefresh = false;
    this.cdRef.detectChanges(); //bidouille, car la valeur childHaveChanges pointe sur un Ngif..et que c'Est ca- https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  }

  //Ce event indique que l'enfant à demander au parent (nous) de forcer un refresh des datas.
  OnChildAskForForceRefresh(val: any): void {
    if (val === true) {
      this.launchRefresh();
      this.needRefresh = false;
    }
  }

  createNew() {
    this.selectedItem = null;
    this.showChildForm = true;
  }

  //ici, c'est qu'on a cliqué sur le panneau de protection de 'changement détecté'.  ca permet de faire un DISCARD
  popUpConfirmDiscard() {
    let test = 'bla';
    // pop dialog

    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Changements non sauvegardés',
        texte:
          'Attention! Des changements ont été détectés dans votre formulaire.  Voulez-vous IGNORER ces changementes ou POURSUIVRE la modification à droite?',
        affirmativeActionName: 'Poursuivre',
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Ignorer',
        dismissiveActionColor: 'warn',
      },
    });

    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        return;
      }

      this.showChildForm = false;
      this.childHaveChanges = false;
    });
  }
}
