import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ReceptionService } from 'src/app/service/reception.service';
import { State, FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, take, Subject, takeUntil } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-reception-detail',
  templateUrl: './reception-detail.component.html',
  styleUrls: ['./reception-detail.component.css'],
})
export class ReceptionDetailComponent implements AfterViewInit, OnDestroy {
  @ViewChild(GridComponent) grid!: GridComponent;
  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public isLoading = false;
  public totalItemCount = 0;

  private unsubNotifier = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private receptionService: ReceptionService,
    private location: Location,
    private utilService: UtilService
  ) {}

  public state: State = {
    take: 25,
    skip: 0,
    sort: [
      {
        field: 'ItemReceptionHeaderId',
        dir: 'desc',
      },
    ],
  };

  pieuvreDestinations = ['DVL', 'DVL-UNIQUE', 'INCONNU', 'MAGASIN', 'MAGASIN-COMMANDE-CLIENTE', 'SPECIALISTE', 'SHOPATRON', 'CHANNELADVISOR'];
  TitreGlobalPage: string = 'Réceptions / Detail';
  IconGlobalPage: string = 'local_shipping';

  ngOnInit(): void {
    //Setup la pagination
    this.state.take = this.utilService.getRowPerPage();

    // subscribe to data
    this.receptionService.ReceptionDetails.pipe(takeUntil(this.unsubNotifier)).subscribe((response) => {
      // set grid data
      this.totalItemCount = response['@odata.count'];
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }

  ngAfterViewInit(): void {
    // On va aller chercher les noms et les types des colonnes du grid kendo.
    let ColsNamesAndTypes: FieldType[] = this.grid.columns.toArray().map((y: any) => ({ name: y.field, type: y.filter }));

    // Cette méthode permet de créer le state initiale du grid.
    this.initGridFilter(ColsNamesAndTypes);

    // Puisqu'on change des données à la fin d'un change detection cycle, il faut manuellement dire à Angular qu'il ya eu des changements.
    // Sinon, sa fait des exceptions: NG0100.
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  refresh() {
    this.isLoading = true;

    /*On va sélectionner les champs un à un... ça permet de réduire de 70% la taille du QUERY!*/
    let strMyQuery = '$select=id,ItemReceptionHeaderId,SupplierInvoiceNumber,ProductNumber,ProductDescription,PrecalcPieuvreDestination,';
    strMyQuery += 'HH_ConsumerNumber,OrigWarehouseCode,OrigWarehouseSubCode, NbOfScanTrace, QtyToReceive,QtyReceived,';
    strMyQuery += 'QtyRemaining,PrecalcPieuvreDestinationSubCode';
    strMyQuery += '&$expand=ItemReceptionHeader($select=IsCompleted)';
    this.receptionService
      .odataStr_ReceptionDetails(this.state, strMyQuery)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((res) => {
        this.isLoading = false;
      });
  }

  /**
   * Initialise l'objet filter du state du Kendo Grid à partir des params de l'URI
   * @param ColsNamesAndTypes Données des colonnes du Kendo Grid
   */
  private initGridFilter(columnNamesAndTypes: FieldType[]): void {
    //Si les données ne sont pas chargées, alors on ne fait rien.
    if (columnNamesAndTypes.length === 0) return;

    // Si on a le modèle des colonnes ainsi que leur types.
    // alors on va contruire le filter object du kendo Grid
    this.route.params.pipe(take(1)).subscribe((uriParams: Params) => {
      this.state.filter = this.utilService.constructKendoFilterFromQueryParams(uriParams, columnNamesAndTypes);
      this.addCustomAttributesToFilter(uriParams);
      this.refresh();
    });
  }

  dataStateChange(state: State) {
    this.utilService.setRowPerPage(state.take ?? 25);
    this.state = state;
    this.removeQueryParams();
    this.reloadQueryParams();
    this.refresh();
  }

  removeQueryParams() {
    let currentUrl = this.location.path();
    let updatedUrl = currentUrl.split(';')[0]; // Remove the query parameters
    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  reloadQueryParams() {
    let updatedUrl = `${this.location.path()}`;
    updatedUrl += this.utilService.constructURIQueryParamsFromFilter(this.state.filter);

    if (updatedUrl.charAt(updatedUrl.length - 1) === ';') {
      updatedUrl = updatedUrl.substring(0, updatedUrl.length - 1);
    }

    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  private addCustomAttributesToFilter(params: Params): void {
    let propertiesName = Object.keys(params);
    let propertiesValues = Object.values(params);
    let customPropIndex = propertiesName.indexOf('LigneNonTerminee');

    // attribut custom (anciennement une colonne autoCalc) si présent on rajoute un filtre
    if (customPropIndex > -1 && propertiesValues[customPropIndex] === 'true') {
      let newFilterToAdd: FilterDescriptor = {
        field: 'QtyRemaining',
        operator: 'gt',
        value: 0,
      };
      this.state.filter.filters.push(newFilterToAdd);
    } else if (customPropIndex > -1 && propertiesValues[customPropIndex] === 'false') {
      let newFilterToAdd: FilterDescriptor = {
        field: 'QtyRemaining',
        operator: 'eq',
        value: 0,
      };
      this.state.filter.filters.push(newFilterToAdd);
    }
  }
}
