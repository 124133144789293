import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageCommande',
})
export class AgeCommandePipe implements PipeTransform {
  transform(orderDate: Date): string {
    try {
      let dateCommande = new Date(orderDate);
      let dateEnCours = new Date();
      let ageCommandeEnMs = dateEnCours.valueOf() - dateCommande.valueOf();
      let ageCommandeEnJours = Math.round(ageCommandeEnMs / (1000 * 3600 * 24));
      return ageCommandeEnJours.toString();
    } catch {
      return '';
    }
  }
}
