<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre"><mat-icon>attach_money</mat-icon>{{ TitreGlobalPage }}</h1>
</div>

<div class="main-page-container">
  <!-- CHAMPS RECHERCHE -->
  <div class="listpage-search-container"></div>
  <div class="listpage-container">
    <div class="ss-list-button-container">
      <!-- Permet de choisir pour quel fournisseur on veut déclencher un extract-->

      <!--Grosse barrure indique que des changemnets sont en cours-->
      <div class="ss-list-button-container">
        <div class="info-container">
          <mat-expansion-panel hideToggle (opened)="actionZoneLaunchExtractExpanded = true" (closed)="actionZoneLaunchExtractExpanded = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>sync_icon</mat-icon>
                <h3>Déclancher une nouvelle extraction</h3>
              </mat-panel-title>
              <mat-panel-description>
                {{ actionZoneLaunchExtractExpanded ? "Saisir les infos puis cliquez sur Lancer" : "Cliquez ici pour afficher les options d'Extraction" }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field class="parametre-execution-action">
              <mat-label>Fournisseur</mat-label>
              <mat-select name="SiteID">
                <mat-option [value]="'HOME'">HOME</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="parametre-execution-action">
              <mat-label>Date de l'Extraction à faire</mat-label>
              <mat-date-range-input [rangePicker]="pickerRange">
                <input matStartDate placeholder="Date début" [(ngModel)]="processingOptions.dateStart" name="orderDate_DATE_FILTER_GE" />
                <input matEndDate placeholder="Date fin" [(ngModel)]="processingOptions.dateEnd" name="orderDate_DATE_FILTER_LE" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerRange"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerRange></mat-date-range-picker>
            </mat-form-field>

            <!--Checkbox si on veut extraire à partir des fournisseur (ex:HH Connect) ou non-->
            <mat-checkbox color="primary" class="parametre-execution-action" [(ngModel)]="processingOptions.AllowSupplierDataExtraction"
              >Extraction fournisseur</mat-checkbox
            >

            <!--Checkbox si on veut créer dans ACOMBA ou non-->
            <mat-checkbox color="primary" class="parametre-execution-action" [(ngModel)]="processingOptions.AllowERPInvoiceAPCreation"
              >Créer dans Acomba</mat-checkbox
            >

            <!--LAncement du traitement-->
            <button mat-stroked-button (click)="LaunchInvoiceAPExtraction()" class="parametre-execution-action" color="primary">
              <mat-icon>add</mat-icon> Relancer une extraction
            </button>
          </mat-expansion-panel>
        </div>
      </div>
    </div>

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      filterable="menu"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
          <mat-icon>delete</mat-icon>
          Vider les filtres
        </button>
        <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
          <mat-icon>upload_file</mat-icon>
          <span>Excel</span>
        </button>
        <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
      </ng-template>
      <!-- COLUMNS -->
      <kendo-grid-excel fileName="InvoicesAP.xlsx"></kendo-grid-excel>
      <!-- COLUMNS -->

      <!-- SS_OrderNo -->
      <kendo-grid-column field="ERP_SupplierCode" title="#Four" [width]="40" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <!-- SS_OrderNo -->
      <kendo-grid-column field="ERP_InvoiceNumber" title="# Facture" [width]="65" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <kendo-grid-column field="Ctrl_Status" title="Statut" [width]="65" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <kendo-grid-column field="ERP_Description" title="Description" [width]="120" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <!-- SS_OrderNo -->
      <kendo-grid-column field="ERP_InvoiceDate" title="Date Facture" [width]="60" [minResizableWidth]="50" filter="date">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ERP_InvoiceDate | date : "yyyy-MM-dd" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="InvoiceTotal" title="Total" [width]="50" [minResizableWidth]="50" filter="numeric"></kendo-grid-column>

      <kendo-grid-column field="Ctrl_ERP_InvoiceAP_CreatedDate" title="Date création" [width]="80" [minResizableWidth]="50" filter="date">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.Ctrl_ERP_InvoiceAP_CreatedDate | date : "yyyy-MM-dd HH:mm" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="OriginalInvoiceCopyURIFullPath" title="Lien facture PDF" [width]="50" [minResizableWidth]="50" filter="text">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a href="{{ dataItem.OriginalInvoiceCopyURIFullPath }}">VOIR</a>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Ctrl_LastStep" title="Derniere Étape" [width]="200" [minResizableWidth]="50" filter="text">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [style.color]="dataItem.Ctrl_Status === 'FAILED' ? 'red' : dataItem.Ctrl_Status === 'COMPLETED' ? 'green' : 'yellow'">
            {{ dataItem.Ctrl_LastStep }}
          </span>
          <span *ngIf="dataItem.Ctrl_Status === 'FAILED'" title="{{ dataItem.Ctrl_ERP_InvoiceAP_Error }}"> Détail Erreur </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Re-traiter" [width]="50" [minResizableWidth]="50">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button color="primary" type="button" (click)="ReprocessOneElement(dataItem)">
            <mat-icon>sync_icon</mat-icon>
            <span>Retry</span>
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
