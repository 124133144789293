import { Pipe, PipeTransform } from '@angular/core';
import { Shipstation_dimensions } from 'src/app/models/shipstation/shipstation-dimensions';
import { ShipStation_Shipments } from 'src/app/models/shipstation/shipstation-shipment';

@Pipe({
  name: 'dimensionsEnPouces',
})

/**
 * Ce pipe prend un Shipment shipstation en param, va chercher sa props 'dimensions' (si existe), et retourne les dimensions en pouces
 */
export class DimensionsEnPoucesPipe implements PipeTransform {
  transform(myShipment: ShipStation_Shipments, codeBoite: string): string {
    if (!myShipment || !myShipment.dimensions || myShipment.dimensions === undefined) return '';

    //on appelle la fonction de conversion sur les 3 dimensions
    let chaine = this.UneDimensionEnPouces(myShipment.dimensions.length, myShipment.dimensions.units);
    chaine += ` x ${this.UneDimensionEnPouces(myShipment.dimensions.width, myShipment.dimensions.units)}`;
    chaine += ` x ${this.UneDimensionEnPouces(myShipment.dimensions.height, myShipment.dimensions.units)} in.`;

    // Si c'est dans une boîte conventionnelle, affichage du code boîte
    if (codeBoite != undefined && codeBoite != '') chaine = `[${codeBoite}] ${chaine}`;

    return chaine;
  }

  UneDimensionEnPouces(valeur: number, unite: string): string {
    let val = 0;

    let uniteModified = unite.toLowerCase().substring(0, 4);

    // Conversion en fonction de l'unité de départ
    switch (uniteModified) {
      case 'inch': //inche, inches
        val = valeur;
        break;
      case 'cent': //centimeter, centimeters
        val = valeur * 0.3937007874;
        break;
    }

    // Arrondi à 2 décimales
    let chaine = val.toFixed(2);
    // Élimine les zéros à la fin des décimales
    if (chaine.includes('.')) chaine = chaine.replace(/0+$/, '');
    // Élimine le point si c'est un entier
    if (chaine.endsWith('.')) chaine = chaine.substring(0, chaine.length - 1);

    return chaine;
  }
}
