import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usager } from '../models/usager';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsagerService {
  constructor(private httpClient: HttpClient) {}

  private BASE_URL = environment.apiUrl + 'Auth/';

  getBasicList() {
    return this.httpClient.get<Usager[]>(`${this.BASE_URL}GetBasicList?$select=id,UserName&$orderby=Username`);
  }

  get(userName: string) {
    return this.httpClient.get<Usager>(`${this.BASE_URL}GetList?$select=id,UserName,Email,PhoneNumber,PinCode,IsEnabled&$filter=UserName eq '${userName}'`);
  }

  update(user: Usager) {
    return this.httpClient.put(`${this.BASE_URL}UpdateUserInfo?userID=${user.Id}`, user);
  }

  updatePassword(newPassword: string, user: Usager) {
    return this.httpClient.put(`${this.BASE_URL}ForceChangePassword?userID=${user.Id}&NewPassword=${newPassword}`, user.UserName);
  }

  getUserRights(username: string) {
    return this.httpClient.get(`${this.BASE_URL}UserRole?userName=${username}`);
  }

  removeRole(username: string, role: string) {
    return this.httpClient.delete(`${this.BASE_URL}deleteUserRole?userName=${username}&role=${role}`);
  }

  addRole(username: string, role: string) {
    return this.httpClient.put(`${this.BASE_URL}addUserRole?userName=${username}&role=${role}`, '');
  }

  addUsager(user: Usager, password: string) {
    let userObjectToPass: any = Object.assign(user, { Password: password });
    return this.httpClient.post(`${this.BASE_URL}Register`, userObjectToPass);
  }

  changeActiveStatus(user: Usager) {
    return this.httpClient.patch(`${this.BASE_URL}ChangeUserActiveStatus?userID=${user.Id}`, user.IsEnabled);
  }
}
