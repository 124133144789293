import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopConfirmComponent } from 'src/app/component/core/pop-confirm/pop-confirm.component';
import { Shipstation_rate } from 'src/app/models/shipstation/shipstation-rate';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';
import { DVLService } from 'src/app/service/DVL.service';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-selection-expedition',
  templateUrl: './selection-expedition.component.html',
  styleUrls: ['./selection-expedition.component.scss'],
})
export class SelectionExpeditionComponent implements OnInit {
  orderId: number;
  ratesData: ShipStation_ShippingRatesData;
  loading: boolean = false;
  isRefreshRatesAllowed: boolean = false;
  isPurchaseLabelAllowed: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SelectionExpeditionComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData,
    public dvlService: DVLService,
    private loaderService: LoaderService,
    public matDialog: MatDialog
  ) {
    this.GetData();
  }

  ngOnInit(): void {}

  GetData() {
    // this.loaderService.show();
    this.loading = true;
    this.ratesData = null;
    this.orderId = this.inputData.orderId;
    this.isRefreshRatesAllowed = this.inputData.CanOrderShipCanBeModified;

    if (this.inputData.ratesData != undefined) {
      this.ratesData = { ...this.inputData.ratesData };
      this.loading = false;
      return;
    }

    if (this.isRefreshRatesAllowed === false) {
      alert("La commande sélectionnée n'a aucun calcul de coût précédent, et ne peut pas recevoir de nouveau calcul non plus");

      return;
    }

    this.dvlService.ShippingLabelFullProcessOneOrder(this.orderId).subscribe((result) => {
      this.ratesData = result;

      this.loading = false;
      // this.loaderService.hide();
    });
  }

  // Évènement qui permet de sélectionner un tarif en cliquant sur une ligne du tableau
  onSelectRow(rate: Shipstation_rate) {
    // Si cette ligne est déjà sélectionnée, on la dé-sélectionne
    if (this.ratesData.manuallySelectedRate == rate) {
      this.ratesData.manuallySelectedRate = undefined;
      return;
    }
    this.ratesData.dateManualSelection = new Date();
    this.ratesData.manuallySelectedRate = rate;
  }

  // Lance une nouvelle récupération de données depuis ShipStation pour écraser les données de la BD
  onReloadClick() {
    this.inputData.ratesData = null;
    this.ratesData = null;
    this.GetData();
  }

  /**
   * Permet de fermer le popup.
   */
  onAbortClick() {
    this.dialogRef.close(null);
  }
  onConfirmClick() {
    this.dialogRef.close(this.ratesData);
  }
  async onPurchaseClick() {
    if (this.isRefreshRatesAllowed === false || this.isPurchaseLabelAllowed === false) {
      this.matDialog
        .open(PopConfirmComponent, {
          data: {
            titre: 'Erreur',
            texte: "Une erreur est survenue lors de la création de l'étiquette d'expédition",
            mainIcon: 'error',
            showDismissiveButton: false,
            affirmativeActionName: 'OK',
            affirmativeActionColor: 'warn',
          },
        })
        .afterClosed()
        .toPromise();
    }

    // Pop-up de confirmation avant d'acheter
    let popupResult = this.matDialog.open(PopConfirmComponent, {
      data: {
        titre: "Acheter l'étiquette ?",
        texte: `Êtes vous certain de vouloir acheter l'étiquette d'expédition pour la commande ${this.orderId} ?`,
        affirmativeActionName: 'Oui, acheter',
        affirmativeActionColor: 'warn',
        dismissiveActionName: 'Non, annuler',
        dismissiveActionColor: 'primary',
      },
    });

    var monRetour = await popupResult.afterClosed().toPromise();
    if (monRetour !== true) {
      return;
    }

    let myRate: Shipstation_rate = null;
    if (this.ratesData.manuallySelectedRate != null) myRate = this.ratesData.manuallySelectedRate;
    else myRate = this.ratesData.autoSelectedRate;

    this.dvlService.CreateLabelForOneOrderManual(this.orderId, myRate).subscribe(async (succes) => {
      if (succes) {
        popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Étiquette créée',
            texte: "L'étiquette d'expédition a été créée avec succès",
            mainIcon: 'info',
            showDismissiveButton: false,
            affirmativeActionName: 'OK',
          },
        });
      } else {
        popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Erreur',
            texte: "Une erreur est survenue lors de la création de l'étiquette d'expédition",
            mainIcon: 'error',
            showDismissiveButton: false,
            affirmativeActionName: 'OK',
            affirmativeActionColor: 'warn',
          },
        });
      }

      await popupResult.afterClosed().toPromise();
      this.dialogRef.close(this.ratesData);
    });
  }
}
