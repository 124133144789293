import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { NumericKeyboard } from 'src/app/models/numericKeyboard';
import { Router } from '@angular/router';
import { UserRights } from 'src/app/models/user/userRights';
import { LocalStorage } from 'src/app/models/enum/LocalStorage';

@Component({
  selector: 'app-logByPin.component',
  templateUrl: './logByPin.component.html',
  styleUrls: ['./logByPin.component.scss'],
})
export class logByPinFormComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}
  // clavier numérique pour rentrer le code de l'utilisateur
  numericKeyboard: NumericKeyboard;
  scanPermission: UserRights;

  ngOnInit(): void {
    this.numericKeyboard = new NumericKeyboard(true, 'Entrez votre NIP');
    this.scanPermission = this.authService.rights;
    this.authService.CurrentLoginPage = '/logByPin';
    localStorage.setItem(LocalStorage.LOGIN_PREFERED_METHOD, this.authService.CurrentLoginPage);
  }

  /**
   * Ajoute une valeur au code pin
   * @param qte valeur à ajouter
   */
  addPin(qte: string): void {
    this.numericKeyboard.addValue(qte);
  }

  /**
   * Retire du contenu au code pin
   * @param removeAll vide tout si true, le dernier caractère sinon
   */
  resetPin(removeAll: boolean): void {
    this.numericKeyboard.remove(removeAll);
  }

  /**
   * Appelle l'API pour débuter l'authentification
   */
  CloseNumericKeyboard(): void {
    const data = this.numericKeyboard.reset();
    this.authService.LogByPin(data); // appel API
  }

  /**
   * Redirige vers un scan.
   * @param name nom du scan vers lequel rediriger
   */
  OnScanButton(name: string): void {
    this.router.navigate([name]);
  }

  LogByStandardLogin(): void {
    this.authService.CurrentLoginPage = '/regularLogin';
    localStorage.setItem(LocalStorage.LOGIN_PREFERED_METHOD, this.authService.CurrentLoginPage);
    this.router.navigate([this.authService.CurrentLoginPage]);
  }
}
