<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre"><mat-icon>account_circle</mat-icon> {{ titre }} : {{ currentItem?.UserName }}</h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>Nom d'utilisateur</mat-label>
        <input matInput [(ngModel)]="currentItem.UserName" name="UserName" class="form-control" required autocomplete="off" />
      </mat-form-field>

      <section class="checkboxGroup" *ngIf="!addNewUserMode">
        <mat-checkbox name="userActiveStatus" [(ngModel)]="currentItem.IsEnabled">Statut: {{ activationStatus }}</mat-checkbox>
      </section>

      <!-- S'applique uniquement que si on ajoute un usager -->
      <mat-form-field appearance="outline" *ngIf="addNewUserMode" style="margin-bottom: 5px">
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password" [(ngModel)]="newPassword" [pattern]="passwordRegexPattern" name="Password" required autocomplete="new-password" />
        <mat-error *ngIf="passwordLock">Min de 6 caractères, y compris: une majuscule, une miniscule, un nombre, et un caractère non-alphanumérique.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Courriel</mat-label>
        <input matInput [(ngModel)]="currentItem.Email" name="Email" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Numéro de téléphone</mat-label>
        <input matInput [(ngModel)]="currentItem.PhoneNumber" name="PhoneNumber" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>PIN CODE</mat-label>
        <input matInput [(ngModel)]="currentItem.PinCode" name="PinCode" />
      </mat-form-field>

      <section class="checkboxGroup">
        <h3>Droits de l'usager</h3>
        <mat-checkbox name="globalAdmin" [(ngModel)]="userRights.globalAdmin">Administrateur</mat-checkbox>
        <mat-checkbox *ngIf="getConnectedUsersRights().financeAdmin" name="fiananceAdmin" [(ngModel)]="userRights.financeAdmin"
          >Administrateur Financier</mat-checkbox
        >
        <mat-checkbox name="scan1" [(ngModel)]="userRights.scan1">Scan 1</mat-checkbox>
        <mat-checkbox name="scan2Emballeur" [(ngModel)]="userRights.scan2Emballeur">Scan 2 Emballeur</mat-checkbox>
        <mat-checkbox name="scan2EmballeurChangerDVL" [(ngModel)]="userRights.scan2EmballeurChangerDVL" class="securityNodeChildLevel2"
          >Permettre de changer la DVL</mat-checkbox
        >
        <mat-checkbox name="scan2EmballeurChangerBoite" [(ngModel)]="userRights.scan2EmballeurChangerBoite" class="securityNodeChildLevel2"
          >Permettre de changer la boîte</mat-checkbox
        >
        <mat-checkbox name="onlineOrders" [(ngModel)]="userRights.onlineOrders">Commande en Ligne</mat-checkbox>
        <mat-checkbox name="reception" [(ngModel)]="userRights.reception">Réception</mat-checkbox>
        <mat-checkbox name="achatLabels" [(ngModel)]="userRights.achatLabels">Achat labels</mat-checkbox>
      </section>

      <mat-form-field appearance="outline" *ngIf="!addNewUserMode" style="margin-bottom: 5px">
        <mat-label>Nouveau mot de passe</mat-label>
        <input matInput type="password" [ngModelOptions]="{ standalone: true }" [(ngModel)]="newPassword" [pattern]="passwordRegexPattern" />
        <mat-error *ngIf="passwordLock">Min de 6 caractères, y compris: une majuscule, une miniscule, un nombre, et un caractère non-alphanumérique.</mat-error>
      </mat-form-field>
      <button
        [disabled]="passwordLock"
        *ngIf="newPasswordEntered && !addNewUserMode"
        matInput
        mat-flat-button
        color="warn"
        (click)="forceNewPassword(newPassword, currentItem)"
      >
        Forcer un nouveau Mot de Passe
      </button>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-raised-button [disabled]="true" style="margin: 0px 2px 2px 2px"><mat-icon>delete</mat-icon>Supprimer</button>

    <div style="float: right">
      <button
        type="submit"
        matInput
        mat-flat-button
        [disabled]="passwordLock"
        (click)="OnSauvegardeClick()"
        *ngIf="!myform.pristine && !addNewUserMode"
        color="primary"
        style="margin: 0px 2px 2px 2px"
      >
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button
        type="submit"
        matInput
        mat-flat-button
        [disabled]="passwordLock"
        (click)="OnSauvegardeClick()"
        *ngIf="!myform.pristine && addNewUserMode"
        color="primary"
      >
        Ajouter
      </button>
      <button type="submit" matInput mat-stroked-button (click)="fermer()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-stroked-button (click)="fermer()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
