<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre">
      <mat-icon>business_center</mat-icon>
      {{ titre }}
    </h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>ID Entête</mat-label>
        <input matInput [(ngModel)]="currentItem.PickListHeaderId" name="PickListHeaderID" class="form-control" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>#Split</mat-label>
        <input matInput [(ngModel)]="currentItem.SplittingIdenfier" name="SplittingIdenfier" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description du produit</mat-label>
        <input matInput [(ngModel)]="currentItem.ProductDescription" name="ProductDescription" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>SKU</mat-label>
        <input matInput [(ngModel)]="currentItem.ProductNumber" name="ProductNumber" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité à rammasser</mat-label>
        <input matInput [(ngModel)]="currentItem.QuantityToPick" name="QuantityToPick" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité Instore Acomba</mat-label>
        <input matInput [(ngModel)]="currentItem.QtyInstore_Acomba" name="QtyInstore_Acomba" disabled="true" />
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-stroked-button [disabled]="true" style="margin: 0px 2px 2px 2px"><mat-icon>delete</mat-icon>Supprimer</button>
    <div style="float: right">
      <button type="submit" matInput mat-stroked-button (click)="annuler()" style="margin: 0px 2px 2px 2px">Fermer</button>
    </div>
  </div>
</form>
