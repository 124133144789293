<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre">
      <mat-icon>business_center</mat-icon>
      {{ titre }}
    </h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>Sku</mat-label>
        <input matInput [(ngModel)]="currentItem.Sku" name="Sku" class="form-control" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité</mat-label>
        <input matInput [(ngModel)]="currentItem.Quantity" name="Quantity" type="number" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Longeur empilée</mat-label>
        <input matInput [(ngModel)]="currentItem.LengthStacked" type="number" name="LengthStacked" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Largeur empilée</mat-label>
        <input matInput [(ngModel)]="currentItem.WidthStacked" type="number" name="WidthStacked" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hauteur empilée</mat-label>
        <input matInput [(ngModel)]="currentItem.HeightStacked" type="number" name="HeightStacked" required />
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-stroked-button (click)="detruire()" [disabled]="true" style="margin: 0px 2px 2px 2px">
      <mat-icon>delete</mat-icon>Supprimer
    </button>

    <div style="float: right">
      <button type="submit" matInput mat-flat-button (click)="OnSauvegardeClick()" *ngIf="!myform.pristine" color="primary" style="margin: 0px 2px 2px 2px">
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button type="submit" matInput mat-stroked-button (click)="annuler()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-stroked-button (click)="annuler()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
