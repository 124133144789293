import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemManagerService } from 'src/app/module/systemManager/SystemManager.service';
@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css'],
})
export class AccueilComponent implements OnInit {
  constructor(private systemManagerService: SystemManagerService) {}
  apiURL = environment.apiUrl;
  appVersion = environment.appVersion;
  production = environment.production;
  environmentName = environment.environmentName;

  apiVersion = 'fetching from server...';
  apiEnvironment = 'fetching from server...';

  ngOnInit(): void {
    this.systemManagerService.getApiInfo().subscribe(
      (data) => {
        this.apiVersion = data.WebApiVersion;
        this.apiEnvironment = data.Environment;
      },
      (error) => {
        alert(error);
      }
    );
  }
}
