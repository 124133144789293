import { Injectable } from '@angular/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class TranslationManagementService extends MessageService {
  //https://www.telerik.com/kendo-angular-ui/components/globalization/localization/messages/
  //https://www.telerik.com/kendo-angular-ui/components/grid/api/CustomMessagesComponent/#toc-filter
  private translationData: any = {
    'fr-CA': {
      rtl: false,
      messages: {
        'kendo.grid.columnsApply': 'Appliquer',
        'kendo.grid.columnsReset': 'réinitialiser',
        'kendo.grid.detailCollapse': 'réduire',
        'kendo.grid.detailExpand': 'aggrandir',
        'kendo.grid.filter': 'filtrer',
        'kendo.grid.filterAfterOperator': 'Après',
        'kendo.grid.filterAfterOrEqualOperator': 'Après ou égale',
        'kendo.grid.filterAndLogic': 'Et',
        'kendo.grid.filterOrLogic': 'Ou',
        'kendo.grid.filterBeforeOperator': 'Avant',
        'kendo.grid.filterBeforeOrEqualOperator': 'Avant ou égale',
        'kendo.grid.filterBooleanAll': 'Tous',
        'kendo.grid.filterClearButton': 'Vider',
        'kendo.grid.filterContainsOperator': 'Contient',
        'kendo.grid.filterDateToday': "Aujourd'hui",
        'kendo.grid.filterDateToggle': 'basculer',
        'kendo.grid.filterEndsWithOperator': 'Se terminer par',
        'kendo.grid.filterEqOperator': 'Égale',
        'kendo.grid.filterFilterButton': 'Filtrer',
        'kendo.grid.filterGteOperator': 'Plus grand ou égale',
        'kendo.grid.filterGtOperator': 'Plus grand',
        'kendo.grid.filterIsEmptyOperator': 'Est vide',
        'kendo.grid.filterIsFalse': 'Faux',
        'kendo.grid.filterIsNotEmptyOperator': "N'est pas vide",
        'kendo.grid.filterIsNotNullOperator': 'Non nulle',
        'kendo.grid.filterIsNullOperator': 'Nulle',
        'kendo.grid.filterIsTrue': 'Vrai',
        'kendo.grid.filterLteOperator': 'Plus petit ou égale',
        'kendo.grid.filterLtOperator': 'Plus petit',
        'kendo.grid.filterNotContainsOperator': 'Ne contient pas',
        'kendo.grid.filterNotEqOperator': "N'est pas égale",
        'kendo.grid.filterNumericDecrement': 'Baisser',
        'kendo.grid.filterNumericIncrement': 'Monter',
        'kendo.grid.filterStartsWithOperator': 'Commence par',
        'kendo.grid.groupCollapse': 'groupe réduire',
        'kendo.grid.groupExpand': 'groupe agrandir',
        'kendo.grid.groupPanelEmpty': 'Vide',
        'kendo.grid.noRecords': 'Aucun enregistrement disponible',
        'kendo.grid.pagerFirstPage': 'Première page',
        'kendo.grid.pagerItems': 'items',
        'kendo.grid.pagerItemsPerPage': '',
        'kendo.grid.pagerLastPage': 'Dernière page',
        'kendo.grid.pagerNextPage': 'Prochaine page',
        'kendo.grid.pagerOf': 'de',
        'kendo.grid.pagerPage': 'page',
        'kendo.grid.pagerPageNumberInputTitle': '',
        'kendo.grid.pagerPreviousPage': 'Page précédente',
        'kendo.grid.selectAllCheckboxLabel': 'Tous',
        'kendo.grid.selectionCheckboxLabel': 'Sélection',
        'kendo.grid.setColumnPosition': '',
        'kendo.grid.sortable': 'triable',
        'kendo.grid.sortAscending': 'Croissant',
        'kendo.grid.sortDescending': 'Décroissant',
        'kendo.grid.sortedAscending': 'Trié par ordre croissant',
        'kendo.grid.sortedDescending': 'Trié par ordre décroissant',
        'kendo.grid.sortedDefault': 'Non trié',
        'kendo.grid.stick': 'collé',
        'kendo.grid.unlock': 'débloqué',
        'kendo.grid.unstick': 'décollé',
      },
    },
    'en-US': {
      rtl: false,
      messages: {},
    },
  };

  constructor(private intlService: IntlService) {
    super();
    // On force kendo à être en FR-CA
    (<CldrIntlService>this.intlService).localeId = 'fr-CA';
  }

  private get messages(): any {
    let localId = (<CldrIntlService>this.intlService).localeId;

    const lang = this.translationData[localId];

    return lang.messages;
  }

  public override get(key: string): string {
    let msg: string = this.messages[key];
    return msg;
  }
}
