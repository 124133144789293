import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorMsg } from '../models/HttpErrorMsg';
import { LoaderService } from '../service/loader.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  dialogRef: any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request);
    this.loaderService.show(); // on affiche le loader

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide(); // on cache le loader
        }
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.loaderService.hide(); // on cache le loader

        //on va fabriquer notre classe d'erreur flexible
        let MyError = {
          fullHttpErrorReponse: errorResponse,
          statusCode: errorResponse.status,
        } as HttpErrorMsg;

        let msg: string = '(' + errorResponse.status + ') ';

        // switch case pour écrire le message par défaut ou adapter le comportement.
        switch (errorResponse.status) {
          case HttpStatusCode.Ok:
          case HttpStatusCode.NoContent:
          case HttpStatusCode.NotModified: {
            //this.authService.logout();
            msg += 'Exécution réussie.  Retour du serveur: <br/>';
            MyError.titreErreur = 'Exécution réussie';
            break;
          }
          case HttpStatusCode.Unauthorized: {
            //this.authService.logout();
            msg += "Erreur d'authentification. Veuillez vous reconnecter.";
            MyError.titreErreur = 'Erreur serveur - Accès non-autorisé.';
            break;
          }
          case HttpStatusCode.Conflict: {
            //this.authService.logout();
            msg += 'Erreur de conflit de donnée lors de votre action : <br/>';
            MyError.titreErreur = 'Erreur serveur - Conflit.';
            break;
          }
          case HttpStatusCode.InternalServerError: {
            msg += "Erreur interne du serveur.  Prenez-note de la date et l'heure et contactez votre administrateur.";
            MyError.titreErreur = 'Erreur serveur - erreur interne.';
            break;
          }
          case HttpStatusCode.Forbidden: {
            msg += "Vous n'avez pas les droits pour éxécuter cette fonction.<br/><br/>Demandez à l'administrateur de vous fournir les droits d'accès.";
            MyError.titreErreur = 'Erreur serveur - Forbidden';
            break;
          }
          case HttpStatusCode.ServiceUnavailable: {
            msg += 'Le serveur est présentement en maintenance. Veuillez vous reconnecter plus tard.';
            MyError.titreErreur = 'Erreur serveur - Service non-disponible';
            break;
          }
          case HttpStatusCode.NotFound: {
            msg += '';
            MyError.titreErreur = 'Erreur serveur - Element non trouvé';
            break;
          }
          case 0: {
            msg +=
              "Le serveur ne répond pas ou l'adresse que vous tentez de rejoindre est invalide.<br/><br/>Veuillez contacter votre administrateur ou réessayer de vous connecter plus tard.";
            MyError.titreErreur = 'Erreur serveur - non-accessible';
            break;
          }
          case HttpStatusCode.BadRequest: {
            msg += 'Le serveur a retourné une erreur : <br/><br/>';
            MyError.titreErreur = 'Erreur serveur - Bad-Request';
            break;
          }

          default: {
            MyError.titreErreur = 'Erreur serveur - Autre erreur (' + errorResponse.status + ')';
            if (errorResponse.message === '') {
              msg += "Une erreur inconnnue est survenue. Code d'erreur HTTP : " + errorResponse.status + '.';
            } else {
              msg += errorResponse.message;
            }
            break;
          }
        }

        // Parfois, le serveur détaille le problème dans un tableau dans errors.traceId.
        // Si c'est le cas, on récupère ce tableau pour l'afficher, sinon, on fait un tableau vide pour ne pas
        // casser le ng-for
        if (errorResponse.error == null || errorResponse.error.errors === undefined) {
          MyError.traceId = [];
        } else {
          MyError.traceId = errorResponse.error.errors.traceId;
        }

        console.log(msg);
        console.log(errorResponse);

        //si on a un enfant 'error'
        if (errorResponse.error) {
          //si erreur est un simple string..
          let TypeOfMyError = typeof errorResponse.error;
          if (TypeOfMyError === 'string') {
            msg += errorResponse.error;
          } //sinon ,error est donc un objet...
          else {
            //si nous avons l'objet 'detail'...
            if (errorResponse.error.detail) msg += '<br/>- ' + errorResponse.error.detail;
            //si nous avons l'objet 'title'...
            if (errorResponse.error.title) msg += '<br/>- ' + errorResponse.error.title;
            //si nous avons l'objet 'Message'...
            if (errorResponse.error.Message) msg += '<br/>- ' + errorResponse.error.Message;
            //si nous avons l'objet 'Message'...
            if (errorResponse.error.value) msg += '<br/>- ' + errorResponse.error.value;

            //si nous avons une LISTE d'Erreur sous-jacente...
            if (errorResponse.error.errors && errorResponse.error.errors.length > 0) {
              msg += '<ul>';
              errorResponse.error.errors.forEach((element) => {
                if (element.error) msg += '<li>' + element.error + '</li>';
                else msg += '<li>' + element + '</li>';
              });
              msg += '</ul>';
            }
          }
        }
        if (errorResponse.status === 200 && errorResponse.error.text) {
          msg += '<br/>- ' + errorResponse.error.text;
        }
        MyError.messageErreurDetail = msg;

        return throwError(() => MyError);
      })
    );
  }
}
