import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { OData } from 'src/app/models/oData/OData_file';
import { ReceptionHeader } from 'src/app/models/receptionHeader';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { ReceptionService } from 'src/app/service/reception.service';
import { ListComponent } from 'src/app/component/core/list/list.component';

@Component({
  selector: 'app-reception-header',
  templateUrl: './reception-header.component.html',
  styleUrls: ['./reception-header.component.css'],
})
export class ReceptionHeaderComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    public dialog: MatDialog,
    private receptionService: ReceptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(httpClient);

    this.endpoint = 'ItemReceptionHeaders';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'string',
      },
      {
        key: 'SupplierNumber',
        nom: 'Fournisseur',
        type: 'string',
      } /*
      {
        key: 'ReceptionSource',
        nom: 'Source',
        type: 'string',
      },*/,
      {
        key: 'ReceptionIdentifier',
        nom: 'id unique',
        type: 'string',
      },
      {
        key: 'IsCompleted',
        nom: 'Complété',
        type: 'boolean',
      },
      {
        key: 'ReceptionCreationDate',
        nom: 'Date création (HH)',
        type: 'date',
        hide: true,
      },
      {
        key: 'TotalLines',
        nom: 'Nb Lignes Tot.',
        type: 'number',
      },
      {
        key: 'TotallinesWithQtyRemaining',
        nom: 'Nb Lignes rest.',
        type: 'number',
      },
      {
        key: 'NbScanTraces',
        nom: 'Nb traces',
        type: 'number',
      },
      {
        key: 'NbERPErrors',
        nom: 'Nb Acomba Erreur',
        type: 'number',
      },
      {
        key: 'NbERPSucceeded',
        nom: 'Nb Acomba Succès',
        type: 'number',
      },
      {
        key: 'DateCompleted',
        nom: 'Date complétée',
        type: 'datetime',
      },
    ];
    this.champsHardcoded = ['ToggleCompletedOrNot', 'ForageDetailDetail', 'ForageDetailTrace', 'RapportReclamation', 'RapportReceptionAcomba'];

    this.recherche = {
      IsCompleted: undefined,
    };
  }

  profiles = [
    {
      ProfileID: 12010161,
      name: 'Le monde du foyer',
    },
    {
      ProfileID: 12011108,
      name: 'MEXICO',
    },
    {
      ProfileID: 12009943,
      name: 'USA',
    },
  ];
  sites = [
    {
      SiteID: 587,
      SiteName: 'Checkout Direct',
    },
    {
      SiteID: 1261,
      SiteName: 'Walmart Canada',
    },
    {
      SiteID: 644,
      SiteName: 'Amazon Seller Central - CA',
    },
    {
      SiteID: 862,
      SiteName: 'Shopify',
    },
    {
      SiteID: 1275,
      SiteName: 'Best Buy CA',
    },
    {
      SiteID: 576,
      SiteName: 'eBay Fixed Price CA',
    },
    {
      SiteID: 640,
      SiteName: 'Amazon Seller Central - US',
    },
  ];
  TitreGlobalPage: string = 'Réceptions / Header';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'ItemReceptionHeader';

  champsDisplay: any[];

  ngOnInit() {
    super.ngOnInit();

    this.recherche.IsCompleted = this.route.snapshot.paramMap.get('IsCompleted');
    this.recherche.ReceptionCreationDate_DATE_FILTER_GE = this.route.snapshot.paramMap.get('ReceptionCreationDate_DATE_FILTER_GE');
    this.recherche.ReceptionCreationDate_DATE_FILTER_LE = this.route.snapshot.paramMap.get('ReceptionCreationDate_DATE_FILTER_LE');
    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.IsCompleted) params['IsCompleted'] = this.recherche.IsCompleted;
    if (this.recherche.ReceptionCreationDate_DATE_FILTER_GE)
      params['ReceptionCreationDate_DATE_FILTER_GE'] = this.formatDate(this.recherche.ReceptionCreationDate_DATE_FILTER_GE);
    if (this.recherche.ReceptionCreationDate_DATE_FILTER_LE)
      params['ReceptionCreationDate_DATE_FILTER_LE'] = this.formatDate(this.recherche.ReceptionCreationDate_DATE_FILTER_LE);

    if (this.recherche.IsCompleted == '') this.recherche.IsCompleted = undefined;

    this.router.navigate(['/reception-header', params]);
    this.launchRefresh();
  }

  effacerRecherche(): void {
    this.recherche = new ReceptionHeader();
    this.orderBy = 'id desc';
  }

  toggleIsCompleted(e, row): void {
    // stop click propagation
    e.stopPropagation();
    // textes dynamiques
    let titre: string;
    let texte: string;
    let affirmativeActionName: string;
    let dismissiveActionName: string;
    if (row.IsCompleted) {
      titre = 'Ré-ouvrir cette réception?';
      texte = 'Êtes-vous sûr de vouloir ré-ouvrir cette réception?';
      affirmativeActionName = 'Ré-ouvrir la commande';
      dismissiveActionName = 'Ne rien faire';
    } else {
      titre = 'Compléter cette réception?';
      texte = 'Êtes-vous sûr de vouloir compléter cette réception?';
      affirmativeActionName = 'Compléter la réception';
      dismissiveActionName = 'Ne rien faire';
    }
    // pop dialog
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: titre,
        texte: texte,
        affirmativeActionName,
        dismissiveActionName,
      },
    });
    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        let NewValue = !row.IsCompleted; //valeur inversé...

        this.receptionService.ItemReceptionHeaderMarkAsCompleted(row.id, NewValue).subscribe(() => {
          // update row
          row.IsCompleted = NewValue;
        });
      }
    });
  }
}
