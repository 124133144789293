import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PackageTypes } from '../models/packageTypes';
import { environment } from '../../environments/environment';
import { Observable, tap } from 'rxjs';
import { UtilService } from './util.service';
import { State } from '@progress/kendo-data-query';
import { ReplaySubject } from 'rxjs';
import { toODataString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class BackendErpService {
  public RapportFacturationAcomba: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private httpclient: HttpClient, private utilService: UtilService) {}

  private BASE_URL = `${environment.apiUrl}BackendERP`;

  /** Permet de tenter de créer/recréer une FACTURE HOME HARDWARE [H01] vers l'ERP (Acomba), sous forme d'un ACHAT, NON RÉCEPTIONNÉ.
   * La création se fait par Numéro de facture Fournisseur
   * @param HHInvoiceNumber Le numéro de la facture HomeHardware
   * @returns
   */
  ForceHHInvoiceToERPPurchaseByInvoiceNumber(HHInvoiceNumber: number): Observable<any> {
    var myBody = {};
    let MyUrl = `${this.BASE_URL}/ForceHHInvoiceToERPPurchaseByInvoiceNumber?`;
    MyUrl += 'HHInvoiceNumber=' + HHInvoiceNumber;
    return this.httpclient.post(MyUrl, myBody);
  }

  /** Permet de tenter de créer/recréer une FACTURE HOME HARDWARE [H01] vers l'ERP (Acomba), sous forme d'un ACHAT, NON RÉCEPTIONNÉ.
   * La création se fait par Numéro de ItemREceptionHeader
   * @param ItemReceptionHeaderId
   * @returns Un observable
   */
  ForceHHInvoiceToERPPurchaseByItemReceptionHeaderID(ItemReceptionHeaderId: number): Observable<any> {
    var myBody = {};
    let MyUrl = `${this.BASE_URL}/ForceHHInvoiceToERPPurchaseByItemReceptionHeaderID?`;
    MyUrl += 'ItemReceptionHeaderId=' + ItemReceptionHeaderId;
    return this.httpclient.post(MyUrl, myBody);
  }

  /** Permet de relancer la RÉCEPTIONS de toute les lignes d'un ItemREceptionHeaderId, pour tenter de corriger  toute divergeance
   * @param ItemReceptionHeaderId
   * @returns
   */
  ReprocessReceptionLinesForItemReceptionHeader(ItemReceptionHeaderId: number): Observable<any> {
    var myBody = {};
    let MyUrl = `${this.BASE_URL}/ReprocessReceptionLinesForItemReceptionHeader?`;
    MyUrl += 'ItemReceptionHeaderId=' + ItemReceptionHeaderId;
    return this.httpclient.post(MyUrl, myBody);
  }

  /** Permet de relancer la RÉCEPTIONS de toute les lignes d'un ItemREceptionHeaderId, pour tenter de corriger  toute divergeance
   * @param ItemReceptionHeaderId
   * @returns
   */
  ProxyToOnlineERP(oerpQuery: string): Observable<any> {
    var myBody = JSON.parse(oerpQuery);
    let MyUrl = `${this.BASE_URL}/ProxyToOnlineERP`;
    return this.httpclient.post(MyUrl, myBody);
  }
}
