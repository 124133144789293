import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ListComponent } from 'src/app/component/core/list/list.component';
import { ReceptionService } from 'src/app/service/reception.service';
import { UserInfo } from 'src/app/models/user/UserInfo';
import { AuthService } from 'src/app/service/auth.service';
import { DVLService } from 'src/app/service/DVL.service';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorMsg } from 'src/app/models/HttpErrorMsg';
import { LoaderService } from 'src/app/service/loader.service';
import { Usager } from 'src/app/models/usager';
import { UsagerService } from 'src/app/service/usager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rapport-expedition-dvl',
  templateUrl: './rapport-expedition-dvl.component.html',
  styleUrls: ['./rapport-expedition-dvl.component.scss'],
})
export class RapportExpeditionDvlComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthService,
    private receptionService: ReceptionService,
    public dvlService: DVLService,
    private snack: MatSnackBar,

    private dialogService: PopUpDialogService,
    private loaderService: LoaderService,
    private usagerService: UsagerService
  ) {
    super(httpClient);

    this.endpoint = 'Expedition';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'SCAN_DateTime',
        nom: 'Date scan',
        type: 'datetime',
      },

      {
        key: 'DVL_SiteOrderID',
        nom: 'No DVL',
        type: 'string',
      },
      {
        key: 'SCAN_ctrl_UserName',
        nom: 'Usager',
        type: 'string',
      },
      {
        key: 'SCAN_ProductNumber',
        nom: '#Produit',
        type: 'string',
      },
      {
        key: 'SCAN_QtyScanned',
        nom: 'Qté',
        type: 'number',
      },
      {
        key: 'SCAN_LabelPrinted_Count',
        nom: 'IMPRESSION - Nb.',
        type: 'number',
      },
      {
        key: 'SCAN_LabelPrinted_LastDatetime',
        nom: 'IMPRESSION - LastTime',
        type: 'datetime',
      },

      {
        key: 'SCAN_Completion_Datetime',
        nom: 'Complété - date',
        type: 'datetime',
      },

      {
        key: 'SCAN_Cancelation_Datetime',
        nom: 'Annulé - Date',
        type: 'datetime',
      },
      {
        key: 'SCAN_Cancelation_Reason',
        nom: 'Annulé - Raison',
        type: 'string',
      },

      {
        key: 'SCAN1_ctrl_UserName',
        nom: 'SCAN1 - User',
        type: 'number',
      },
      {
        key: 'SCAN1_ctrl_StationName',
        nom: 'SCAN1 - Station',
        type: 'number',
      },
      {
        key: 'SCAN1_PreAssign_Datetime',
        nom: 'SCAN1 - date',
        type: 'datetime',
      },
      {
        key: 'SCAN_ProcessingStatus',
        nom: 'Statut',
        type: 'number',
      },
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
    ];
    this.defaultOrderBy = 'id desc';
    this.champsHardcoded_Before = ['StatutTraceExpedition', 'LienShipstation', 'AnnulerTraceExpedition'];
    this.champsHardcoded = [];

    this.ExportExcelfileName = 'RapportTracesExpedition.xlsx'; //si on veut changer le nom du fichier Excel par défaut
  } //fin du constructeur

  TitreGlobalPage: string = "Trace d'expédition [Scan2-Emballeur et Scan1 pre-assign]";
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'trace';

  ListeUsager: Usager[] = [];

  TempTxtContent: string = '';

  //Liste des STATUT possible d'une trace d'expédition.  quickfix : étant donné que dans l'API c'est un ENUM, https://www.schaeflein.net/filter-using-enum-values-in-odata-v4/,
  ListeTypeTrace: any[] = [
    { id: "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'PENDING'", description: 'SCAN1_PENDING' },
    { id: "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'CANCELLED'", description: 'CANCELLED' },
    { id: "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'WORKING'", description: 'WORKING' },
    { id: "MDPWEB.SharedLibrary.Models.ItemReceptions.DVL_ProcessingStatus'COMPLETED'", description: 'COMPLETED' },
  ];

  CurrentUserInfo: UserInfo; // statistiques sur l'utilisateur

  ngOnInit(): void {
    super.ngOnInit();

    this.recherche.DVL_SiteOrderID = this.route.snapshot.paramMap.get('DVL_SiteOrderID');
    this.recherche.SCAN_ProductNumber = this.route.snapshot.paramMap.get('SCAN_ProductNumber');
    this.recherche.SCAN_ctrl_UserName = this.route.snapshot.paramMap.get('SCAN_ctrl_UserName');
    this.recherche.SCAN_DateTime_DATE_FILTER_GE = this.route.snapshot.paramMap.get('SCAN_DateTime_DATE_FILTER_GE');
    this.recherche.SCAN_DateTime_DATE_FILTER_LE = this.route.snapshot.paramMap.get('SCAN_DateTime_DATE_FILTER_LE');

    this.CurrentUserInfo = this.authservice.info;

    //si on a passé le paramètre ;FixedDate=TODAY  (/expedition-trace;FixedDate=TODAY) alors on va calculer ça...permet de mettre ce lien dans le menu à gauche
    if (this.route.snapshot.paramMap.get('FixedDate') === 'TODAY') {
      this.recherche.SCAN_DateTime_DATE_FILTER_GE = new Date();
      this.recherche.SCAN_DateTime_DATE_FILTER_LE = new Date();
    }

    //on va feeder la liste des usagers pour notre filtre dropdown
    this.usagerService.getBasicList().subscribe((dataFromService) => {
      this.ListeUsager = dataFromService.map((obj) => ({ ...obj }));
    });

    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.SCAN_ProductNumber) params['SCAN_ProductNumber'] = this.recherche.SCAN_ProductNumber;
    if (this.recherche.DVL_SiteOrderID) params['DVL_SiteOrderID'] = this.recherche.DVL_SiteOrderID;
    if (this.recherche.SCAN_ctrl_UserName) params['SCAN_ctrl_UserName'] = this.recherche.SCAN_ctrl_UserName;
    if (this.recherche.SCAN_DateTime_DATE_FILTER_GE) params['SCAN_DateTime_DATE_FILTER_GE'] = this.formatDate(this.recherche.SCAN_DateTime_DATE_FILTER_GE);
    if (this.recherche.SCAN_DateTime_DATE_FILTER_LE) params['SCAN_DateTime_DATE_FILTER_LE'] = this.formatDate(this.recherche.SCAN_DateTime_DATE_FILTER_LE);

    this.router.navigate(['/expedition-trace', params]);
    this.launchRefresh();
  }

  //WIP - pas fonctionnel pour expedition..provient du systeme réclamation
  async AnnulerLigne(e, myWholeRow) {
    let MyIDs: number[] = [];
    MyIDs.push(myWholeRow.id);

    let SiteOrderID = myWholeRow.DVL_SiteOrderID;
    let MyTraceID = myWholeRow.id;

    let strTitre = '';
    let strDetail = '';

    //si le statut est à COMPLÉTÉ ou EN TRAITEMENT PIEUVRE, les implications sont plus grandes que juste Scan1_idle...
    let boolWasCompleted = false;
    if (myWholeRow.SCAN_ProcessingStatus === 'COMPLETED') {
      boolWasCompleted = true;
      strTitre = 'Flag shipstation non modifié';
      strDetail = 'Attention! <br/><br/>La DVL sélectionné (' + SiteOrderID + "') était marquée COMPLÉTÉE.<br/><br/>";
      //      strDetail+= "Vous ne pouvez donc pas l'annuler ICI."
      strDetail += 'Cela signifie que dans Shipstation, les flags 00-ReadyToGo était supprimé et le flag 02-COMPLETED était surement ajouté.<br/><br/>';
      strDetail += 'Vous devrez manuellement mettre les Flags Shipstation (dans shipstaiton) pour remetter la DVL en état';
      //var MyREsult = await this.dialogService.SimpleErrorAlert(strDetail,strTitre, "warning");
      var MyREsult = await this.dialogService.ConfirmDialog(strDetail, strTitre, 'live_help', 'OK, ooursuivre', 'Non, annuler mon action');
      if (MyREsult !== true) return;
    }

    let MustAssign = true;
    if (myWholeRow.ClaimProcessedInfo != null) MustAssign = false;

    var MyReturn = await this.dvlService.Scan2Emballeur_AnnulerTraitementDVL(SiteOrderID, 'CANCELLATION_FROM_RAPPORT_EXPEDITION', MyTraceID).toPromise();

    if (MyReturn !== null) {
      myWholeRow.SCAN_ProcessingStatus = 'CANCELLED';
    }
  }

  public async RefreshDVL() {
    let tmpTest = '';
    this.dvlService.RefreshOrders_ReadyToGo().subscribe((jsonData) => {
      this.snack.open('Les DVL Ready-to-go devrait être rafraichit.', '', { duration: 3000 });
    });
    this.loaderService.hide(); // on cache le loader

    this.snack.open('Le rafraichissement a été déclanché, et devrait prendre quelques minutes (2-3 minutes)', '', { duration: 3000 });
  }

  /**
   * Comme la touche Shift lance la recherche, on met une sécurité pour les champs de saisie de texte
   * pour éviter de rechercher alors qu'on veut juste taper des majuscules ou symboles
   * On lance la recherche seulement si le texte n'a pas changé entre le keydown et le keyup
   * Nécessaire car l'évènement keypress n'existe plus, on n'a que keydown et keyup
   * @param event
   */
  onTextInputShiftDown(event) {
    this.TempTxtContent = event.target.value;
  }
  onTextInputShiftUp(event) {
    if (event.target.value === this.TempTxtContent) this.rechercher();
  }
}
