// Concatène les infos disponibles sur le calcul de profit en une string affichable
import { Pipe, PipeTransform } from '@angular/core';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';

@Pipe({
  name: 'shippingCalcDetailsCalculProfit',
})
export class shippingCalcDetailsCalculProfitPipe implements PipeTransform {
  transform(ratesData: ShipStation_ShippingRatesData): string[] {
    let strings = [];
    try {
      if (ratesData == null || ratesData.infoCalculCoutantCommande == null) return null;

      let infoCalc = ratesData.infoCalculCoutantCommande;

      strings.push(`A - Sous-total commande: $${(infoCalc.orderSubTotalAmount ?? 0).toFixed(2)}`);
      strings.push('------------');

      if (infoCalc.InfoConversionDevise && infoCalc.InfoConversionDevise !== '') {
        strings.push(`Devise conversion: - ${infoCalc.InfoConversionDevise}`);
        strings.push('------------');
      }

      if (infoCalc.baseProductCostInCA != null) {
        strings.push(`Coûtant produit: $${(infoCalc.baseProductCostInCA ?? 0).toFixed(2)}`);
        strings.push(`Marge HH: ${((infoCalc.pctProfitMagHome ?? 0) * 100).toFixed(2)}%`);
        strings.push(`B - Coûtant avec marge HH: $${(infoCalc.productCostWithHomeStoreMargin ?? 0).toFixed(2)}`);
      } else strings.push('Coûtant produit: Introuvable');

      strings.push('------------');

      if (infoCalc.CodeBoite != '') strings.push(`C - Prix boîte: $${(infoCalc.prixBoite ?? 0).toFixed(2)}`);
      else strings.push('Pas dans une boîte');

      let myRate = null;
      if (ratesData.autoSelectedRate != null) {
        //si nous avons un taux auto-sélectionné..
        myRate = ratesData.autoSelectedRate;
      } else {
        // sinon on sélectionne le taux ici
        let allRates = ratesData.allRates;
        let lowerRate = null;
        if (allRates != null && allRates.length > 0) {
          allRates.forEach((rate) => {
            if (lowerRate == null) {
              lowerRate = rate;
            } else if (rate.calc_TotalOrderCost < lowerRate.calc_TotalOrderCost) {
              lowerRate = rate;
            }
          });
          myRate = lowerRate;
        }
      }

      // affichage du taux
      if (myRate != null) {
        strings.push('------------');
        if (myRate.calc_TotalShippingCostNoTax !== undefined) strings.push(`D - Total Shipping Cost: $${(myRate.calc_TotalShippingCostNoTax ?? 0).toFixed(2)}`);

        if (myRate.shipmentCostNoTax !== undefined) strings.push(`-> Base Cost (noTax) : $${(myRate.shipmentCostNoTax ?? 0).toFixed(2)}`);
        else strings.push(`-> Base Cost : $${(myRate.shipmentCost ?? 0).toFixed(2)}`);

        if (myRate.otherCostNoTax !== undefined) strings.push(`-> Other Cost (NoTax): $${(myRate.otherCostNoTax ?? 0).toFixed(2)}`);
        else strings.push(`-> Other Cost: $${(myRate.otherCost ?? 0).toFixed(2)}`);

        strings.push(`-> Frais additionnels: $${(myRate.mdpweb_custom_extraFees ?? 0).toFixed(2)}`);

        if (myRate.raisonsFrais.length > 0) {
          strings.push('------------');
          strings.push(`Raisons frais:`);
          myRate.raisonsFrais.forEach((x) => strings.push(x));
        }

        if (myRate.calc_TotalOrigShipstationCostWithTax !== undefined)
          strings.push(`-> (info) Orig SS Shippping Cost: $${(myRate.calc_TotalOrigShipstationCostWithTax ?? 0).toFixed(2)}`);

        if (myRate.shipstationTaxAmount && myRate.shipstationTaxAmount !== 0)
          strings.push(`-> (info) Shipping Tax: $${(myRate.shipstationTaxAmount ?? 0).toFixed(2)}`);

        strings.push('------------');
        strings.push(`E - Coûtant brut (B+C+D): $${(myRate.calc_TotalOrderCostBeforeShipStationCommission ?? 0).toFixed(2)}`);
        strings.push('------------');
        strings.push(`%Comm. Amazon: ${((infoCalc.pctCommissionMarketPlace ?? 0) * 100).toFixed(2)}%`);
        strings.push(`F - $Comm. Amazon (sur A): $${(infoCalc.amountCommissionMarketPlace ?? 0).toFixed(2)}`);
        strings.push('------------');

        strings.push(`G - Coûtant final (E + F): $${(myRate.calc_TotalOrderCost ?? 0).toFixed(2)}`);
        strings.push('------------');
        strings.push(`Profit total (G - A): $${(myRate.profit ?? 0).toFixed(2)}`);
      }
    } catch (error) {
      strings.push('Erreur affichage:' + error);
    }

    return strings;
  }
}
