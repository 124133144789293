<div class="page">
  <h1 class="page-titre">Bienvenue sur Logiciel MDP!</h1>
  <br /><br />

  <h1>Version du webApp</h1>

  <ul>
    <li>
      <h2 class="page-titre">Version WebApp Actuel : {{ this.appVersion }}</h2>
    </li>
    <li>
      <h2 class="page-titre">WebApp Environnement : {{ this.environmentName }}</h2>
    </li>
  </ul>
  <br /><br />
  <h2>Web API</h2>
  <ul>
    <li>
      <h2 class="page-titre">API Actuel : {{ this.apiURL }}</h2>
    </li>
    <li>
      <h2 class="page-titre">Version API Actuel : {{ this.apiVersion }}</h2>
    </li>
    <li>
      <h2 class="page-titre">Environnement API Actuel : {{ this.apiEnvironment }}</h2>
    </li>
  </ul>
</div>
