import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DateFilterFn } from '@angular/material/datepicker';
import { hhConsumerNumberMapping } from 'src/app/models/reception-models/hh-Consumer-Number-mapping';

@Injectable({
  providedIn: 'root',
})
export class hhConsumerNumberMappingService {
  constructor(private httpclient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}HHConsumerNumberMapping`;

  cachedhhConsumerNumberMapping: hhConsumerNumberMapping[] = undefined;
  dateOfLastReload: Date;

  getFromCache(): hhConsumerNumberMapping[] {
    return this.cachedhhConsumerNumberMapping;
  }

  getAll(forceRefreshCache: boolean = true): Observable<hhConsumerNumberMapping[]> {
    let aef: Observable<hhConsumerNumberMapping[]> = undefined;

    //ici, on permettra de récupérer les données à partir de la cache, si possible
    if (this.cachedhhConsumerNumberMapping == undefined || forceRefreshCache === true) {
      aef = this.httpclient.get<hhConsumerNumberMapping[]>(`${this.BASE_URL}`);
      aef.subscribe((data) => {
        this.dateOfLastReload = new Date();
        this.cachedhhConsumerNumberMapping = data;
      });
    }

    return aef;
  }
  get(id: number): Observable<hhConsumerNumberMapping> {
    return this.httpclient.get<hhConsumerNumberMapping>(`${this.BASE_URL}/${id}`);
  }

  create(pt: hhConsumerNumberMapping): Observable<any> {
    return this.httpclient.post(`${this.BASE_URL}`, pt);
  }

  update(pt: hhConsumerNumberMapping): Observable<any> {
    return this.httpclient.put(`${this.BASE_URL}/${pt.id}`, pt);
  }

  delete(id: number): Observable<any> {
    return this.httpclient.delete(`${this.BASE_URL}/${id}`);
  }
}
