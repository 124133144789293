import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShipStation_Tags } from '../models/shipstation/shipstation-tags';
import { ShipStation_Orders } from '../models/shipstation/shipstation-order';

@Injectable({
  providedIn: 'root',
})
export class shipstationService {
  constructor(private httpClient: HttpClient) {}

  BASE_URL = `${environment.apiUrl}shipStationOrders/`;

  /** On va chercher UNE commande dans Shipstation directement (via l'api MDPWEB..)  */
  GetOrderLive(shipstationOrderID: number): Observable<ShipStation_Orders> {
    return this.httpClient.get<ShipStation_Orders>(`${this.BASE_URL}GetShipStationOrderLive/${shipstationOrderID}`);
  }

  /** On va chercher UNE commande dans Shipstation directement (via l'api MDPWEB..)  */
  GetOrderBySiteOrderID(SiteOrderID: string): Observable<ShipStation_Orders> {
    //{{API-URL}}/api/shipStationOrders/GetShipStationOrderBySiteOrderID?SiteOrderID=113-5944060-4068255
    let URL = `${this.BASE_URL}GetShipStationOrderBySiteOrderID?SiteOrderID=${SiteOrderID}`;
    return this.httpClient.get<ShipStation_Orders>(URL);
  }

  /** On va chercher UNE commande dans Shipstation directement (via l'api MDPWEB..)  */
  GetShipStationShipmentsBySiteOrderID(SiteOrderID: string): Observable<any> {
    //{{API-URL}}/api/shipStationOrders/GetShipStationOrderBySiteOrderID?SiteOrderID=113-5944060-4068255
    let URL = `${this.BASE_URL}GetShipStationShipmentsBySiteOrderID?SiteOrderID=${SiteOrderID}`;
    return this.httpClient.get<any>(URL);
  }

  //************************************************ */
  /*Tags shipstation, avec système de cache */
  cachedShipstationTags: ShipStation_Tags[] = undefined;
  dateOfLastReload: Date;

  /** l'appel ici n'est pas observable, ce qui permet de retourner de la cache immédiatement, sans délais et donc éviter de scrapper le livecycle de l'appelant*/
  GetListTagsFromCache(): ShipStation_Tags[] {
    return this.cachedShipstationTags;
  }
  /** Methode pour aller chercher soit dans l'API soit danns la cache..*/
  GetListTagsAsync(): Observable<ShipStation_Tags[]> {
    let myLocalObservable = this.httpClient.get<ShipStation_Tags[]>(`${this.BASE_URL}GetShipStation_TagsAsync`);
    myLocalObservable.subscribe((data) => {
      this.dateOfLastReload = new Date();
      this.cachedShipstationTags = data;
    });
    return myLocalObservable;
  }
  // Méthode pour ajouter/retirer un flag shipstation d'une trace
  AddRemoveShipStationFlags(mustReserve: boolean, shipStationOrderId: number, flags: number[]): Observable<any> {
    let ToReserve = 'ADD';
    if (mustReserve === false) ToReserve = 'REMOVE';
    let url = environment.apiUrl + 'shipStationOrders/AddRemoveShipStationFlags';
    url += '/' + shipStationOrderId;
    url += '/' + ToReserve;
    return this.httpClient.post(url, flags);
  }
  //************************************************ */
}
