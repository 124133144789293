<div class="kbd-bg">
  <div class="kbd-container">
    <div class="kbd-titre">{{ numericKeyboard.title }}</div>
    <div>
      <input class="kbd-input" id="code" type="password" [(ngModel)]="numericKeyboard.valueNumericKeyboard" />
    </div>
    <div>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('1')">1</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('2')">2</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('3')">3</button>
    </div>
    <div>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('4')">4</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('5')">5</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('6')">6</button>
    </div>
    <div>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('7')">7</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('8')">8</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('9')">9</button>
    </div>
    <div>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="resetPin(false)"><mat-icon>keyboard_backspace</mat-icon></button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addPin('0')">0</button>
      <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="resetPin(true)"><mat-icon>close</mat-icon></button>
    </div>
    <div>
      <button
        mat-flat-button
        color="primary"
        type="button"
        class="kbd-btn-footer"
        (click)="CloseNumericKeyboard()"
        [disabled]="numericKeyboard.valueNumericKeyboard.length == 0"
      >
        <mat-icon>check</mat-icon> Valider
      </button>
    </div>
  </div>

  <div style="margin-top: 5em; padding: 0.5em">
    <div>
      <button (click)="LogByStandardLogin()" mat-stroked-button>
        <div>
          <mat-icon style="vertical-align: middle">password</mat-icon>
          Changer pour une connexion avec usager/mot de passe
        </div>
      </button>
    </div>
  </div>
</div>
