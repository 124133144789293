<div class="main">
  <!-- COMPANY INFO -->
  <div class="application-container" routerLink="/accueil" routerLinkActive="selected">
    <mat-icon class="application-logo">store</mat-icon>
    <div class="application-nom">Logiciel MDP</div>
  </div>
  <!-- LINK CONTAINER -->
  <div class="link-container">
    <!-- SCAN 1 -->
    <div class="title-container">
      <div class="title">Scan 1</div>
      <div class="subtitle">Réception de produit : 1ère étape</div>
    </div>
    <a *ngIf="userRight.scan1" class="menu" routerLink="/scan1" routerLinkActive="selected" mat-button><mat-icon>qr_code_scanner</mat-icon>Scan 1</a>
    <a *ngIf="userRight.scan1" class="menu" routerLink="/scan1-old" routerLinkActive="selected" mat-button><mat-icon>qr_code_scanner</mat-icon>Scan 1 (Old)</a>

    <!-- SCAN 2 -->
    <div class="title-container">
      <div class="title">Scan 2</div>
      <div class="subtitle">2e étape</div>
    </div>
    <a *ngIf="userRight.scan2Emballeur" class="menu" routerLink="/scan2-emballeur" routerLinkActive="selected" mat-button
      ><mat-icon>qr_code_scanner</mat-icon>Scan 2 - Emballeur</a
    >

    <!-- RECHERCHES -->
    <div class="title-container">
      <div class="title">DVL-Expéditions</div>
    </div>
    <a *ngIf="userRight.onlineOrders" class="menu" routerLink="/commande" routerLinkActive="selected" mat-button
      ><mat-icon>shopping_cart</mat-icon>Commandes en lignes</a
    >
    <a *ngIf="userRight.reception" class="menu" [routerLink]="['/', 'expedition-trace', { FixedDate: 'TODAY' }]" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>Traces expédition</a
    >
    <a
      *ngIf="userRight.achatLabels"
      class="menu"
      [routerLink]="['/', 'achat-labels', { Tags_IsLivraisonAAcheter_CalcColumn: true }]"
      routerLinkActive="selected"
      mat-button
      ><mat-icon>account_balance_wallet</mat-icon>Achat labels</a
    >
    <a *ngIf="userRight.achatLabels" class="menu" [routerLink]="['/', 'carrier-invoice-summary']" routerLinkActive="selected" mat-button
      ><mat-icon>receipt_long</mat-icon>Factures Transporteurs</a
    >

    <div class="title-container">
      <div class="title">PickList</div>
    </div>
    <a *ngIf="userRight.achatLabels" class="menu" routerLink="/picklist" routerLinkActive="selected" mat-button><mat-icon>checklist_rtl</mat-icon>PickList</a>
    <!--<a *ngIf="userRight.achatLabels" class="menu" routerLink="/picklist-details" routerLinkActive="selected" mat-button ><mat-icon>checklist_rtl</mat-icon>PickList Détails</a>-->

    <div class="title-container">
      <div class="title">Combo Produits</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/product-combo" routerLinkActive="selected" mat-button
      ><mat-icon>checklist_rtl</mat-icon>Combo Produits</a
    >

    <!-- RECEPTION -->
    <div class="title-container">
      <div class="title">Réceptions</div>
    </div>

    <a *ngIf="userRight.reception" class="menu" [routerLink]="['/', 'reception-header', { IsCompleted: false }]" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>En-têtes</a
    >
    <a *ngIf="userRight.reception" class="menu" routerLink="/reception-detail" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>Détail</a
    >
    <a *ngIf="userRight.reception" class="menu" routerLink="/reception-trace" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>Traces</a
    >
    <!-- LIENS -->
    <div class="title-container">
      <div class="title">Sites web</div>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title><mat-icon>language</mat-icon>Liens</mat-panel-title>
      </mat-expansion-panel-header>
      <a class="expmenu" target="_blank" mat-button href="https://complete.channeladvisor.com/Marketplaces/Dashboard.mvc?apid=12010161"
        ><mat-icon>launch</mat-icon> ChannelAdvisor</a
      >
      <a class="expmenu" target="_blank" mat-button href="https://ship11.shipstation.com/orders/awaiting-shipment"><mat-icon>launch</mat-icon> ShipStation</a>
      <a class="expmenu" target="_blank" mat-button href="https://www.homehardware.ca/"><mat-icon>launch</mat-icon> HomeHardware</a>
      <a class="expmenu" target="_blank" mat-button href="https://dsa.homehardware.ca:446/demandorders/faces/jsp/login.jsp"
        ><mat-icon>launch</mat-icon> HomeHardware – DSA</a
      >
      <a class="expmenu" target="_blank" mat-button href="https://www.amazon.ca/"><mat-icon>launch</mat-icon> Amazon</a>
    </mat-expansion-panel>

    <!-- sécurité -->
    <div class="title-container">
      <div class="title">Sécurité</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/manage-workstations" routerLinkActive="selected" mat-button
      ><mat-icon>computer</mat-icon>Gestion Workstation</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/usager" routerLinkActive="selected" mat-button><mat-icon>account_circle</mat-icon>Usagers</a>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/auditLogList" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>Audit Log</a
    >

    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/userSessionList" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>Liste session Usager</a
    >

    <div class="title-container">
      <div class="title">Gestion des données</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/scanProblemTypes" routerLinkActive="selected" mat-button
      ><mat-icon>report_problem</mat-icon>Gérer les types de problèmes</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/package-types" routerLinkActive="selected" mat-button
      ><mat-icon>inventory_2</mat-icon>Gérer les types de boites</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/hh-consumer-number-mapping" routerLinkActive="selected" mat-button
      ><mat-icon>mediation</mat-icon>Mapping Code Conso.</a
    >

    <!-- Finance -->
    <div class="title-container">
      <div class="title">Finance</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/rapport-facturation-sommaire-acomba" routerLinkActive="selected" mat-button
      ><mat-icon>request_quote</mat-icon>Rapport Facturation Sommaire</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/rapport-facturation-acomba" routerLinkActive="selected" mat-button
      ><mat-icon>request_quote</mat-icon>Rapport Facturation Acomba</a
    >
    <a *ngIf="userRight.financeAdmin" class="menu" routerLink="/user-defined-rates" routerLinkActive="selected" mat-button
      ><mat-icon>percent</mat-icon>Gestion des Taux</a
    >
    <a *ngIf="userRight.financeAdmin" class="menu" routerLink="/invoiceApList" routerLinkActive="selected" mat-button
      ><mat-icon>attach_money</mat-icon>Factures Fournisseur</a
    >

    <div class="title-container">
      <div class="title">Système Admin</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/systemManager/BatchExecSummary" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>BatchExec-Sommaire</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/systemManager/BatchExecDetail" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>BatchExec-Detail</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/systemManager/LogExecSummary" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>LogExec-Sommaire</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/systemManager/LogExecDetail" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>LogExec-Detail</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/channelAdvisor-ProductSync-Overview" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>ChannelAdvisor Qty Sync</a
    >

    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/OerpAdminPanelComponent" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>OerpAdminPanelComponent</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/SystemConfigurationComponent" routerLinkActive="selected" mat-button
      ><mat-icon>assignment_return</mat-icon>SystemConfigurationComponent</a
    >

    <div class="title-container">
      <div class="title">Home Hardware</div>
    </div>
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/HhfileGetInfoSommaireH01Component" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>HomeReceiveFile - Sommaire H01</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/hhfileH01GetDetails" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>HomeReceiveFile - Detail H01</a
    >
    <a *ngIf="userRight.globalAdmin" class="menu" routerLink="/hhfileLogExecution" routerLinkActive="selected" mat-button
      ><mat-icon>local_shipping</mat-icon>HomeReceiveFile - Log exécution</a
    >
  </div>
</div>
