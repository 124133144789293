<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="main-page-container">
  <div class="listpage-container">
    <!--Grosse barrure indique que des changemnets sont en cours-->
    <div *ngIf="childHaveChanges" class="modal-preventChange" (click)="popUpConfirmDiscard()">Changements détectés...</div>
    <div class="ss-list-button-container">
      <button mat-stroked-button (click)="createNew()" color="primary"><mat-icon>add</mat-icon> Créer une PickList</button>
    </div>

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      filterable="menu"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="modifier($event)"
    >
      <!-- COLUMNS -->

      <!-- PickListHeaderID -->
      <kendo-grid-column field="PickListHeaderID" title="ID Entête" [width]="15" [minResizableWidth]="15" filter="numeric"></kendo-grid-column>

      <!-- PickListHeaderID -->
      <kendo-grid-column field="NumberOfSplits" title="Nombres d'employés" [width]="18" [minResizableWidth]="18" filter="numeric"></kendo-grid-column>

      <!-- StartDate -->
      <kendo-grid-column field="StartDate" title="Date de début" [width]="35" [minResizableWidth]="35" filter="date">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ formatDate(dataItem.StartDate) }}
        </ng-template>
      </kendo-grid-column>

      <!-- EndDate -->
      <kendo-grid-column field="EndDate" title="Date de fin" [width]="35" [minResizableWidth]="35" filter="date">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ formatDate(dataItem.EndDate) }}
        </ng-template>
      </kendo-grid-column>

      <!-- Action -->
      <kendo-grid-column field="PickListHeaderID" title="Action(s)" [width]="35" [minResizableWidth]="35" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="['/picklist-summarized', { pickListHeaderId: dataItem.PickListHeaderID }]" mat-stroked-button color="primary"> Voir Sommaire </a>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <app-picklist-header-form
    #myChildForm
    *ngIf="showChildForm"
    [currentPicklistHeader]="selectedItem"
    class="formpage-container"
    (submitEmitterChildHaveChanges)="onChangeChildState($event)"
    (submitEmitterForceRefresh)="OnChildAskForForceRefresh($event)"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)"
  ></app-picklist-header-form>
</div>
