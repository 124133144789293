import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-test-print',
  templateUrl: './test-print.component.html',
  styleUrls: ['./test-print.component.scss']
})
export class TestPrintComponent implements OnInit {

  @ViewChild('pdfFrame')
  PdfFrame: ElementRef;
  PdfUrl;

  constructor(
    private sanitizer: DomSanitizer,
    ) { }

  ngOnInit(): void {
    this.onClickPrintTest();
  }

  //NE FONCTIONNE PAS!| ATTENTION!
  public GotoLinkAndPrint(url : string){
    var printWindow = window.open(url, "_blank");
    try
    {
      printWindow.focus();
      printWindow.print();
    }
    catch (e){
      console.log(e);
    } 
  }

  async onClickPrintTest() {
    //let MyShipmentID=0; 
    //this.CurrentShipStationShipments = await this.shipStationService.GetShipStationShipmentsBySiteOrderID(this.CurrentShipstationOrder.orderNumber).toPromise();
    //MyShipmentID = this.CurrentShipStationShipments[0].shipmentId;

    let strUrl = 'https://ship11.shipstation.com/api/download/label/190107360';
    // Permet de loader le site externe sans erreur cross-origin
    let tmp= this.sanitizer.bypassSecurityTrustResourceUrl(strUrl);
    this.PdfUrl  = tmp;

    // On attend un peu que le pdf soit récupéré (peut mieux faire)
    await new Promise(f => setTimeout(f, 2000)); 
    //this.PdfFrame.nativeElement.contentWindow.print();
    let iframe = document.getElementById('myTestDiv') as HTMLIFrameElement;
   // iframe.contentWindow.print();
 
  }
/*
  testPrint(){  
    e.preventDefault();
    var w = window.open(); 
    var printOne = document.getElementById('contentToPrint') as .html();
    var printTwo = $('.termsToPrint').html();
    w.document.write('<html><head><title>Copy Printed</title></head><body><h1>Copy Printed</h1><hr />' + printOne + '<hr />' + printTwo) + '</body></html>';
    w.window.print();
    w.document.close();
    return false;}*/

    ShowPopUp(){
      var testWindows = window.open('https://ship11.shipstation.com/api/download/label/190107360','popup','width=600,height=600'); 
      testWindows.window.print();
      return false;
    }

}
