import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PackageTypes } from '../models/packageTypes';
import { environment } from '../../environments/environment';
import { Observable, tap } from 'rxjs';
import { UtilService } from './util.service';
import { State } from '@progress/kendo-data-query';
import { ReplaySubject } from 'rxjs';
import { toODataString } from '@progress/kendo-data-query';
import { RapportFacturationSommaireAcomba } from '../models/facturationAcomba/RapportFacturationSommaireAcomba';

@Injectable({
  providedIn: 'root',
})
export class FacturationAcombaService {
  public RapportFacturationAcomba: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private httpclient: HttpClient, private utilService: UtilService) {}

  private BASE_URL = `${environment.apiUrl}FacturationAcomba`;

  // ODATA : STATE

  odata_RapportFacturationAcomba(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}?${toODataString(_state)}&$count=true`;
    return this.httpclient.get<any>(uri).pipe(
      tap((response: any) => {
        this.RapportFacturationAcomba.next(response);
      })
    );
  }

  public GetMismatchDVLOrderToERPInvoiceByMonth(OffsetInMonths: number | null = null): Observable<RapportFacturationSommaireAcomba[]> {
    let uri = `${this.BASE_URL}/GetMismatchDVLOrderToERPInvoiceByMonth`;
    if (OffsetInMonths) {
      uri += `?StartFromMonth=${OffsetInMonths}`;
    }
    return this.httpclient.get<RapportFacturationSommaireAcomba[]>(uri);
  }
}
