import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ListComponent } from 'src/app/component/core/list/list.component';
import { ReceptionService } from 'src/app/service/reception.service';
import { UserInfo } from 'src/app/models/user/UserInfo';
import { AuthService } from 'src/app/service/auth.service';
import { ScanProblemTypesService } from 'src/app/service/scanProblemTypes.service';
import { ScanProblemTypes } from 'src/app/models/scanProblemTypes';

@Component({
  selector: 'app-rapport-reclamation-home',
  templateUrl: './rapport-reclamation-home.html',
  styleUrls: ['./rapport-reclamation-home.css'],
})
export class RapportReclamationHome extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthService,
    private receptionService: ReceptionService,
    private scanProblemTypesService: ScanProblemTypesService
  ) {
    super(httpClient);

    //this.endpoint = "ItemReceptionDetails/GetRapportReclamation";
    this.endpoint = 'ItemReceptionReclamation';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'ItemReceptionDetailId',
        nom: 'ItemReceptionDetailId',
        type: 'number',
      },
      {
        key: 'ItemReceptionHeaderId',
        nom: 'ID en-tête',
        type: 'number',
        hide: true,
      },
      {
        key: 'SupplierInvoiceNumber',
        nom: '#Facture fournisseur',
        type: 'string',
      },
      {
        key: 'ProductNumber',
        nom: '#Produit',
        type: 'string',
      },
      {
        key: 'HH_ConsumerNumber',
        nom: 'Code conso',
        type: 'number',
      },
      {
        key: 'ProductDescription',
        nom: 'Description produit',
        type: 'string',
      },
      {
        key: 'QtyToReceive',
        nom: 'Qté à recevoir',
        type: 'number',
      },
      {
        key: 'QtyReceived',
        nom: 'Qté reçu',
        type: 'number',
        hide: false,
      },
      {
        key: 'QtyRemaining',
        nom: 'Qté restante',
        type: 'number',
        hide: false,
      },
      {
        key: 'Problem',
        nom: 'Problem',
        type: 'string',
        hide: false,
      },
      {
        key: 'QtyInProblem',
        nom: 'Qté Problème',
        type: 'number',
        hide: false,
      },
      {
        key: 'ClaimProcessedInfo',
        nom: 'Info Reclam.',
        type: 'string',
        hide: false,
      },
    ];
    this.champsHardcoded = ['ToggleCompletedOrNot'];

    this.ExportExcelfileName = 'RapportReclamations.xlsx'; //si on veut changer le nom du fichier Excel par défaut
  } //fin du constructeur

  TitreGlobalPage: string = 'Rapport réclamation';
  IconGlobalPage: string = 'local_shipping';
  NomEntiteCourante: string = 'trace';

  CurrentUserInfo: UserInfo; // statistiques sur l'utilisateur
  ListOfProblemType: ScanProblemTypes[];

  ngOnInit(): void {
    super.ngOnInit();
    this.recherche.ItemReceptionHeaderId = this.route.snapshot.paramMap.get('ItemReceptionHeaderId');

    // this.recherche

    this.CurrentUserInfo = this.authservice.info;

    this.scanProblemTypesService.getAll().subscribe((result) => {
      this.ListOfProblemType = result;
    });

    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.ItemReceptionHeaderId) params['ItemReceptionHeaderId'] = this.recherche.ItemReceptionHeaderId;

    this.router.navigate(['/RapportReclamationHome', params]);
    this.launchRefresh();
  }

  async MarquerLigneTraitee(e, myWholeRow) {
    let MyIDs: number[] = [];
    MyIDs.push(myWholeRow.ItemReceptionDetailId);
    let MustAssign = true;
    if (myWholeRow.ClaimProcessedInfo != null) MustAssign = false;
    var MyReturn = await this.receptionService.MarquerItemReceptionLigneReclamee(MyIDs, MustAssign).toPromise();
    let MyLocaldateStr = new Date().toLocaleString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
      second: '2-digit',
    });

    let curIndex = this.dataSource.filteredData.indexOf[myWholeRow];

    //si c'Est réussi, on va simuler le refresh des données
    if (MyReturn === null) {
      if (MustAssign) myWholeRow.ClaimProcessedInfo = 'OK - ' + MyLocaldateStr + ' - Usager:' + this.CurrentUserInfo.name;
      else myWholeRow.ClaimProcessedInfo = null;
    }
  }

  async MarquerLignesAfficheesTraitee() {
    let MyIDs: number[] = [];
    this.CurrentData.forEach((element) => {
      MyIDs.push(element.ItemReceptionDetailID);
    });
    var MyReturn = await this.receptionService.MarquerItemReceptionLigneReclamee(MyIDs, true).toPromise();
    if (MyReturn === null) {
    }
  }
}
