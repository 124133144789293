import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';

import { NgForm } from '@angular/forms';
import { WorkStation } from 'src/app/models/security/WorkStation';
import { WorkstationService } from 'src/app/service/security/workstation.service';

@Component({
  selector: 'app-workstation-form',
  templateUrl: './workstation-form.component.html',
  styleUrls: ['./workstation-form.component.scss'],
})
export class WorkstationFormComponent implements OnInit, OnChanges {
  @Input() currentItem: WorkStation;
  @Output() ShowForm = new EventEmitter<boolean>();

  @Output() submitEmitterCanCloseChildForm = new EventEmitter<any>(); //event permettant d'indiquer que l'enfant (ici) veut se fermer
  @Output() submitEmitterChildHaveChanges = new EventEmitter<any>(); //event permettant d'indiquer que l'enfant (ici) a des changemenets
  @Output() submitEmitterForceRefresh = new EventEmitter<any>();

  @ViewChild(NgForm) myform!: NgForm; //permet d'accéder ici (TS) au FORM fabriqué FrontEnd, nommé MyForm.

  titre: string;
  NomEntiteCourante: string = 'Station';

  IsChangesPublished: boolean = false;

  //constructor(private location: Location, private route: ActivatedRoute, private dialog: MatDialog, private snack: MatSnackBar, private dateSpeService: DateSpecialeService) { }
  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private myMainService: WorkstationService,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    //on va essayer de ramasser le ID du projet par la route, si possible (en cas de pop-up, entre autre.. pas obligé)
    let MyProjectIdByParam = this.route.snapshot.paramMap.get('id');
    //si on recoit un ID par la route, on va instancier le composant actuel
    if (MyProjectIdByParam) {
      this.currentItem = new WorkStation();
      this.currentItem.id = +MyProjectIdByParam;
    }
  }

  //méthode permettant de rafraichir les données actuelle
  refreshInterface() {
    //var id = this.route.snapshot.paramMap.get('id');
    if (this.currentItem) {
      // EDIT
      this.titre = 'Modifier une station';
      // fetch project data
      this.myMainService.get(this.currentItem.id).subscribe((jsonData) => {
        this.currentItem = jsonData;
      });
    } else {
      // ADD
      this.currentItem = new WorkStation();
      this.titre = 'Ajouter une station';
    }
  }

  //Evenement de sauvegarde...on encapsule pour gérer si on doit faire un pop-up ou non
  OnSauvegardeClick() {
    //si le formulaire est invalide [des champs required qui sont vides], on va demander à l'usager de confirmer
    if (this.myform.valid === false) {
      // pop dialog
      const dialogRef = this.dialog.open(PopConfirmComponent, {
        data: {
          titre: 'Formulaire avec champ invalide',
          texte: 'Attention!  Certains champs obligatoires ne semblent pas avoir été remplit. <br/><br/>Désirez-vous enregistrer QUAND MÊME?',
          affirmativeActionName: 'Oui, enregistrer quand même',
          affirmativeActionColor: 'primary',

          dismissiveActionName: 'Non, revenir en arrière',
          dismissiveActionColor: 'warn',
        },
      });
      // after close dialog
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.sauvegarder();
        }
      });
    } else {
      //si le modèle était valide dès le début, on enregistre.

      this.sauvegarder();
    }
  }

  //méthode pour enregistrer dans la bd
  sauvegarder() {
    let action = 'sauvegardé';
    //si le projet a un ID, c'estun UPDATe...
    if (this.currentItem.id) {
      // EDIT
      this.myMainService.update(this.currentItem).subscribe((jsonData) => {
        //on ferme le FORM
        this.cacherForm();
        //pop-up...
        this.snack.open('La station de travail a été ' + action + '.', '', { duration: 3000 });
        this.IsChangesPublished = true;
      });
    } else {
      // ADD
      this.myMainService.create(this.currentItem).subscribe((jsonData) => {
        this.currentItem = jsonData;
        action = 'ajouté';

        //on ferme le FORM
        this.cacherForm();
        //pop-up...
        this.snack.open('La station de travail à été ' + action + '.', '', { duration: 3000 });
        this.IsChangesPublished = true;
      });
    }
  }

  detruire() {
    // pop dialog
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Supprimer la station',
        texte: 'Êtes-vous sûr de vouloir supprimer cette station de travail?',
        affirmativeActionName: 'Supprimer',
        affirmativeActionColor: 'warn',

        dismissiveActionName: 'Annuler',
        dismissiveActionColor: '',
      },
    });
    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        //si on répond OUI, (affirmative)  on procède à la suppression
        this.myMainService.delete(this.currentItem.id).subscribe((jsonData) => {
          this.cacherForm();
          this.snack.open('La station de travail a été détruite.', '', { duration: 3000 });
          this.IsChangesPublished = true;
        });
      }
    });
  }

  annuler() {
    this.cacherForm();
  }

  cacherForm(param_ForceParentRefresh: boolean = false): void {
    this.submitEmitterChildHaveChanges.emit(false); //on raise le event indiquant si l'enfant a des changements en cours (false, ici)
    this.submitEmitterCanCloseChildForm.emit(true); //on raise le Event permettant de demander de se faire fermer
    this.submitEmitterForceRefresh.emit(param_ForceParentRefresh);
  }

  validateForm(parmaForm: any) {
    myForm: parmaForm;
  }

  //Détecte N'IMPORTE quel changement sur mon composant
  ngDoCheck(changes: any) {
    let test = changes;

    if (this.myform?.pristine === false && this.IsChangesPublished === false) {
      this.IsChangesPublished = true;
      this.submitEmitterChildHaveChanges.emit(true);
    }
  }

  //ne détecte QUE les changements sur les @input values
  ngOnChanges() {
    this.refreshInterface();
  }

  async Deassigner() {
    //************************
    //pop-=up de confirmation de sur-assignation
    let strMessage = "Êtes-vous certain de vouloir déassigner la station de travail '" + this.currentItem.WorkStationCode + "'?";

    let popupResult = this.matDialog.open(PopConfirmComponent, {
      data: {
        titre: 'DéAssignation forcée de station',
        texte: strMessage,
        mainIcon: 'warning',
      },
    });

    var monRetour = await popupResult.afterClosed().toPromise();
    //si l'usager ne dit pas OUI
    if (monRetour !== true) {
      return;
    } else {
      this.myMainService.AssignWorkStation(this.currentItem.id, true, true).subscribe(
        (result) => {
          this.snack.open('Dé-assignation réussie', '', { duration: 3000 });
          this.cacherForm(true);
        },
        (error) => {
          console.log('Erreur lors de la dé-assignation : ' + error);
          this.snack.open('Erreur lors de la dé-assignation : ' + error, '', { duration: 5000 });
        }
      );
    }
  }
}
