<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>

  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    filterable="menu"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
        <mat-icon>delete</mat-icon>
        Vider les filtres
      </button>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>
    <!-- COLUMNS -->
    <kendo-grid-excel fileName="Products.xlsx"></kendo-grid-excel>

    <!-- SS_Order_OrderNumber -->
    <kendo-grid-column field="SS_Order_OrderNumber" title="ID en-tête" [width]="150" [minResizableWidth]="150" filter="text" format="0"></kendo-grid-column>

    <!-- SS_Order_OrderDate -->
    <kendo-grid-column field="SS_Order_OrderDate" title="Date Commande" [width]="150" [minResizableWidth]="150" filter="text" format="date"
      ><ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.SS_Order_OrderDate | date : "yyyy-MM-dd" }}
      </ng-template>
    </kendo-grid-column>

    <!-- SS_OrderItem_subTotal_CAD -->
    <kendo-grid-column field="SS_OrderItem_subTotal_CAD" title="Sous total" [width]="150" [minResizableWidth]="150" filter="text" format=""></kendo-grid-column>

    <!-- Global_Erreur -->
    <kendo-grid-column field="Global_Erreur" title="Erreur?" [width]="150" [minResizableWidth]="150" filter="text" format="0"></kendo-grid-column>
  </kendo-grid>
</div>
