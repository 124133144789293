import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Guard qui autorise l'accès vers le scan 1 ou redirige vers /logByPin.
 */
export class Scan1Service  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.authService.rights.scan1 || this.authService.rights.globalAdmin) {
      return true;
    } else {
      // si l'utilisateur ne dispose pas de la permission, on renvoie vers la page de login
      return this.router.createUrlTree(['/' + this.authService.CurrentLoginPage]);
    }
  }
}
