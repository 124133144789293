// #region IMPORTS
// Angular
import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, firstValueFrom, lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// Models
import { Item } from '../../models/item';
import { UserInfo } from '../../models/user/UserInfo';
import { LocalStorage } from '../../models/enum/LocalStorage';
import { Station } from '../../models/enum/Station';
import { APIPostProblem } from 'src/app/models/API/APIPostProblem';
import { ShipStation_Orders } from 'src/app/models/shipstation/shipstation-order';
import { DVLOrder } from 'src/app/models/DVL/DVLOrder';
import { HttpErrorMsg } from 'src/app/models/HttpErrorMsg';
import { ItemExpeditionTrace } from 'src/app/models/DVL/itemExpeditionTrace';
import { enumItemTraceExpedition_Statut_For_API, enumItemTraceExpedition_Statut_String } from 'src/app/models/enum/enumItemTraceExpedition_Statut';
import { PackageTypes } from 'src/app/models/packageTypes';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';
// Services
import { AuthService } from '../../service/auth.service';
import { ProductService } from 'src/app/service/product.service';
import { ScanProblemTypesService } from 'src/app/service/scanProblemTypes.service';
import { shipstationService } from 'src/app/service/shipstation.service';
import { DVLService } from 'src/app/service/DVL.service';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { ChanneladvisorService } from 'src/app/service/channeladvisor.service';
import { PackagetypesService } from 'src/app/service/packagetypes.service';
// Components
import { PopConfirmComponent } from '../core/pop-confirm/pop-confirm.component';
import { DialogProblemComponent } from '../problem/dialog-problem/dialog-problem.component';
import { DialogSelectionDvlComponent } from './dialog-selection-dvl/dialog-selection-dvl.component';
// Autres
import { environment } from 'src/environments/environment';
import { OrderFinanceService } from 'src/app/module/order/order.service';
import { OrderExtendedInfo } from 'src/app/module/order/models/OrderExtendedInfo.model';
// #endregion

@Component({
  selector: 'app-scan2-emballeur',
  templateUrl: './scan2-emballeur.component.html',
  styleUrls: ['./scan2-emballeur.component.scss'],
})
export class Scan2EmballeurComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    // alert('By refreshing this page you may lost all data.');
    // event.returnValue = false;
  }

  @ViewChild('scan2SKUInput') skuInput: ElementRef;

  CurrentProductInfo = {} as Item; //info sur le produit en cours

  CurrentUserInfo: UserInfo; // statistiques sur l'utilisateur
  barcodeScanned = ''; //on peut vouloir noter le code-BARRE utilisé
  formScan2: UntypedFormGroup; // formulaire
  listItems: Item[] = []; // liste des items à expédier

  // #region boîtes
  nomBoiteSuggeree: string = '-'; // taille de la boite à utiliser
  listeToutesBoites: PackageTypes[]; // toutes les boîtes Channel Advisor
  listeBoitesFiltree: PackageTypes[]; // toutes les boîtes sauf celle actuellement affichée
  nomBoiteSelectionnee: string; // boîte sélectionnée dans le drop-down
  // #endregion

  ShipStationLabelWasPrinted: boolean = false;

  globalErrorMessage: string = '';

  CurrentShipStationShipment_BAD: any = undefined;

  listDVLOrders: DVLOrder[] = [];
  currentSelectedDVL: DVLOrder = undefined;
  currentTraceExpeditionUsed: ItemExpeditionTrace = undefined;

  ListExistingExpeditionTraceForThisDVL: ItemExpeditionTrace[] = [];

  CurrentShipstationOrder: ShipStation_Orders = undefined;
  CurrentShipStationShipments: any[] = [];

  CurrentShipStationShipmentID: number; // ShipStation shipmentID actuel
  CurrentShipstation_Tags;
  CurrentShipStation_Notes: string[];
  currentTracesId: number[]; //plusieurs ItemReceptionTrace peuvent permettre de clore UNE commande

  BoutonRechercheActif = true;

  AvailableReceptionTrace: any[]; //cet Array contient toute les lignes "traitables" par le scan2.
  SelectedReceptionTrace: any; //cet entité contient la ligne qu'on va traiter LÀ

  IsTraceFromOtherUser: boolean = false; // permet de savoir si un trace sélectionnée appartient à un autre usager.  va permettre d'empecher d'annuler une trace si pas permit

  instructionProblem = ''; // instructions retournées en cas de problème

  isSpecialist = false;

  noTablet = null;
  ScanIsCompleted = true;

  private orderService = inject(OrderFinanceService);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private productsService: ProductService,
    private snack: MatSnackBar,
    private problemService: ScanProblemTypesService,
    private shipStationService: shipstationService,
    private channelAdvisorService: ChanneladvisorService,
    private packageTypesService: PackagetypesService,
    private dvlService: DVLService,
    private dialogService: PopUpDialogService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    localStorage.setItem(LocalStorage.SCAN_TYPE_CURRENT_MODE, Station.SCAN2_EMBALLEUR);
    this.initForm();
    this.CurrentUserInfo = this.authService.info;

    // Ne pas permettre de faire des Scan2 si pas de station sélectionnée
    if (this.CurrentUserInfo.stationCode === 'AUCUNE_SÉLECTIONNÉE') {
      this.BoutonRechercheActif = false;
      this.GlobalError_AddError('Une station de travail doit être sélectionnée');
    }

    this.shipStationService.GetListTagsAsync(); //fire and forget...
    this.getListeBoites();

    this.problemService.getAll(); //fire and forget..c'est juste pour forcer la cache à se rafraichir.

    this.setFocusOnSKUInput();
  }

  private setFocusOnSKUInput(): void {
    setTimeout(() => {
      this.skuInput.nativeElement.focus();
    }, 0);
  }

  private initForm(): void {
    // Formulaire UPC/Barcode
    this.formScan2 = this.formBuilder.group({
      data: ['', Validators.required],
    });
  }

  populateTestData() {
    this.formScan2.setValue({ data: '5522035' });
    var monItem1: Item = {
      SkuOrig: '5522035x4',
      SkuCleaned: '5522035',
      Description: '4 X 30 inch(s) x 248 inch(s) Hammock Furnace Filter',
      Title: '4 X 30 inch(s) x 248 inch(s) Hammock Furnace Filter',
      quantity: 1,
      imageUrl: 'https://d3d71ba2asa5oz.cloudfront.net/12010161/images/5522035__1.jpg',
      isMultipack: true,
    };
    this.listItems.push(monItem1);
    this.nomBoiteSuggeree = 'A';
    this.GlobalError_AddError('fausse erreur a afficher');
    this.SelectedReceptionTrace = monItem1;
    this.CurrentShipStationShipment_BAD = {
      shipmentId: 205279030,
      orderId: 333563015,
      orderKey: '5997130',
      userId: '2364e208-f879-4efd-9e9d-c2a88960c063',
      orderNumber: '702-2719279-8337013',
      createDate: '2021-10-02T08:34:24.203',
      shipDate: '2021-10-02T00:00:00',
      shipmentCostNoTax: 24.96,
      insuranceCost: 0.0,
      trackingNumber: '333161310664',
      isReturnLabel: false,
      batchNumber: '103896',
      carrierCode: 'purolator_ca',
      serviceCode: 'purolator_ground ',
      packageCode: 'package',
      warehouseId: 208314,
      voided: false,
      marketplaceNotified: true,
      weight_value: 0.0,
      dimensions_length: 0.0,
      dimensions_width: 0.0,
      dimensions_height: 0.0,
      insuranceOptions_insureShipment: false,
      insuranceOptions_insuredValue: 0.0,
      shipmentItems: [
        {
          dbid: 12148,
          ShipmentId: 205279030,
          orderItemId: 544407027,
          lineItemKey: '65718062235578',
          sku: '5522035x4',
          name: '4 X 30 inch(s)  x 248 inch(s)  Hammock Furnace Filter',
          imageUrl: 'https://d3d71ba2asa5oz.cloudfront.net/12010161/images/5522035__1.jpg',
          quantity: 1,
          unitPrice: 71.44,
          taxAmount: 4.52,
          shippingAmount: 18.91,
          warehouseLocation: '',
          productId: 19322220,
          adjustment: false,
          upc: '065605302485',
          createDate: '2021-10-01T09:44:42.137',
          modifyDate: '2021-10-01T09:44:42.137',
        },
      ],
      PrintLabel_URILink: 'https://ship11.shipstation.com/api/download/label/205279030',
    };
  }

  /**
   * On Scan un produit, et on veut entamer le traitement d'emballage.
   * 1 - valider si le produit existe ou non, et afficher son nom....
   * 2 - aller chercher les ItemREceptionTraces du produit Scanné, étant destiné à DVL-UNIQUE, et n'étant pas complété.
   * 3 -
   * @private
   */
  public async OnSearchClick() {
    this.resetInterfaceData();

    //on récupère le barcode/sku inscrit sur le formulaire..
    let skuOrUPC = this.formScan2.get('data').value;
    skuOrUPC = skuOrUPC.trim();

    //on déclanche une recherche de produit
    var productfound = await this.getProductInfo(skuOrUPC);
    if (productfound === false) return false;

    //idée TODO : mettre l'icone de chargement.... spinner...
    this.listDVLOrders = [];
    let tmpListe = await lastValueFrom(this.dvlService.GetDVL(this.CurrentProductInfo.SkuCleaned, 0, true, false, true, true)); //on veut les ReadyToGo,  NotCompleted...on va ramener les "en traitement pieuvre, car plus loin, l'assignateur de traitement va nous protéger"
    this.listDVLOrders = this.listDVLOrders.concat(tmpListe);

    if (!this.listDVLOrders || this.listDVLOrders.length === 0) {
      this.GlobalError_AddError(
        "Aucune commande DVL disponible.<br/>Appuyez sur 'Choisir une DVL' pour voir les autres DVL sur ce produit ou contactez votre superviseur.",
        true
      );
      return;
    }

    //sélection de la DVL la plus adéquate.
    let DVLFound = await this.AssignBestPossibleDVL(); //cette méthode s'occupe de faire le code qui va sélectionneer la DVL, et l'assign à  this.currentSelectedDVL
    if (DVLFound === false) {
      this.GlobalError_AddError(
        "Aucune commande DVL disponible.<br/>Appuyez sur 'Choisir une DVL' pour voir les autres DVL sur ce produit ou contactez votre superviseur.",
        true
      );
      return;
    }

    let mySiteOrdeRID = this.currentSelectedDVL.SiteOrderID;
    await this.GetDetailsDVL(mySiteOrdeRID);

    // var Succeed = await this.GetItemReceptionTraceAvailable();
    // if (Succeed === false)
    //   return false;

    this.UpdateCurrentBoxSelected();

    //on va réserver le traitement de la trace, pour éviter qu'un autre usager traite cette commande
    // var result = await this.receptionService.ReserverTraitementTrace(this.SelectedReceptionTrace.id, true).toPromise();
  }

  /**
   * Cette méthode encapsule le code qu'il faut pour choisir ZE bonne DVL à traiter, pour l'usager en cours (car il peut y avoir plusieurs DVL,  certaines DVL déjà en traitement par d'autre station,  certaines DVL entamé par l'usager courant etc...)
   */
  private async AssignBestPossibleDVL(): Promise<boolean> {
    //si il n'y a qu'une seule DVL dispo, on prendra celle là.
    let IsDVLTrouvee = false;
    if (this.listDVLOrders.length >= 1) {
      //si plusieurs DVL dispo...
      //boucle bidon WHILE pour facilement permettre de quitter les assignations dès qu'on réussi
      while (true) {
        //on va chercher aussi une liste des traces d'expedition qui sont soit pendingscan1 ou working...
        let ListTraceExpeditionPendingOrWorking = await lastValueFrom(this.dvlService.GetExpeditionTraceByProduct(this.CurrentProductInfo.SkuCleaned));

        //******************************************************** */
        //1ere méthode : on va FORCER l'assignation de la DVL que l'usager en cours était en train de traiter... [donc on ne veut pas tant cycler au travers de toutes les DVL trouvées, mais vraiment FORCER celle qui était en traitement...]
        //on va chercher les traces qui touchent MON usager en cours...
        var TracesDeMonUserEnCours = ListTraceExpeditionPendingOrWorking.filter(
          (x) =>
            x.SCAN_ctrl_UserName === this.authService.info.name && //usager en cours
            x.SCAN_ProcessingStatus.toString() === enumItemTraceExpedition_Statut_String.WORKING
        ); //statut working

        //si on trouve des traces d'expédition WORKING/PENDING associée à mon usager, on va priviligée ceux là (pour 'reprendre' le traitement)
        if (TracesDeMonUserEnCours && TracesDeMonUserEnCours.length > 0) {
          //pour chaque trace, on va pogner la 1ere qui fite...
          for (let index = 0; index < TracesDeMonUserEnCours.length; index++) {
            const TraceDeMonUser = TracesDeMonUserEnCours[index];

            //on double-check dans la liste des DVL valides si encore dispo...
            let LaDVLEnTraitement = this.listDVLOrders.find((x) => (x.SiteOrderID = TraceDeMonUser.DVL_SiteOrderID));
            //si la DVL pointé par la trace de mon usager est encore une DVL valide et active..on assigne...
            if (LaDVLEnTraitement) {
              this.currentSelectedDVL = LaDVLEnTraitement;
              IsDVLTrouvee = true;
              break; //FIN immédiat des tests : on a trouvé la DVL que l'usager en cours était en train de travailler...on la réouvre...
            }
          }

          if (IsDVLTrouvee) break;
        } //fin du test si on trouve des traces de traitements en cours....
        //******************************************************** */

        //si on est ici, il n'y a donc pas de trace 'pré-assigné'...on poursuit vers une assignation DVL standard

        //******************************************************** */
        //2eme méthode : on a pas trouvé de pending DVL (de traces d'expédition) par l'usager en cours..on va donc boucler les DVL une après l'autre et prendre la 1ere qui est valide pour sélectionner ici
        //on va essayer de prendre la 1ere qui est LIBRE...
        for (let element of this.listDVLOrders) {
          //on regarde si nous avons une trace d'expédition
          var TraceExpedition = ListTraceExpeditionPendingOrWorking.find((x) => x.DVL_SiteOrderID === element.SiteOrderID);

          //si on trouve une trace d'expedition qui est associé à MON usager, on assigne et on quitte..théoriquement on ne tombera pas dessus, car assigné plus haut
          if (TraceExpedition && TraceExpedition.SCAN_ctrl_UserName === this.authService.info.name) {
            this.currentSelectedDVL = element;
            IsDVLTrouvee = true;
            break; //le break ici ne fait que de sortir du LOOP du listing des DVL pour essayer la prochaines DVL..si elle est LIBRE....
          }

          //si on trouve une trace d'expédition assigné à la DVL testé, mais que ce n'est PAS notre usager, on va passer à la prochaine...
          if (
            TraceExpedition &&
            TraceExpedition.SCAN_ProcessingStatus.toString() === enumItemTraceExpedition_Statut_String.WORKING &&
            TraceExpedition.SCAN_ctrl_UserName !== this.authService.info.name
          ) {
            continue;
          }

          //ici, on va essayer d'assigner les traces PENDINGS (qui arrive du SCAN1... ce sera donc un autre UserName assurément)
          if (TraceExpedition && TraceExpedition.SCAN_ProcessingStatus.toString() === enumItemTraceExpedition_Statut_String.SCAN1_PENDING) {
            this.currentSelectedDVL = element;
            IsDVLTrouvee = true;
            break;
          }

          //dès qu'on en trouve une LIBRE, on BREAK.  à voir c'est dans quel scénarios que c'Est possible...
          if (element.SS_Tag_EnTraitementPieuvre === false) {
            this.currentSelectedDVL = element;
            IsDVLTrouvee = true;
            break;
          }
        }
        //******************************************************** */

        break; //ne jamais permettre d'itération du while  c'Est juste poru avoir un BREAK() facile à déclancher et sortir des testes d'assignations
      }
    }

    return IsDVLTrouvee;
  }

  /**
   *  Cette méthode permet d'aller chercher le détail du produit scanné initiallemet.  Permet aussi de convertir un upc/sku en sku
   */
  private async getProductInfo(Sku_or_upc): Promise<boolean> {
    let ProductNumberValide = Sku_or_upc;
    this.GlobalError_ClearMessages();
    this.barcodeScanned = '';

    //********************************************************* */
    //on va permettre de passer un code produit au format 9999-999
    if (Sku_or_upc !== undefined) {
      Sku_or_upc = Sku_or_upc.trim();
      if (Sku_or_upc.substring(4, 5) === '-' && Sku_or_upc.length === 8) {
        var FirstPart = Sku_or_upc.substring(0, 4);
        var SecondPart = Sku_or_upc.substring(5, 8);
        Sku_or_upc = FirstPart.concat(SecondPart);
        ProductNumberValide = Sku_or_upc;
      }
    }
    //********************************************************* */

    //********************************************************* */
    //si c'est un barcode, on va tenter immédiatement de convertir ça en code produit...
    if (this.productsService.isUpc(Sku_or_upc)) {
      this.barcodeScanned = Sku_or_upc;
      var data = await lastValueFrom(this.productsService.getNoProductFromUpc(Sku_or_upc));
      if (data.length !== 0) {
        ProductNumberValide = data[0].sku;
      } else {
        let tmpMesg = 'Le sku/barcode est introuvable.';
        this.GlobalError_AddError(tmpMesg);
      }
    }
    //********************************************************* */

    //********************************************************* */
    if (ProductNumberValide !== undefined) {
      var myProductInfo = await lastValueFrom(this.productsService.GetProductDetail(ProductNumberValide));

      if (myProductInfo.length !== 0) {
        let MyItem: Item = {
          SkuOrig: ProductNumberValide,
          Title: myProductInfo[0].ProductShortDescFR,
          imageUrl: myProductInfo[0].MainImageURL,
          SkuCleaned: myProductInfo[0].ProductNo,
        };

        this.CurrentProductInfo = MyItem;
      } else {
        let tmpMesg = 'Le sku/barcode est introuvable.';

        this.GlobalError_AddError(tmpMesg);
      }
    }
    //********************************************************* */

    return this.globalErrorMessage === '';
  }

  /**Cette méthode va aller chercher la boite qui est la meilleure pour emballer la DVL en cours */
  private async UpdateCurrentBoxSelected() {
    try {
      let suggestedBox = '';

      //*************************
      //PLAN-A - validation de la boite selon les dimensions Shipstation.  on regarde donc les dimension de Shisptation
      let MyShipstationPackage = this.GetBoiteByDimension(this.CurrentShipstationOrder);
      if (MyShipstationPackage !== undefined) {
        suggestedBox = MyShipstationPackage.PackageShortCode;
      } else {
        let DimensionsText = `${this.CurrentShipstationOrder?.dimensions?.length} X ${this.CurrentShipstationOrder?.dimensions?.width} X ${this.CurrentShipstationOrder?.dimensions?.height}`;

        let error = `Aucune boite ne correspond aux dimensions Shipstation (${DimensionsText})...Utilisation du format channelAdvisor`;
        this.GlobalError_AddError(error, false);
      }
      //*************************

      //*************************
      // PLAN-B - Récupération des attributs du produit de Channel Advisor
      if (suggestedBox == '') {
        let mySku = this.listItems[0].SkuOrig;
        let myAttributes = '["A0 BOITE", "A0 BOITE OVERIDE"]';
        let listeAttributs = await lastValueFrom(this.channelAdvisorService.GetProductAttributes(mySku, myAttributes));
        // Si on a overridé la boîte de ce produit, on choisit la nouvelle valeur
        let boite = listeAttributs.find((attribut) => attribut.Name == 'A0 BOITE OVERIDE');

        // Si pas d'override, on prend la valeur d'origine
        if (!boite) boite = listeAttributs.find((attribut) => attribut.Name == 'A0 BOITE');

        // Si aucune valeur de boîte renseignée pour ce produit
        if (!boite || boite.Value == '') {
          this.GlobalError_AddError('Aucune boîte renseignée pour ce produit dans Channel Advisor.', false);
        } else suggestedBox = boite.Value;
      }
      //*************************

      //si rendu ici, nous n'avons pas de boite, on mets un '-'
      if (suggestedBox == '') suggestedBox = '-';

      this.nomBoiteSuggeree = suggestedBox;
      this.filtreListeBoites();
    } catch (err) {
      this.GlobalError_AddError('Erreur lors de la récupération de la boîte de Channel Advisor.', false);
      console.error('Erreur lors de la récupération de la boîte de Channel Advisor. ' + err);
      this.nomBoiteSuggeree = '-';
    }
  }

  private async getListeBoites() {
    // this.listeToutesBoites = await this.packageTypesService.getAll().toPromise();
    this.packageTypesService.getAll().subscribe((data) => {
      this.listeToutesBoites = data['value'];
    });
  }

  private filtreListeBoites() {
    // Liste de boîtes sans celle actuellement affichée (pour la modifier si on veut)
    this.listeBoitesFiltree = this.listeToutesBoites.filter((b) => b.PackageShortCode != this.nomBoiteSuggeree);
  }

  /**
   * Lors du clic sur "Modifier la boîte"
   */
  async onClickModifierBoite() {
    let mySku = this.listItems[0].SkuOrig;
    let myAttribut = 'A0 BOITE OVERIDE';
    let myValeur = this.nomBoiteSelectionnee;

    await lastValueFrom(this.channelAdvisorService.UpdateBoiteProduit(mySku, myAttribut, myValeur));

    this.nomBoiteSuggeree = this.nomBoiteSelectionnee;
    this.filtreListeBoites();
  }

  /**
   * Lors du clic sur le bouton de deconnexion.
   */
  onLogout(): void {
    this.authService.logout();
  }

  resetInterfaceData(MustEmptyProductAlso: boolean = false): void {
    this.CurrentProductInfo = {} as Item; //info sur le produit en cours
    this.currentTracesId = [];
    this.CurrentShipStationShipmentID = undefined;
    this.ShipStationLabelWasPrinted = false;
    this.GlobalError_ClearMessages();
    this.noTablet = '';
    this.listItems = [];
    this.CurrentShipStationShipment_BAD = undefined;
    this.CurrentShipstationOrder = undefined;
    this.barcodeScanned = '';
    this.currentTraceExpeditionUsed = undefined as ItemExpeditionTrace;
    this.listDVLOrders = [];
    this.currentSelectedDVL = undefined;
    this.ListExistingExpeditionTraceForThisDVL = [] as ItemExpeditionTrace[];
    this.IsTraceFromOtherUser = false;

    if (MustEmptyProductAlso) this.formScan2.setValue({ data: '' });
  }

  /**
   *
   * @returns
   */
  public async onPrintShipStationLabel(): Promise<any> {
    var NbPrint = this.NbTotalImpression();

    //si il y a plus qu'un print de déjà fait sur cette DVL
    if (NbPrint > 0) {
      let MyMsg = 'Attention!<br/><br/>Il semble que votre DVL ait déjà été imprimée <b>' + NbPrint + '</b> fois.';
      MyMsg += '<br/><br/>Veuillez indiquer ici bas le motif de votre ré-impression';

      //**********************************************
      //on prépare les données de l'Problem
      let MyApidata: APIPostProblem = {
        productNumber: this.CurrentProductInfo.SkuCleaned,
        ScannedBarCode: this.barcodeScanned,
        productDescription: this.CurrentProductInfo.Title,
        ShipStation_OrderID: this.CurrentShipstationOrder?.orderId,
        SiteOrderID: this.CurrentShipstationOrder?.orderNumber,
        fonctionOrig: Station.SCAN2_EMBALLEUR, //on indique que l'Problem provient du scan1
        stationOrig: this.CurrentUserInfo.stationCode,
        userOrig: this.CurrentUserInfo.name,
        displayedTitle: 'Protection ré-impression',
        qtyProblem: -1, //pour NE PAS afficher la zone de QTÉ
        displayedMessage: MyMsg,
        ProblemCode: 'REIMPRESSION',
      };

      //**********************************************

      //**********************************************
      // afficher le  popup
      const ProblemDialog = this.matDialog.open(DialogProblemComponent, {
        data: MyApidata,
      });
      MyApidata.displayedMessage = null;

      // Exécution de la réponse après la fermeture.
      var resultDialogProblem = await lastValueFrom(ProblemDialog.afterClosed()); //.subscribe(resultDialogProblem => {

      //si l'usager clique sur 'annuler', quitter immédiatement
      if (resultDialogProblem === null) {
        this.snack.open('Vous devez ABSOLUMENT fournir une justification à votre ré-impression.', 'OK', { duration: 3000 });
        return;
      }

      resultDialogProblem.qtyProblem = null;

      let postProblemPromise = await lastValueFrom(this.dvlService.PostProblemeScan2Emballage(resultDialogProblem));

      console.log('Resultat Problem : ' + postProblemPromise);
      this.snack.open('Une note de ré-impression a été ajoutée soumit correctement.  Vous pouvez poursuivre.', 'OK', { duration: 3000 });
    }

    try {
      let strsiteORderID: string = this.CurrentShipstationOrder.orderNumber ?? '(INCONNU)';
      let InTraceID: number = this.currentTraceExpeditionUsed?.id ?? -1;
      this.dvlService.Scan2Emballeur_ImpressionShipstationLabel(strsiteORderID, InTraceID).subscribe(
        (data) => {
          this.currentTraceExpeditionUsed = data;
          console.log("Marqueur d'impression DVL réussie");
          //on va aussi aller chercher toute traces existantes sur cette DVL...normalement, yen aura QU'UNE... mias possible d'en avooir plusieurse
          this.dvlService.GetExpeditionTraceBySiteOrderID(strsiteORderID).subscribe((data2) => {
            this.ListExistingExpeditionTraceForThisDVL = data2;
          });
        },
        (erreur) => {
          console.log("erreur au marqueur d'impression : " + erreur);
        }
      );
    } catch {}

    return this.onPrintShipStationLabelByDirectAccessToWebSite();
  }

  /**
   * Lors du clic sur l'imprimante, on télécharge le pdf que l'on ouvre dans un popup.
   */
  async onPrintShipStationLabelByCrawler(): Promise<any> {
    let MyOrderID = 0;

    this.CurrentShipStationShipments = await lastValueFrom(
      this.shipStationService.GetShipStationShipmentsBySiteOrderID(this.CurrentShipstationOrder.orderNumber)
    );
    if (this.CurrentShipStationShipments && this.CurrentShipStationShipments.length > 0) {
      MyOrderID = this.CurrentShipStationShipments[0].orderId;
    }

    this.dvlService.GetPDFLabelCrawlerMode(MyOrderID).subscribe(
      async (data) => {
        let blob = new Blob([data], { type: 'application/pdf' });
        let blobURL = window.URL.createObjectURL(blob);

        let iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

        this.ShipStationLabelWasPrinted = true;
      },
      async (error: HttpErrorMsg) => {
        //******************************************************** */
        let strMessage = "Une erreur est survenue lors de la tentative d'imprimer l'étiquette Shipstation.<br/><br/>";
        strMessage += 'Voulez-vous quand même marquer la DVL comme imprimée, pour que vous puissez la compléter?';
        strMessage += "<br/><br/>Détail de l'erreur : <br/><b>" + error.titreErreur + '<b><br/>' + error.messageErreurDetail;

        // Pop-up de confirmation avant de terminer
        let popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: "Erreur lors de la tentative d'imprimer le Label",
            texte: strMessage,
            affirmativeActionName: 'Oui, marquer comme imprimée',
            affirmativeActionColor: 'warn',
            dismissiveActionName: 'Non, ne rien faire',
            dismissiveActionColor: 'primary',
          },
        });

        var monRetour = await lastValueFrom(popupResult.afterClosed());
        if (monRetour !== true) {
          return;
        }
        this.ShipStationLabelWasPrinted = true;
        //******************************************************** */
      }
    );
  }

  /**
   * Lors du clic sur l'imprimante, on navigue directement sur la page de shipstaiton, à la page du PDF
   */
  onPrintShipStationLabelByDirectAccessToWebSite() {
    let MyShipmentID = 0;
    this.shipStationService.GetShipStationShipmentsBySiteOrderID(this.CurrentShipstationOrder.orderNumber).subscribe((data) => {
      this.CurrentShipStationShipments = data;
      MyShipmentID = this.CurrentShipStationShipments[0].shipmentId;
      let strURL = 'https://ship11.shipstation.com/api/download/label/' + MyShipmentID;

      this.goToLinkPopup(strURL);

      this.ShipStationLabelWasPrinted = true;
    });
  }

  /**
   * Permet d'ouvrir un nouvel onglet sur la page spécifié
   */
  public goToLink(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Permet d'ouvrir un nouvel onglet sur la page spécifié
   */
  public goToLinkPopup(url: string) {
    window.open(url, 'popup', 'width=600,height=600');
  }

  /**
   * Lors du clic sur "Sélection", on affiche un popup permettant de choisir la/les DVL
   */
  public async onClickChoisirDVL() {
    if (this.CurrentProductInfo?.SkuCleaned == '' || this.CurrentProductInfo?.SkuCleaned == undefined) {
      this.dialogService.SimpleErrorAlert('Vous devez absolument saisir un code de produit pour ensuite choisir une DVL.', 'Code de produit invalide');
      return;
    }

    //**********************************************
    // afficher le  popup
    const SelectionDialog = this.matDialog.open(DialogSelectionDvlComponent, {
      data: {
        productNumber: this.CurrentProductInfo.SkuCleaned,
        productDescription: this.CurrentProductInfo.Title,
      },
    });

    // Exécution de la réponse après la fermeture.
    var resultDialogSelection = await lastValueFrom(SelectionDialog.afterClosed());
    //si l'usager clique sur 'CONFIRMER'
    if (resultDialogSelection != null) {
      this.nomBoiteSuggeree = '-';
      if (this.CurrentShipstationOrder !== undefined) {
        let CurrentTraceToAbort = this.currentTraceExpeditionUsed?.id ?? -1;
        await lastValueFrom(
          this.dvlService.Scan2Emballeur_AnnulerTraitementDVL(this.CurrentShipstationOrder.orderNumber, 'CHANGEMENT_DVL', CurrentTraceToAbort)
        );
      }
      let DVLSelectionnee = resultDialogSelection;
      let mySiteOrdeRID = DVLSelectionnee.SiteOrderID;
      await this.GetDetailsDVL(mySiteOrdeRID);

      this.UpdateCurrentBoxSelected();
    }
  }

  /**
   * Lors du clic sur le bouton problème
   */
  public async onClickProblem(): Promise<any> {
    if (this.CurrentProductInfo?.SkuCleaned == '' || this.CurrentProductInfo?.SkuCleaned == undefined) {
      this.dialogService.SimpleErrorAlert('Vous devez absolument saisir un code de produit pour noter un problème.', 'Code de produit invalide');
      return;
    }

    //veut-on récupérer la ligne de réception en cours si, par exemple, c'est la ligne DVL en cours qui contient un produit cassé??

    //si plus qu'une sélection....

    let MyMsg: string = 'Attention!  Vous vous apprêtez à noter  un problème.  Info :';
    MyMsg += '<br/><br/>Produit sélectionné : #' + this.CurrentProductInfo.SkuCleaned + '(' + this.CurrentProductInfo?.Title + ')';
    MyMsg += "<br/><br/>Veuillez choisir un motif d'Problem et une QUANTITÉE d'Problem (par défaut, 1)";

    //**********************************************
    //on prépare les données de l'Problem
    let MyApidata: APIPostProblem = {
      productNumber: this.CurrentProductInfo.SkuCleaned,
      ScannedBarCode: this.barcodeScanned,
      productDescription: this.CurrentProductInfo.Title,
      ShipStation_OrderID: this.CurrentShipstationOrder?.orderId,
      SiteOrderID: this.CurrentShipstationOrder?.orderNumber,
      fonctionOrig: Station.SCAN2_EMBALLEUR, //on indique que l'Problem provient du scan1
      stationOrig: this.CurrentUserInfo.stationCode,
      userOrig: this.CurrentUserInfo.name,
      qtyProblem: 1,

      displayedMessage: MyMsg,
    };

    //**********************************************

    //**********************************************
    // afficher le  popup
    const ProblemDialog = this.matDialog.open(DialogProblemComponent, {
      data: MyApidata,
    });
    MyApidata.displayedMessage = null;

    // Exécution de la réponse après la fermeture.
    var resultDialogProblem = await lastValueFrom(ProblemDialog.afterClosed()); //.subscribe(resultDialogProblem => {
    //si l'usager clique sur 'CONFIRMER'
    if (resultDialogProblem !== null) {
      var resultat;

      let postProblemPromise = lastValueFrom(this.dvlService.PostProblemeScan2Emballage(resultDialogProblem));

      let annulerTraitementDVLPromise = Promise.resolve();
      if (this.CurrentShipstationOrder) {
        let CurrentTraceToAbort = this.currentTraceExpeditionUsed?.id ?? -1;
        annulerTraitementDVLPromise = await lastValueFrom(
          this.dvlService.Scan2Emballeur_AnnulerTraitementDVL(this.CurrentShipstationOrder.orderNumber, 'CANCELLATION', CurrentTraceToAbort)
        );
      }
      Promise.all([postProblemPromise, annulerTraitementDVLPromise]).then((retour) => {
        resultat = retour[0];
      });

      console.log('Resultat Problem : ' + resultat);
      this.snack.open('Le problème a été soumit correctement.  Vous pouvez poursuivre.', 'OK', { duration: 3000 });
      this.resetInterfaceData(true);
    }
    //**********************************************
  }

  /**
   * Lors du clic sur « terminer ».
   */
  public async onFinish() {
    let production = environment.production;
    let environmentName = environment.environmentName;
    if (this.IsTraceFromOtherUser === true) {
      let strMessage = 'La DVL sélectionnée est assignée à un autre usager.<br/><br/>Vous ne pouvez pas la compléter ici.';
      strMessage += "<br/><br/>Pour fermer la trace, vous devrez aller dans l'interface de gestion des traces d'expéditions et en refaire une autre.";

      this.dialogService.SimpleErrorAlert(strMessage, 'DVL assignée à un autre usager');

      return;
    }

    //si le shipstationLabel n'avait pas été imprimééé
    if (this.ShipStationLabelWasPrinted === false) {
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Scan2 invalide - manque impression',
          texte: "Il faut imprimer l'étiquette Shipstation avant de poursuivre.",
          mainIcon: 'warning',
          showDismissiveButton: false,
          affirmativeActionName: 'OK',
        },
      });
      return;
    } else {
      //******************************************************** */

      // Pop-up de confirmation avant de terminer
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Terminer le Scan2',
          texte: "Êtes vous certain de vouloir marquer cette commande DVL comme étant '02-TERMINÉ' dans Shipstation ?",
          affirmativeActionName: 'Oui, terminer',
          affirmativeActionColor: 'warn',
          dismissiveActionName: 'Non, annuler',
          dismissiveActionColor: 'primary',
        },
      });

      var monRetour = await lastValueFrom(popupResult.afterClosed());
      if (monRetour !== true) {
        return;
      }
      //******************************************************** */

      //******************************************************** */
      //si nous ne sommes pas en production, avertir l'usager des impacts IRRÉVERSIBLE de ses ACTIONS [c'est JP qui écrit ça]
      if (production === false) {
        popupResult = this.matDialog.open(PopConfirmComponent, {
          data: {
            titre: 'Terminer le Scan2',
            texte:
              "Vous n'êtes pas en environnement de production (" +
              environmentName +
              '). ' +
              'Ces modifications vont être appliquées dans ShipStation. ' +
              'Êtes-vous sûr de vouloir continuer ?',
            affirmativeActionName: 'Oui, continuer',
            affirmativeActionColor: 'warn',
            dismissiveActionName: 'Non, annuler',
            dismissiveActionColor: 'primary',
          },
        });

        monRetour = await lastValueFrom(popupResult.afterClosed());
        if (monRetour !== true) {
          return;
        }
      }
      //******************************************************** */

      var dataRetour = await lastValueFrom(this.dvlService.Scan2Emballeur_CompleteDVL(this.CurrentShipstationOrder.orderNumber, false));
      console.log(dataRetour);

      this.snack.open('La commande en ligne a été complétée correctement.', '', { duration: 3000 });
      this.resetInterfaceData(true);
      this.setFocusOnSKUInput();
    }
  }

  /**
   * Lors du clic sur « Annuler »
   */
  public async onCancel() {
    if (this.IsTraceFromOtherUser === true) {
      let strMessage =
        "La DVL sélectionnée est assignée à un autre usager.<br/><br/>L'annulation que vous faite ici  ne va pas ANNULER la trace mais simplement fermer la fenêtre.";
      strMessage += "<br/><br/>Pour fermer la trace, vous devrez aller dans l'interface de gestion des traces d'expéditions.";

      this.dialogService.SimpleErrorAlert(strMessage, 'DVL assignée à un autre usager');
      this.snack.open('Le Scan2 a bien été annulé.', '', { duration: 3000 });
      this.resetInterfaceData(true);

      return;
    }

    // Popup de confirmation
    let popupResult = this.matDialog.open(PopConfirmComponent, {
      data: {
        titre: 'Annuler le Scan2',
        texte: 'Êtes-vous sûr de vouloir abandonner le traitement de cette DVL ?<br/>' + 'La réservation sera retirée',
        affirmativeActionName: 'Oui, annuler',
        affirmativeActionColor: 'warn',
        dismissiveActionName: 'Non, continuer',
        dismissiveActionColor: 'primary',
      },
    });

    let monRetour = await lastValueFrom(popupResult.afterClosed());
    if (monRetour !== true) {
      return;
    }

    let CurrentTraceToAbort = this.currentTraceExpeditionUsed?.id ?? -1;
    // Si OK, retrait de la réservation de cette DVL
    await lastValueFrom(this.dvlService.Scan2Emballeur_AnnulerTraitementDVL(this.CurrentShipstationOrder.orderNumber, 'CANCELLATION', CurrentTraceToAbort));

    this.snack.open('Le Scan2 a bien été annulé.', '', { duration: 3000 });
    this.resetInterfaceData(true);
    this.setFocusOnSKUInput();
  }

  /** Va chercher la boite (ou PackageType) qui correspond exactemetn aux dimensions de la commande shipstation passée en paramètre */
  private GetBoiteByDimension(myOrder: ShipStation_Orders): PackageTypes {
    //#region   Compréhension du code
    /*
    1 - Dans shipstation, j'ai 8 x 5 x 2
    2 - mais ma boites que je voudrait est    5 x 2 x 8
    3 - Ce sont les BONNES dimensions, mais pas dans le bon ordre.
    4 - Solution : trier les 2 'dimensions' en ordre ascendant, et tester ça
//#endregion
    */

    //on va trier les 3 dimensions de Shipstation en ordre ASCENDANT..
    let OrderDimensionsSortedAsc = [myOrder.dimensions.length, myOrder.dimensions.height, myOrder.dimensions.width];
    OrderDimensionsSortedAsc = OrderDimensionsSortedAsc.sort((n1, n2) => n1 - n2);

    //notre boite qui va matcher
    let myMatchingPackage: PackageTypes = undefined;

    //on va boucler sur les boites dispo ,
    this.listeToutesBoites.forEach((x) => {
      //on tri les 3 dimensions de notre boite
      let BoxDimensionsSortedAsc = [x.LengthInch, x.HeighInch, x.WidthInch];
      BoxDimensionsSortedAsc = BoxDimensionsSortedAsc.sort((n1, n2) => n1 - n2);

      //on demande un match parfait entre les 2 arrays [la liste des 3 dimensions du produit et la liste des 3 dimensions de ma boite]
      let perfectMatch = BoxDimensionsSortedAsc.every((dim, index) => dim === OrderDimensionsSortedAsc[index]);

      //si j'ai un match, c'est ma boite
      if (perfectMatch === true) {
        myMatchingPackage = x;
        return;
      }
    });

    return myMatchingPackage;
  }

  /**
   * Cette méthode est responsable d'aller chercher le détail d'une DVL (produits, images, etc..),
   *  ce qui va la faire afficher..
   */
  private async GetDetailsDVL(siteOrderID: string) {
    this.GlobalError_ClearMessages();

    this.CurrentShipstationOrder = await lastValueFrom(this.shipStationService.GetOrderBySiteOrderID(siteOrderID));
    this.IsTraceFromOtherUser = false;

    // On essait de retirer la boite choise par le robot acheteur de label
    let MyOrderExtendedInfo = await lastValueFrom(this.orderService.OrderExtendedInfo_GetBySiteOrderId(siteOrderID));

    // …dont les IDs des tags
    let myTagIds = this.CurrentShipstationOrder.tagIds;
    // On récupère les infos de tous les tags depuis Shipstation
    let allTags = this.shipStationService.cachedShipstationTags;

    // On filtre pour avoir les infos complètes des tags correspondant aux tagIds de notre shipment
    this.CurrentShipstation_Tags = [];
    myTagIds.forEach((myTagId) => {
      let myTag = allTags.find((tag) => tag.tagId == myTagId);
      if (myTag != undefined) {
        this.CurrentShipstation_Tags.push(myTag);
      }
    });

    // Récupération des notes ShipStation
    this.CurrentShipStation_Notes = [];
    try {
      // Note From Buyer
      if (this.CurrentShipstationOrder.customerNotes)
        this.CurrentShipStation_Notes.push('Note From Buyer: "' + this.CurrentShipstationOrder.customerNotes + '"');
      // Gift Note
      if (this.CurrentShipstationOrder.giftMessage) this.CurrentShipStation_Notes.push('Gift Note: "' + this.CurrentShipstationOrder.giftMessage + '"');
      // Internal Note
      if (this.CurrentShipstationOrder.internalNotes) this.CurrentShipStation_Notes.push('Internal Note: "' + this.CurrentShipstationOrder.internalNotes + '"');
      // Custom Field 1
      if (this.CurrentShipstationOrder.advancedOptions.customField1)
        this.CurrentShipStation_Notes.push('Custom Field 1: "' + this.CurrentShipstationOrder.advancedOptions.customField1 + '"');
      // Custom Field 2
      if (this.CurrentShipstationOrder.advancedOptions.customField2)
        this.CurrentShipStation_Notes.push('Custom Field 2: "' + this.CurrentShipstationOrder.advancedOptions.customField2 + '"');
      // Custom Field 3
      if (this.CurrentShipstationOrder.advancedOptions.customField3)
        this.CurrentShipStation_Notes.push('Custom Field 3: "' + this.CurrentShipstationOrder.advancedOptions.customField3 + '"');
    } catch {}

    this.listItems = [];
    //pour chaque item du shipstation_Shipments....
    this.CurrentShipstationOrder.items.forEach((MyOrderItem) => {
      let CleansedProduct = this.productsService.ProductCleanser(MyOrderItem.sku);
      const item: Item = {
        //trackingNumber: MyOrderItem.trackingNumber,
        fulfillmentID: -1, // e.fulfillmentID,
        imageUrl: MyOrderItem.imageUrl, //
        quantity: MyOrderItem.quantity,
        Title: MyOrderItem.name,
        SkuCleaned: MyOrderItem.sku,
        SkuOrig: MyOrderItem.sku,
        Description: MyOrderItem.name, //meeh,  metre ca dans la table de scan...
        isMultipack: CleansedProduct.QtyMultiple > 1, //e.isMultipack,
        Inventaire_InStore: 0, // e.Inventaire_InStore,
        Inventaire_Debert: 0, // e.Inventaire_Debert,
        Inventaire_StJacob: 0, // e.Inventaire_StJacob,
        Inventaire_Wetawiskin: 0, // e.Inventaire_Wetawiskin,*/
        note: '',
      };
      this.listItems.push(item);
      //this.multiple_cmd(data);
    });

    // On vérifie que la commande porte bien le flag "Ready To Go"
    let readyToGoResult = myTagIds.find((x) => x === 7322);
    if (readyToGoResult == undefined) {
      // Affichage du message
      let error = "La commande DVL trouvée ne semble pas avoir le flag Shipstation '00-Ready to GO'.";
      this.GlobalError_AddError(error, false);
    }

    // On vérifie que la commande ne porte pas déjà le flag "Traitement pieuvre en cours"
    let reserveResult = myTagIds.find((x) => x === 69844);
    if (reserveResult != undefined) {
      // Affichage du message
      let error = "La commande DVL trouvée semble déjà avoir le flag Shipstation 'Traitement Pieuvre en cours'";
      this.GlobalError_AddError(error, false);
      // On laisse les boutons activés car c'est peut-être le user actuel qui a commencé le traitement
    }

    // On vérifie que la commande ne porte pas déjà le flag "02 Terminé"
    let termineResult = myTagIds.find((x) => x === 57746);
    if (termineResult != undefined) {
      // Affichage du message
      let error =
        "La commande DVL trouvée semble déjà avoir le flag Shipstation '02-Terminé'<br/>Vous devrez ANNULER votre TRACE pour POURSUIVRE sur la prochaine DVL.";
      this.GlobalError_AddError(error, false);
    }

    this.dvlService
      .Scan2Emballeur_EntamerTraitementDVL(this.CurrentShipstationOrder.orderNumber, this.CurrentProductInfo.SkuCleaned)
      .pipe(
        catchError((erreur) => {
          this.currentTraceExpeditionUsed = undefined as ItemExpeditionTrace;

          //finaly!
          this.dvlService.GetExpeditionTraceBySiteOrderID(siteOrderID).subscribe((resultat) => {
            this.ListExistingExpeditionTraceForThisDVL = resultat;

            //on va tenter d'assigner la trace la plus adéquate, pour à tout le moins pouvoir noter le nombre d'impression...
            let statutToCheck = enumItemTraceExpedition_Statut_For_API[enumItemTraceExpedition_Statut_For_API.WORKING];
            this.ListExistingExpeditionTraceForThisDVL.forEach((x) => {
              let strStatut = '';
              if (x.SCAN_ProcessingStatus) strStatut = x.SCAN_ProcessingStatus.toString();
              if (strStatut === 'WORKING') this.currentTraceExpeditionUsed = x;
            });

            if (this.currentTraceExpeditionUsed === undefined)
              this.currentTraceExpeditionUsed = this.ListExistingExpeditionTraceForThisDVL[this.ListExistingExpeditionTraceForThisDVL.length - 1];
          });
          if (erreur.statusCode && erreur.statusCode === 409) {
            let strMessage = erreur.messageErreurDetail;

            this.IsTraceFromOtherUser = true;

            let error = '<br/>Conflit - La DVL actuellement sélectionnée semble être assignée à un AUTRE usager.  Vos actions seront limitées.';
            this.GlobalError_AddError(error, false);

            this.dialogService.SimpleErrorAlert(strMessage, 'Erreur de conflit sur la DVL', 'warning', 'warn');
          }

          throw erreur;
        })
      )
      .subscribe((MyResultat) => {
        //on sélectionne la trace qui est retournée par l'assignation
        this.currentTraceExpeditionUsed = MyResultat;

        //finaly!
        this.dvlService.GetExpeditionTraceBySiteOrderID(siteOrderID).subscribe((resultat) => {
          this.ListExistingExpeditionTraceForThisDVL = resultat;
        });
      });
  }

  public NbTotalImpression(): number {
    var MonCalc = this.ListExistingExpeditionTraceForThisDVL.reduce((MyTotal, { SCAN_LabelPrinted_Count }) => MyTotal + (SCAN_LabelPrinted_Count ?? 0), 0);
    return MonCalc;
  }

  // Dans le cas d'un multipack, extrait la quantité du SKU (NNNNNNNxYY)
  GetTotalItemQuantity(sku: string, quantity: number): number {
    // Si pas multipack, on garde la quantité originale
    if (!sku.includes('x')) return quantity;

    // Extrait la quantité après le 'x' du sku
    let qtyParItemStr = sku.substring(sku.indexOf('x') + 1);
    let qtyParItem = parseFloat(qtyParItemStr);

    // Si on ne trouve pas de nombre valide après le 'x', on garde la quantité originale
    if (isNaN(qtyParItem)) return quantity;

    return quantity * qtyParItem;
  }

  /**Permet de vider le label d'erreur global */
  private GlobalError_ClearMessages() {
    this.globalErrorMessage = '';
  }

  /**Permet d'ajouter une ligne d'Erreur */
  private GlobalError_AddError(strMessage: string, clearBefore: boolean = true) {
    if (clearBefore) this.GlobalError_ClearMessages();

    if (this.globalErrorMessage !== '') this.globalErrorMessage += '<br/>';
    this.globalErrorMessage += strMessage;
  }
}
