// Concatène les infos disponibles sur le calcul de profit en une string affichable
import { Pipe, PipeTransform } from '@angular/core';
import { ShipStation_Shipments } from 'src/app/models/shipstation/shipstation-shipment';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';

@Pipe({
  name: 'convertStringToShipstationShipment',
})
export class convertStringToShipstationShipmentPipe implements PipeTransform {
  transform(ratesData: string): ShipStation_Shipments {
    let shipments: ShipStation_Shipments = JSON.parse(ratesData);
    return shipments;
  }
}
