<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="main-page-container">
  <div class="listpage-container">
    <!--Grosse barrure indique que des changemnets sont en cours-->
    <div *ngIf="childHaveChanges" class="modal-preventChange" (click)="popUpConfirmDiscard()">Changements détectés...</div>
    <div class="ss-list-button-container"></div>

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      filterable="menu"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="modifier($event)"
    >
      <!-- COLUMNS -->

      <!-- PickListHeaderID -->
      <kendo-grid-column field="PickListHeaderId" title="ID Entête" [width]="18" [minResizableWidth]="18" filter="numeric"></kendo-grid-column>

      <!-- PickListDetailId -->
      <kendo-grid-column field="PickListDetailId" title="ID Détail" [width]="15" [minResizableWidth]="15" filter="numeric"></kendo-grid-column>

      <!-- SplittingIdentifier -->
      <kendo-grid-column
        field="SplittingIdentifier"
        title="Idenficateur de séparaison"
        [width]="20"
        [minResizableWidth]="20"
        filter="numeric"
      ></kendo-grid-column>

      <!-- TotalReadyToGo -->
      <kendo-grid-column field="TotalReadyToGo" title="Ready to go" [width]="20" [minResizableWidth]="20" filter="numeric"></kendo-grid-column>

      <!-- QuantityToPick -->
      <kendo-grid-column field="QuantityToPick" title="Quantité à ramasser" [width]="20" [minResizableWidth]="20" filter="numeric"></kendo-grid-column>

      <!-- QuantityPicked -->
      <kendo-grid-column field="QuantityPicked" title="Quantité ramassée" [width]="20" [minResizableWidth]="20" filter="numeric"></kendo-grid-column>

      <!-- TotalQtyOnHandInStore -->
      <kendo-grid-column field="TotalQtyOnHandInStore" title="Quantité Instore" [width]="15" [minResizableWidth]="15" filter="numeric"></kendo-grid-column>

      <!-- TotalQtyOnHandOutStore -->
      <kendo-grid-column field="TotalQtyOnHandOutStore" title="Quantité Outstore" [width]="15" [minResizableWidth]="15" filter="numeric"></kendo-grid-column>
    </kendo-grid>
  </div>
  <app-picklist-detail-form
    #myChildForm
    *ngIf="showChildForm"
    [currentItem]="selectedItem"
    class="formpage-container"
    (submitEmitterChildHaveChanges)="onChangeChildState($event)"
    (submitEmitterForceRefresh)="OnChildAskForForceRefresh($event)"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)"
  ></app-picklist-detail-form>
</div>
