export class UserDefinedRates {
  UserDefinedRateId: number;
  RateMainCode: string;
  RateSubCode1: string;
  RateAmount: number;
  EffectiveDate: Date;
  Ctrl_DateInserted: Date;
  Ctrl_InfoInserted: string;
  Ctrl_DateUpdated: Date;
  Ctrl_InfoUpdated: string;
}
