import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { UtilService } from './util.service';
import { State, toODataString } from '@progress/kendo-data-query';

import { InvoiceAPHeaderSync } from '../models/supplier/InvoiceApSync';
import { InvoicesAPProcessingOptions } from '../models/supplier/InvoicesAPProcessingOptions';

@Injectable({
  providedIn: 'root',
})
export class InvoiceAPService {
  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  BASE_URL = `${environment.apiUrl}`;

  public InvoicesAP: ReplaySubject<any> = new ReplaySubject<any>(1);

  odata_Rates(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}InvoiceAP?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.InvoicesAP.next(response);
      })
    );
  }

  get(id: number): Observable<InvoiceAPHeaderSync> {
    return this.httpClient.get<InvoiceAPHeaderSync>(`${this.BASE_URL}/${id}`);
  }

  LaunchAccountPayableInvoiceProcessing(param_InvoiceApConfig: InvoicesAPProcessingOptions): Observable<any> {
    let MyBody: any;
    //si on veut retraiter une ou des factures précises..
    if (param_InvoiceApConfig.ReprocessSpecificInvoicesById && param_InvoiceApConfig.ReprocessSpecificInvoicesById.length > 0) {
      MyBody = {
        AllowSupplierDataExtraction: false,
        AllowERPInvoiceAPCreation: true,
        ReprocessSpecificInvoicesById: param_InvoiceApConfig.ReprocessSpecificInvoicesById,
      };
    } else {
      MyBody = {
        DateStart: param_InvoiceApConfig.dateStart.toISOString().substring(0, 10),
        DateEnd: param_InvoiceApConfig.dateEnd.toISOString().substring(0, 10),
        AllowERPInvoiceAPCreation: param_InvoiceApConfig.AllowERPInvoiceAPCreation,
        AllowSupplierDataExtraction: param_InvoiceApConfig.AllowSupplierDataExtraction,
        SupplierCodeList: ['HOMEHARDWARE_CONNECT'],
      };
    }
    let url = environment.apiUrl + 'supplier/InvoiceAP/LaunchAccountPayableInvoiceProcessing';

    return this.httpClient.post(url, MyBody);
  }
}
