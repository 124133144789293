<div class="globalPopupClass">
  <div class="MainTitleSection">
    <mat-icon style="align-self: center">warning</mat-icon>
    <h3 style="padding-left: 20px; align-self: center">{{ displayedTitle }}</h3>
  </div>

  <div mat-dialog-content>
    <p [innerHTML]="[displayedMessage]"></p>
    <mat-form-field appearance="outline">
      <mat-label>Motif du problème</mat-label>
      <mat-select [(ngModel)]="data.ProblemCode" required>
        <mat-option *ngFor="let myItem of ListScanProblemTypes" [value]="myItem.ProblemCode">
          <span [title]="myItem.ProblemDescription">{{ myItem.ProblemCode }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline" *ngIf="data.qtyProblem !== -1">
      <mat-label>Qté problème</mat-label>
      <div style="display: flex; flex-direction: row">
        <input matInput class="input-stroked" type="text" [(ngModel)]="data.qtyProblem" (click)="openNumericKeyboard()" />

        <button mat-flat-button (click)="scanQte_AddOne()">
          <mat-icon>add_box</mat-icon>
        </button>
        <button mat-flat-button (click)="scanQte_RemoveOne()">
          <mat-icon>indeterminate_check_box</mat-icon>
        </button>
      </div>
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea [(ngModel)]="data.note" matInput></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions style="display: flex; flex-direction: row">
    <button mat-raised-button style="flex: 50" (click)="onAbortClick()">Annuler</button>
    <button mat-raised-button color="warn" style="flex: 50" (click)="onConfirmClick()">Confirmer le problème</button>
  </div>
</div>
