export class PickListHeader {
  public PickListHeaderID: number;
  public NumberOfSplits: number;
  public StartDate: Date;
  public EndDate: Date;
  public OutputType: string;
  public CreationUserId: string;
  public Ctrl_DateInserted: Date;
  public Ctrl_InfoInserted: string;
  public Ctrl_DateUpdated: Date;
  public Ctrl_InfoUpdated: string;
}
