import { Component, OnInit, inject } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { OrderFinanceService } from '../../../order/order.service';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/service/util.service';
import { ShipStation_ShippingRatesData } from 'src/app/models/shipstation/shipstation-shipping-rates-data';
import { DVLService } from 'src/app/service/DVL.service';
import { OrderExtendedInfo } from '../../../order/models/OrderExtendedInfo.model';
import { SelectionExpeditionComponent } from 'src/app/component/expedition/achat-labels/selection-expedition/selection-expedition.component';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@progress/kendo-angular-dialog';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';
import { ShippingService } from '../../shipping.services';
import { CarrierShipmentInvoicesSummary } from '../../models/CarrierShipmentInvoicesSummary.model';

@Component({
  selector: 'carrier-shipment-invoices-summary',
  templateUrl: './carrier-shipment-invoices-summary.component.html',
  styleUrls: ['./carrier-shipment-invoices-summary.component.scss'],
})
export class CarrierShipmentInvoicesSummaryComponent implements OnInit {
  isLoading = false;

  //************************************ */
  //#region  DEPENDENCY INJECTION (DI) -
  private utilService = inject(UtilService);
  public _shippingService = inject(ShippingService);
  private matDialog = inject(MatDialog);
  private dialogService = inject(PopUpDialogService);
  //#endregion
  //************************************

  private subscription!: Subscription;

  //************************************
  //#region Variable pour le GRID [pagination, state, et  bla]
  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public state: State = {
    take: 100,
    skip: 0,
    sort: [
      {
        field: 'Shipstation_vs_EDIInvoices_Pct',
        dir: 'desc',
      },
    ],
    filter: {
      filters: [
        {
          filters: [
            {
              field: 'LastInvoiceDate',
              operator: 'gte',
              value: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            },
            {
              filters: [
                {
                  field: 'Shipstation_vs_EDIInvoices_Pct',
                  operator: 'gte',
                  value: 0.05,
                },
              ],
              logic: 'and',
            },
          ],
          logic: 'and',
        },
      ],
      logic: 'and',
    },
  };
  public totalItemCount = 0;
  //#endregion
  //************************************

  /** Constructeur */
  constructor() {
    //NOTE : la DI (dependency injection) se fait avec la nouvelle méthode (en tk, as of 2023) avec le verbe inject(module) (voir plus haut)
  }

  ngOnInit(): void {
    //on va chercher le nombre d'élément par page global
    this.state.take = this.utilService.getRowPerPage();

    // fetch product list
    this.refresh();

    // subscribe to data
    this.subscription = this._shippingService.CarrierInvoiceSummary.subscribe((response) => {
      this.gridView = {
        data: response.value as CarrierShipmentInvoicesSummary[],
        total: response['@odata.count'],
      };
    });
  }
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  dataStateChange(state: State) {
    this.state = state;
    this.refresh();
  }

  /** permet de vider les filtres kendo */
  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  /** Relance le rafraichissement des commandes à partir de oData */
  refresh() {
    this.isLoading = true;

    let MYStrinf = JSON.stringify(this.state);
    this._shippingService.odata_CarrierInvoiceSummary(this.state).subscribe((response) => {
      this.isLoading = false;
    });
  }

  /** Bouton permettant d'Afficher le pop-up de détail des calculs  */
  OnClickCalcul(currentOrder: OrderExtendedInfo) {
    if (currentOrder == null || currentOrder.ShippingLabel_Buying_LastShippingRatesCalcResults == null) {
      this.dialogService.SimpleErrorAlert('Vous devez absolument saisir un code de produit pour ensuite choisir une DVL.', 'Code de produit invalide');
      return;
    }

    // afficher le  popup
    this.matDialog.open(SelectionExpeditionComponent, {
      data: {
        orderId: currentOrder.shipstation_OrderID,
        CanOrderShipCanBeModified: false,
        ratesData: currentOrder.ShippingLabel_Buying_LastShippingRatesCalcResults,
      },
    });
  }
}
