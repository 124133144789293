export class PackageTypes {
  id: number;
  PackageShortCode: string;
  PackageDescription: string;
  LengthInch: number;
  WidthInch: number;
  HeighInch: number;
  DisplayIndex: number;
  PackageCost: number;
  IsActive: boolean;
  MaxWeightPound: number;
  UsableLengthInch: number;
  UsableWidthInch: number;
  UsableHeighInch: number;
  VolumetricWeight: number;
}
