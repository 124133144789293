import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListComponent } from '../../core/list/list.component';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ScanProblemTypes } from '../../../models/scanProblemTypes';
import { ScanProblemTypesService } from '../../../service/scanProblemTypes.service';

@Component({
  selector: 'app-scan-problem-types-list',
  templateUrl: './scanProblemTypes-list.component.html',
  styleUrls: ['./scanProblemTypes-list.component.scss'],
})
export class ScanProblemTypesListComponent extends ListComponent {
  showChildForm: boolean = true; //

  selectedItem: any; //représente l'entité actuellement sélectionnée

  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...

  childHaveChanges: boolean; //indique si le panneau ENFANT contient une modification
  needRefresh: boolean = false;

  TitreGlobalPage: string = 'Gestion des types de problème de SCAN';
  NomEntiteCourante: string = 'Types de problème SCAN';

  constructor(protected httpClient: HttpClient, private dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    super(httpClient);
    this.endpoint = 'ScanProblemType';
    this.IsOdataEndpoint = false;
    this.autoload = true;
    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
        hide: false, //pour l'instnat, on est obligé sinon le udpate casse...
      },
      {
        key: 'ProblemCode',
        nom: 'ProblemCode',
        type: 'string',
      },
      {
        key: 'ProblemDescription',
        nom: 'ProblemDescription',
        type: 'string',
        hide: true,
      },
      {
        key: 'DisplayIndex',
        nom: 'Ordre',
        type: 'number',
      },
      {
        key: 'IsScan1Enabled',
        nom: 'IsScan1Enabled',
        type: 'boolean',
      },
      {
        key: 'IsScan2EmballeurEnabled',
        nom: 'IsScan2EmballeurEnabled',
        type: 'boolean',
      },
    ];
    this.defaultOrderBy = 'DisplayIndex asc';
  }

  openForm(MyRow: any) {
    this.selectedItem = MyRow;

    this.showChildForm = true;
  }

  //evenement, issue du child, indiquant qu'on veut afficher ou masquer le FORM
  OnChildFormVisibilityChanged(val: boolean): void {
    if (val === false && this.childHaveChanges === true) this.popUpConfirmDiscard();
    else this.showChildForm = val;

    if (this.needRefresh === true) {
      this.launchRefresh();
      this.needRefresh = false;
    }
  }

  onChangeChildState(val: any): void {
    this.childHaveChanges = val;
    if (val === true) this.needRefresh = true;
    else this.needRefresh = false;
    this.cdRef.detectChanges(); //bidouille, car la valeur childHaveChanges pointe sur un Ngif..et que c'Est ca- https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  }

  //Ce event indique que l'enfant à demander au parent (nous) de forcer un refresh des datas.
  OnChildAskForForceRefresh(val: any): void {
    if (val === true) {
      this.launchRefresh();
      this.needRefresh = false;
    }
  }

  createNew() {
    this.selectedItem = null;
    this.showChildForm = true;
  }

  //ici, c'est qu'on a cliqué sur le panneau de protection de 'changement détecté'.  ca permet de faire un DISCARD
  popUpConfirmDiscard() {
    let test = 'bla';
    // pop dialog

    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Changements non sauvegardés',
        texte:
          'Attention! Des changements ont été détectés dans votre formulaire.  Voulez-vous IGNORER ces changementes ou POURSUIVRE la modification à droite?',
        affirmativeActionName: 'Poursuivre',
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Ignorer',
        dismissiveActionColor: 'warn',
      },
    });

    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        return;
      }

      this.showChildForm = false;
      this.childHaveChanges = false;
    });
  }
}
