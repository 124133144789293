<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>

  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    filterable="menu"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
        <mat-icon>delete</mat-icon>
        Vider les filtres
      </button>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>
    <!-- COLUMNS -->
    <kendo-grid-excel fileName="Products.xlsx"></kendo-grid-excel>

    <!-- ItemReceptionHeaderId -->
    <kendo-grid-column field="ItemReceptionHeaderId" title="ID en-tête" [width]="150" [minResizableWidth]="150" filter="numeric" format="0"></kendo-grid-column>

    <!-- ItemReceptionHeader.IsCompleted -->
    <kendo-grid-column field="ItemReceptionHeader.IsCompleted" title="Entête complétée?" [width]="150" [minResizableWidth]="150" filter="boolean">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.ItemReceptionHeader.IsCompleted; then Completed; else Notcompleted"></span>

        <ng-template #Completed> Vrai </ng-template>

        <ng-template #Notcompleted> Faux </ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- supplierInvoiceNumber -->
    <kendo-grid-column field="SupplierInvoiceNumber" title="#Facture fournisseur" [width]="125" [minResizableWidth]="125" filter="text"></kendo-grid-column>

    <!-- ProductNumber -->
    <kendo-grid-column field="ProductNumber" title="#Produit" [width]="110" [minResizableWidth]="110" filter="text"></kendo-grid-column>

    <!-- productDescription -->
    <kendo-grid-column field="ProductDescription" title="Description produit" [width]="200" [minResizableWidth]="110" filter="text"></kendo-grid-column>

    <!-- precalcPieuvreDestination -->
    <kendo-grid-column field="PrecalcPieuvreDestination" title="Pieuvre Destination" [width]="200" [minResizableWidth]="160" filter="text">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <my-dropdown-filter
          [filter]="filter"
          [data]="pieuvreDestinations"
          textField="Pieuvre Destination"
          valueField="PrecalcPieuvreDestination"
        ></my-dropdown-filter>
      </ng-template>
    </kendo-grid-column>

    <!-- hH_ConsumerNumber -->
    <kendo-grid-column field="HH_ConsumerNumber" title="#Client" [width]="110" [minResizableWidth]="110" filter="numeric" format="0"></kendo-grid-column>

    <!-- OrigWarehouseCode -->
    <kendo-grid-column field="OrigWarehouseCode" title="Code entrepôt" [width]="150" [minResizableWidth]="150" filter="text"></kendo-grid-column>

    <!-- OrigWarehouseSubCode -->
    <kendo-grid-column field="OrigWarehouseSubCode" title="SousCode entrepôt" [width]="120" [minResizableWidth]="120" filter="text"></kendo-grid-column>

    <!-- nbOfScanTrace -->
    <kendo-grid-column field="NbOfScanTrace" title="Nbr trace scan" [width]="120" [minResizableWidth]="120" filter="numeric" format="0"></kendo-grid-column>

    <!-- QtyToReceive -->
    <kendo-grid-column field="QtyToReceive" title="Qté à recevoir" [width]="120" [minResizableWidth]="120" filter="numeric" format="0"></kendo-grid-column>

    <!-- QtyReceived -->
    <kendo-grid-column field="QtyReceived" title="Qté reçue" [width]="120" [minResizableWidth]="120" filter="numeric" format="0"></kendo-grid-column>

    <!-- QtyRemaining -->
    <kendo-grid-column field="QtyRemaining" title="Qté Restante" [width]="110" [minResizableWidth]="110" filter="numeric" format="0"></kendo-grid-column>

    <!-- HH_ConsumerCode_Calc_ProcessPriority -->
    <kendo-grid-column
      field="PrecalcPieuvreDestinationSubCode"
      title="Souscode Pieuvre Destination"
      [width]="150"
      [minResizableWidth]="110"
      filter="text"
    ></kendo-grid-column>

    <!-- id -->
    <kendo-grid-column field="id" title="Id" [width]="110" [minResizableWidth]="110" filter="numeric" format="0"></kendo-grid-column>

    <!-- Traces -->
    <kendo-grid-column field="ItemReceptionHeaderId" title="Traces" [width]="150" [minResizableWidth]="120" [filterable]="false" [sortable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a mat-stroked-button color="primary" [routerLink]="['/', 'reception-trace', { ItemReceptionDetailId: dataItem.id }]">
          <mat-icon>description</mat-icon> Traces
        </a>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
