<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre"><mat-icon>business_center</mat-icon> {{ titre }}</h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>Code station</mat-label>
        <input matInput [(ngModel)]="currentItem.WorkStationCode" name="WorkStationCode" class="form-control" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Nom station</mat-label>
        <input matInput [(ngModel)]="currentItem.FullName" name="FullName" required />
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-raised-button (click)="detruire()" [disabled]="!currentItem?.id" style="margin: 0px 2px 2px 2px">
      <mat-icon>delete</mat-icon>Supprimer
    </button>

    <div style="float: right">
      <button type="submit" matInput mat-raised-button (click)="OnSauvegardeClick()" *ngIf="!myform.pristine" color="primary" style="margin: 0px 2px 2px 2px">
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="Deassigner()"
        *ngIf="currentItem.CurrentLoggedUser_StartTime !== null"
        style="margin: 0px 2px 2px 2px"
      >
        Déassigner
      </button>
      <button type="submit" matInput mat-raised-button (click)="annuler()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-raised-button (click)="annuler()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
