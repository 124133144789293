<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>

  <kendo-grid
    [data]="gridView"
    [sortable]="false"
    [pageable]="false"
    [filterable]="false"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange()"
  >
    <ng-template kendoGridToolbarTemplate>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>
    <!-- COLUMNS -->
    <kendo-grid-excel fileName="RapportFacturationSommaireAcomba.xlsx"></kendo-grid-excel>

    <!-- SS_OrderMonth -->
    <kendo-grid-column field="SS_OrderMonth" title="SS Mois Commande" [width]="150" [minResizableWidth]="150" filter="numeric" format="0"></kendo-grid-column>

    <!-- NbCommandes -->
    <kendo-grid-column
      field="NbCommandes_Total"
      title="Nb de Commandes Total"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="0"
    ></kendo-grid-column>
    <!-- NbCommandes -->
    <kendo-grid-column
      field="Nbcommande_MisMatch"
      title="Nb de Commandes Mismatch"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="0"
    ></kendo-grid-column>

    <!-- CA_AttributeCost_plus_35 -->
    <kendo-grid-column
      field="CA_costPlus35_All"
      title="CA Cost+3.5% Total"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="c2"
    ></kendo-grid-column>
    <!-- CA_AttributeCost_plus_35 -->
    <kendo-grid-column
      field="CA_costPlus35_Mismatch"
      title="CA Cost+3.5% MisMatch"
      [width]="150"
      [minResizableWidth]="150"
      filter="numeric"
      format="c2"
    ></kendo-grid-column>

    <kendo-grid-column field="SS_OrderMonth" title="Voir factures (mismatch)" [width]="150" [minResizableWidth]="150" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          mat-stroked-button
          color="primary"
          [routerLink]="['/', 'rapport-facturation-acomba', { SS_OrderMonth: dataItem.SS_OrderMonth, NbMoisDiff_AcombaMDP_vs_sommative: 'neq0' }]"
        >
          <mat-icon>description</mat-icon>Voir factures mismatch
        </a>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
