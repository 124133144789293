<mat-sidenav-container class="app-master-container">
  <!--SIDE NAV-->
  <mat-sidenav mode="side" [opened]="authService.isLogged && isSideNavOpen" disableClose>
    <app-sidenav [userRight]="authService.rights"></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <!--Toolbar en haut - sticky - la classe est DANS le componennt...-->
    <div id="SiteNavBar">
      <!-- Le seul but du DIV ici est que les devTools graphique 'voient' la section...typiquement les devTools ne reconnaissent pas bien les objets angular-->
      <app-navbar [userInfo]="authService.info"></app-navbar>
    </div>

    <!--Contenu principal de la page - pas d'overflow ici-->
    <div class="site-main-container">
      <router-outlet></router-outlet>
    </div>

    <!---->
    <div class="site-footer">
      <div style="background-color: aqua">mettre un footer si on veut</div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-loader></app-loader>
