<!-- titre -->
<h1 class="page-titre page-marge"><mat-icon>qr_code_scanner</mat-icon>Scan 2 Emballeur</h1>

<section id="header-search-bar">
  <!-- header, infos/actions user -->

  <div class="info-container bg-gris scan2">
    <div class="btn-selection">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!authService.rights.globalAdmin && !authService.rights.scan2EmballeurChangerDVL"
        (click)="onClickChoisirDVL()"
      >
        Choisir une DVL
      </button>
    </div>

    <form [formGroup]="formScan2" (ngSubmit)="OnSearchClick()">
      <span>SKU/BarCode: </span>
      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <input matInput #scan2SKUInput formControlName="data" placeholder="SKU / BarCode" required />
      </mat-form-field>
      <button mat-flat-button color="primary" [disabled]="formScan2.invalid || !BoutonRechercheActif" style="margin-left: 10px">Rechercher</button>
    </form>

    <div id="btn-probleme">
      <button mat-flat-button color="warn" (click)="onClickProblem()">Problème</button>
    </div>
  </div>
</section>

<!-- section avec le scan et info globale-->
<section id="section-sku" *ngIf="this.CurrentShipstationOrder" class="bg-gris">
  <!--1ere ligne avec SCAN et info commande-->
  <!--Section du SCAN-->
  <div>
    <span
      ><strong>Site Order ID : {{ this.CurrentShipstationOrder.orderNumber }}</strong></span
    >
    <div class="InfoImpressionShipstation">
      <div>
        Nombre de trace : {{ ListExistingExpeditionTraceForThisDVL.length }}
        <button
          mat-icon-button
          matTooltip="Visualiser toutes les LIGNES D'EXPÉDITION pour cette DVL"
          color="Basic"
          type="button"
          (click)="goToLink('/expedition-trace;DVL_SiteOrderID=' + this.CurrentShipstationOrder.orderNumber)"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div>Nombre d'impression: {{ NbTotalImpression() }}</div>
    </div>
  </div>
  <div class="scan-sku-container">
    <!-- Section pour les TAGS -->
    <div class="tags">
      <div class="tag" *ngFor="let tag of CurrentShipstation_Tags" [ngStyle]="{ 'background-color': tag.color }">
        <h4 class="tag__text">{{ tag.name }}</h4>
      </div>
    </div>
  </div>
</section>
<!--Section pour erreur (pleine largeur)-->
<div *ngIf="this.globalErrorMessage !== ''">
  <!--<div *ngIf="this.globalErrorMessage !== ''"> -->
  <p style="color: red" [innerHTML]="globalErrorMessage"></p>
</div>

<!-- Ne s'affiche que s'il y a des instructions données suite au signalement d'un problème. -->
<div id="instructionProblem" *ngIf="instructionProblem !== ''">
  {{ instructionProblem }}
  <!-- Retire le bandeau en vidant les instructions -->
  <button (click)="instructionProblem = ''">OK</button>
</div>

<!-- section avec les 2 colonnes: boite, produits-->
<section *ngIf="this.CurrentShipstationOrder !== undefined" class="scan2-col">
  <!--1ere colonne de la section avec l'info de la boite-->

  <div class="scan2-col1">
    <div>
      <p id="box-suggestion"><strong>Boite suggérée:</strong></p>
      <div id="boxSize">{{ nomBoiteSuggeree }}</div>
    </div>

    <div *ngIf="(authService.rights.globalAdmin || authService.rights.scan2EmballeurChangerBoite) && listItems.length == 1 && nomBoiteSuggeree != '-'">
      <mat-form-field>
        <mat-label>Choisir une autre boîte</mat-label>
        <mat-select name="select-boite" id="id-select-boite" [(ngModel)]="nomBoiteSelectionnee">
          <mat-option *ngFor="let boite of listeBoitesFiltree" value="{{ boite.PackageShortCode }}"
            >{{ boite.PackageShortCode }} - {{ boite.LengthInch }}x{{ boite.WidthInch }}x{{ boite.HeighInch }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="onClickModifierBoite()">Modifier la boîte</button>
    </div>
  </div>

  <!--2e colonne de la section avec les infos produits-->
  <div class="scan2-col2">
    <div *ngIf="isSpecialist">
      <div>Commande incomplète</div>
      <div>Emplacement de la commande <span>XXX XXX</span></div>
    </div>

    <!-- gestion du produit -->
    <div class="item items-list" *ngFor="let item of listItems">
      <div class="image-item">
        <a href="{{ item.imageUrl }}" target="_blank">
          <img src="{{ item.imageUrl }}" alt="Photo " class="image-produit-small" />
        </a>
      </div>
      <div class="descr-item">
        <h3>{{ item.Title }}</h3>
        <p>
          SKU:
          <u [ngClass]="item.SkuOrig.includes('x') ? 'sku-multipack' : 'sku-unique'">
            {{ item.SkuOrig }}
          </u>
        </p>
        <p>
          {{ item.Description }}
        </p>
        <p *ngIf="item.note !== ''" class="sectionNoteItem">{{ item.note }}</p>
        <p class="sectionQuantite">
          Quantité:
          <span class="spanQuantite">
            {{ item.quantity }}
          </span>
        </p>

        <p class="sectionQuantiteTotale">
          Quantité totale:
          <span [ngClass]="item.SkuOrig.includes('x') ? 'spanQuantiteTotaleMultipack' : 'spanQuantiteTotale'">
            {{ GetTotalItemQuantity(item.SkuOrig, item.quantity) }}
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="scan2-col3">
    <div class="shipstation-notes">
      <!-- Notes ShipStation -->
      <div class="shipstation-notes__div" *ngIf="this.CurrentShipStation_Notes?.length > 0">
        <h3>Notes ShipStation</h3>
        <ul>
          <li *ngFor="let note of this.CurrentShipStation_Notes">
            {{ note }}
          </li>
        </ul>
      </div>

      <div class="autres-actions">
        <!-- Info global sur la commande et cie-->
        <div *ngIf="this.CurrentShipstationOrder && this.CurrentShipstationOrder.orderNumber">
          <a target="_blank" href="https://ship11.shipstation.com/orders/all-orders-search-result?quickSearch={{ this.CurrentShipstationOrder.orderNumber }}">
            <img
              src="https://theme.zdassets.com/theme_assets/732720/c566d3c26d960aa4deea589deb45187b32c6ca2e.png"
              style="background-color: black"
              height="20px"
              alt=""
            />
          </a>
          <a
            target="_blank"
            href="https://complete.channeladvisor.com/Orders/OrderDetailV2.mvc?apid=12010161&invoiceId={{ this.CurrentShipstationOrder.orderKey }}"
          >
            <img src="https://s35545.pcdn.co/wp-content/uploads/2021/02/CA-Logo-1.png" style="background-color: white" height="25px" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Section du bas, avec les actions (boutons impression...)-->
<section class="confirmer-container">
  <div class="boutons-info-complementaire">
    <input type="text" [value]="noTablet" *ngIf="!ScanIsCompleted" />
    <!-- Actions en bas de page -->
    <button mat-flat-button class="annuler-btn" color="warn" (click)="onCancel()" [disabled]="this.CurrentShipstationOrder == undefined">
      <mat-icon>cancel</mat-icon> Annuler
    </button>
    <!-- <button mat-flat-button class="imprimer-btn" color="accent" (click)="onPrintShipStationLabelByDirectAccessToWebSite()" -->
    <button mat-flat-button class="imprimer-btn" color="accent" (click)="onPrintShipStationLabel()" [disabled]="this.CurrentShipstationOrder == undefined">
      <mat-icon class="MatIcon-smiley-performance">print</mat-icon> Imprimer
    </button>
    <button mat-flat-button class="terminer-btn" color="primary" (click)="onFinish()" [disabled]="this.CurrentShipstationOrder == undefined">
      <mat-icon>check</mat-icon> Terminer
    </button>
  </div>
</section>
