<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre">
      <mat-icon>business_center</mat-icon>
      {{ titre }}
    </h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>ID Entête</mat-label>
        <input matInput [(ngModel)]="currentItem.PickListHeaderId" name="PickListHeaderID" class="form-control" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>ID Détail</mat-label>
        <input matInput [(ngModel)]="currentItem.PickListDetailId" name="PickListHeaderID" class="form-control" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Numéro de commande</mat-label>
        <input matInput [(ngModel)]="currentItem.OrderNumber" type="string" name="OrderNumber" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Numéro de produit</mat-label>
        <input matInput [(ngModel)]="currentItem.ProductNumber" type="string" name="ProductNumber" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité à ramassée</mat-label>
        <input matInput [(ngModel)]="currentItem.QuantityToPick" type="number" name="NumberOfSplits" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité Ramassée</mat-label>
        <input matInput [(ngModel)]="currentItem.QuantityPicked" type="number" name="QuantityPicked" disabled="false" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité InStore</mat-label>
        <input matInput [(ngModel)]="currentItem.TotalQtyOnHandInStore" type="number" name="TotalQtyOnHandInStore" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Quantité OutStore</mat-label>
        <input matInput [(ngModel)]="currentItem.TotalQtyOnHandOutStore" type="number" name="TotalQtyOnHandOutStore" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Ready To Go</mat-label>
        <input matInput [(ngModel)]="currentItem.TotalReadyToGo" type="number" name="TotalReadyToGo" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Entrepôt Original</mat-label>
        <input matInput [(ngModel)]="currentItem.OriginalCAWarehouse" type="string" name="OriginalCAWarehouse" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Date de début</mat-label>
        <input matInput [(ngModel)]="currentItem.StartDate" type="string" name="StartDate" disabled="true" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Date de fin</mat-label>
        <input matInput [(ngModel)]="currentItem.EndDate" type="string" name="EndDate" disabled="true" />
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-stroked-button (click)="detruire()" [disabled]="true" style="margin: 0px 2px 2px 2px">
      <mat-icon>delete</mat-icon>Supprimer
    </button>

    <div style="float: right">
      <button type="submit" matInput mat-flat-button (click)="OnSauvegardeClick()" *ngIf="!myform.pristine" color="primary" style="margin: 0px 2px 2px 2px">
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button type="submit" matInput mat-stroked-button (click)="annuler()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-stroked-button (click)="annuler()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
