<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre">
    <mat-icon>{{ IconGlobalPage }}</mat-icon
    >{{ TitreGlobalPage }}
  </h1>
</div>

<div class="main-page-container">
  <div class="listpage-container">
    <!--Grosse barrure indique que des changemnets sont en cours-->
    <div *ngIf="childHaveChanges" class="modal-preventChange" (click)="popUpConfirmDiscard()">Changements détectés...</div>
    <div class="ss-list-button-container">
      <button mat-stroked-button (click)="createNew()" color="primary"><mat-icon>add</mat-icon> Ajouter un Combo</button>
    </div>

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      filterable="menu"
      [resizable]="true"
      [selectable]="true"
      scrollable="scrollable"
      class="ss-list-grid"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="modifier($event)"
    >
      <!-- COLUMNS -->
      <!-- id -->
      <kendo-grid-column field="id" title="Id" [width]="50" [minResizableWidth]="50" filter="numeric"></kendo-grid-column>

      <!-- Sku -->
      <kendo-grid-column field="Sku" title="Sku" [width]="50" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <!-- Quantity -->
      <kendo-grid-column field="Quantity" title="Quantité" [width]="50" [minResizableWidth]="50" filter="text"></kendo-grid-column>

      <!-- LengthStacked -->
      <kendo-grid-column field="LengthStacked" title="Longeur empilée" [width]="50" [minResizableWidth]="50" filter="numeric"></kendo-grid-column>

      <!-- WidthStacked -->
      <kendo-grid-column field="WidthStacked" title="Largeur empilée" [width]="50" [minResizableWidth]="50" filter="numeric"></kendo-grid-column>

      <!-- HeightStacked -->
      <kendo-grid-column field="HeightStacked" title="Hauteur empilée" [width]="50" [minResizableWidth]="50" filter="numeric"></kendo-grid-column>
    </kendo-grid>
  </div>
  <app-product-combo-form
    #myChildForm
    *ngIf="showChildForm"
    [currentItem]="selectedItem"
    class="formpage-container"
    (submitEmitterChildHaveChanges)="onChangeChildState($event)"
    (submitEmitterForceRefresh)="OnChildAskForForceRefresh($event)"
    (submitEmitterCanCloseChildForm)="OnChildFormVisibilityChanged(!$event)"
  ></app-product-combo-form>
</div>
