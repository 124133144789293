export class InvoicesAPProcessingOptions {
  dateStart?: Date;
  dateEnd?: Date;

  AllowERPInvoiceAPCreation: boolean;
  AllowSupplierDataExtraction: boolean;
  SupplierCodeList: string[];

  ReprocessSpecificInvoicesById: number[];

  constructor() {}
}
