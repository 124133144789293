export class PickListDetail {
  public PickListDetailId: number;
  public PickListHeaderId: number;
  public SplittingIdentifier: number;
  public StartDate: Date;
  public EndDate: Date;
  public QuantityToPick: number;
  public QuantityPicked: number;
  public TotalQtyOnHandInStore: number;
  public TotalQtyOnHandOutStore: number;
  public TotalReadyToGo: number;
  public OrderNumber: string;
  public ProductNumber: string;
  public OriginalCAWarehouse: string;
  public Ctrl_DateInserted: Date;
  public Ctrl_InfoInserted: string;
  public Ctrl_DateUpdated: Date;
  public Ctrl_InfoUpdated: string;
}
