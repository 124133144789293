// basic angular modules
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// interceptor
import { GlobalHttpInterceptor } from './interceptor/GlobalHttp.interceptor';

// Pieuvre modules
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { logByPinFormComponent } from './component/auth/logByPin/logByPin.component';
import { LoginUserPwdComponent } from './component/auth/logUserPwd/LoginUserPwd.component';
import { WorkstationFormComponent } from './component/auth/workstation-form/workstation-form.component';
import { WorkstationListComponent } from './component/auth/workstation-list/workstation-list.component';
import { UsagerFormComponent } from './component/auth/usager-form/usager-form.component';
import { UsagerListComponent } from './component/auth/usager-list/usager-list.component';

import { Scan1Component } from './component/scan1/scan1.component';
import { Scan2EmballeurComponent } from './component/scan2-emballeur/scan2-emballeur.component';
import { OnlineOrderListComponent } from './component/OnlineOrders/online-order-list/online-order-list.component';

import { SidenavComponent } from './component/core/sidenav/sidenav.component';
import { NavbarComponent } from './component/core/navbar/navbar.component';

import { JwtModule } from '@auth0/angular-jwt';
import { LocalStorage } from './models/enum/LocalStorage';
import { ReceptionHeaderComponent } from './component/reception/reception-header/reception-header.component';
import { ReceptionDetailComponent } from './component/reception/reception-detail/reception-detail.component';
import { ReceptionTraceComponent } from './component/reception/reception-trace/reception-trace.component';
import { LoaderComponent } from './component/core/loader/loader.component';
import { PopConfirmComponent } from './component/core/pop-confirm/pop-confirm.component';
import { AccueilComponent } from './component/core/accueil/accueil.component';
import { DialogProblemComponent } from './component/problem/dialog-problem/dialog-problem.component';
import { RapportReclamationHome } from './component/reception/rapport-reclamation-home/rapport-reclamation-home';

import { PackageTypesFormComponent } from './component/package-types/package-types-form/package-types-form.component';
import { PackageTypesListComponent } from './component/package-types/package-types-list/package-types-list.component';
import { ScanProblemTypesFormComponent } from './component/scanProblemTypes/scanProblemTypes-form/scanProblemTypes-form.component';
import { ScanProblemTypesListComponent } from './component/scanProblemTypes/scanProblemTypes-list/scanProblemTypes-list.component';

import { AuditlogsListComponent } from './component/auth/report/auditlogs-list/auditlogs-list.component';
import { UsersesssionsListComponent } from './component/auth/report/usersesssions-list/usersesssions-list.component';
import { WorkstationSelectionComponent } from './component/auth/workstation-selection/workstation-selection.component';
import { ProductSyncOverviewComponent } from './module/systemManager/reports/ProductSyncOverview/ProductSyncOverview.component';
import { RapportReceptionAcombaComponent } from './component/reception/rapport-reception-acomba/rapport-reception-acomba';
import { OerpAdminPanelComponent } from './component/backendERP/oerp-admin-panel/oerp-admin-panel.component';

import { SystemConfigurationComponent } from './module/systemManager/system-configuration/system-configuration.component';
import { HhfileGetInfoSommaireH01Component } from './component/home-hardware/hhfile-get-info-sommaire-h01/hhfile-get-info-sommaire-h01.component';
import { hhfileH01GetDetails } from './component/home-hardware/hhfile-h01-get-details/hhfile-h01-get-details.component';
import { hhfileLogExecution } from './component/home-hardware/hhfile-get-log-execution/hhfile-get-log-execution.component';
import { ErrorHandlerService } from './service/core/error-handler.service';
import { RapportExpeditionDvlComponent } from './component/reception/rapport-expedition-dvl/rapport-expedition-dvl.component';
import { DialogSelectionDvlComponent } from './component/scan2-emballeur/dialog-selection-dvl/dialog-selection-dvl.component';
import { TestPrintComponent } from './test-print/test-print.component';
import { hhConsumerNumberMappingListComponent } from './component/hh-consumer-number-mapping/hh-consumer-number-mapping-list/hh-consumer-number-mapping-list.component';
import { hhConsumerNumberMappingFormComponent } from './component/hh-consumer-number-mapping/hh-consumer-number-mapping-form/hh-consumer-number-mapping-form.component';

import { SelectionExpeditionComponent } from './component/expedition/achat-labels/selection-expedition/selection-expedition.component';
import { RapportFacturationAcombaComponent } from './component/facturationAcomba/rapport-facturation-acomba/rapport-facturation-acomba.component';
import { RapportFacturationSommaireAcombaComponent } from './component/facturationAcomba/rapport-facturation-sommaire-acomba/rapport-facturation-sommaire-acomba.component';
import { OnlineOrderFinancialDetailComponent } from './module/order/order-financial-detail/order-financial-detail.component';
import { OrdersProfitCalculationDetail } from './module/order/models/ordersProfitCalculationDetail.model';
import { UserDefinedRatesListComponent } from './component/userDefinedRates/rates-list/rates-list.component';
import { RatesFormComponent } from './component/userDefinedRates/rates-form/rates-form.component';
import { PickListHeaderListComponent } from './component/pickList/picklist-header-list/picklist-header-list.component';
import { PickListHeaderFormComponent } from './component/pickList/picklist-header-form/picklist-header-form.component';
import { PickListDetailListComponent } from './component/pickList/picklist-detail-list/picklist-detail-list.component';
import { PickListDetailFormComponent } from './component/pickList/picklist-detail-form/picklist-detail-form.component';
import { PickListSummarizedListComponent } from './component/pickList/picklist-summarized-list/picklist-summarized-list.component';
import { PickListSummarizedFormComponent } from './component/pickList/picklist-summarized-form/picklist-summarized-form.component';
import { InvoicesAPListComponent } from './component/supplier/InvoiceAP/invoiceAP-List/invoices-ap-list/invoices-ap-list.component';
import { ProductComboListComponent } from './component/productCombo/product-combo-list/product-combo-list.component';
import { ProductComboFormComponent } from './component/productCombo/product-combo-form/product-combo-form.component';
import { appShippingPurchaseOverviewComponent } from './module/shipping/components/app-shipping-purchase-overview/app-shipping-purchase-overview.component';

//PIPE Customer
import { PoidsEnLivresPipe } from './module/shipping/pipe/poids-en-livres.pipe';
import { AgeCommandeAwaitingShipmentPipe } from './component/expedition/achat-labels/pipe/age-commande-awaiting-shipment.pipe';
import { DimensionsEnPoucesPipe } from './module/shipping/pipe/dimensions-en-pouces.pipe';
import { AgeCommandePipe } from './pipe/age-commande.pipe';
import { FormatDatePipe } from './pipe/format-date.pipe';
import { shippingCalcDetailsCalculProfitPipe } from './module/shipping/pipe/shippingCalc-detailsCalculProfit.pipe';
import { convertStringToShipstationShipmentPipe } from './module/shipping/pipe/convertStringToShipstationShipmentPipe.pipe';
import { ShippingCalcGetErrorPrettierPipe } from './module/shipping/pipe/ShippingCalc-GetErrorPrettier.pipe';

// Kendo
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DropDownListFilterComponent } from './component/core/dropdownlist-filter/dropdownlist-filter.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslationManagementService } from './service/translate.service';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import '@progress/kendo-angular-intl/locales/fr-CA/all';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { CarrierShipmentInvoicesSummary } from './module/shipping/models/CarrierShipmentInvoicesSummary.model';
import { CarrierShipmentInvoicesSummaryComponent } from './module/shipping/components/carrier-shipment-invoices-summary.component/carrier-shipment-invoices-summary.component';
import { Scan1Component_OldBefore202405 } from './component/scan1.oldV2Before202405/scan1-oldV2Before202405.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    NavbarComponent,
    Scan1Component,
    Scan1Component_OldBefore202405,
    Scan2EmballeurComponent,
    OnlineOrderListComponent,
    logByPinFormComponent,
    LoginUserPwdComponent,
    PopConfirmComponent,
    ReceptionHeaderComponent,
    ReceptionDetailComponent,
    //FormatDatePipe,
    convertStringToShipstationShipmentPipe,
    ReceptionTraceComponent,
    LoaderComponent,
    AccueilComponent,
    DialogProblemComponent,
    RapportReclamationHome,
    WorkstationFormComponent,
    WorkstationListComponent,
    UsagerFormComponent,
    UsagerListComponent,
    PackageTypesFormComponent,
    PackageTypesListComponent,
    ScanProblemTypesFormComponent,
    ScanProblemTypesListComponent,
    AuditlogsListComponent,
    UsersesssionsListComponent,
    WorkstationSelectionComponent,
    CarrierShipmentInvoicesSummaryComponent,
    //SystemManagerReportBatchExecSummary,
    //SystemManagerReportBatchExecDetail,
    //SystemManagerReportLogExecSummary,
    //SystemManagerReportLogExecDetail,
    ProductSyncOverviewComponent,
    RapportReceptionAcombaComponent,
    AgeCommandePipe,
    OerpAdminPanelComponent,
    //SystemConfigurationComponent,
    HhfileGetInfoSommaireH01Component,
    hhfileH01GetDetails,
    hhfileLogExecution,
    RapportExpeditionDvlComponent,
    DialogSelectionDvlComponent,
    TestPrintComponent,
    hhConsumerNumberMappingListComponent,
    hhConsumerNumberMappingFormComponent,
    SelectionExpeditionComponent,
    PoidsEnLivresPipe,
    shippingCalcDetailsCalculProfitPipe,
    AgeCommandeAwaitingShipmentPipe,
    DimensionsEnPoucesPipe,
    ShippingCalcGetErrorPrettierPipe,
    DropDownListFilterComponent,
    RapportFacturationAcombaComponent,
    RapportFacturationSommaireAcombaComponent,
    OnlineOrderFinancialDetailComponent,
    UserDefinedRatesListComponent,
    RatesFormComponent,
    PickListHeaderFormComponent,
    PickListHeaderListComponent,
    PickListDetailListComponent,
    PickListDetailFormComponent,
    PickListSummarizedListComponent,
    PickListSummarizedFormComponent,
    InvoicesAPListComponent,
    ProductComboListComponent,
    ProductComboFormComponent,
    appShippingPurchaseOverviewComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    GridModule,
    InputsModule,
    SharedModule,
    JwtModule.forRoot({
      // Ajoute le token JWT automatiquement lors de l'envoie d'une rqt HTTP
      config: {
        tokenGetter: () => {
          return localStorage.getItem(LocalStorage.JWT); // récupère le token xxxx.xxxx.xxxx
        },
        // indique les domaines sur lesquel ajouter le Authorization:Bearer JWT
        allowedDomains: ['dev1.api.mdpweb.co', 'preprod.api.mdpweb.co', 'prod.api.mdpweb.co', 'staging1.api.mdpweb.co', 'localhost:44322'],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: MessageService,
      useClass: TranslationManagementService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
