import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, take, Subscription } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { BackendErpService } from 'src/app/service/backend-erp.service';
import { FacturationAcombaService } from 'src/app/service/facturationAcomba.service';

@Component({
  selector: 'app-rapport-facturation-sommaire-acomba',
  templateUrl: './rapport-facturation-sommaire-acomba.component.html',
  styleUrls: ['./rapport-facturation-sommaire-acomba.component.scss'],
})
export class RapportFacturationSommaireAcombaComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid!: GridComponent;
  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  public isLoading = false;
  public totalItemCount = 0;

  public initSub: Subscription | null = null;
  public refreshSub: Subscription | null = null;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private facturationAcombaService: FacturationAcombaService,
    private location: Location,
    private utilService: UtilService
  ) {}

  TitreGlobalPage: string = 'Rapport Facturation Sommaire Acomba';
  IconGlobalPage: string = 'local_shipping';

  ngOnInit(): void {
    // subscribe to data
    this.initSub = this.facturationAcombaService.GetMismatchDVLOrderToERPInvoiceByMonth().subscribe((response) => {
      let orderedResponse = response.sort((a, b) => b.SS_OrderMonth - a.SS_OrderMonth);
      // set grid data
      this.totalItemCount = response.length;
      this.gridView = {
        data: orderedResponse,
        total: response.length,
      };
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) this.refreshSub.unsubscribe();
    if (this.initSub) this.initSub.unsubscribe();
  }

  dataStateChange() {
    this.removeQueryParams();
    this.reloadQueryParams();
  }

  removeQueryParams() {
    let currentUrl = this.location.path();
    let updatedUrl = currentUrl.split(';')[0]; // Remove the query parameters
    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  reloadQueryParams() {
    let updatedUrl = `${this.location.path()}`;

    if (updatedUrl.charAt(updatedUrl.length - 1) === ';') {
      updatedUrl = updatedUrl.substring(0, updatedUrl.length - 1);
    }

    this.location.replaceState(updatedUrl); // Replace the current URL with the updated URL
  }

  clearFilters(): void {}
}
