export class hhConsumerNumberMapping {
  id: number;
  HHCustCodeFrom: number;
  HHCustCodeTO: number;
  IsScan2EmballeurEnabled: boolean;
  ProcessPriority: number;
  IsOnlineSales: boolean;
  PieuvreDestinationCode: string;
  PieuvreDestinationSubCode: string;
  InfoCustomerCode: string;
  SpecialMessageToShow: string;
}
