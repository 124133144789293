import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UtilService } from './util.service';
import { Observable, tap, ReplaySubject, map, switchMap, of } from 'rxjs';
import { toODataString, State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { PickListHeader } from '../models/PickList/picklist-header';
import { PickListDetail } from '../models/PickList/picklist-detail';
import { PopUpDialogService } from './popUpDialog.service';
import { PickListSummarized } from '../models/PickList/picklist-summarized';

@Injectable({
  providedIn: 'root',
})
export class PicklistService {
  constructor(private httpClient: HttpClient, private utilService: UtilService, private popUpDialogService: PopUpDialogService) {}

  private BASE_URL = `${environment.apiUrl}`;

  public PickListHeaders: ReplaySubject<any> = new ReplaySubject<any>(1);
  public PickListDetails: ReplaySubject<any> = new ReplaySubject<any>(1);
  // ODATA : STATE

  odata_PickListHeader(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}PickListHeader?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.PickListHeaders.next(response);
      })
    );
  }

  odata_PickListDetails(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}PickListDetail?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.PickListDetails.next(response);
      })
    );
  }

  getHeader(id: number): Observable<PickListHeader> {
    let uri = `${this.BASE_URL}PickListHeader?$filter=PickListHeaderId eq ${id}`;
    return this.httpClient.get<PickListHeader>(uri).pipe(map((x: any) => x.value[0]));
  }

  getDetail(id: number): Observable<PickListDetail> {
    let uri = `${this.BASE_URL}PickListDetail?filter=PickListDetailId eq ${id}`;
    return this.httpClient.get<PickListDetail>(uri).pipe(map((x: any) => x.value[0]));
  }

  getDetailsSummarized(picklistHeaderId: number): Observable<PickListSummarized[]> {
    let uri = `${this.BASE_URL}PickListDetail/GetDetailsSummarized?PickListHeaderId=${picklistHeaderId}`;
    return this.httpClient.get<PickListSummarized[]>(uri).pipe(
      tap((data) => {
        data.forEach((lineOfData) => {
          lineOfData.LineIsUnfinished = false; // On rajoute la colonne sur le fly car cela n'existe pas dans l<api
        });
      })
    );
  }

  getRemainingReadyToGoFromPickList(picklistHeaderId: number): Observable<string[]> {
    let uri = `${this.BASE_URL}PickListDetail/GetRemainingReadyToGoFromPickList?PickListHeaderId=${picklistHeaderId}`;
    return this.httpClient.get<string[]>(uri);
  }

  sendPickListToClickNCollect(picklistHeaderId: number): Observable<any> {
    let uri = `${this.BASE_URL}PickListDetail/SendPickListToClickNCollect?PickListHeaderId=${picklistHeaderId}`;
    return this.httpClient.put(uri, {});
  }

  create(NumberOfSplits: number, OutputType: string): Observable<PickListHeader> {
    let uri = `${this.BASE_URL}PickListHeader/CreateNewPickList?NumberOfSplits=${NumberOfSplits}&OutputType=${OutputType}`;

    return this.httpClient
      .post(uri, {}, { observe: 'response' }) //on transforme la réponse en réponse HTTP pour observer le statut
      .pipe(
        switchMap((response: HttpResponse<PickListHeader>) => {
          // l'API peut retourner des 204 No Content si aucun produit satisfait les critères minimales de génération d'une picklist
          if (response.status === 204) {
            this.popUpDialogService.SimpleErrorAlert("Aucun produit correspond aux critères de génération d'une PickList", "Aucune PickList n'a été créée");
            return of(new PickListHeader()); // On retourne un header vide car un no content contient rien
          }
          //si on se rend ici alors on a eu une réponse 200 OK et on retoune le corps de la réponse HTTP qui contient l'info qu'on veut
          else return of(response.body);
        })
      );
  }

  updateDetail(_PickListDetail: PickListDetail): Observable<PickListDetail> {
    let uri = `${this.BASE_URL}PickListDetail/UpdateDetail`;
    return this.httpClient.put<PickListDetail>(uri, _PickListDetail);
  }

  updateHeader(_PicklistHeader: PickListHeader): Observable<PickListHeader> {
    let uri = `${this.BASE_URL}PickListHeader/Update`;
    return this.httpClient.put<PickListHeader>(uri, {
      PickListHeaderID: _PicklistHeader.PickListHeaderID,
      NumberOfSplits: _PicklistHeader.NumberOfSplits,
      OutputType: _PicklistHeader.OutputType,
    });
  }
}
