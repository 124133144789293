<div class="kbd-bg" style="margin-top: 5em">
  <div class="kbd-container">
    <div class="kbd-titre"></div>
    <h2>Saisir votre station de travail</h2>

    <div class="grid-container">
      <div *ngFor="let item of listExistingWorkstation; index as i">
        <button mat-stroked-button color="primary" type="button" style="width: 200px; height: 100px" (click)="selectWorkstation(item.id)">
          <span>{{ item.WorkStationCode }} </span>
          <p class="kbd-statut-container" *ngIf="!item.CurrentLoggedUser_StartTime" style="background-color: chartreuse">LIBRE</p>
          <p
            class="kbd-statut-container"
            *ngIf="item.CurrentLoggedUser_StartTime && item.CurrentLoggedUser_UserCode !== this.authService.info.name"
            style="background-color: red"
          >
            OCCUPÉ
          </p>
          <p
            class="kbd-statut-container"
            *ngIf="item.CurrentLoggedUser_StartTime && item.CurrentLoggedUser_UserCode === this.authService.info.name"
            style="background-color: goldenrod"
          >
            VOUS EST ASSIGNÉ
          </p>
        </button>
      </div>
    </div>

    <div>
      <button mat-flat-button color="primary" type="button" (click)="ChooseNoWorkstation()">
        <mat-icon>check</mat-icon>
        Aucune station de travail
      </button>
    </div>
  </div>
</div>
