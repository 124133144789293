import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PickListSummarized } from 'src/app/models/PickList/picklist-summarized';

@Component({
  selector: 'app-picklist-summarized-form',
  templateUrl: './picklist-summarized-form.component.html',
  styleUrls: ['./picklist-summarized-form.component.scss'],
})
export class PickListSummarizedFormComponent implements OnInit {
  @Input() currentItem: PickListSummarized;
  @Output() ShowForm = new EventEmitter<boolean>();

  @Output() submitEmitterCanCloseChildForm = new EventEmitter<any>(); //event permettant d'indiquer que l'enfant (ici) veut se fermer

  titre: string = 'Consultatiom';
  NomEntiteCourante: string = 'Picklist Sommarisée';
  IsChangesPublished: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  annuler() {
    this.cacherForm();
  }

  cacherForm(): void {
    this.submitEmitterCanCloseChildForm.emit(true); //on raise le Event permettant de demander de se faire fermer
  }
}
