import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient, private dialog: MatDialog) {}

  /**
   * Détermine si un string est un UPC ou un sku
   * @param input entrée utilsateur
   */
  isUpc(input: string): boolean {
    return !isNaN(Number(input)) && input.length >= 10;
  }

  /**
   * Effectue un GET API pour récupérer un sku à partir du code barre
   * @param upc code barre
   */
  getNoProductFromUpc(upc: string): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.get(environment.apiUrl + 'Products/GetSkuByUPC', { params: { BarCode: upc } });
  }

  ///permet de décoder un produit multipack et retourner les infos associée...
  public ProductCleanser(OrigSku: string): any {
    //si code produit est vide, ou pas 9 de long, on retourne tout de suite...
    if (OrigSku === undefined || OrigSku === '' || OrigSku.length < 9) return { CleanedSku: OrigSku, QtyMultiple: 1 };

    //on regarde si le sku a la forme 9999999x999
    if (OrigSku.substring(7, 8) === 'x') {
      var realSku = OrigSku.substring(0, 7);
      if (isNaN(+realSku) === false) {
        var Qty = OrigSku.substring(8, 999);
        if (isNaN(+Qty) === false) {
          return { CleanedSku: realSku, QtyMultiple: Number(Qty) };
        }
      }
    }

    return { CleanedSku: OrigSku, QtyMultiple: 1 };
  }

  /**
   * Effectue un GET API pour récupérer le détail d'un produit
   * @param sku le code produit
   */
  GetProductDetail(sku: string): Observable<any> {
    // vérifie que le login de l'utilisateur est toujours valide
    const checkLogin = AuthService.checkIfLoginExpired();
    if (checkLogin !== null) {
      //return checkLogin;
    }

    return this.httpClient.get(environment.apiUrl + 'Products/GetProductDetail', { params: { param_SKU: sku } });
  }
}
