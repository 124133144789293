import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { toODataString } from '@progress/kendo-data-query';

import { environment } from 'src/environments/environment';
import { State } from '@progress/kendo-data-query';
import { UtilService } from 'src/app/service/util.service';
import { OrderExtendedInfo } from 'src/app/module/order/models/OrderExtendedInfo.model';
import { OrderShippingTrace } from 'src/app/module/shipping/models/OrderShippingTrace.model';

@Injectable({
  providedIn: 'root',
})
export class OrderFinanceService {
  public OrderFinanceDetailsData: ReplaySubject<any> = new ReplaySubject<any>(1);
  public OrderExtendedInfo: ReplaySubject<any> = new ReplaySubject<any>(1);
  public OrderShippingTrace: ReplaySubject<any> = new ReplaySubject<any>(1);
  private BASE_URL = environment.apiUrl;

  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  // ODATA : STATE

  odata_OrderFinanceDetailsData(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let partialuri = `OrdersProfitCalculationDetail?${toODataString(_state)}&$count=true`;
    let uri = `${this.BASE_URL}${partialuri}`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        let isFinished = response.value.length;

        this.OrderFinanceDetailsData.next(response);
      })
    );
  }

  /** Cette méthode permet d'aller fetcher oData les info de la pieuvre pour les commandes
   * va feeder le BehaviorSubjet OrderExtendedInfo(),  qui lui se fait écouter par les composants
   */
  odata_OrderExtendedInfo(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let partialuri = `OrderExtendedInfo?${toODataString(_state)}&$count=true`;
    let uri = `${this.BASE_URL}${partialuri}`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        let isFinished = response.value.length;
        this.OrderExtendedInfo.next(response);
      })
    );
  }

  /** permet de retourner UNE extendedInof */
  OrderExtendedInfo_GetBySiteOrderId(SiteOrderId: string): Observable<OrderExtendedInfo> {
    let partialuri = `OrderExtendedInfo/GetBySiteOrderId?SiteOrderId=${SiteOrderId}`;
    let uri = `${this.BASE_URL}${partialuri}`;

    return this.httpClient.get<OrderExtendedInfo>(uri);
  }

  /** Cette méthode permet d'aller fetcher oData les info de la pieuvre pour les traces de shipping
   * va feeder le BehaviorSubjet OrderShippingTrace(),  qui lui se fait écouter par les composants
   */
  odata_OrderShippingTrace(state: State): Observable<OrderShippingTrace> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let partialuri = `OrderShippingTrace?${toODataString(_state)}&$count=true`;
    let uri = `${this.BASE_URL}${partialuri}`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        let isFinished = response.value.length;
        this.OrderShippingTrace.next(response);
      })
    );
  }
}
