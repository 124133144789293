<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre"><mat-icon>business_center</mat-icon> {{ titre }}</h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>Code conso. FROM</mat-label>
        <input matInput [(ngModel)]="currentItem.HHCustCodeFrom" type="number" name="HHCustCodeFrom" class="form-control" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Code conso. TO</mat-label>
        <input matInput [(ngModel)]="currentItem.HHCustCodeTO" type="number" name="HHCustCodeTO" required />
      </mat-form-field>

      <section appearance="outline">
        <mat-label></mat-label>
        <mat-checkbox name="IsOnlineSales" [(ngModel)]="currentItem.IsOnlineSales">Permettre l'assignation de DVL ?</mat-checkbox>
      </section>

      <mat-form-field appearance="outline">
        <mat-label>ProcessPriority</mat-label>
        <input matInput [(ngModel)]="currentItem.ProcessPriority" type="number" name="ProcessPriority" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Pieuvre Code Destination</mat-label>

        <mat-hint>Pour DVL, prendre 'DVL' simplement sauf avis contraire!</mat-hint>

        <mat-select [(ngModel)]="currentItem.PieuvreDestinationCode" name="PieuvreDestinationCode">
          <mat-option *ngFor="let MyDestination of listdestination" [value]="MyDestination.codeDestination">{{ MyDestination.codeDestination }}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Pieuvre Sub-Code [ignoré pour DVL]</mat-label>
        <input matInput [(ngModel)]="currentItem.PieuvreDestinationSubCode" name="PieuvreDestinationSubCode" />

        <mat-hint>Laissez VIDE pour un comportement standard. Ajoutez une valeur pour créer un regroupement distinct</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-stroked-button (click)="detruire()" [disabled]="!currentItem?.id" style="margin: 0px 2px 2px 2px">
      <mat-icon>delete</mat-icon>Supprimer
    </button>

    <div style="float: right">
      <button type="submit" matInput mat-flat-button (click)="OnSauvegardeClick()" *ngIf="!myform.pristine" color="primary" style="margin: 0px 2px 2px 2px">
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button type="submit" matInput mat-stroked-button (click)="annuler()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-stroked-button (click)="annuler()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
