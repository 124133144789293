import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LocalStorage } from '../../../models/enum/LocalStorage';
import { APIPostProblem } from '../../../models/API/APIPostProblem';
import { NumericKeyboard } from 'src/app/models/numericKeyboard';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { ScanProblemTypes } from 'src/app/models/scanProblemTypes';
import { ScanProblemTypesService } from 'src/app/service/scanProblemTypes.service';
import { Observable } from 'rxjs';
import { Station } from 'src/app/models/enum/Station';

@Component({
  selector: 'app-dialog-problem',
  templateUrl: './dialog-problem.component.html',
  styleUrls: ['./dialog-problem.component.css'],
})
export class DialogProblemComponent implements OnInit {
  displayedMessage: string = 'Merci de sélectionner un motif au problème <span >pour le produit #{{data.productNumber}} ({{data.productDescription}})</span>.';
  displayedTitle: string = 'Problème';

  // clavier numérique
  numericKeyboard: NumericKeyboard;

  ListScanProblemTypes: ScanProblemTypes[];

  constructor(
    public dialogRef: MatDialogRef<DialogProblemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: APIPostProblem,
    public matDialog: MatDialog,
    private problemService: ScanProblemTypesService
  ) {
    if (data.displayedMessage) this.displayedMessage = data.displayedMessage;

    if (data.qtyProblem === undefined) data.qtyProblem = 1;

    if (data.displayedTitle !== undefined) {
      this.displayedTitle = data.displayedTitle;
    }
  }

  ngOnInit() {
    // Initialisation du clavier numérique
    this.numericKeyboard = new NumericKeyboard(false, 'Quantité en problème');

    this.ListScanProblemTypes = this.problemService.getFromCache();

    if (this.data.fonctionOrig == Station.SCAN1)
      this.ListScanProblemTypes = this.ListScanProblemTypes.filter((x) => x.IsScan1Enabled).sort((x) => x.DisplayIndex);
    else if (this.data.fonctionOrig == Station.SCAN2_EMBALLEUR)
      this.ListScanProblemTypes = this.ListScanProblemTypes.filter((x) => x.IsScan2EmballeurEnabled).sort((x) => x.DisplayIndex);
  }

  /**
   * Permet de fermer le popup.
   */
  onAbortClick(): void {
    this.dialogRef.close(null);
  }
  onConfirmClick(): void {
    if (this.data.ProblemCode === undefined) {
      let popupResult = this.matDialog.open(PopConfirmComponent, {
        data: {
          titre: 'Problem Invalide',
          texte: 'Vous devez choisir un motif au problème.',
          mainIcon: 'warning',
          showDismissiveButton: false,
          affirmativeActionName: 'OK',
        },
      });
      return;
    }

    this.dialogRef.close(this.data);
  }
  openNumericKeyboard(): void {
    this.numericKeyboard.open(this.data.qtyProblem.toString());
  }

  scanQte_AddOne(): void {
    this.data.qtyProblem++;
  }
  scanQte_RemoveOne(): void {
    this.data.qtyProblem--;
  }
}
