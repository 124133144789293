<form #myform="ngForm" class="form-group" style="height: 100%">
  <!--Form Header-->
  <div class="formpage-header">
    <h3 class="page-titre"><mat-icon>business_center</mat-icon> {{ titre }}</h3>
  </div>

  <!--Form Body-->
  <div class="formpage-body" *ngIf="currentItem">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>Code paquet</mat-label>
        <input matInput [(ngModel)]="currentItem.PackageShortCode" name="PackageShortCode" class="form-control" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description paquet</mat-label>
        <input matInput [(ngModel)]="currentItem.PackageDescription" name="PackageDescription" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Ordre d'affichage [numérique]</mat-label>
        <input matInput [(ngModel)]="currentItem.DisplayIndex" type="number" name="DisplayIndex" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Longeur (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.LengthInch" type="number" name="LengthInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Largeur (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.WidthInch" type="number" name="WidthInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hauteur (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.HeighInch" type="number" name="HeighInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Longeur utilisable (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.UsableLengthInch" type="number" name="UsableLengthInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Largeur utilisable (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.UsableWidthInch" type="number" name="UsableWidthInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hauteur utilisable (pouces)</mat-label>
        <input matInput [(ngModel)]="currentItem.UsableHeighInch" type="number" name="UsableHeighInch" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Prix</mat-label>
        <input matInput [(ngModel)]="currentItem.PackageCost" type="number" name="PackageCost" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Poids Max (lbs)</mat-label>
        <input matInput [(ngModel)]="currentItem.MaxWeightPound" type="number" name="MaxWeightPound" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Poids Volumetric (lbs)</mat-label>
        <input matInput [(ngModel)]="currentItem.VolumetricWeight" type="number" name="VolumetricWeight" />
      </mat-form-field>

      <mat-checkbox name="IsActive" [(ngModel)]="currentItem.IsActive">Est Actif</mat-checkbox>
    </div>
  </div>

  <!--Form Footer-->
  <div class="formpage-footer">
    <button type="button" matInput mat-stroked-button (click)="detruire()" [disabled]="!currentItem?.id" style="margin: 0px 2px 2px 2px">
      <mat-icon>delete</mat-icon>Supprimer
    </button>

    <div style="float: right">
      <button type="submit" matInput mat-flat-button (click)="OnSauvegardeClick()" *ngIf="!myform.pristine" color="primary" style="margin: 0px 2px 2px 2px">
        <mat-icon>check</mat-icon>Sauvegarder
      </button>
      <button type="submit" matInput mat-stroked-button (click)="annuler()" *ngIf="myform.pristine" style="margin: 0px 2px 2px 2px">Fermer</button>
      <button type="button" matInput mat-stroked-button (click)="annuler()" *ngIf="!myform.pristine" style="margin: 0px 2px 2px 2px">Ignorer</button>
    </div>
  </div>
</form>
