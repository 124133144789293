import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';
import { LoaderState } from 'src/app/models/loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loaderState: LoaderState;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {}
  ngOnInit(): void {
    // récupère le loader subject à partir du service.
    this.subscription = this.loaderService.loaderSubject.subscribe((state: LoaderState) => {
      this.loaderState = state;
    });
  }
  ngOnDestroy(): void {
    // libère la mémoire à la fermeture
    this.subscription.unsubscribe();
  }
}
