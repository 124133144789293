import { Injectable, Injector, ErrorHandler, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopConfirmComponent } from 'src/app/component/core/pop-confirm/pop-confirm.component';
import { HttpErrorMsg } from 'src/app/models/HttpErrorMsg';
import { PopUpDialogService } from '../popUpDialog.service';

//https://www.prestonlamb.com/blog/angular-global-error-handling
//https://pkief.medium.com/global-error-handling-in-angular-ea395ce174b1

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private _injector: Injector, private dialogService: PopUpDialogService, private dialog: MatDialog, private zone: NgZone) {
    // Setup Sentry
  }

  async handleError(err: any) {
    this.zone.run(() => {
      //si c'est une erreur  issue du HttpInterceptor (donc un HtmlErrorMsg)
      let strMessage = "Détail de l'erreur : ";
      let titre = 'Une erreur est survenue';
      let iconepop = 'warning';
      let CouleurBouton = 'warn';

      let MyHttpError: HttpErrorMsg; // //on convertit l'erreur global en erreur géré par nous

      //************************************* */
      //ici, on détermine si l'erreur est issue du HttpInterceptor. soit directement, soit en promise (l'erreur est comme encapsulée sous err.rejection)
      if ((<HttpErrorMsg>err).messageErreurDetail) MyHttpError = <HttpErrorMsg>err;
      else if (err.rejection && (<HttpErrorMsg>err.rejection).messageErreurDetail) {
        MyHttpError = <HttpErrorMsg>err.rejection;
      }

      //si notre instance erreur-http est déclarée...
      if (MyHttpError) {
        strMessage += MyHttpError.messageErreurDetail;
        titre = MyHttpError.titreErreur || 'Une erreur est survenue';
        if (MyHttpError.statusCode >= 200 && MyHttpError.statusCode <= 299) {
          strMessage = 'Résultat exécution : ' + MyHttpError.messageErreurDetail;
          CouleurBouton = 'primary';
          iconepop = 'information';
        }
        //sinon, on va tenter de prendre directement le 'rejection'
      } else if (err.rejection) {
        strMessage += err.rejection;
        //sinon..on va prendre le message de l'erreur...
      } else {
        strMessage += err.message || err.Message;
      }

      console.error('Error from global error handler', titre, strMessage);

      this.dialogService.SimpleErrorAlert(strMessage, titre, iconepop, CouleurBouton);
    });
  }
}
