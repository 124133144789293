<!--Titre du right-pane - au complet () - plus bouton ajouter-->
<div class="main-page-toolbar">
  <h1 class="page-titre"><mat-icon>account_balance_wallet</mat-icon>Factures Transporteur</h1>
</div>

<div class="ss-list-container">
  <div class="ss-list-button-container"></div>
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    filterable="menu"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    class="ss-list-grid"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button mat-stroked-button color="primary" type="button" fillMode="clear" (click)="clearFilters()">
        <mat-icon>delete</mat-icon>
        Vider les filtres
      </button>
      <button type="button" class="kendo-export-excel-button" kendoGridExcelCommand>
        <mat-icon>upload_file</mat-icon>
        <span>Excel</span>
      </button>
      <p class="elements-count">{{ totalItemCount }} Élément(s) trouvé(s)</p>
    </ng-template>

    <!-- COLUMNS -->
    <kendo-grid-excel fileName="FacturesTransporteur.xlsx"></kendo-grid-excel>

    <!-- SiteOrderId -->
    <kendo-grid-column field="SiteOrderId" title="DVL #" [width]="60" [minResizableWidth]="60" filter="text"></kendo-grid-column>

    <!-- TrackingNumber -->
    <kendo-grid-column field="TrackingNumber" title="Tracking Number" [width]="60" [minResizableWidth]="60" filter="text"></kendo-grid-column>

    <!-- TrackingNumber -->
    <kendo-grid-column field="ListOfSkus" title="Skus" [width]="60" [minResizableWidth]="60" filter="text"></kendo-grid-column>

    <!-- CarrierIdentifier -->
    <kendo-grid-column field="CarrierIdentifier" title="Carr." [width]="30" [minResizableWidth]="30" filter="text"></kendo-grid-column>

    <!--Order Date-->
    <kendo-grid-column field="LastInvoiceDate" title="Dernier Fact Date" [width]="50" [minResizableWidth]="50" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.LastInvoiceDate | date : "yyyy-MM-dd" }}
      </ng-template>
    </kendo-grid-column>

    <!-- LastInvoiceNumber -->
    <kendo-grid-column field="LastInvoiceNumber" title="Dernier #Fact" [width]="40" [minResizableWidth]="60" filter="text"></kendo-grid-column>

    <!-- ShipmentAmt_Base -->
    <kendo-grid-column field="ShipmentAmt_Base" title="Cost" [width]="30" [minResizableWidth]="30" filter="numeric" format="C"></kendo-grid-column>

    <!-- ShipmentAmt_FuelSurcharge -->
    <kendo-grid-column field="ShipmentAmt_FuelSurcharge" title="Fuel$" [width]="30" [minResizableWidth]="30" filter="numeric" format="C"></kendo-grid-column>

    <!-- ShipmentAmt_Overcharge -->
    <kendo-grid-column field="ShipmentAmt_Overcharge" title="OverCharge$" [width]="30" [minResizableWidth]="30" filter="numeric" format="C"></kendo-grid-column>

    <!-- ShipmentAmt_OverchargeExplanation -->
    <kendo-grid-column
      field="ShipmentAmt_OverchargeExplanation"
      title="OverCharge explain"
      [width]="60"
      [minResizableWidth]="60"
      filter="text"
    ></kendo-grid-column>

    <!-- IsAReturn -->
    <kendo-grid-column field="IsAReturn" title="Retour?" [width]="25" [minResizableWidth]="25" filter="boolean"></kendo-grid-column>

    <!-- ShipmentAmt_Total -->
    <kendo-grid-column field="ShipmentAmt_Total" title="Carrier Total$" [width]="35" [minResizableWidth]="35" filter="numeric" format="C"></kendo-grid-column>

    <!-- ShipStationShipmentCost -->
    <kendo-grid-column field="ShipStationShipmentCost" title="SS Cost$" [width]="30" [minResizableWidth]="30" filter="numeric" format="C"></kendo-grid-column>

    <!-- Shipstation_vs_EDIInvoices_Amt -->
    <kendo-grid-column
      field="Shipstation_vs_EDIInvoices_Amt"
      title="Diff$"
      [width]="30"
      [minResizableWidth]="30"
      filter="numeric"
      format="C"
    ></kendo-grid-column>

    <!-- Shipstation_vs_EDIInvoices_pct -->
    <kendo-grid-column
      field="Shipstation_vs_EDIInvoices_Pct"
      title="Diff%"
      [width]="35"
      [minResizableWidth]="35"
      filter="numeric"
      format="{0:P0}"
    ></kendo-grid-column>

    <!-- Lien Shipstation -->
    <kendo-grid-column title="Ship Station" [width]="30" [minResizableWidth]="30" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a mat-stroked-button target="_blank" href="https://ship11.shipstation.com/orders/all-orders-search-result?quickSearch={{ dataItem.SiteOrderId }}">
          <mat-icon>shopping_cart</mat-icon>Voir dans Shipstation
        </a>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
