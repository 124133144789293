import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { OData } from 'src/app/models/oData/OData_file';
import { ReceptionHeader } from 'src/app/models/receptionHeader';
import { ReceptionService } from 'src/app/service/reception.service';
import { ListComponent } from 'src/app/component/core/list/list.component';

@Component({
  selector: 'app-usersesssions-list',
  templateUrl: './usersesssions-list.component.html',
  styleUrls: ['./usersesssions-list.component.css'],
})
export class UsersesssionsListComponent extends ListComponent {
  constructor(
    protected httpClient: HttpClient,
    public dialog: MatDialog,
    private receptionService: ReceptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(httpClient);

    this.endpoint = 'UserSessions';
    this.IsOdataEndpoint = true;
    this.autoload = false;

    this.champs = [
      {
        key: 'id',
        nom: 'id',
        type: 'number',
      },
      {
        key: 'UserName',
        nom: 'UserName',
        type: 'string',
      },
      {
        key: 'WorkStationID',
        nom: 'WorkStationID',
        type: 'number',
      },
      {
        key: 'WorkStationName',
        nom: 'WorkStationName',
        type: 'string',
      },
      {
        key: 'SessionLoggedInDate',
        nom: 'Login Date',
        type: 'datetime',
      },
      {
        key: 'SessionLoggedOutDate',
        nom: 'Login-Out Date',
        type: 'datetime',
      },
      {
        key: 'LastScanDate',
        nom: 'LastScanDate',
        type: 'datetime',
      },
      {
        key: 'Scan1_NbProcessed',
        nom: 'Scan1_NbProcessed',
        type: 'number',
      },
      {
        key: 'Scan1_NbProblems',
        nom: 'Scan1_NbProblems',
        type: 'number',
      },

      {
        key: 'Scan2Emb_NbStarted',
        nom: 'Scan2Emb_NbStarted',
        type: 'number',
      },

      {
        key: 'Scan2Emb_NbFinished',
        nom: 'Scan2Emb_NbFinished',
        type: 'number',
      },
    ];
  }

  TitreGlobalPage: string = 'Liste des sessions usagers';
  IconGlobalPage: string = 'user';
  NomEntiteCourante: string = 'UserSession';

  champsDisplay: any[];

  ngOnInit() {
    super.ngOnInit();
    this.recherche.SessionLoggedInDate_DATE_FILTER_GE = this.route.snapshot.paramMap.get('SessionLoggedInDate_DATE_FILTER_GE');
    this.recherche.SessionLoggedInDate_DATE_FILTER_LE = this.route.snapshot.paramMap.get('SessionLoggedInDate_DATE_FILTER_LE');
    this.launchRefresh();
  }

  rechercher() {
    var params = [];
    if (this.recherche.SessionLoggedInDate_DATE_FILTER_GE)
      params['SessionLoggedInDate_DATE_FILTER_GE'] = this.formatDate(this.recherche.SessionLoggedInDate_DATE_FILTER_GE);
    if (this.recherche.SessionLoggedInDate_DATE_FILTER_LE)
      params['SessionLoggedInDate_DATE_FILTER_LE'] = this.formatDate(this.recherche.SessionLoggedInDate_DATE_FILTER_LE);
    this.router.navigate(['/userSessionList', params]);
    this.launchRefresh();
  }

  effacerRecherche(): void {
    this.recherche = new ReceptionHeader();
    this.orderBy = 'id desc';
  }
}
