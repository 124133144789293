import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListComponent } from 'src/app/component/core/list/list.component';
import { PopConfirmComponent } from '../../core/pop-confirm/pop-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Usager } from 'src/app/models/usager';
import { UsagerService } from '../../../service/usager.service';

@Component({
  selector: 'app-usager-list',
  templateUrl: './usager-list.component.html',
  styleUrls: ['./usager-list.component.scss'],
})
export class UsagerListComponent extends ListComponent {
  showChildForm: boolean = true;

  selectedItem: any; //représente l'entité actuellement sélectionnée

  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...

  childHaveChanges: boolean; //indique si le panneau ENFANT contient une modification
  needRefresh: boolean = false;

  TitreGlobalPage: string = 'Gestion de usagers';
  NomEntiteCourante: string = 'usager';

  constructor(protected httpClient: HttpClient, private dialog: MatDialog, private usagerService: UsagerService, private cdRef: ChangeDetectorRef) {
    super(httpClient);
    this.endpoint = 'Auth/GetList';

    this.autoload = true;
    this.IsOdataEndpoint = false;
    this.rechercheOverrideGlobalSearchFilterFields = ['Id', 'PhoneNumber', 'Email', 'UserName'];

    this.champs = [
      {
        key: 'UserName',
        nom: 'UserName',
        type: 'string',
      },
      {
        key: 'Email',
        nom: 'Email',
        type: 'string',
      },
      {
        key: 'PhoneNumber',
        nom: 'PhoneNumber',
        type: 'string',
      },
      {
        key: 'IsEnabled',
        nom: 'Actif?',
        type: 'boolean',
      },
    ];
    this.defaultOrderBy = 'UserName asc';

    //Override du dataSource pour permettre le filtrage sur le champ IsEnabled
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      return String(data.IsEnabled).toLowerCase().includes(filter);
    };
  }

  private defaultDataSourceFilter = this.dataSource.filter;

  openForm(MyRow: Usager) {
    this.selectedItem = MyRow;

    this.showChildForm = true;
  }

  //evenement, issue du child, indiquant qu'on veut afficher ou masquer le FORM
  OnChildFormVisibilityChanged(val: boolean): void {
    if (val === false && this.childHaveChanges === true) this.popUpConfirmDiscard();
    else this.showChildForm = val;

    if (this.needRefresh === true) {
      this.launchRefresh();
      this.needRefresh = false;
    }
  }

  onChangeChildState(val: any): void {
    this.childHaveChanges = val;
    if (val === true) this.needRefresh = true;
    this.cdRef.detectChanges(); //bidouille, car la valeur childHaveChanges pointe sur un Ngif..et que c'Est ca- https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  }

  createNew() {
    this.selectedRow = null;
    this.selectedItem = null;
    this.showChildForm = true;
  }

  applyActiveStatusFilter(value: string): void {
    if (value === 'true' || value === 'false') {
      this.dataSource.filter = value;
    } else if (value === 'reset') {
      this.dataSource.filter = this.defaultDataSourceFilter;
    }
  }

  //ici, c'est qu'on a cliqué sur le panneau de protection de 'changement détecté'.  ca permet de faire un DISCARD
  popUpConfirmDiscard() {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: 'Changements non sauvegardés',
        texte:
          'Attention! Des changements ont été détectés dans votre formulaire. <br/><br/> Voulez-vous <b>IGNORER</b> ces changements ou <b>POURSUIVRE</b> les modifications ?',
        affirmativeActionName: 'Poursuivre',
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Ignorer',
        dismissiveActionColor: 'warn',
      },
    });

    // after close dialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        return;
      }

      this.showChildForm = false;
      this.childHaveChanges = false;
    });
  }
}
